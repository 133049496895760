.paymentStripe-wrapper {
    display: flex;
    width: 100%;
    gap: 46px;
}
.paymentStrip-left-section {
    width: 60%;
    /* border: 1px solid; */
    background-color: #F4F4F4;
    padding: 20px 30px 60px 30px;
    border-radius: 18px;
}
.payment-des-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid;
    padding: 0px 0px 20px 0px;
    margin: 0px 0px 24px 0px;
}
.payment-des-container p {
    font-size: 15px;
    color: black;
    max-width: 416px;
}
.payment-form-input label{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 13px;
}
/* .ElementsApp .InputElement {
    font-size: 13px !important;
    color: #808080 !important;
} */
#cardNumber .CardNumberField-input-wrapper .InputElement {
    font-size: 13px;
}
.InputContainer .InputElement {
    position: absolute;
    top: 1px !important;
}
#cardNumber, 
#cardExpiry, 
#cardCvc , 
#Name_on_card, 
#cardholderName, #cardholder-name{
    padding: 12px 55px;
    background-color: white;
    border-radius: 41px;
    /* font-size: 18px; */
}
#cardholder-name{
    border: none;
    font-size: 15px;
}
.credit_card {
    position: absolute;
    top: 57%;
    left: 3%;
    width: 24px;
}
.payment-form-Card-inner-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin: 25px 0px;
}
.payment-form-Card-inner-wrapper .payment-form-input{
    width: 100%;
}
.paymentStrip-right-section {
    width: 40%;
}
.paymentStrip-right-section h2{
    font-size: 20px;
    margin: 0px 0px 14px 0px;
}
.paymentStrip-right-section p {
    font-size: 15px;
    color: black;
    font-weight: 500;
    /* border-bottom: 1px solid; */
    margin: 0px 0px 15px 0px;
    /* padding: 0px 0px 15px 0px; */
}
.paymentStripe-course {
    display: flex;
    justify-content: space-between;
}
.paymentStripe-course span{
    color: #797979;
    font-size: 14px;
}
.paymentStripe-course-container p {
    margin: 0px 0px 10px 0px;
    border-bottom: 1px solid #C4C4C4;
    padding: 0px 0px 10px 0px;
}
.paymentStripe-course-folder {
    height: 98px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: scroll;
    padding: 0px 4px 0px 0px;
}
.paymentStrip-price-folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    border-bottom: 1px dotted #C4C4C4;
    padding: 20px 0px;
    border-top: 1px solid #C4C4C4;
}
/* .paymentStrip-price-folder span, 
.paymentStrip-price-totle-folder span{
    width: 50%;
} */
.paymentStrip-price-totle-folder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}
.promo-code-btn{
    color: #0068FF;
    font-size: 14px;
    margin-bottom: 25px;
    background-color: transparent;
    border: none;
}
.payment-proceed-to-pay{
    width: 100%;
    padding: 9px 0px;
    font-size: 13px;
    background-color: #0068FF;
    color: white;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 3px #a7a7a7;
    margin: 0px 0px 20px 0px;
    position: relative;
    height: 36px;
}
.payment-support-des {
    font-size: 13px !important;
    color: black;
    max-width: 349px;
    border: none !important;
    padding: 0px !important;
}
.paymentStrip-price-totle-folder span{
    font-weight: 700;
    font-size: 17px;
}
.payment-visa-image-folder {
    display: flex;
    align-items: center;
    gap: 17px;
}
.payment-visa-image{
    width: 35px;
    height: 22px;
}
.payment-form-input {
    display: flex;
    flex-direction: column;
    position: relative;
}
.payment-form-Card-inner-wrapper .payment-form-input .credit_card {
    position: absolute;
    top: 57%;
    left: 6%;
}
.paymentStripe-course span span i {
    color: black;
    margin-right: 7px;
    font-size: 13px;
    cursor: pointer;
}
.paymentStrip-price-folder span{
    font-weight: 600;
}
.paymentStripe-course-no-courses-found {
    margin: 0px;
    border-bottom: 0px;
    padding: 0px 0px 10px 0px;
    font-size: 13px;
    color: #909090;
}
.payment-stripe-payment-popup i{
    position: absolute;
    top: 165px;
    left: 0px;
    right: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: white;
    z-index: 116;
}

@media only screen and (min-width: 830px) and (max-width:  900px){
    .paymentStrip-left-section {
        width: 60%;
        padding: 15px 30px 31px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .paymentStripe-wrapper {
        display: flex;
        flex-direction: column;
        gap: 23px;
        margin: 20px 0px 0px 0px;
    }
    .paymentStrip-left-section {
        width: 100%;
        padding: 20px 20px;
    }
    .paymentStrip-right-section{
        width: 100%;
    }
    .payment-des-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid;
        padding: 0px 0px 15px 0px;
        margin: 0px 0px 15px 0px;
    }
    .payment-des-container p {
        font-size: 12px;
    }
    .payment-visa-image {
        width: 29px;
        height: 22px;
    }
    .payment-form-input label {
        font-size: 12px;
        margin-bottom: 7px;
    }
    .payment-support-des {
        font-size: 11px !important;
    }
    .payment-proceed-to-pay {
        width: 100%;
        padding: 9px 0px;
        font-size: 11px;
        margin: 0px 0px 12px 0px;
    }
    .promo-code-btn {
        font-size: 13px;
        margin-bottom: 14px;
    }
    .paymentStrip-price-folder span{
        font-size: 13px;
    }
    .paymentStrip-price-totle-folder span {
        font-size: 16px;
    }
    .paymentStrip-right-section h2 {
        font-size: 17px;
        margin: 0px 0px 8px 0px;
    }
    .paymentStrip-right-section p {
        font-size: 14px;
        margin: 0px 0px 15px 0px;
        padding: 0px 0px 15px 0px;
    }
    .paymentStrip-price-totle-folder span {
        font-size: 14px;
    }
    .paymentStrip-right-section h2 {
        font-size: 15px;
    }       
    .paymentStrip-right-section p {
        font-size: 12px;
    }
    .paymentStripe-course span {
        font-size: 13px;
    }
    .payment-des-container {
        display: flex;
        gap: 6px !important;    
    }
    .credit_card {
        position: absolute;
        top: 55%;
        left: 4%;
        width: 18px;
    }
    .payment-form-Card-inner-wrapper .payment-form-input .credit_card {
        position: absolute;
        top: 55%;
        left: 7%;
    }
    #cardNumber, 
    #cardExpiry, 
    #cardholder-name, 
    #cardCvc{
        padding: 12px 33px;
    }
    .payment-form-Card-inner-wrapper {
        margin: 13px 0px;
    }
    .ElementsApp, .ElementsApp .InputElement {
        font-size: 14px !important;
        margin-top: 0.9px !important;
    }
    #cardholder-name {
        font-size: 13px;
    }
}

@media only screen and (max-width: 768px){
    .payment-des-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 17px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .paymentStrip-left-section {
        width: 65%;
    }
    .paymentStrip-right-section {
        width: 40%;
    }
    .payment-des-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
    }
    .payment-form-input label {
        font-size: 12px;
    }
    .paymentStripe-course span {
        font-size: 11px;
    }
    .paymentStrip-right-section h2 {
        font-size: 14px;
        margin: 0px 0px 6px 0px;
    }
    .paymentStrip-right-section p {
        font-size: 12px;
    }
    .paymentStrip-price-folder span {
        font-size: 13px;
    }
    .paymentStrip-price-totle-folder span {
        font-size: 14px;
    }
    .paymentStripe-wrapper {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    .marketplace-shopping-cart-left-container {
        overflow-y: scroll;
        height: 100vh;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    #cardCvc {
        padding: 12px 7px 12px 29px;
    }
    .payment-des-container {
        display: flex;
        gap: 11px;
    }
    .payment-form-input label {
        font-size: 12px;
    }
    #cardExpiry{
        padding: 12px 4px 12px 27px !important;
    }
    .CardNumberField-input-wrapper .InputContainer .ElementsApp .InputElement {
        font-size: 14px !important;
    }
    #cardholder-name {
        font-size: 12px;
    }
}