.filter-dropdown .sort-popup-assignprocess{
    /* right: 224px !important; */
    right: 410px !important;
}
.Sort-dropdown .sort-popup-assignprocess{
    top: 172px !important;
    /* right: 135px !important; */
    right: 310px !important;
}   

@media only screen and (max-width: 767px){
    .search_header-container {
        margin: 15px 0px 20px 0px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end !important;
        gap: 8px !important;
    }
}