.Dashboard-Review-Card-foler {
    width: 268px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 2px 8px #efefef;
}
.Dashboard-Review-Card-teacher-id-folder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Teacher_ID{
    font-size: 14px;
    color: black;
}
.teacher_Grade {
    font-size: 14px;
    color: black;
    margin: 5px 0px 0px 0px;
    font-weight: 500;
}
.Dashboard-Review-Card-image-folder {
    text-align: center;
    position: relative;
}
.Dashboard-Review-Card-image {
    width: 75px;
    height: 75px;
    margin: 18px auto;
}
.Dashboard-Review-Card-image img{
    width: 100%;
}
.Dashboard-Review-Card-image-aword {
    position: absolute;
    top: 0%;
    left: 59px;
    right: 0%;
}
.Dashboard-Review-Card-foler h2{
    text-align: center;
    font-size: 19px;
    color: #303972;
    font-weight: 700;
    margin: 0px 0px 5px 0px;
}
.teacher-reviews {
    display: flex;
    align-items: baseline;
    gap: 4px;
    justify-content: center;
}
.teacher-reviews span, 
.teacher-reviews p{
    font-size: 14px;
}
.Dashboard-Review-subject-folder {
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0px;
    gap: 8px;
}
.Dashboard-Review-subject-folder p {
    font-size: 11px;
    border-radius: 20px;
    padding: 4px 15px;
    background-color: #CFE5FB;
    color: black;
}
.Dashboard-Review-Card-button{
    display: flex;
    margin: 0px auto;
    background-color: transparent;
    border: none;
    color: #0068FF;
    font-size: 15px;
}
.Dashboard-Review-Card-wrapper {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 20px;
    gap: 25px;
}
.seacrh-header-folder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.Select-organization-dropdown-menu-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000045;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}
.Select-organization-dropdown-menu-container {
    background-color: white;
    width: 481px;
    padding: 32px;
    border-radius: 13px;
}
.Select-organization-dropdown-serach {
    position: relative;
    margin: 20px 0px;
}
.Select-organization-dropdown-serach input {
    width: 100%;
    padding: 10px 41px;
    font-size: 13px;
    border-radius: 8px;
    outline: none;
    border: 1px solid;
}
.Select-organization-dropdown-serach span {
    position: absolute;
    top: 10px;
    left: 16px;
}
.Select-organization-dropdown-inner-wrapper {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 197px;
    overflow-y: scroll;
}
.Select-organization-dropdown-inner-folder {
    display: flex;
    align-items: center;
    gap: 10px;
}
.Select-organization-dropdown-inner-folder input{
    width: 16px;
    height: 16px;
}
.Select-organization-dropdown-inner-folder label{
    font-size: 14px;
    font-weight: 500;
    color: black;
}
.Select-organization-dropdown-btn {
    display: flex;
    justify-content: center;
    gap: 13px;
    margin: 30px 0px 25px 0px;
}
.Select-organization-dropdown-btn button{
    font-size: 13px;
    padding: 7px 30px;
    border-radius: 6px;
    border: 1px solid #0068FF;
    background-color: transparent;
    color: #0068FF;
}
.Select-organization-dropdown-btn button:nth-child(1){
    background-color: #0068FF;
    color: white;
    border: none;
}
.Select-organization-dropdown-menu-popup-cross-btn i {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    top: 11%;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    background-color: white;
}
.Dashboard-Review-Card-toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}