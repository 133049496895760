.Linked-Accounts-connection-section h2{
    font-size: 19px;
    margin: 20px 0px 23px 0px;
}
.Linked-Accounts-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 66%;
    gap: 17px;
    margin: 0px 0px 0px 24px;
}
.Linked-Accounts-container {
    display: flex;
    align-items: center;
    gap: 42px;
}
.Linked-Accounts-folder {
    display: flex;
    align-items: center;
    gap: 12px;
}
.Linked-Accounts-folder p {
    font-size: 14px;
    color: black;
    font-weight: 500;
    width: 157px;
}
.Linked-Accounts-container button {
    color: #0068FF;
    background-color: transparent;
    border: 2px solid #0068FF;
    font-size: 12px;
    padding: 7px 21px;
    border-radius: 6px;
    font-weight: 500;
}
.Linked-Accounts-folder-icon {
    width: 25px;
}
.Linked-Accounts-connection-section {
    display: none;
}

@media only screen and (max-width: 767px){
    .Linked-Accounts-folder p {
        font-size: 11px;
        width: 123px;
    }
    .Linked-Accounts-folder-icon {
        width: 16px;
    }
    .Linked-Accounts-container button {
        border: 1px solid #0068FF;
        font-size: 10px;
        padding: 6px 11px;
    }
    .Linked-Accounts-wrapper {
        display: flex;
        gap: 7px;
        margin: 0px 0px 0px 0px;
    }
    .Linked-Accounts-connection-section h2 {
        font-size: 13px;
        margin: 13px 0px 12px 0px;
    }
    .Linked-Accounts-container {
        display: flex;
        gap: 20px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .Linked-Accounts-wrapper {
        display: flex;
        gap: 16px;
        margin: 0px 0px 0px 0px;
        width: 100%;
    }
    .Linked-Accounts-container {
        display: flex;
        gap: 10px;
    }
    .Linked-Accounts-container button {
        font-size: 11px;
        padding: 6px 11px;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .Linked-Accounts-container {
        display: flex;
        gap: 5px;
    }    
    .Linked-Accounts-folder {
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .Linked-Accounts-folder p {
        font-size: 10px;
        width: 97px;
    }
    .Linked-Accounts-container button {
        font-size: 8px;
        padding: 4px 6px;
    }
}