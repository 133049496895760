.Teacher-My-Course-Tab-card-wrapper {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.rate-count{
    color: #ffbb33;
}
.Republish-course-popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 114;
    background-color: rgba(0, 0, 0, 0.469);
}
.Republish-course-popup-folder {
    max-width: 718px;
    margin: auto;
    /* display: flex; */
    justify-content: center;
    /* text-align: center; */
    padding: 33px 25px;
    background-color: white;
    border-radius: 17px;
    position: fixed;
    top: 50%;
    left: 0%;
    right: 0%;
    transform: translateY(-50%);
}
.Republish-course-date-container{
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    justify-content: space-between;
}
.Republish-Start-Date-input, 
.Republish-end-Date-input {
    width: 45.5%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 9px;
}
.Republish-Start-Date-input label, .Republish-end-Date-input label {
    font-size: 15px;
    font-weight: 500;
}
.Republish-end-Date-input label span{
    font-size: 12px;
    color: #525252;
}
.Republish_btn {
    background-color: #0068FF;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    margin: 29px auto 0px auto;
    display: flex;
}
.Republish-course-popup-back-btn h2{
    font-size: 21px;
    margin: 0px;
}
.Republish-course-popup-back-btn i{
    font-size: 18px;
}
.Republish-course-popup-back-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0px 0px 25px 0px;
}
.Teacher-My-Course-Tab-card-wrapper .created-my-courses-title-wrapper h2 {
    font-size: 22px;
    margin: 0px;
    /* width: 555px !important; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media only screen and (max-width: 767px){
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-title-wrapper h2 {
        max-width: 73px !important;
    }
    .Sales-Courses-toggle-btn {
        gap: 4px;
    }
    /* .switch {
        width: 40px !important;
    } */
    .Sales-Courses-toggle-btn p {
        font-size: 11px;
    }
    .created-my-courses-ratings-folder {
        display: flex;
        gap: 3px;
    }
    .created-my-courses-ratings-folder a , 
    .created-my-courses-ratings-folder p{
        font-size: 12px;
    }
    .Teacher_My_Course-tabs .view-lessons-btn-container {
        margin-top: 0px;
        justify-content: space-between;
    }
    .Teacher_My_Course-tabs .tab-content {
        margin: 23px 0px 0px 0px;
    }
    .Teacher_My_Course-tabs .view-lessons-btn-container button {
        padding: 4px 4px;
        font-size: 12px;
    }
    .Republish-course-popup-back-btn {
        margin: 0px 0px 13px 0px;
    }
    .Republish-course-popup-folder {
        max-width: 320px;
        padding: 23px 16px;
    }
    .Republish-course-popup-back-btn h2 {
        font-size: 15px;
        margin: 0px;
    }
    .Republish-course-popup-back-btn i {
        font-size: 14px;
    }
    .Republish_btn {
        padding: 7px 20px;
        font-size: 11px;
        margin: 16px auto 0px auto;
    }
    .Republish-Start-Date-input label, 
    .Republish-end-Date-input label {
        font-size: 12px;
        font-weight: 500;
    }
    .Republish-Start-Date-input .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input,
    .Republish-end-Date-input .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 11px !important;
    }
    .course-inner-info-title h2 {
        font-size: 12px;
    }
    .course-inner-info-title p {
        font-size: 11px;
    }
    .inactive-image-folder{
        text-align: center;
    }
    .inactive-image {
        width: 159px;
        margin: 0px auto;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder .course-inner-title-wrapper,
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder .edit-course-dropdown-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder{
        display: flex;
        flex-wrap: wrap;
        width: 48%;
    }
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .Students-courses-details-container{
        display: flex;
        align-items: baseline;
        gap: 10px;
    }
    .Teacher-My-Course-Tab-card-wrapper {
        margin: 30px 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: unset;
        gap: 13px;
    }
    .Republish-course-popup-folder {
        max-width: 420px;
    }
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-title-wrapper h2 {
        min-width: 73px !important;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder .course-inner-title-wrapper,
    .Teacher-My-Course-Tab-card-wrapper .created-my-courses-folder .created-my-courses-title-folder .edit-course-dropdown-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .Republish-course-popup-folder {
        max-width: 258px;
        padding: 18px 10px;
    }
    .Republish-course-popup-back-btn h2 {
        font-size: 13px;
    }
    .Republish-Start-Date-input label, 
    .Republish-end-Date-input label {
        font-size: 11px;
    }    
}