@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
/*font-family: 'Roboto', sans-serif;*/

@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap");

/*font-family: 'Roboto Flex', sans-serif;*/

@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;500;600;700;800;900&display=swap");
/*font-family: 'Barlow Semi Condensed', sans-serif;*/

/*font-family: 'Montserrat', sans-serif;*/

/*html {scroll-behavior: smooth;}*/
/*Rj Code*/
::selection {
  background: #0096c7;
  color: #fff;
}

::-moz-selection {
  background: #0096c7;
  color: #fff;
}

/*html {scroll-behavior: smooth;}*/
select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* h1 {
  margin: 0px 0px 30px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h2 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h3 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 46px;
  font-weight: 600;
}

h4 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h5 {
  margin: 0px 0px 15px 0px;
  line-height: 25px;
  font-weight: 600;
}

h6 {
  margin: 0px 0px 15px 0px;
  line-height: 1.1;
  font-weight: normal;
} */
h1 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h2 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h3 {
  margin: 25px 0px 7px 0px;
  padding: 0px;
  line-height: 30px;
  /* font-weight: 600; */
  font-size: 22px;
}

h4 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h5 {
  margin: 25px 0px 5px 0px;
  line-height: 25px;
  /* font-weight: 600; */
}

h6 {
  margin: 15px 0px 5px 0px;
  line-height: 1.1;
  font-weight: normal;
}
.spacer1px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 1px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer5px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 5px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer15px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 15px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer10px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 10px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer20px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 20px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer30px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 30px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer50px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 50px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer80px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 50px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

body {
  position: static !important;
  /* or 'relative' depending on your needs */
  overflow-x: hidden;
  color: #333333;
  font-size: 16px;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
  outline: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Roboto Flex", sans-serif;
  background-color: #fff;
  /*height: 100vh;*/
}

p {
  line-height: 1.4;
  margin: 0px 0px 20px 0px;
  font-family: "Roboto Flex", sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

-webkit-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: "Roboto Flex", sans-serif;
}

-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: "Roboto Flex", sans-serif;
}

-ms-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: "Roboto Flex", sans-serif;
}

-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: "Roboto Flex", sans-serif;
}

.form-control::-webkit-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control::-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control:-ms-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control:-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

:focus,
button:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

*:focus {
  outline: none;
}

input:focus {
  outline: none;
}

.pagetitle {
  position: relative;
  text-align: left;
  padding: 0px 0px 70px 0px;
}

.pagetitle h2 {
  line-height: 1.1;
  font-size: 51px;
  color: #fff;
  padding: 0px;
  margin: 0px 0px 0px 0px;
}

img {
  max-width: 100%;
  height: auto;
  outline: none;
}

a {
  outline: none;
  border: none;
  text-decoration: none;
}

.fl_w {
  float: left;
}

.container-fluid {
  padding-left: 60px;
  padding-right: 60px;
}

.header_main_sec {
  position: relative;
  width: 100%;
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
}
.header_main_sec.scrolled {
  box-shadow: 0px 5px 3px #0000000f;
}

.header_main_sec_inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header_logo {
  position: relative;
  width: 150px;
}

.header_logo img {
  width: 100%;
}

.header_menu {
  position: relative;
  background: #dce8ff;
  width: 580px;
  padding: 12px 30px;
  border-radius: 80px;
  box-shadow: 0px 4px 4px 0px #0000000a;
}

.header_menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  position: relative;
}
.header-mob-folder {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: auto;
}
.header_menu ul li {
  display: inline-block;
}

.header_menu ul li a {
  display: inline-block;
  color: #002469;
  font-weight: 400;
  font-family: "Roboto Flex", sans-serif;
  text-decoration: none;
  font-family: "Roboto Flex", sans-serif;
  cursor: pointer;
}

.header_menu ul li a.active {
  font-size: 17px;
  font-weight: 600;
  top: -5px;
  position: relative;
}

.header_menu ul li a.active:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #0068ff;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.header_login {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

.login_btn,
.sign_btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  border-radius: 50px;
  /* border: 1.5px solid #000; */
  padding: 9px 35px;
  color: #002469;
  background-color: #dce8ff;
  box-shadow: 0px 4px 4px 0px #0000000a;
}

.sign_btn {
  position: relative;
  display: flex;
  align-items: center;
  /* font-size: 17px; */
  font-size: 15px;
  font-weight: 400;
  border-radius: 50px;
  border: 1.5px solid #0068ff;
  padding: 9px 35px;
  background: #0068ff;
  color: #fff;
  box-shadow: 0px 4px 4px 0px #0000000a;
}

.empower_sec_new {
  position: relative;
  padding: 20px 0;
  margin-top: 88px;
}

.empower_sec_new_inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
}

.empower_sec_new_inner_right {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.empower_sec_new_inner_right img {
  width: 84%;
}

/* .empower_sec_new_inner_right img {
  width: 70%;
}
.banner_school_girl_image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px !important; 
  margin-top: -50px;
  margin-left: -50px; 
  transform-origin: center center;
  animation: rotate 30s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.empower_sec_new_inner_left {
  position: relative;
  text-align: left;
}

.empower_sec_new_inner_left h3 {
  position: relative;
  color: #002469;
  font-size: 60px;
  font-weight: 600;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 30px;
  line-height: 65px;
}

/* .empower_sec_new_inner_left h3:after{position: absolute;content: '';background: url'{image5}';background-size: cover;background-repeat: no-repeat;} */
.empower_sec_new_inner_left p {
  position: relative;
  color: #252525;
  font-weight: 300;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
}

.pre_stu_sec {
  position: relative;
  background-color: #e1eaff;
  width: 310px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 80px;
}

.pre_stu_sec p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  position: relative;
}

.pre_stu_sec span {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  position: relative;
}

.stu_img_pro {
  position: absolute;
  width: 50px;
  top: -40px;
  right: 20px;
}

.pre_stu_sec_main {
  position: relative;
  background-color: #e1eaff;
  width: 450px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 80px;
  left: 80px;
  margin-top: 30px;
}

.pre_stu_sec_main p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  position: relative;
}

.pre_stu_sec_main span {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  position: relative;
}

.indi_cls_main {
  position: absolute;
  width: 140px;
  right: 207px;
  bottom: 140px;
}

.lets_explore_btn {
  position: relative;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.lets_explore_btn a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #0068ff;
  border-radius: 50px;
  height: 50px;
  width: 239px;
  justify-content: center;
  font-size: 17px;
  color: #ffffff !important;
  font-weight: 500;
  cursor: pointer;
}

.lets_explore_btn a i {
  transform: rotate(45deg);
}

.line_text_img {
  position: absolute;
  right: 130px;
  bottom: -15px;
}

.line_text_image2 {
  position: absolute;
  right: 110px;
  bottom: -20px;
}

.customize_learing_journey_light_image {
  position: absolute;
  right: 155px;
  top: -69px;
  width: 70px;
}

.customize_learing_journey {
  margin: 62px 0px 0px 0px;
}

.customize_learing_journey-folder {
  display: flex;
  align-items: center;
  gap: 39px;
  position: relative;
  overflow: hidden;
}

.customize_learing_journey_left_section {
  width: 53%;
  position: relative;
}

.customize_learing_journey_image {
  width: 95%;
}

.customize_learing_journey_image img {
  width: 100%;
}

.customize_learing_journey_right_section {
  width: 47%;
}

.customize_learing_journey_half_circle {
  position: absolute;
  top: -153px;
  right: -59px;
}

.customize_learing_journey_half_circle svg {
  width: 52px;
}

.customize_learing_journey_right_inner_folder {
  background-color: #edf5ff;
  text-align: left;
  width: 100%;
  padding: 14px 0px 14px 60px;
  border-radius: 22px;
  position: relative;
}

.customize_learing_journey_right_inner_folder h2 {
  font-size: 23px;
  margin: 0px 0px 6px 0px;
}

.customize_learing_journey_right_inner_folder p {
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  max-width: 354px;
  color: black;
  min-width: 98%;
}

.customize_learing_journey_right_inner_container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.customize_learing_journey_right_icon {
  position: absolute;
  top: 50%;
  left: -4%;
  transform: translateY(-50%);
  width: 44px;
}

.customize_learing_journey_right_icon svg {
  width: 100%;
}

.choose-your-role-folder {
  width: 330px;
}

.choose-your-role-btn-folder {
  display: flex;
  margin: 25px 0px 0px 0px;
  justify-content: center;
  width: 100%;
  background-color: #ffbb33;
  border-radius: 38px;
  padding: 8px 0px;
  color: white;
}

.choose-your-role-btn-folder button {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  justify-content: center;
}

.choose-your-role-btn-folder button p {
  margin: 0px;
  color: white;
  font-size: 18px;
}

.choose-your-role-btn-folder button span {
  color: black;
  font-size: 15px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
}

.choose-your-role-folder:nth-child(2) .choose-your-role-btn-folder {
  background-color: #703edb;
}

.choose-your-role-folder:nth-child(3) .choose-your-role-btn-folder {
  background-color: #34b6a9;
}

.choose-your-role-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 47px;
  margin: 50px 0px 0px 0px;
}

.choose-your-role-doted4-image {
  position: absolute;
  top: 153px;
  right: -45px;
}

.choose-your-role-doted4-image svg {
  width: 52px;
}

.choose-your-role-image {
  width: 90%;
  margin: 0px auto;
}

.choose-your-role-image img {
  width: 100%;
}

.customize_learing_journey_arrow {
  position: absolute;
  top: 16px;
  left: 0px;
}

.customize_learing_journey_arrow svg {
  width: 139px;
}

.choose-your-role-section {
  margin: 50px 0px 0px 0px;
}

.transforming-learning-experiences-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 100px auto;
}

.transforming-learning-experiences-line-image {
  position: absolute;
  top: -23px;
  right: 124px;
  margin: 0px auto;
}

.transforming-learning-experiences-doted-image {
  position: absolute;
  bottom: -90px;
  right: 61px;
}

.transforming-learning-experiences-doted-image svg {
  width: 99px;
}

.transforming-learning-experiences-doted2-image {
  position: absolute;
  bottom: -37px;
  left: 29px;
}

.transforming-learning-experiences-doted2-image svg {
  width: 130px;
}

.transforming-learning-experiences-left-section {
  width: 48%;
  padding: 29px 60px;
  border-radius: 31px;
  background-color: #edf5ff;
  position: relative;
}

.orange-line-image2 {
  position: absolute;
  top: 117px;
  left: 127px;
  width: 142px;
}

.transforming-learning-experiences-left-section h2 {
  color: #002469;
  font-size: 33px;
  font-weight: 600;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 0px;
  line-height: inherit;
  max-width: 391px;
  text-align: left;
  margin-bottom: 25px;
  line-height: 44px;
}

.transforming-learning-experiences-list-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.transforming-learning-experiences-list-folder {
  display: flex;
  align-items: center;
  gap: 14px;
}

.transforming-learning-experiences-list-icon svg {
  width: 40px;
}

.transforming-learning-experiences-list-folder p {
  margin: 0px 0px 0px 0px;
  font-size: 17px;
  color: black;
}

.transforming-learning-experiences-right-section {
  width: 48%;
  position: relative;
}

.transforming-learning-experiences-circle-bg-icon {
  position: absolute;
  top: -110px;
  right: -22px;
}

.transforming-learning-experiences-circle-bg-icon svg {
  width: 163px;
}

.transforming-learning-experiences-right-image {
  width: 79%;
  margin: 0px auto;
}

.transforming-learning-experiences-right-image img {
  width: 100%;
}

.choose-your-role-bg-star {
  position: absolute;
  top: 0px;
  left: 84px;
}

.choose-your-role-bg-star svg {
  width: 60px;
}

.choose-your-role-book-image {
  position: absolute;
  top: -47px;
  right: 108px;
}

.choose-your-role-book-image svg {
  width: 77px;
}

.choose-your-role-doted3-image {
  position: absolute;
  top: 50%;
  left: -103px;
}

.choose-your-role-doted3-image svg {
  width: 134px;
}

.blue-line-image2 {
  position: absolute;
  top: 45px;
  left: 210px;
}

.revolution_sec_new {
  position: relative;
  padding: 40px 0 20px 0;
}

.revolution_sec_new_inner,
.choose-your-role-wraper {
  position: relative;
  overflow: hidden;
}

.revolution_sec_new_head {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.revolution_sec_new_head h5,
.customize_learing_journey_inner_head h5 {
  position: relative;
  color: #002469;
  font-size: 40px;
  font-weight: 600;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 0px;
  line-height: inherit;
}

.customize_learing_journey_inner_head h5 {
  max-width: 900px;
  margin: 0px auto;
}

.img_revo {
  position: absolute;
  left: 120px;
  top: -45px;
  width: 70px;
}

.revolution_sec_new_body_sec {
  position: relative;
  background: #edf5ff;
  border-radius: 45px;
  padding: 100px 20px 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 55px;
  overflow: hidden;
}

.revolution_sec_new_single {
  position: relative;
  text-align: center;
  background: #fff;
  border-radius: 40px;
  padding: 70px 25px 25px 25px;
}

.revolution_sec_new_single h6 {
  position: relative;
  font-size: 22px;
  color: #002469;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.revolution_sec_new_single p {
  position: relative;
  margin: 0px;
  color: #002469;
  font-size: 14px;
  font-weight: 400;
}

.revo_img {
  position: absolute;
  top: -60px;
  /* width: 125px; */
  left: 0;
  right: 0;
  margin: 0 auto;
}

.choose-your-role-half-circle-image {
  position: absolute;
  top: -144px;
  left: -59px;
}

.choose-your-role-half-circle-image svg {
  width: 55px;
}

.footer-section {
  background-color: initial;
  box-shadow: 0 2px 4px #0000006b;
}

.footer-wraper {
  display: flex;
  justify-content: space-between;
  padding: 50px 60px;
}

.footer-link-folder,
.footer-Join-our-newsletter-folder,
.footer-get-in-touch-folder {
  text-align: left;
}

.footer-link-folder p {
  color: #002469;
  font-size: 22px;
  font-family: "Roboto Flex";
  font-weight: 500;
  margin-bottom: 10px;
  overflow-wrap: break-word;
}

.footer-link-folder ul {
  display: flex;
  gap: 23px;
}

.footer-link-folder ul li a {
  margin-bottom: 10px;
  cursor: pointer;
  color: #273d82;
  font-size: 15px;
}

.footer-Join-our-newsletter-folder p {
  font-size: 16px;
  margin-bottom: 11px;
  color: #2E83EE;
}

.footer-Join-our-newsletter-search-folder {
  display: flex;
  gap: 12px;
  width: 425px;
}

.footer-Join-our-newsletter-search-folder input {
  width: 85%;
  padding: 8px 17px;
  font-size: 12px;
  border-radius: 49px;
  border: none;
  outline: none;
  border: 1px solid #0000004a;
}

.footer-Join-our-newsletter-search-folder button {
  width: 25%;
  border-radius: 42px;
  font-size: 12px;
  padding: 7px 24px;
  outline: none;
  border: none;
  background-color: #0068ff;
  color: white;
}

.footer-get-in-touch-folder span {
  font-size: 19px;
  font-weight: 600;
  /* color: #273d82; */
  color: #0068FF;
}

.footer-social-link-folder {
  display: flex;
  gap: 17px;
}

.footer-social-link-folder a {
  font-size: 26px;
  /* color: #273d82; */
  color: #0068FF;
}
.footer-social-link-folder a img{
  width: 30px;
}
.footer-social-link-folder a:nth-child(5){
  color: red;
}
.footer-social-link-folder a:nth-child(4){
  color: #0077B5;
}
.footer-social-link-folder a:nth-child(2){
  color: black;
}

.footer-Quick-Links-folder h2 {
  color: #0068ff;
  font-size: 18px;
  margin: 0 0 12px;
}

.footer-Quick-Links li a {
  color: #000;
  font-size: 15px;
}

.footer-Quick-Links {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.footer-contact-folder a {
  color: #273D82 !important;
}

.footer-get-in-touch-folder {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.signup-wrapper,
.login-wrapper {
  /* display: flex;
  width: 100%;
  height: 100vh; */
  width: 100%;
  height: 100vh;
  position: "relative";
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-left-section,
.login-left-section {
  width: 55%;
}

.signup-image,
.login-image {
  width: 100%;
  height: 100%;
}

/* .signup-image img,
.login-image {
  width: 50%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
} */

.signup-image img,
.login-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* .signup-right-section {
  width: 50%;
} */

.signup-right-section h2 {
  /* font-size: 51px; */
  font-size: 36px;
  margin: 8px 0px 0px 0px;
  font-weight: 700;
  text-align: left;
  color: #0068ff;
}

.signup-tab-folder button.active {
  background-color: #323232;
  color: white;
  border-radius: 50px;
  padding: 6px 0px;
  font-size: 14px;
  padding: 11px 0px;
}

.signup-tab-folder button {
  font-size: 12px;
  width: 50%;
  background-color: #f2f2f2;
  border: none;
  border-radius: 50px;
  padding: 9px 0px;
}

.signup-form {
  margin: 40px auto 0px auto;
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
}

.studentDashboar-teacher-des-view-profile-folder span img {
  width: 14px;
}

.signup-input-folder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  align-items: center;
}

.signup-inner-input,
.signup-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.signup-inner-input label,
.signup-input CommonTextField {
  font-size: 12px;
}

.signup-inner-input CommonTextField {
  width: 100%;
  padding: 11px 15px;
  background-color: #f2f2f2;
  border-radius: 8px;
  border: none;
  font-size: 11px;
}

.signup-input CommonTextField {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 35px;
  border: none;
  padding: 15px 25px;
  font-size: 14px;
}

.edit-email,
.hide-show-password {
  position: relative;
}

.edit-emial-btn,
.show-password-btn {
  position: absolute;
  top: 50%;
  right: 3.8%;
  transform: translateY(-50%);
  font-size: 14px;
  text-decoration: underline;
}

.show-password-btn {
  color: black;
}

.email-or-phoneNumber-title {
  text-align: left;
  margin: 0px 0px 5px 0px;
  font-weight: 700;
}

.login-with-page-folder {
  width: 371px;
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 8px 9px;
  text-decoration: none;
  border-radius: 61px;
  box-shadow: 0px 0px 7px #e2e2e2;
  background-color: transparent;
  border: none;
}

.login-btns p {
  text-align: center;
  margin: 0px;
  color: black;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.signup-btn {
  margin: 30px 0px 17px 0px;
}

.signup-btn button {
  font-size: 13px;
  background-color: #0068ff;
  color: white;
  padding: 9px 45px;
  border-radius: 30px;
  border: none;
}

.Already-have-an-accout-folder {
  color: black;
  font-weight: 600;
  font-size: 13px;
}

.Already-have-an-accout-folder span {
  font-size: 15px !important;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  margin: 23px 0px 0px 0px;
}

.Register-container {
  width: 88%;
  margin: 30px auto 0px auto;
  text-align: center;
}

.Register-container .welcome-text {
  display: flex;
  justify-content: center;
  margin: 20px;
  font-weight: 600;
}

.admin-login {
  margin-top: 100px;
}

.Already-have-an-accout-folder a {
  color: #0068ff;
}

.terms-and-Privacy-policy {
  color: #999999;
  font-size: 11px;
  margin: 8px 0px 5px 0px;
}

.signup-or-title,
.login-or-title {
  font-size: 14px !important;
  margin: 10px 0px 21px 0px;
  color: #999999;
}

.login-or-title {
  font-size: 14px !important;
  margin: 15px 0px;
  color: #b4afaf;
}

/* .auth-main {
  width: 100%;
  height: 100vh;
  position: 'relative';
  display: flex;
  justify-content: center;
  align-items: center;

} */

.auth-main img {
  width: 50%;

  height: 100vh;
  object-fit: cover;
}

.auth-header-text {
  margin-top: 39px;
  margin-bottom: 18px;
  color: #000;
  text-align: center;
  font-family: Barlow;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .signup-right-section {
  height: 100vh;
  width: 55%;
  flex-shrink: 0;
  background: #FFF;
  top: 0;
  right: 0;
} */
.signup-right-section {
  height: 100vh;
  width: 53%;
  flex-shrink: 0;
  background: #fff;
  top: 0;
  right: 0;
}

.keep-me-login-container {
  display: flex;
  width: 85%;
  margin: 23px auto 26px auto;
  justify-content: space-between;
  align-items: center;
}

.keep-me-login {
  display: flex;
  align-items: center;
  gap: 10px;
}

.keep-me-login input {
  width: 20px;
  height: 20px;
}

.keep-me-login p {
  margin: 0px;
  font-size: 14px;
}

.forgot-password-btn div {
  font-size: 14px;
  color: black;
  text-decoration: none;
}

#login-page-folder {
  margin: 20px auto 0px auto;
}

.img_revo {
  position: absolute;
  left: 6px;
  top: -71px;
  width: 70px;
}

.light_logo {
  position: absolute;
  left: 423px;
  top: -71px;
  width: 77px;
}

.signup-right-section span {
  display: flex;
  font-size: 16px;
}

.Register-tab-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 25px 0px 0px 0px;
}

.Register-title {
  width: 15%;
  margin: 0px;
  color: #999999;
  font-size: 15px;
  margin-left: -17px;
}

.emaild-verifed-btn {
  width: 100%;
  font-size: 12px;
  margin: 2px 0px 0px 0px;
  /* text-decoration: underline; */
  color: #42f004;
  /* border: 1px solid; */
  cursor: pointer;
}

.forget-password-btn {
  width: 100%;
  font-size: 11px;
  margin: 0px 0px 0px 0px;
  text-decoration: underline;
  color: black;
}
.forget-password-btn a{
  cursor: pointer;
}
.verify-active-password-btn {
  position: absolute;
  width: 100%;
  font-size: 11px;
  margin: 2px 0px 0px 0px;
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.forgot-password-folder {
  text-align: right;
}

.signup-tab-section {
  width: 85%;
  position: relative;
}

.signup-tab-folder,
.signup-tab-folder button.active {
  padding: 9px 0px;
  font-size: 12px;
}

.signup-tab-folder {
  width: 85%;
  margin: 0px auto 0px auto;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 34px;
  padding: 6px 5px;
  width: 100%;
}

/* @media screen and (min-width: 1280px) {
  .signup-tab-folder, 
  .signup-tab-folder button.active {
    padding: 4px 5px;
  }
  .login-btns {
    padding: 6px 10px;
  }
  .login-btns p {
    font-size: 12px;
  }
  .signup-btn button {
    font-size: 12px;
  }
  .signup-btn {
    margin: 14px 0px;
  }
  .signup-inner-input input, 
  .signup-input input {
    padding: 9px 15px;
  }
  .signup-or-title, .login-or-title {
    margin: 8px 0px;
  }
} */

/* @media only screen and (max-width: 768px) {} */

.dashboard-popup-container {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 100%;
  background-color: #00000024;
  z-index: 113;
}

.dashboard-popup-welcome-folder,
.dashboard-popup-teacher-profile-details-folder {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  width: 656px;
  padding: 39px 0px;
  background-color: white;
  border-radius: 12px;
  text-align: center;
}

.dashboard-popup-teacher-profile-details-folder {
  padding: 0px 0px 0px 0px;
  background-color: #f5f9ff;
}

.dashboard-popup-welcome-image {
  width: 302px;
  margin: 0px auto;
}

.dashboard-popup-welcome-image img {
  width: 100%;
}

.dashboard-popup-welcome-folder h2 {
  /* margin: 20px 0px 7px 0px; */
  margin: 10px 0px 2px 0px;
  font-size: 28px;
  color: black;
}

.dashboard-popup-welcome-folder p {
  font-size: 15px;
  color: black;
  margin: 0px 0px 15px 0px;
}

.dashboard-popup-welcome-folder button {
  background-color: #0068ff;
  padding: 10px 66px;
  color: white;
  font-size: 13px;
  border-radius: 7px;
  border: navajowhite;
  outline: none;
}

.dashboard-popup-teacher-profile-details-title {
  display: flex;
  padding: 14px;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 2px 2px #bebebe45;
}

.dashboard-popup-teacher-profile-details-image img {
  width: 28px;
}

.dashboard-popup-teacher-profile-details-title h2 {
  margin: 0px 0px 0px 0px;
  font-size: 19px;
}

.dashboard-popup-teacher-profile-details-form {
  width: 95%;
  margin: 0px auto;
  background-color: white;
  padding: 20px;
  margin: 22px auto 25px auto;
  border-radius: 10px;
}

.dashboard-popup-teacher-profile-details-form span {
  font-size: 10px !important;
  color: #6e6e6e;
}

.dashboard-popup-teacher-profile-details-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}

.dashboard-popup-teacher-profile-details-input {
  width: 48.8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
}

.dashboard-popup-teacher-profile-details-input img {
  width: 21px;
  height: 21px;
}

.dashboard-popup-teacher-profile-details-input input {
  width: 100%;
  padding: 8px 10px 8px 38px;
  /* border-radius: 8px; */
  font-size: 12px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}

.dashboard-popup-teacher-profile-details-input select {
  word-wrap: normal;
  width: 100%;
  padding: 8px 10px 8px 38px;
  /* border-radius: 0px; */
  font-size: 13px;
  /* border: 1px solid #BDBDBD; */
  color: #626262;
  /* -webkit-appearance: none; */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* font-size: 12px; */
  line-height: 1.5;
  padding: 5px 10px 10px 38px;
}

.dashboard-popup-teacher-profile-details-input p {
  color: red;
}

.dashboard-popup-teacher-profile-details-input label {
  font-weight: 500;
  font-size: 13px;
}

.dashboard-popup-teacher-profile-details-input label::after,
.introduce-yourself-title::after {
  content: "*";
  color: red;
  margin-left: 2px
}

.location-input {
  width: 100%;
  margin: 12px 0px 0px 0px;
}

.form-icon {
  position: absolute;
  top: 40px;
  left: 3.6%;
  transform: translateY(-50%);
  z-index: 2;
}

.location-icon {
  position: absolute;
  /* top: 70%; */
  top: 40px !important;
  left: 3.4%;
  /* left: 1.8%; */
  transform: translateY(-50%);
}

.form-icon img {
  width: 21px;
}

.introduce-yourself-title,
.subjects-title {
  text-align: left;
  margin: 0px 0px 16px 0px;
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.subjects-title::after {
  content: "*";
  color: red;
  margin-left: 2px
}


.introduce-yourself-text {
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 10px;
  resize: none;
  font-size: 14px;
}

.you-can-update-the-details-title {
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

/* .subjects-title{
  text-align: left;
  margin: 0px 0px 8px 0px;
} */
.Subjects-you-teach input {
  width: 100%;
  padding: 10px 20px;
  font-size: 12px;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
}

.Subjects-you-teach-feilds-folder {
  margin: 20px 0px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 132px;
  overflow-y: auto;
}

.Subjects-you-teach-feilds {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 22px;
  gap: 10px;
  border-radius: 34px;
  background-color: #b7e5ff;
}

.Subjects-you-teach-feilds p {
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  text-align: center;
  color: black;
  text-transform: capitalize;
}

.subject-delete-btn {
  position: relative;
  left: 8px;
  font-size: 13px;
  color: black;
}

.Subjects-you-teach-suggestions-folder {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  max-height: 132px;
  overflow-y: auto;
}

.Subjects-you-teach-suggestions-folder a {
  border: 1px solid;
  padding: 6px 22px;
  border-radius: 36px;
  font-size: 12px;
  color: black;
  text-transform: capitalize;
  cursor: pointer;
}

.Subjects-you-teach-suggestions-title {
  text-align: left;
  color: #575757;
  font-size: 13px;
  margin-bottom: 16px;
}

.Personalize-your-AI-Assistant-uplode-image {
  position: relative;
}

#choos-profile-image {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  margin: auto;
  width: 125px;
  height: 125px;
  opacity: 0;
  cursor: pointer;
}

#create-course-image {
  margin: auto;
  width: 185px;
  cursor: pointer;
  border: 1px solid #0068ff;
  color: #0068ff;
  background-color: transparent;
  clip: "rect(0 0 0 0)";
  clip-path: "inset(50%)";
  height: 1;
  overflow: "hidden";
  position: "absolute";
  bottom: 0;
  left: 0;
  white-space: "nowrap";
  width: 1;
}

#choos-profile-image.forprofile {
  margin: 0px;
}

.Personalize-your-AI-Assistant-uplode-image img {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  object-fit: cover;
  /* cursor: pointer; */
  position: relative;
}

.dashboard-popup-teacher-profile-details-image-underage {
  display: flex;
  margin-left: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section,
.profile-image-section2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section span,
.profile-image-section2 span {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section2 {
  width: 58%;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section {
  width: 42%;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section img {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  position: relative;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section2 .select-avatar-list {
  display: flex;
  /* gap: 20px; */
  border-radius: 8px;
  background: #dff4ff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  justify-content: space-between;
}

img.select-avatar-image {
  border-radius: 100%;
  width: 60px;
}

img.select-avatar-image.active {
  border: 2px solid #4dff00;
  box-shadow: 0px 0px 4px 0px rgb(0, 0, 0);
}

.profile-image-section.others {
  width: 30%;
}

.profile-image-section2.others {
  width: 70%;
}

.dashboard-popup-teacher-profile-details-image-underage .profile-image-section2 .select-avatar-list .others {
  gap: 20px;
  overflow-x: auto;
}

.Personalize-your-AI-Assistant-left-section p {
  font-size: 10px;
  font-weight: 400;
  margin: 6px 0px 25px 0px;
  color: black;
}

.Personalize-your-AI-Assistant-form-folder {
  text-align: left;
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
}

.Personalize-your-AI-Assistant-input-folder {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.Personalize-your-AI-Assistant-input-folder label {
  font-size: 12px;
  color: black;
}

.Personalize-your-AI-Assistant-input-folder label::after {
  content: "*";
  color: red;
  margin-left: 2px
}


.Personalize-your-AI-Assistant-input-folder input {
  padding: 9px 20px;
  width: 100%;
  font-size: 10px;
  border-radius: 5px;
  border: 2px solid #e8e8e8;
}

.Personalize-your-AI-Assistant-left-section {
  width: 60%;
  border-right: 1px solid #d6d6d6;
  padding-right: 10px;
}

.Personalize-your-AI-Assistant-right-section {
  width: 40%;
  margin-top: 27px;
  padding-left: 11px;
}

.Personalize-your-AI-Assistant-right-section.forprofile {
  text-align: center;
}

.Personalize-your-AI-Assistant-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Personalize-your-AI-Assistant-right-section h2 {
  font-size: 19px;
  margin: 0px 0px 25px 0px;
}

.Personalize-your-AI-Assistant-right-section p {
  font-size: 12px;
  font-weight: 600;
  margin: 21px 0px 0px 0px;
  color: black;
}

.Customize-your-AI-assistant-tone-dropdown-folder p {
  padding: 9px 14px;
  width: 100%;
  font-size: 10px;
  border-radius: 5px;
  border: 2px solid #e8e8e8;
  margin: 0px 0px 10px 0px;
}

.Customize-your-AI-assistant-tone-dropdown-select-option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 13px;
}

.Customize-your-AI-assistant-tone-dropdown-select-option {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0px 6px;
  justify-content: space-between;
}

.Customize-your-AI-assistant-tone-dropdown-select-option input {
  width: 13px;
  height: 13px;
}

.Customize-your-AI-assistant-tone-dropdown-select-option label {
  font-size: 10px;
}

.Customize-your-AI-assistant-tone-dropdown-select-option label::after {
  content: none;
}

.Customize-your-AI-assistant-tone-dropdown-inner-folder {
  border-radius: 4px;
  box-shadow: 0px 4px 12px #d5d5d5;
  padding: 10px;
  position: absolute;
  top: 97%;
  left: 0%;
  right: 0%;
  background-color: white;
  z-index: 3;
}

.Personalize-your-AI-Assistant-input-folder-title {
  border: none !important;
  border-bottom: 2px solid #e8e8e8 !important;
  border-radius: 0px !important;
  margin: 0px;
}

.Customize-your-AI-assistant-tone-dropdown-folder {
  position: relative;
}

.uploded-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.dashboard-popup-teacher-profile-gender-folder {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.update-profile-image {
  padding: 7px 15px;
  font-size: 15px;
  border-radius: 5px;
  background-color: #0068ff !important;
  color: white;
}

.dashboard-popup-teacher-profile-gender-folder select {
  width: 100%;
  padding: 5px 10px 10px 38px;
  border: 1px solid;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
  font-size: 12px;
  color: #626262;
  -webkit-appearance: none;
}

.dropdown {
  /* width: 100%; */
  border-radius: 10px;
  max-width: 305px;
}

.dropdown-header {
  padding: 15px;
  padding: 9px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  font-size: 12px;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.dashborad-Courses-folder {
  width: 171px;
  background-color: #f3deff;
  padding: 18px;
  border-radius: 14px;
  text-align: center;
  cursor: pointer;
  transition: transform .2s; /* Animation */
  box-shadow: 0px 2px 7px #efefef;
}
.dashborad-Courses-folder:hover {
  transform: scale(1.05);
}

.dashborad-Courses-count {
  background-color: #ffffff73;
  margin: 18px 0px 0px 0px;
  padding: 8px 0px;
  border-radius: 14px;
}

.dashborad-Courses-count h2 {
  margin: 0px;
  font-size: 23px;
}

.dashborad-Courses-count p {
  margin: 6px 0px 0px 0px;
  font-size: 13px;
  margin: 6px 0 0;
  color: black;
}

.dashboard-name-title {
  font-size: 24px;
  text-align: left;
  margin: 0px 0px 16px 0px;
}

.dashborad-Courses-container,
.dashboard_tab {
  display: flex;
  gap: 21px;
}

.Start-by-creating-your-course-container {
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 32px;
  margin: 38px 0px;
  box-shadow: 0px 2px 11px #d8d8d8;
}

.Start-by-creating-your-course-container p {
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  color: black;
  cursor: default;
}

.Start-by-creating-your-course-container button {
  border: 1px solid;
  padding: 8px 41px;
  background-color: #0068ff;
  color: white;
  font-size: 13px;
}

.my-course-title {
  font-size: 23px;
  margin: 0px 0px 23px 0px;
  border-bottom: 1px solid;
  padding: 0px 0px 16px 0px;
}

.created-my-courses-wrapper {
  text-align: left;
  font-size: 26px;
  margin: 0px 0px 27px 0px;
  padding: 0px 0px 10px 0px;
}

.created-my-courses-folder {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 13px;
  box-shadow: 0px 1px 14px #d5d5d5bd;
  border-radius: 10px;
}

.created-my-courses-folder-image {
  width: 100%;
  height: 254px;
}

.view-course-details-image {
  width: 100%;
  height: 282px;
}

.created-my-courses-folder-image img,
.view-course-details-image img {
  /* height: 100%; */
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.create-course {
  padding: 0px 9px;
}

.create-course svg {
  width: 30px;
}

/* .create-course-text {
  font-size: 11px !important;
} */
.create-course {
  padding: 2px 8px !important;
}

.Create-Courses-wrapper {
  padding: 20px !important;
}

.created-my-courses-folder-image img {
  width: 100%;
  cursor: pointer;
}

.created-my-courses-title-folder {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 17px 0px;
  cursor: pointer;
}

.created-my-courses-title-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.created-my-courses-title-wrapper h2 {
  font-size: 22px;
  margin: 0px;
  /* width: 740px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.edit-course-dropdown-menu-container {
  position: relative;
  display: flex;
  align-items: center;
}

.created-my-courses-ratings-folder {
  display: flex;
  gap: 5px;
  align-items: center;
}

.created-my-courses-ratings-folder a {
  color: #ffbb33;
  font-size: 11px;
}

.created-my-courses-ratings-folder p {
  margin: 0px;
  font-size: 12px;
}

.edit-course-dropdowun-menu-container a {
  font-size: 20px;
  color: black;
}

.Students-courses-details-folder,
.view-Students-course-details-folder {
  /* width: 154px; */
  width: 131px;
  text-align: center;
}

.Students-courses-details-folder span,
.view-Students-course-details-folder span {
  /* font-size: 12px !important; */
  font-size: 11px !important;
  color: #888888;
}

.Students-courses-details-counts,
.view-Students-course-details-counts {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  border-radius: 45px;
  background-color: #ecccff;
  display: flex;
  justify-content: center;
  gap: 9px;
}

.Students-courses-details-counts p,
.view-Students-course-details-counts p {
  margin: 0px;
  font-size: 12px;
}

.Students-courses-details-container,
.view-course-details-container {
  display: flex;
  align-items: center;
  /* gap: 32px; */
  /* gap: 12px; */
  gap: 8px;
  position: relative;
}

.Students-courses-details-container {
  margin-top: -12px;
}

.Students-des {
  font-size: 13px;
  margin: 0px;
  /* color: #606060; */
  color: black;
  margin: 12px 0px 4px 0px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 7.5em;
  white-space: normal;
}

.View-Course, 
.practice-assignment-btn, 
.view-result {
  /* width: 152px; */
  text-align: center;
  font-size: 13px;
  padding: 7px 32px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
}
.view-result{
  background-color: transparent;
  border: 2px solid #0068ff !important;
  color: #0068ff;
  font-weight: 500;
}

.created-my-courses-folder-image-left-folder,
.view-course-details-left-section,
.edit-course-details-left-section {
  width: 19%;
  position: relative;
}
.created-my-courses-folder-image-left-folder{
  width: 24%; 
}
/* .created-my-courses-folder-image-left-folder {
  flex: 0 0 261px;
} */

.created-my-courses-des-wrapper,
.edit-course-details-right-section {
  width: 81%;
  position: relative;
}

.edit-course-dropdowun-menu-container {
  position: relative;
}

.course-edit-or-delete-container {
  position: absolute;
  top: 23px;
  right: 7px;
  padding: 14px;
  width: 93px;
  border-radius: 5px;
  background-color: white;
  width: 114px;
  box-shadow: 0px 4px 7px #dddddd;
  display: flex;
  flex-direction: column;
  gap: 14px;
  z-index: 3;
}

.course-edit-or-delete-folder {
  display: flex;
  gap: 11px;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
}

.course-edit-or-delete-folder a {
  font-size: 14px;
  color: black;
}

.course-edit-or-delete-folder p {
  margin: 0px;
  font-size: 14px;
}

.profile-menu-header, 
.current-plan {
  display: flex;
  gap: 11px;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
}

.profile-menu-header ReactSVG {
  font-size: 14px;
  color: black;
}

.profile-menu-header p, 
.current-plan-title p {
  margin: 0px;
  font-size: 14px;
}
.current-plan-title span{
  font-size: 10px;
  color: #2396D8;
}
.current-plan-title p{
  margin: 0px 0px -7px 0px;
}

.edit-course-or-delete-course {
  font-size: 14px;
  color: black;
}

.edit-course-dropdown-menu-container {
  position: relative;
}

.delete-course-form {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  margin: auto;
  background-color: #35353585;
  z-index: 113;
}

.delete-course-form-container {
  max-width: 718px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 47px 36px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 0%;
  right: 0%;
  margin: auto;
  border-radius: 14px;
  z-index: 2;
}

.delete-course-alert-image img {
  width: 80px;
}

.delete-course-form-container h2 {
  font-size: 25px !important;
  margin: 20px 0px 25px 0px !important;
}

.delete-course-form-btn-folder {
  display: flex;
  gap: 53px;
}

.delete-course-form-btn-folder button {
  border: 1px solid !important;
  padding: 5px 40px !important;
  font-size: 17px !important;
  border-radius: 7px !important;
}
.delete-course-form-btn-folder .not-delete-btn{
  color: black !important;
}

.delete-btn {
  background-color: #f0183d !important;
  color: white !important;
}
.assignmen-submit-popup #delete-AssingmentMCQs-form .delete-course-form-btn-folder button:nth-child(2) {
  background-color: #2e83ff !important;
}

.Create-Courses-section {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: #00000042;
  padding: 27px 0px;
  z-index: 113;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Create-Courses-wrapper {
  background-color: white;
  border-radius: 30px;
  /* padding: 28px;
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%) !important; */
  margin: auto;
  width: 95%;
}

.Create-Courses-wrapper h2 {
  margin: 0px 0px 25px 0px;
  border-bottom: 1px solid;
  padding-bottom: 12px;
  font-size: 25px;
  text-align: left;
}

.Create-Course-inner-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.Create-Course-disscount-wrapper {
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 15px 0px 0px 0px;
}
.Create-Course-disscount-wrapper .Create-Course-inner-input{
  width: 20%;
  /* background-color: #F2F7FF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px #e5e5e5; */
}
.Create-Course-disscount-wrapper .Create-Course-inner-input:nth-child(3), 
.Create-Course-disscount-wrapper .Create-Course-inner-input:nth-child(4),
.Create-Course-disscount-wrapper .Create-Course-inner-input:nth-child(5){
  background-color: #F2F7FF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px #e5e5e5;
}
.course-discount-input {
  /* box-shadow: 0px 1px 5px #e5e5e5; */
  color: #0068FF;
  display: flex;
  align-items: center;
  /* height: 35px; */
  border-radius: 8px;
  /* padding: 0px 0px 0px 15px; */
}
.course-discount-input p{
  /* color: #0068FF; */
  color: black;
  font-weight: 500;
}
.Create-Course-inner-input {
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 32%;
  text-align: left;
  position: relative;
}
.Create-Course-inner-input i, .Course-Start-Date i{
  position: absolute;
  top: 42px;
  right: 13px;
  font-size: 11px;
  z-index: 3;
}

.create-course-Grade-input-folder, 
.course-toggle-uplode-folder {
  display: flex;
  width: 32%;
  align-items: center;
  gap: 33px;
}

.create-course-inner-input-grade {
  width: 50%;
}

.create-course-enhance-course-folder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Create-Course-image p .edit-Course-inner-input label {
  font-size: 15px;
}

.Create-Course-inner-input label,
.Create-Course-des-folder label {
  font-size: 13px;
}

.Create-Course-inner-input label::after,
.Create-Course-des-folder label::after {
  content: "*" !important;
  color: red;
  margin-left: 2px;
}
.Create-Course-disscount-wrapper .Create-Course-inner-input label::after{
  content: "" !important;
}
.Create-Course-inner-input:nth-child(4) label::after, 
.Create-Course-inner-input:nth-child(5) label::after{
  content: "";
}
.restriction-iocn {
  display: flex;
  gap: 5px;
}
.create-course-end-date label::after,
#edit-course-end-date label::after,
.lesson-end-date label::after {
  content: inherit !important;
}

.Create-Course-des-folder Waveform {
  position: absolute;
  top: 40%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
}

.Create-Course-image p {
  font-size: 13px;
  margin-bottom: 15px;
}

.Create-Course-image p::after {
  content: "*";
  color: red;
  margin-left: 2px
}

.Create-Course-inner-input input,
.Create-Course-inner-input select,
.edit-Course-inner-input input,
.edit-Course-inner-input textarea {
  padding: 10px 20px;
  font-size: 13px;
  border-radius: 10px;
  /* background-color: #ECECEC; */
  outline: none;
  border: none;
}

.edit-Course-inner-textarea {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 9px;
  position: relative;
}

.edit-Course-inner-textarea label {
  font-size: 14px;
}

.edit-Course-inner-textarea label::after {
  content: "*";
  color: red;
  margin-left: 2px
}

.edit-Course-inner-textarea input {
  padding: 10px 20px;
  font-size: 13px;
  border-radius: 10px;
  background-color: #ececec;
  outline: none;
  border: none;
  resize: none;
}

#Course-Des-input {
  margin: 10px 20px;
}

.create-course-inner-input-grade label span {
  color: #525252;
  font-size: 13px;
}

.Create-Course-des-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 22px;
  /* margin: 34px 0px 0px 0px; */
  margin: 15px 0px 0px 0px;
  text-align: left;
}

.Create-Course-des-folder {
  display: flex;
  flex-direction: column;
  /* width: 68%; */
  width: 80%;
  position: relative;
}

.Create-Course-image {
  position: relative;
}

.uploded-image-section {
  /* width: 35%; */
  width: 24%;
}

.Create-Course-btn-wrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.Create-Course-btn-wrapper button {
  font-size: 13px;
  padding: 8px 43px;
  outline: none;
  border: none;
  transform: scale(1);
  opacity: 1 !important;
}

.Create-Course-cancle-btn {
  border: 1px solid black !important;
  background-color: transparent !important;
}

.Create-Courses-btn {
  background-color: #0068ff;
  color: white;
  position: relative;
}

.Create-Courses-btn:disabled {
  filter: grayscale(100%);
  opacity: 0.6;
  cursor: not-allowed;
}

.finish-button:disabled {
  filter: grayscale(100%);
  opacity: 0.6;
  cursor: not-allowed;
}

.course-file-image {
  width: 100%;
  /* height: 272px; */
  height: 225px;
  border-radius: 6px;
  border: 2px #2396d8 dashed;
}
.Create-Course-des-folder .css-w0oh05-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: transparent !important;
}

.course-file-image-error {
  width: 100%;
  height: 286px;
  border-radius: 6px;
  border: 2px #d32f2f dashed;
}

.Course-created-successfully-main, .Congratulations-Popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 114;
  background-color: rgba(0, 0, 0, 0.469);
}

.course-image-close {
  position: absolute;
  /* top: 10%;
  right: 0%; */
  top: 9%;
  right: -2%;
  cursor: pointer;
}
.course-image-close svg{
  width: 22px;
  height: 22px;
  padding: 2px;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 1px 3px #b5b5b5;
}

.course-uploded-image {
  width: 100%;
  /* height: 286px; */
  /* height: 232px; */
  height: 225px;
  border-radius: 6px;
  object-fit: cover;
}

.Create-Course-uplode-image-container {
  position: absolute;
  top: 57%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.Create-Course-uplode-image-container button,
.Create-Course-uplode-outer-image button,
.Regenerate-course-btn {
  font-size: 13px;
  padding: 7px 23px;
  border-radius: 7px;
}
.Create-Course-uplode-outer-image label{
  width: 177px !important;
}

input.Create-Course-uplode-image-file {
  position: absolute;
  top: 68%;
  left: 3%;
  right: 0%;
  margin: auto;
  font-size: 17px;
  width: 156px;
  z-index: 2;
  opacity: 0;
}

.course-Generating-AI-btn {
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
}

.Create-Course-uplode-images {
  border: 1px solid #0068ff;
  color: #0068ff;
  background-color: transparent;
}

.Course-Start-Date,
.Course-end-Date {
  position: relative;
}

#Course-Start-Date-input,
#Course-end-Date-input {
  padding-left: 44px;
}

.Course-description-inner-ai-feilds-folder {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.Create-Course-des-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Course-description-inner-ai-feilds-folder p,
.Course-description-inner-ai-feilds span {
  font-size: 12px;
}

.Course-description-inner-ai-feilds span {
  color: #0068ff;
}

.Course-description-inner-ai-feilds:disabled {
  filter: grayscale(100%);
  opacity: 0.6;
  cursor: not-allowed;
}

.course-Generating-AI-btn:disabled {
  filter: grayscale(100%);
  opacity: 0.6;
  cursor: not-allowed;
}

.Course-description-inner-ai-feilds {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 6px 18px;
  background-color: #e5f6ff;
  border-radius: 8px;
}

.Course-description-inner-ai-image img {
  width: 21px;
}

.reuplode-image-folder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 0px 0px;
}

.Regenerate-image {
  width: 20px;
  height: 20px;
  transform: translateX(14px);
  position: relative;
  z-index: 2;
}

.Create-Course-uplode-outer-image-btn {
  background-color: transparent;
  color: #0068ff;
  border: 1px solid #0068ff;
}

.Regenerate-course-btn {
  background-color: transparent;
  color: #0068ff;
  border: none;
}

.Course-created-successfully-container, 
.Congratulations-Popup-folder{
  max-width: 718px;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 47px 36px;
  background-color: white;
  border-radius: 17px;
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%);
}
.Congratulations-Popup-folder{
  display: flex;
  flex-direction: column;
}
.Congratulations-Popup-folder h2 {
  font-size: 25px;
  margin: 6px auto 0px auto !important;
  max-width: 477px;
}

.Course-created-successfully-folder h2 {
  font-size: 26px;
}

.not-delete-btn {
  background-color: transparent;
}

/* view course details start */
/* .Preview-lesson-wrapper{
  max-width: 1430px;
  margin: 0px auto;
} */
.view-course-details-folder,
.edit-course-details-folder {
  display: flex;
  gap: 24px;
  width: 100%;
}
/* .view-course-details-folder{
  margin: 26px 0px 0px 0px;
} */

.view-course-details-right-section {
  width: 81%;
}

.custom-dialog-header {
  display: flex;
  justify-content: space-between;
}

.custom-dialog-header center {
  color: black;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

.custom-dialog-header-close span {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-dialog-header-close i {
  width: 20px;
  height: 20px;
}

.custom-dialog-main-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 30px;
}

.view-course-Details-back-btn-folder,
.LessonViewProgress-back-btn-folder {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin: 0px 0px 26px 0px; */
}

.view-course-Details-back-btn-folder i {
  cursor: pointer;
}

.LessonViewProgress-back-btn-folder {
  align-items: flex-start;
  margin: 0px 0px 0px 0px;
}

.view-course-Details-back-btn-folder a,
.view-course-Details-back-btn-folder a {
  font-size: 25px;
  color: black;
}

.view-course-Details-back-btn-folder h2 {
  margin: 0px;
  font-size: 24px;
}

.view-course-Details-back-btn-folder h2 {
  text-align: left;
  margin: 0px;
  font-size: 20px;
  /* max-width: 401px; */
  font-weight: 500;
  width: 667px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.view-course-details-edit-and-share-container {
  display: flex;
  justify-content: flex-end;
  gap: 17px;
}

.view-course-details-edit-and-share-folder,
.Invited_students_btn {
  display: flex;
  align-items: center;
  gap: 11px;
  border: 1px solid black;
  padding: 7px 22px;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
}

.Invited_students_btn {
  border: 1px solid #0068FF;
}

.Invited_students_btn p {
  margin: 0px !important;
  color: #0068FF !important;
}

.view-course-details-share-btn {
  border: none;
}

.view-course-details-edit-and-share-folder span {
  font-size: 14px;
  color: black;
}

.view-course-details-edit-and-share-folder p {
  margin: 0px;
  font-size: 15px;
  color: black;
}

.view-course-details-right-section h2 {
  text-align: left;
  font-size: 25px;
  margin: 0px 0px 0px 0px;
  word-wrap: break-word;
  width: 740px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.view-course-details-right-section p {
  text-align: left;
  font-size: 13px;
  margin: 0px;
  /* color: #606060; */
  margin: 14px 0px 24px 0px;
  line-height: 22px;
  color: black;
}

.view-Students-course-details-counts {
  margin: 9px 0px 0px 0px;
  padding: 6px 0px;
}

.view-Students-course-details-counts p {
  margin: 0px;
  font-size: 12px;
}

.view-course-details-edit-and-share-folder p {
  margin: 0px;
}

.view-course-details-share-btn {
  background-color: #0068ff;
  color: white;
  cursor: pointer;
}

.view-course-details-share-btn span,
.view-course-details-share-btn p {
  color: white;
}

.view-course-details-Create-lesson-container {
  border: 1px solid;
  margin: 24px 0px;
  padding: 42px 0px;
  border-radius: 10px;
  border: 1px solid #b1b1b1;
  text-align: center;
}

.view-course-details-Create-lesson-container p {
  margin: 20px 0px;
  font-size: 18px;
}

.view-course-details-Create-lesson-container button {
  padding: 8px 66px;
  font-size: 14px;
  color: white;
  background-color: #0068ff;
  border: none;
  border-radius: 5px;
}

.viewcourseDetails-section {
  position: relative;
}

/* view course details end */

/* .edit-course-Details-wrapper{
  width: 1430px;
  margin: 0px auto;
} */
.edit-Course-inner-input {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 9px;
  width: 50%;
}

.edit-course-details-inner-input-container .create-course-Grade-input-folder {
  display: flex;
  width: 50%;
  align-items: center;
  gap: 13px;
}

.edit-Course-inner-input label {
  font-size: 13px;
}

.edit-Course-inner-input label::after {
  content: "*";
  color: red;
  margin-left: 2px
}

.edit-Course-inner-input input {
  font-size: 13px;
}

.edit-course-date-and-price-container .edit-Course-inner-input {
  width: 32.5%;
}

.edit-course-date-and-price-container {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}

.edit-course-details-inner-input-container {
  display: flex;
  width: 100%;
  gap: 13px;
}

.edit-course-details-right-section {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.edit-Course-inner-input textarea {
  resize: none;
}

.Update-Publish-btn {
  margin: 40px auto;
  font-size: 14px;
  padding: 8px 32px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
  display: flex;
}
.Update-Publish-btn-disable {
  margin: 40px auto;
  font-size: 14px;
  padding: 8px 32px;
  background-color: #8c8c8c;
  color: white;
  border: none;
  outline: none;
  display: flex;
}
.edit-course-details-left-section {
  position: relative;
}

.edit-image-courses {
  background-color: #fff;
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
  color: #000;
  display: flex;
  gap: 10px;
  padding: 8px 14px;
  position: absolute;
  right: 6%;
  top: 5%;
  z-index: 2;
}

.edit-image-courses a {
  color: black;
  font-size: 13px;
}

.edit-image-courses p {
  margin: 0px;
  font-size: 13px;
}

#edit-image-course-btn {
  position: absolute;
  top: 9%;
  right: 10%;
  z-index: 2;
  width: 109px;
  opacity: 0;
}

.dashborad-Courses-folder:nth-child(2) {
  background-color: #ffe0e0;
}

.dashborad-Courses-folder:nth-child(3) {
  background-color: #cfe5fb;
}

.dashborad-Courses-folder:nth-child(4) {
  background-color: #ffdec6;
}

.Create-Course-uplode-outer-image {
  position: relative;
}

.Create-Course-uplode-outer-image-file {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.view-course-details-share-form {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #00000042;
  width: 100%;
  height: 100%;
  z-index: 113;
}

#outlined-multiline-static {
  font-size: 13px;
}

.view-course-details-share-folder {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  max-width: 600px;
  background-color: white;
  text-align: left;
  border-radius: 15px;
}

.view-course-details-share-folder h2 {
  border-bottom: 1px solid;
  margin: 0px 0px 0px 0px;
  padding: 13px 20px;
  font-size: 22px;
}

.view-course-details-share-folder p {
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  color: black;
}

.view-course-social-links-image img {
  width: 47px;
}

.viewcourse-share-btn {
  background-color: transparent;
  border: 1px solid #0068ff;
  color: #0068ff;
  cursor: pointer;
}

.viewcourse-share-btn span,
.viewcourse-share-btn p {
  color: #0068ff;
}

.view-course-details-inner-wrapper {
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.view-course-social-links-folder {
  display: flex;
  gap: 20px;
}

.copy-link-or-path-folder {
  position: relative;
}

.copy-link-or-path-folder input {
  border: 1px solid #bababa;
  font-size: 13px;
  margin-bottom: 20px;
  padding: 10px 20px;
  width: 100%;
}

.copy-link-or-path-folder button {
  /* position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%); */
  height: 100%;
  padding: 8px 31px;
  border-radius: 7px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
  font-size: 14px;
  margin: 0px auto;
  display: flex;
}

.copy-link-or-path-folder p {
  text-align: center;
  /* margin-bottom: 10px; */
}

.shocial-cross-btn {
  position: absolute;
  top: -39px;
  right: 2%;
  font-size: 16px;
  border: 1px solid;
  padding: 2px 7px;
  border-radius: 100%;
  color: black;
}

.share-course-social-media-btn {
  position: absolute;
  top: -41px !important;
  right: 2%;
  font-size: 16px;
  border: 1px solid;
  padding: 5px 7px !important;
  border-radius: 100%;
  color: black;
}

.welcome-polly-popup-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: #0000006e;
  color: white;
  z-index: 113;
  /* display: none; */
}

.welcome-polly-popup-container {
  /* background: linear-gradient(to bottom, #B5E6FF 40%, white 60%);
  background: -moz-linear-gradient(bottom, #B5E6FF 40%, white 60%);
  background: -webkit-linear-gradient(bottom, #B5E6FF 40%, white 60%);
  background: linear-gradient(to bottom, #B5E6FF 40%, white 60%); */
  background: url(../src/assets/images/welcome-poppy-popup-backgroud.png) 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 448px;
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  padding: 40px 20px;
  background-color: white;
  color: black;
  border-radius: 29px;
  text-align: center;
}

.welcome-polly-popup-image img {
  width: 122px;
  height: 122px;
  border-radius: 100%;
  object-fit: cover;
  border: 7px solid #3db8fb;
}

.welcome-polly-popup-container h2 {
  margin: 10px 0px;
  font-size: 23px;
}

.welcome-polly-popup-container p {
  font-size: 17px;
  margin: 0px 0px 0px 0px;
  color: black;
}

.welcome-polly-popup-image {
  position: relative;
}

.welcome-polly-cross-btn {
  position: absolute;
  top: -60px;
  left: 209px;
  margin: auto;
  padding: 9px 15px;
  border-radius: 100%;
  background-color: white;
}

.view-Students-course-details-folder:nth-child(1) .view-Students-course-details-counts,
.Students-courses-details-folder:nth-child(2) .Students-courses-details-counts {
  background-color: #ebf3ff;
}

.view-Students-course-details-folder:nth-child(1) .view-Students-course-details-counts p,
.Students-courses-details-folder:nth-child(2) .Students-courses-details-counts p {
  color: #0068ff;
}

.view-Students-course-details-folder:nth-child(2) .view-Students-course-details-counts p,
.Students-courses-details-folder:nth-child(1) .Students-courses-details-counts p {
  color: #9803f2;
}

.view-Students-course-details-folder:nth-child(3) .view-Students-course-details-counts,
.Students-courses-details-folder:nth-child(3) .Students-courses-details-counts {
  background-color: #d6ffd6;
}

.view-Students-course-details-folder:nth-child(3) .view-Students-course-details-counts p,
.Students-courses-details-folder:nth-child(3) .Students-courses-details-counts p {
  color: #0fba00;
}

.view-Students-course-details-folder:nth-child(4) .view-Students-course-details-counts,
.Students-courses-details-folder:nth-child(4) .Students-courses-details-counts {
  background-color: #ffecd6;
}

.view-Students-course-details-folder:nth-child(4) .view-Students-course-details-counts p,
.view-Students-course-details-folder:nth-child(6) .view-Students-course-details-counts p,
.Students-courses-details-folder:nth-child(4) .Students-courses-details-counts p {
  color: #ff8a00;
}

.view-Students-course-details-folder:nth-child(5) .view-Students-course-details-counts {
  background-color: #c3fff1;
}

.view-Students-course-details-folder:nth-child(5) .view-Students-course-details-counts p {
  color: #009672;
}

.view-Students-course-details-folder:nth-child(6) .view-Students-course-details-counts {
  background-color: #ffe4ae;
}

.view-lessons-section {
  margin: 50px 0px;
}

.view-lessons-btn-container {
  display: flex;
  border-bottom: 1px solid;
  position: relative;
}

.view-lessons-btn-container button {
  padding: 3px 32px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  text-transform: capitalize;
}

.view-course-details-Create-lesson-container-image img {
  width: 238px;
}

.view-lessons-btn-container button.active {
  border-bottom: 5px solid #0068ff;
  color: #0068ff;
}

.view-lessons-created-course-wrapper {
  margin: 20px 0px;
  padding: 33px 50px;
  box-shadow: 0px 1px 9px #dedede;
  border-radius: 11px;
  text-align: left;
}

.view-lessons-created-course-inner-prewview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view-lessons-created-course-inner-preview-title {
  display: flex;
  align-items: center;
  gap: 22px;
  margin: 0px 0px 0px 0px;
}
.view-lessons-created-course-inner-preview-title i{
  cursor: pointer;
}

.view-lessons-created-course-inner-preview-title a {
  color: black;
  font-size: 16px;
}

.view-lessons-created-course-inner-preview-title p {
  font-size: 13px;
  font-weight: 700;
  color: black !important;
  /* width: 719px; */
  width: 662px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.view-lessons-created-course-inner-preview-btn-folder {
  display: flex;
  align-items: center;
  gap: 39px;
}

.View-Progress-btn {
  font-size: 14px;
  text-decoration: underline;
  color: #0068ff;
  cursor: pointer;
}

.Preview-lesson-btn {
  padding: 5px 50px;
  font-size: 13px;
  border: 1px solid #0068ff;
  color: #0068ff;
  background-color: transparent;
}

.Create-Assignment-btn {
  padding: 5px 50px;
  font-size: 13px;
  border: 1px solid #0068ff;
  color: white;
  background-color: #0068ff;
}

.view-lessons-created-course-wrapper .preview-lession-date-folder span {
  font-size: 12px !important;
}

.view-lessons-created-course-wrapper p {
  font-size: 14px;
  color: #626262;
  color: black;
}

.preview-assignments-wrapper h2 {
  font-size: 15px;
  margin: 0px 0px 23px 0px;
}

.preview-assignments-inner-folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0px;
  border-top: 1px solid;
}

.preview-assignments-inner-folder ol {
  padding-left: 10px;
}

.preview-assignments-inner-folder ol li {
  font-size: 13px;
  /* list-style: auto; */
  list-style: none;
  width: 210px;
}

.Created-assignments-date {
  display: flex;
  gap: 10px;
}

.Created-assignments-date p {
  margin: 0px 0px 0px 0px;
  color: black;
  font-size: 12px;
}

.Created {
  color: #44b200 !important;
}

.due-date {
  color: #f43939 !important;
}

.preview-assignments-inner-folder a {
  font-size: 13px;
  text-decoration: underline;
}

.preview-assignments-inner-folder button {
  padding: 5px 36px;
  font-size: 12px;
  border: 1px solid #0068ff;
  color: #0068ff;
  background-color: transparent;
}

.assignments-sec-container {
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 0px 36px;
  margin: 20px 0px 0px 0px;
}

.assignments-sec-container .Overview-Lesson-date span {
  font-size: 13px;
}

.assignments-sec-container .Overview-Lesson-date .Overview-Lesson-date-counts p {
  font-size: 11px;
}

/* .Overview-Lesson-wrapper {
  width: 1443px;
  margin: 0px auto;
} */
.Overview-Lesson-inner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Overview-Lesson-inner-header .view-course-Details-back-btn-folder {
  margin: 0px 0px 0px 0px;
  align-items: flex-start;
}

.Overview-Lesson-date-container {
  display: flex;
  gap: 41px;
}

.Overview-Lesson-date span {
  font-size: 14px;
  color: #888888;
}

.Overview-Lesson-date-counts {
  width: 98px;
  margin: 7px auto 0px auto;
  border-radius: 35px;
  background-color: #d6ffd6;
  text-align: center;
}

.Overview-Lesson-date-counts p {
  padding: 5px 0px;
  color: #0fba00;
  font-size: 13px;
}

.Overview-Lesson-inner-header .view-course-Details-back-btn-folder h2 {
  font-size: 20px;
}

.overview-lesson-read-wrapper {
  display: flex;
  gap: 16px;
  margin: 15px 0 0;
  text-align: left;
  width: 100%;
}

.overview-lesson-read-left-section {
  background: #fff;
  border: 1px solid #e0dddd;
  border-radius: 15px;
  height: 510px;
  padding: 15px 0;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 13%;
  width: 20%;
}

.overview-lesson-read-right-section {
  width: 80%;
}

.overview-lesson-read-left-box-folder {
  padding: 17px 10px;
}

.overview-lesson-read-left-box-folder.active {
  border-left: 5px solid #0068ff;
  background-color: #ecf8ff;
}

.overview-lesson-read-left-des p {
  font-size: 13px;
  line-height: 21px;
  color: black;
}

.read-min-folder {
  display: flex;
  gap: 8px;
  margin: 10px 0px 0px 0px;
}

.read-min-folder i,
.read-min-folder p {
  font-size: 13px;
  color: #c1c1c1;
}

.overview-lesson-read-left-container {
  height: 478px;
  overflow-y: scroll;
}

.overview-lesson-read-right-inner-body {
  background: #fff;
  border: 1px solid #e0dddd;
  border-radius: 15px;
  height: 460px;
  overflow-y: scroll;
  padding: 20px;
  position: relative;
}

.chat-with-ai-edit-user-copy-past-folder.lesson_generator_body_sec_right_past_container {
  height: 514px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 10px 10px;
}

.overview-lesson-read-right-des-container {
  width: 100%;
  height: 517px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.overview-lesson-read-right-des-container pre {
  overflow-x: hidden;
  /* Hide horizontal overflow */
  white-space: pre-wrap;
  /* Allow text to wrap into next line */
}

.overview-lesson-genrate-des-AI-chat-folder .inner-chat-name li {
  font-size: 17px;
  list-style: auto;
  margin-left: 16px;
  font-weight: 600;
}

.overview-genrate-des-AI-inner-text li {
  margin-left: 17px;
  font-size: 13px;
}

.overview-genrate-des-AI-inner-text li {
  font-size: 13px !important;
}

.overview-lesson-genrate-des-AI-chat-folder {
  margin-bottom: 4px;
}

.overview-lesson-mark-complete-btn button {
  padding: 10px 20px !important;
  /* background-color: #0068ff !important; */
  /* color: white !important; */
  font-size: 11px !important;
  border-radius: 5px !important;
  /* border: none !important; */
  outline: none !important;
}

.overview-lesson-mark-complete-btn .status-disabled-button {
  color: #2BCC00;
  border: 2px solid #2BCC00;
}

.overview-lesson-mark-complete-btn .status-enabled-button {
  background-color: #0068ff !important;
  color: white !important;
}

/* 
.overview-lesson-mark-complete-btn button {
  padding: 10px 20px !important;
  background-color: #0068ff !important;
  color: white !important;
  font-size: 11px !important;
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
}
*/
.overview-next-or-preview-btn-folder {
  display: flex;
  gap: 15px;
  margin: 14px 0 0;
  position: relative;
}

.overview-next-or-preview-btn-folder button {
  display: flex;
  align-items: baseline;
  gap: 16px;
  padding: 12px 21px;
  border-radius: 12px;
  background-color: transparent;
  border: none;
  color: black;
}

.overview-next-or-preview-btn-folder button i,
.overview-next-or-preview-btn-folder button p {
  color: black;
  font-size: 13px;
}

.overview-next-or-preview-btn-folder button:nth-child(2) {
  border: 1px solid #0068ff;
}

.overview-next-or-preview-btn-folder button:nth-child(2) i,
.overview-next-or-preview-btn-folder button:nth-child(2) p {
  color: #0068ff;
}

.Overview-Lesson-date:nth-child(2) .Overview-Lesson-date-counts {
  background-color: #ffecd6;
}

.Overview-Lesson-date:nth-child(2) .Overview-Lesson-date-counts p {
  color: #ff8a00;
}

.add-lesson-btn button {
  padding: 10px 50px;
  background-color: rgb(0, 104, 255);
  color: white;
  display: flex;
  margin: 0px auto 20px auto;
  gap: 9px;
  font-size: 13px;
  border-radius: 6px;
  border: none;
  outline: none;
  position: absolute;
  top: -13px;
  right: 0px;
}

.add-lesson-btn button a {
  color: white;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: transparent !important;
  position: inherit !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  border: none !important;
  background-color: transparent !important;
  position: absolute !important;
  top: 75% !important;
  left: 89.6% !important;
  /* inset: 0px !important; */
  padding: 0px !important;
  width: 122px !important;
  height: 123px !important;
}

/* studentDashboard start */
.studentDashboardbody {
  display: flex;
  flex-direction: column;
}

.studentDashboardSidePannel {
  background-color: #f5f9ff;
  border-radius: 63px;
  display: flex;
  flex-direction: revert;
  flex-direction: row;
  gap: 123px;
  justify-content: center;
  margin: 0 auto;
  padding: 14px;
  width: 94%;
}

.studentDashboardSidePannel button {
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 36px;
  background-color: #f5f9ff;
  border: none;
  font-size: 15px;
  align-items: center;
}

.studentDashboardSidePannel button.active {
  display: flex;
  gap: 10px;
  padding: 10px 42px;
  border-radius: 36px;
  background-color: #cfe5fb;
  font-size: 16px;
  align-self: center;
  color: #0068ff;
}

.studentDashboar-section {
  text-align: left;
}

.studentDashboar-actives-container {
  position: relative;
  display: flex;
  gap: 22px;
}

.teacher_tooltip {
  position: absolute;
  top: -83px;
  left: 270px;
  background: #535353;
  border-radius: 8px;
  padding: 16px 18px;
  opacity: 0;
  transition: opacity 1s;
}

.teacher_tooltip.show {
  opacity: 1;
  /* Show with opacity transition */
}

.teacher_tooltip p {
  color: #ffffff;
  font-size: 13px;
}

.tooltip_btn {
  border-radius: 4.029px;
  border: 1.5px solid #fff;
  background-color: transparent;
  color: white;
  font-size: 13px;
  padding: 3px 14px;
  margin: 7px 0px 0px 0px;
}

.tooltip_trangle {
  position: absolute;
  bottom: -14px;
  left: 10px;
}

.studentDashboar-actives-folder {
  width: 100%;
  display: flex;
  padding: 14px 0px;
  align-items: center;
  gap: 30px;
  justify-content: center;
  border-radius: 10px;
  background-color: #f5e6ff;
  box-shadow: 0px 4px 15px #f1f1f1;
  cursor: pointer;
  transition: transform .2s; /* Animation */
}
.studentDashboar-actives-folder:hover{
  transform: scale(1.05);
}

.studentDashboar-wrapper h2 {
  font-size: 24px;
  margin: 0px 0px 16px 0px;
}

.studentDashboar-actives-course-count {
  width: 176px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 9px;
  background-color: white;
}

.studentDashboar-actives-course-count h2 {
  margin: 0px;
  font-size: 24px;
  color: #784dc7;
}

.studentDashboar-actives-course-count p {
  margin: 6px 0px 0px 0px;
  font-size: 14px;
  color: black;
}

/* .studentDashboar-actives-image img {
  width: 55px;
} */

.studentDashboardMainBody {
  flex: 1 1;
  padding: 20px 0px;
}

.studentDashboar-search-filter {
  margin: 35px 0px;
  position: relative;
}

.studentDashboar-search-filter input {
  width: 100%;
  padding: 9px 56px;
  border-radius: 35px;
  border: none;
  outline: none;
  background-color: #f7f7f7;
  font-size: 14px;
}

.serach-input-icon {
  position: absolute;
  top: 50%;
  left: 1.7%;
  transform: translateY(-50%);
  width: 20px;
}

.serach-input-icon img {
  width: 100%;
}

.studentDashboar-Suggested-Courses-short-by-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.studentDashboar-Suggested-Courses-short-by-filter-container p {
  font-size: 22px;
  color: black;
}

.studentDashboar-Suggested-Courses-short-by-filter-section {
  display: flex;
  align-items: center;
  gap: 18px;
}

.studentDashboar-Suggested-Courses-short-by-filter-inner-folder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.studentDashboar-course-filter {
  font-size: 12px;
  padding: 4px 5px;
}

.studentDashboar-Suggested-Courses-folder {
  width: 331px;
  padding: 18px;
  border-radius: 14px;
  box-shadow: 0px 2px 8px #e0e0e0;
  transition: transform .2s; /* Animation */
}
.studentDashboar-Suggested-Courses-folder:hover{
  transform: scale(1.02);
}

.studentDashboar-Suggested-Courses-container, 
.ratingCard-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin: 30px 0px 0px 0px;
}
.ratingCard-wrapper{
  margin: 30px 0px 30px 0px;
}

.studentDashboar-Suggested-Courses-image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 192px;
  cursor: pointer;
}

.studentDashboar-Suggested-Courses-profile-folder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 13px 0px 14px 0px;
}

.studentDashboar-Suggested-Courses-profile {
  display: flex;
  gap: 9px;
  align-items: center;
  cursor: pointer;
}

.studentDashboar-profile-image img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.tudentDashboar-profile-card-title {
  font-size: 14px !important;
  margin: 0px 0px 0px 0px !important;
  color: #0068ff;
  font-weight: 500;
  text-transform: capitalize;
}

.studentDashboar-Suggested-Courses-profile-title p {
  font-size: 12px;
}

.studentDashboar-Suggested-Courses-profile-rate {
  display: flex;
  align-items: center;
  gap: 6px;
}

.studentDashboar-Suggested-Courses-profile-rate a,
.studentDashboar-Suggested-Courses-profile-rate p {
  font-size: 13px;
  color: #ffbb33;
}

.number {
  color: #898989 !important;
}

.studentDashboar-profile-course-name {
  font-size: 14px;
  color: #898989;
  cursor: pointer;
}

.studentDashboar-profile-course-title {
  font-size: 17px !important;
  margin: 5px 0px 10px 0px !important;
  /* min-height: 45px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  cursor: pointer;
}

.studentDashboar-profile-price {
  font-size: 21px !important;
  font-weight: 600 !important;
  color: #ee7139;
}

.studentDashboar-profile-btn-folder {
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 12px 0px 0px 0px;
}

.studentDashboar-profile-btn-folder button {
  width: 100%;
  padding: 7px 0px;
  font-size: 13px;
  border-radius: 7px;
  background-color: #0068ff !important;
  color: white !important;
  border: none;
  outline: none;
  font-size: 13px;
}
.studentDashboar-profile-btn-folder button:nth-child(1){
  background-color: transparent !important;
  color: #0068ff !important;
  border: 2px solid #0068ff;
  font-weight: 500;
}

.studentDashboar-profile-btn-folder button:disabled {
  background-color: white !important;
  border: 1px solid #0068ff !important;
  color: #0068ff !important;
  font-size: 15px !important;
}

.studentDashboar-profile-btn-folder button:disabled i {
  margin-right: 10px;
}

.studentDashboar-profile-btn-folder button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #0068ff;
  color: #0068ff;
}

.studentDashboar-actives-folder:nth-child(2) {
  background-color: #ffeee2;
}

.studentDashboar-actives-folder:nth-child(3) {
  background-color: #e8f3fe;
}

.studentDashboar-popup-container {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 100%;
  background-color: #00000024;
  z-index: 113;
}

.studentDashboar-popup-section .dashboard-popup-teacher-profile-gender-folder select {
  padding: 7px 10px 7px 38px;
}

.studentDashboar-section .welcome-polly-popup-container {
  text-align: center;
  display: none;
}

.studentDashboard-chatbot-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #00000026;
  z-index: 1051;
}

/* .studentDashboard-chatbot-container {
  position: fixed;
  bottom: 14%;
  right: 3%;
  width: 419px;
  height: 661px;
  background-color: white;
  border-radius: 34px;
} */
.studentDashboard-chatbot-container {
  position: fixed;
  bottom: 11%;
  right: 3%;
  width: 419px;
  height: 595px;
  background-color: white;
  border-radius: 34px;
}

.studentDashboard-chatbot-title-folder {
  display: flex;
  gap: 13px;
  align-items: center;
  padding: 23px;
}

/* .studentDashboard-chatbot-container-image img{
  width: 100%;
} */
.studentDashboard-chatbot-title-folder h2 {
  font-size: 19px;
  margin: 0px;
}

/* .studentDashboard-universal-chatbot-inner-chat {
  width: 100%;
  height: 514px;
  margin: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0px 17px;
  overflow-y: scroll;
  position: relative;
} */
.studentDashboard-universal-chatbot-inner-chat {
  width: 100%;
  height: 442px;
  margin: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0px 17px;
  overflow-y: scroll;
  position: relative;
}

.studentDashboard-universal-chatbot-AI-des,
.studentDashboard-universal-chatbot-send-massege {
  width: 294px;
  padding: 14px;
  font-size: 12px;
  color: black;
  margin-top: 10px;
  line-height: 19px;
  border-radius: 15px 15px 15px 0px;
  background-color: #edf5ff;
  text-align: left;
}

.studentDashboard-universal-chatbot-send-massege {
  margin-left: auto;
  margin-right: 0px;
  border-radius: 15px 15px 0px 15px;
  text-align: right;
}

.studentDashboard-universal-chatbot-send-text-folder textarea {
  width: 100%;
  height: 56.4px;
  border-radius: 0px 0px 34px 34px;
  resize: none;
  padding: 18px 50px;
  font-size: 13px;
  background-color: #f5f5f5;
  border: none;
  outline: none;
}

.studentDashboard-universal-chatbot-send-text-folder {
  margin: 0px 0px 0px 0px;
  position: relative;
}

#studentDashboard-universal-chatbot-send-btn {
  position: absolute;
  top: 50%;
  right: 6% !important;
  transform: translateY(-50%);
}

.studentDashboard-chatbot-container-close-image {
  position: absolute;
  top: 3%;
  right: 6%;
}

.studentDashboard-chatbot-container-minimize-image {
  position: absolute;
  top: 3%;
  right: 14%;
}

/* .studentDashboard-chatbot-container-minimize-image img,
.studentDashboard-chatbot-container-close-image img {
  width: 23px;
} */

.studentDashboard-chatbot-container-image {
  position: fixed;
  bottom: 2%;
  right: 2%;
}

.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar,
.chat-with-ai-lession-container::-webkit-scrollbar {
  width: 0px;
}


/* width */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}





.genrate-des-AI-inner-container::-webkit-scrollbar,
.preview-lession_generator_body_inner_des_folder_list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.genrate-des-AI-inner-container::-webkit-scrollbar-track,
.preview-lession_generator_body_inner_des_folder_list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.genrate-des-AI-inner-container::-webkit-scrollbar-thumb,
.preview-lession_generator_body_inner_des_folder_list::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 10px;
}

/* Handle on hover */
.genrate-des-AI-inner-container::-webkit-scrollbar-thumb:hover,
.preview-lession_generator_body_inner_des_folder_list::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}

/* studentDashboard end */

.logo img {
  width: 124px;
}

.coming-soon {
  position: absolute;
  top: 37px;
  right: 70px;
}

.coming-soon img {
  width: 63px;
}

.ql-toolbar.ql-snow {
  background-color: #f2f7ff !important;
  text-align: left !important;
  padding: 13px 21px !important;
  border: none !important;
}

.ql-container.ql-snow {
  position: relative !important;
  padding: 15px !important;
  background: #fff !important;
  border-radius: 15px !important;
  border: none !important;
  height: 550px !important;
}

.ql-editor {
  height: 514px !important;
  width: 100% !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 10px 10px !important;
  text-align: left !important;
}

.quill {
  box-shadow: 0px 1px 5px #dadada !important;
}

.ql-toolbar .ql-header h3::before,
.ql-toolbar .ql-header h4::before {
  content: "H3";
  color: black !important;
}

.ql-toolbar .ql-header h3::before {
  font-size: 14px;
  color: black !important;
}

.ql-toolbar .ql-header h4::before {
  font-size: 12px;
}

.Waveform-module_container__kGWZq {
  position: absolute;
  top: 40%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
}

.created-my-courses-container,
.School_Course_Card_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* .Course-Start-Date input,
.Course-end-Date input {
  padding-left: 43px;
} */

.edit-course-or-delete-course i {
  font-weight: 900;
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: auto;
}

.loading-spinner-course {
  border: 4px solid white;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: loading 1s linear infinite;
  margin-left: 72.5px;
  margin-right: 72.5px;
}

.loading-spinner-lesson {
  border: 4px solid white;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: loading 1s linear infinite;
  /* Apply the animation */
  margin: 0 auto;
  /* Center the spinner */
  margin-top: 20px;
  /* Adjust as needed */
}

.blue-border {
  border: 1px solid blue !important;
}

.Create-Course-uplode-image-container button {
  width: 185px;
}

.Create-Course-image p {
  color: #333;
}

.introduce-yourself-text {
  line-height: 1.8;
  font-size: 12px;
}

.introduce-yourself-text::placeholder {
  color: #afafaf;
}

.minimized {
  height: 300px;
  /* Add any other styles you want for the minimized chatbot here */
}

.studentDashboard-chatbot-Small-AI-image img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
}

.student-view-course-Detaails-folder {
  display: flex;
  align-items: center;
}

.StudentViewCourseDetails-lesson-wrapper {
  /* display: flex; */
  text-align: left;
  margin: 30px 0px 0px 0px;
}

.StudentViewCourseDetails-lesson-wrapper h2 {
  font-size: 18px;
}

.StudentViewCourseDetails-lesson-folder {
  /* border: 1px solid; */
  padding: 33px;
  box-shadow: 0px 1px 7px #cfcfcf;
  margin-bottom: 30px;
  border-radius: 7px;
}

.StudentViewCourseDetails-lesson-toggle-dropdown-section {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  /* margin: 0px 0px 29px 0px; */
}

.StudentViewCourseDetails-lesson-toggle-btn {
  display: flex;
  align-items: center;
  gap: 13px;
}

.StudentViewCourseDetails-lesson-toggle-btn h2 {
  font-size: 15px;
  margin: 0px;
}

.StudentViewCourseDetails-lesson-toggle-dropdown-section p {
  font-size: 14px;
  color: black;
  display: flex;
  align-items: center;
  gap: 7px;
}

.StudentViewCourseDetails-lesson-des {
  width: 458px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.StudentViewCourseDetails-lesson-inner-assignments-container {
  border: 1px solid #e7e7e7;
  padding: 28px;
  border-radius: 10px;
  margin: 30px 0px 0px 0px;
}

.StudentViewCourseDetails-lesson-inner-assignments-container h2 {
  border-bottom: 1px solid;
  margin: 0px 0px 20px 0px;
  padding-bottom: 28px;
  font-size: 18px !important;
  font-weight: 400;
}

.StudentViewCourseDetails-lesson-inner-assignments-folder h2 {
  font-size: 14px !important;
  margin: 0px 10px 6px 0px;
  border: none;
  padding: 0px;
}

.StudentViewCourseDetails-lesson-inner-assignments-folder p {
  font-size: 14px;
  color: black;
}

.StudentViewCourseDetails-lesson-inner-assignments-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.StudentCourseCard-lessons-tab-container {
  display: flex;
  justify-content: space-between;
}

.tab-content-courses {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.no-courses {
  flex-grow: 1;
  /* This will make sure the <p> takes the full width if no other elements are there */
  text-align: center;
  /* Center the text horizontally */
  align-self: center;
  /* Center the text vertically */
  margin: 30px 0px 0px 0px;
}

#studentCourseCard-folder {
  text-align: left;
}

.StudentCourseCard-lessons-search-filter {
  width: 100% !important;
}

/* student Active Lessons start */
.student-Active-Lessons {
  text-align: left;
  font-size: 20px;
}

.Student-Active-Lesson-folder {
  display: flex;
  align-items: center;
  text-align: left;
  /* padding: 28px; */
  gap: 24px;
  justify-content: space-between;
  /* box-shadow: 0px 1px 5px #d2d2d2; */
  /* border-radius: 9px; */
}

.Student-Active-main-wrapper {
  padding: 33px;
  box-shadow: 0px 1px 7px #cfcfcf;
  margin-bottom: 30px;
  border-radius: 7px;
}

.Student-Active-Lesson-title-folder h2 {
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  font-weight: 500;
  width: 234px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Student-Active-Lesson-title-folder p {
  font-size: 13px;
}

.Student-Active-Lesson-folder p {
  display: flex;
  gap: 9px;
  align-items: center;
}

.Student-Active-Lesson-des,
.Student-Active-Assignment-des {
  font-size: 13px;
  font-weight: 400;
  color: #645e5e;
  height: 18px;
  width: 213px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.Student-Active-Lesson-folder p span {
  color: black;
}

.Student-Active-Lesson-title-folder {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.Student-Active-start-Lesson {
  padding: 7px 47px;
  font-size: 13px;
  background-color: #0068ff;
  color: white;
  border-radius: 6px;
  border: none;
  outline: none;
}

.Student-Active-Lesson-container {
  display: flex;
  flex-direction: column;
  /* gap: 22px; */
  margin-bottom: 20px;
}

/* student Active Lessons end */
.invite-course-wrapper.invite-student-popup-folder {
  text-align: center;
}

.invite-student-popup-folder button {
  position: inherit;
  transform: translateY(0px);
  padding: 8px 31px;
  border-radius: 7px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
  font-size: 14px;
  margin: 0px auto 0px auto;
}

.invite-student-close-btn {
  position: absolute;
  top: -41px;
  padding: 5px 8px;
}

.invite-student-feilds-folder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 22px;
  gap: 10px;
  border-radius: 34px;
  /* background-color: #B7E5FF; */
  border: 1px solid;
  background-color: transparent;
}

.invite-student-feilds-folder p {
  font-size: 14px;
  text-transform: lowercase;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.upload-circle {
  background-color: #007bff;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.plus-sign {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.file-input {
  display: none;
}

.upload-container p {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}

.StudentCourseCard-lessons-tab-container button {
  font-size: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.list-of-students-wrappper table thead tr {
  border-bottom: 1px solid #a1d3ef;
}

.list-of-students-wrappper table thead tr th,
.LessonViewProgress-table-container table thead tr th {
  font-size: 14px;
  padding: 0px 0px 18px 0px;
  font-weight: 500;
}
.list-of-students-wrappper table thead tr th input{
  cursor: pointer;
}

.LessonViewProgress-table-container table thead tr th:nth-child(1),
.LessonViewProgress-table-container table tbody tr td:nth-child(1),
.AssignmentViewProgress-table-container table thead tr th:nth-child(7),
.AssignmentViewProgress-table-container table tbody tr td:nth-child(7),
.AssignmentViewProgress-table-container table thead tr th:nth-child(8) , 
.LessonViewProgress-table-container table thead tr th:nth-child(7){
  text-align: center;
}
.LessonViewProgress-table-container table thead tr th:nth-child(1),
.LessonViewProgress-table-container table tbody tr td:nth-child(1){
  text-align: left;
}

.list-of-students-wrappper table tbody tr {
  box-shadow: 0px 1px 9px #dbdbdb;
  border-radius: 5px;
  background-color: #F5FBFF;
}

.list-of-students-wrappper table tbody tr td {
  font-size: 13px;
  padding: 15px 0px;
}

/* .list-of-students-wrappper table tbody tr td:nth-child(1) {
  cursor: pointer;
  width: 31%;
} */
.list-of-students-wrappper table tbody tr td:nth-child(1) {
  width: 6%;
}
.student-request-wrapper table tbody tr td:nth-child(1) {
  width: auto;
}
#invited-student-list-section .list-of-students-wrappper table tbody tr td:nth-child(1) {
  width: 6%;
}
.list-of-students-wrappper table tbody tr td:nth-child(1) input {
  cursor: pointer;
}
#invited-student-list-section .studentrequest-profile-image {
  width: 28%;
}
.student-remove {
  font-size: 12px;
  padding: 6px 16px;
  background-color: white;
  border: none;
  border-radius: 5px;
  /* color: black; */
  box-shadow: 0px 2px 6px #dcdcdc;
  cursor: pointer;
}

.list-of-students-wrappper table tbody tr td img {
  margin: 0px 17px 0px 0px;
  width: 65px;
  height: 65px;
  border-radius: 100%;
}

.progress {
  width: 72%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  height: 12px;
  text-align: center;
  display: flex;
  /* justify-content: center; */
  margin: 0px auto;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
}

.list-of-students-wrappper table tbody tr td a {
  border: 1px solid;
  padding: 7px 10px;
  font-size: 11px;
  border-radius: 4px;
  color: #0068ff;
}

.list-of-students-wrappper {
  margin: 30px 0px 0px 0px;
  padding: 22px;
  box-shadow: 0px 1px 8px #e3e3e3;
  border-radius: 6px;
  text-align: center;
}

.list-of-students-gap {
  margin: 29px 0px;
}

.student-request-btn-folder {
  display: flex;
  justify-content: center;
  gap: 27px;
}

.student-request-btn-folder button {
  padding: 6px 33px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #22a82f;
  color: white;
  border: none;
  outline: none;
}

.student-request-btn-folder button:nth-child(1) {
  background-color: #cc3d3d;
}

/* lessonviewprogress start */
.LessonViewProgress-header-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.LessonViewProgress-student-course-folder {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 231px;
  gap: 17px;
}

.LessonViewProgress-student-course-folder .view-Students-course-details-counts {
  padding: 6px 33px;
  margin: 0px 0px 0px 0px;
}

.LessonViewProgress-header-section button {
  padding: 8px 40px;
  font-size: 13px;
  background-color: #0068ff;
  color: white;
  border: 1px solid;
  outline: none;
}

.LessonViewProgress-tab-folder-container {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 0px 0px;
}

.LessonViewProgress-serach-filter {
  width: 60%;
  position: relative;
}

.LessonViewProgress-serach-filter input {
  width: 100%;
  height: 100%;
  font-size: 13px;
  padding: 8px 48px;
  border-radius: 47px;
  background-color: #f0f3f5;
  border: none;
  outline: none;
}

.lessonviewprogress-filder-tab {
  display: flex;
  align-items: baseline;
  gap: 10px;
  padding: 5px 13px;
  color: black;
  cursor: pointer;
}

.LessonViewProgress-header-section .view-course-details-container {
  display: flex;
  gap: 0px;
}

.lessonviewprogress-filder-tab span {
  font-size: 16px;
}

.lessonviewprogress-filder-tab p , 
.Filter-teacher-type-wrapper p{
  font-size: 15px;
  color: black;
}
.Filter-teacher-type-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Filter-teacher-type-select-dropdown .css-1nrlq1o-MuiFormControl-root .MuiInputBase-formControl .MuiInputBase-input {
  padding: 8px 18px;
  font-size: 14px;
  font-weight: 400;
}

.LessonViewProgress-tab-filter-downlode-folder span {
  font-size: 16px;
  border-radius: 100%;
  width: 37px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0068ff;
  color: white;
}

.LessonViewProgress-filder-tab-wrapper {
  display: flex;
  position: relative;
}

.LessonViewProgress-tab-filter-wrapper {
  display: flex;
  align-items: center;
}

.LessonViewProgress-table-container {
  margin: 58px 0px 0px 0px;
}

.LessonViewProgress-table-container table thead tr th {
  color: #807f7f;
}

.LessonViewProgress-table-container table thead tr,
.LessonViewProgress-table-container table tbody tr {
  border-bottom: 1px solid #d9d9d9;
}

.LessonViewProgress-table-container table tbody tr td {
  padding: 22px 0px;
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.LessonViewProgress-table-container table {
  text-align: left;
}

.LessonViewProgress-table-container table tbody tr td:nth-child(4),
.LessonViewProgress-table-container table tbody tr td:nth-child(5),
.LessonViewProgress-table-container table tbody tr td:nth-child(6),
.LessonViewProgress-table-container table thead tr th:nth-child(4),
.LessonViewProgress-table-container table thead tr th:nth-child(5),
.LessonViewProgress-table-container table thead tr th:nth-child(6) {
  text-align: center;
}

.LessonViewProgress-student-chat {
  /* border: 1px solid #0068ff3d; */
  width: 42px;
  padding: 11px 20px;
  padding: 11px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 0px auto;
  /* background-color: #dee8f8; */
  position: relative;
}

/* .LessonViewProgress-student-chat img {
  width: 25px;
  height: 24px;
  position: absolute;
  top: -10px;
  right: -9px;
} */
.student-chat-profile{
  width: 42px;
  height: 42px;
  border-radius: 100%;
}
.massege-icon {
  width: 25px;
  height: 24px;
  position: absolute;
  top: 6px;
  right: -9px;
}

.LessonViewProgress-student-status {
  border: 1px solid;
  margin: 0px auto;
  padding: 4px 0px;
  border-radius: 34px;
  width: 133px;
  font-size: 13px;
}

.LessonViewProgress-student-status.completed-class {
  background-color: #e6ffe5;
  color: #28991f;
}

.LessonViewProgress-student-status.in-progress-class {
  background-color: #ffd6d6;
  color: #ba2525;
}

.LessonViewProgress-student-status.pending-class {
  background-color: #ffd6d6;
  color: #ba2525;
}

.LessonViewProgress-student-status.overdue-class {
  background-color: #ffe5cc;
  color: #ffa800;
}

.LessonViewProgress-table-container table tbody tr td a {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.completedat-time,
.startedAt-time {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.startedAt-time span:nth-child(2),
.completedat-time span:nth-child(2) {
  color: #898989;
  font-size: 13px;
}

.completedat-time img {
  position: absolute;
  top: 0px;
  right: 15px;
}

/* lessonviewprogress end */

/* TeacherStudentProfile-section start */
.TeacherStudentProfile-details-container {
  text-align: left;
}

.TeacherStudentProfile-name-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f4f9ff;
  padding: 17px 63px;
}

.TeacherStudentProfile-name-folder {
  text-align: left;
}

.TeacherStudentProfile-back-btn-folder {
  padding: 0px 61px;
}

.TeacherStudentProfile-name-folder h2 {
  margin: 0px 0px 4px 0px;
  font-size: 24px;
  font-weight: 500;
}

.TeacherStudentProfile-name-folder p,
.TeacherStudentProfile-date {
  font-size: 12px;
  color: black;
}

.TeacherStudentProfile-aboout-folder {
  margin: 20px 0px 36px 0px;
  padding: 18px;
  box-shadow: 0px 2px 11px #e0e0e0;
  border-radius: 8px;
}

.TeacherStudentProfile-about-title {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid;
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 10px 0px;
}

.TeacherStudentProfile-about-title a {
  font-size: 18px;
}

.TeacherStudentProfile-about-title h2 {
  margin: 0px 0px 0px 0px;
  font-size: 19px;
}

.TeacherStudentProfile-aboout-folder p {
  font-size: 13px;
  color: black;
  line-height: 21px;
}

.TeacherStudentProfile-all-Progress-folder {
  width: 376px;
  padding: 14px;
  box-shadow: 0px 3px 13px #dedede;
  margin: 0px 0px 30px 0px;
  border-radius: 5px;
}

.TeacherStudentProfile-all-Progress-title-folder {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 23px 0px;
  gap: 10px;
}

.TeacherStudentProfile-all-Progress-image img {
  width: 24px;
}

.TeacherStudentProfile-all-Progress-title-folder h2 {
  margin: 0px;
  font-size: 19px;
}

.TeacherStudentProfile-all-hobbies-wrapper {
  display: flex;
  width: 100%;
}

.TeacherStudentProfile-all-hobbies-folder {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.TeacherStudentProfile-all-hobbies-folder p {
  color: black;
  font-size: 13px;
}

.TeacherStudentProfile-all-Progress-container {
  display: flex;
  gap: 20px;
}

/* TeacherStudentProfile-section end */

/* @media (min-width: 1280px) and (max-width: 1299px) {
  .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
    display: flex;
    gap: 24px !important;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-container p {
    font-size: 18px;
  }

  .studentDashboar-search-filter {
    width: 461px !important;
  }

  .studentDashboar-Suggested-Courses-folder {
    width: 272px;
  }

  .studentDashboar-Suggested-Courses-image img {
    width: 100%;
    height: 157px;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 17%;
    right: 3%;
    width: 397px;
    height: 566px;
  }

  .studentDashboard-universal-chatbot-inner-chat {
    width: 100%;
    height: 414px;
  }

  .course-file-image {
    width: 100%;
    height: 273px;
  }

  .dashboardSidePannel button,
  .dashboardSidePannel button.active {
    display: flex;
    font-size: 14px !important;
    align-items: center;
  }
  .dashboard-name-title {
    font-size: 21px;
  }
  .dashborad-Courses-count h2 {
    font-size: 20px;
  }
  .dashborad-Courses-count p {
    font-size: 12px;
  }
  .Start-by-creating-your-course-container p {
    font-size: 13px;
  }
  .Start-by-creating-your-course-container button {
    padding: 8px 41px;
    font-size: 12px;
  }
  .created-my-courses-folder-image-left-folder, 
  .view-course-details-left-section, 
  .edit-course-details-left-section {
    width: 22%;
  }
  .created-my-courses-des-wrapper, 
  .edit-course-details-right-section {
    width: 75%;
  }
  .created-my-courses-title-wrapper h2, 
  .view-course-details-right-section h2 {
    font-size: 20px;
  }
  .Students-courses-details-folder span, 
  .view-Students-course-details-folder span {
    font-size: 11px !important;
  }
  .Students-courses-details-counts p, 
  .view-Students-course-details-counts p {
    font-size: 11px;
  }
  .Students-des, 
  .teacherviewcoursedetails-des {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .teacherviewcoursedetails-des{
    margin: 11px 0px 8px 0px !important;
  }
  .view-course-details-image {
    width: 100%;
    height: 231px;
  }
  .view-lessons-btn-container button {
    font-size: 15px;
  }
  .coming-soon {
    position: absolute;
    top: 37px;
    right: 54px;
  }

  .edit-course-details-right-section {
    display: flex;
    gap: 7px;
  }

  .edit-image-courses p {
    font-size: 12px;
  }

  .edit-image-courses {
    padding: 8px 9px;
  }

  .Update-Publish-btn {
    margin: 19px 0px;
    font-size: 12px;
  }

  .view-course-Details-back-btn-folder h2 {
    font-size: 21px;
  }

  .studentDashboardSidePannel button.active,
  .studentDashboardSidePannel button {
    display: flex;
    font-size: 15px;
    align-items: center;
  }

  .studentDashboar-profile-btn-folder button {
    font-size: 13px;
  }

  .studentDashboar-profile-course-title {
    font-size: 16px !important;
  }

  .studentDashboar-wrapper h2 {
    font-size: 22px;
  }
  .AssingmentMCQs-header-main-contaiiner h2, 
  .AssingmentMCQs-mock-test-question-answer-container h2 {
    font-size: 16px;
  }
  .AssingmentMCQs-Time-remaining-folder p {
    font-size: 13px;
  }
  .AssingmentMCQs-Time-remaining {
    font-size: 12px;
  }
  .AssingmentMCQs-mock-test-answer-folder {
    font-size: 12px;
  }
  .AssingmentMCQs-mock-test-answer-folder input {
    width: 15px;
    height: 15px;
  }
  .AssingmentMCQs-mock-test-answer-btn-folder button {  
    font-size: 14px;
  }
  .settings_main_sec_head ul li a {
    font-size: 15px;
  }
  .View-Course, 
  .Create-Course-btn-wrapper button, 
  .Create-Course-uplode-image-container button, 
  .Create-Course-uplode-outer-image button, 
  .Regenerate-course-btn, 
  .Create-Assignment-btn, 
  .Preview-lesson-btn, 
  .View-Progress-btn, 
  .add-lesson-btn button  {
    font-size: 12px;
  }
  .created-my-courses-folder-image {
    width: 100%;
    height: 227px;
  }
  .Create-Courses-wrapper h2 {
    font-size: 21px;
  }
  .assignments-sec-container .Overview-Lesson-date span {
    font-size: 12px;
  }
  .view-lessons-created-course-wrapper p {
    font-size: 13px;
  }
  .edit-Course-inner-input label {
    font-size: 13px;
  }
  .edit-Course-inner-input input, 
  .edit-Course-inner-textarea label {
    font-size: 12px;
  }
  .msg_sec {
    font-size: 20px;
  }
  .msg_left_search input {
    font-size: 12px;
  }
  .msg_data_sec h6 {
    font-size: 12px !important;
  }
  .msg_data_sec p {
    font-size: 10px;
  }
  .msg_sec_new__left_single_inner span, 
  .right_chat_sec_new_head h6 , .right_chat_sec_right span, 
  .right_chat_sec_left span {
    font-size: 12px !important;
  }
  .right_chat_sec_new_head p {
    font-size: 11px;
  }
  .msg_sec {
    font-size: 19px;
  }
  .settings_main_sec_head ul li a {
    font-size: 14px !important;
    padding: 10px 40px !important;
  }
  .security_alerts_sec_new_single_left h6 {
    font-size: 15px;
  }
  .LessonViewProgress-table-container table tbody tr td , 
  .list-of-students-wrappper table thead tr th, 
  .LessonViewProgress-table-container table thead tr th, 
  .LessonViewProgress-table-container table tbody tr td a{
    font-size: 13px;
  }
  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }
  .LessonAnalytics-wrapper p {
    font-size: 16px !important;
  }
  .LessonAnalytics-profile-folder h2 {
    font-size: 17px !important;
    margin: 9px 0px 0px 0px !important;
  }
  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 70px !important;
  }
  .step-container {
    margin: 23px auto 0px auto !important;
  }
  .AssingmentMCQs-mock-test-question-answer-container {
    margin: 35px auto 0px auto !important;
  }
  .signup-right-section h2 {
    font-size: 30px;
  }
  .signup-tab-folder, 
  .signup-tab-folder button.active, 
  .signup-tab-folder button {
    padding: 9px 0px;
    font-size: 10px;
  }
  .forget-password-btn {
    font-size: 11px;
    margin: 0px 0px 0px 0px;
  }
  .signup-form {
    margin: 40px auto 0px auto;
  }
  .signup-btn {
    margin: 13px 0px 9px 0px;
  }
  .signup-btn button {
    font-size: 11px;
  }
  .signup-or-title, 
  .login-or-title {
    font-size: 12px !important;
    margin: 10px 0px 13px 0px;
  }
  .login-btns {
    padding: 6px 9px;
    font-size: 13px;
  }
  .login-btns p {
    font-size: 11px;
  }
  .Already-have-an-accout-folder span {
    font-size: 13px !important;
    margin: 15px 0px 0px 0px;
  }
  .terms-and-Privacy-policy {
    font-size: 9px;
    margin: 8px 0px 5px 0px;
  }
  .Register-tab-section {
    margin: 12px 0px 0px 0px;
  }
} */

/* @media (min-width: 1300px) and (max-width: 1399px) {
  .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
    display: flex;
    gap: 24px !important;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-container p {
    font-size: 16px;
  }

  .studentDashboar-Suggested-Courses-folder {
    width: 293px;
  }

  .studentDashboar-Suggested-Courses-image img {
    width: 100%;
    height: 157px;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 17%;
    right: 3%;
    width: 419px;
    height: 550px;
  }

  .studentDashboard-universal-chatbot-inner-chat {
    width: 100%;
    height: 398px;
  }

  .dashboard-name-title {
    font-size: 20px;
  }

  .dashborad-Courses-folder {
    width: 156px;
  }

  .dashborad-Courses-count h2 {
    font-size: 18px;
  }

  .dashborad-Courses-count p {
    font-size: 11px;
  }

  .Start-by-creating-your-course-container {
    padding: 14px 32px;
    margin: 31px 0px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 12px;
  }

  .Start-by-creating-your-course-container button {
    padding: 8px 41px;
    font-size: 11px;
  }

  .my-course-title {
    font-size: 19px;
  }

  .created-my-courses-title-wrapper h2, 
  .view-course-details-right-section h2 {
    font-size: 19px;
  }

  .created-my-courses-ratings-folder a {
    font-size: 9px;
  }

  .created-my-courses-ratings-folder p {
    font-size: 11px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 11px !important;
  }

  .Students-courses-details-counts, 
  .view-Students-course-details-counts {
    padding: 3px 0px;
    font-size: 12px;
}
.view-Students-course-details-counts {
  margin: 5px 0px 0px 0px;
}
.view-course-details-image, 
.created-my-courses-folder-image  {
  width: 100%;
  height: 223px;
}
.edit-image-courses {
  position: absolute;
  top: 3%;
  right: 5%;
}

.Students-des {
  font-size: 12px;
  line-height: 20px;
  margin: 11px 0px 0px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

  .View-Course {
    font-size: 10px;
  }

  .dashboardSidePannel button,
  .create-course-text, 
  .settings_main_sec_head ul li a {
    font-size: 14px !important;
  }
  .Personalize-your-AI-Assistant-right-section h2 {
    font-size: 17px;
  }

  .dashboardHeaderProfileText {
    font-size: 14px;
  }

  .Create-Courses-wrapper h2 {
    font-size: 20px;
  }

  .Create-Course-inner-input label,
  .Create-Course-des-folder label {
    font-size: 12px;
  }

  .Create-Course-btn-wrapper button {
    font-size: 11px;
  }

  .Create-Course-uplode-image-container button {
    width: 185px;
    font-size: 11px;
  }

  .Course-description-inner-ai-feilds-folder p,
  .Course-description-inner-ai-feilds span {
    font-size: 11px;
  }

  .Course-description-inne img {
    width: 22px;
  }

  .Create-Course-inner-input-container {
    display: flex;
    gap: 10px;
  }

  .view-course-Details-back-btn-folder h2 {
    font-size: 20px !important;
  }

  .view-course-details-right-section p {
    margin: 0px 0px 0px 0px;
  }
  .teacherviewcoursedetails-des, 
  .studentviewcoursedetails-des{
    font-size: 12px !important;
    margin: 6px 0px 8px 0px !important;
    line-height: 20px !important;
  }
  #StudentViewCourseDetails-section .studentDashboar-profile-btn-folder .view-course-details-edit-and-share-folder p {
    font-size: 13px;
  }

  .view-course-details-Create-lesson-container-image img {
    width: 222px;
  }

  .view-course-details-Create-lesson-container button {
    font-size: 11px;
  }

  .view-course-details-Create-lesson-container p {
    font-size: 14px;
    margin: 14px 0px;
  }

  .view-lessons-btn-container button {
    font-size: 13px;
  }

  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th {
    font-size: 13px;
  }

  .student-request-btn-folder button {
    font-size: 11px;
  }

  .view-course-details-share-folder h2 {
    font-size: 16px;
  }

  .view-course-details-share-folder p {
    font-size: 14px;
  }

  .copy-link-or-path-folder input {
    font-size: 12px;
  }

  .copy-link-or-path-folder button {
    position: absolute;
    top: 50%;
    right: 0%;
    font-size: 12px;
  }

  .Subjects-you-teach-feilds-folder {
    margin: 13px 0px;
  }

  .invite-student-close-btn {
    position: absolute;
    top: -37px;
  }

  .add-lesson-btn button {
    font-size: 11px;
  }

  .edit-course-details-right-section {
    display: flex;
    gap: 10px;
  }

  .edit-Course-inner-input label {
    font-size: 12px;
  }

  .edit-Course-inner-input input {
    font-size: 11px;
  }

  #outlined-multiline-static {
    font-size: 11px;
  }

  .Update-Publish-btn {
    margin: 22px 0px;
    font-size: 11px;
  }

  .edit-image-courses p {
    font-size: 11px;
  }

  .invite-student-popup-folder button {
    position: inherit;
  }

  .assign_main_box_left_single label,
  .assign_main_box_right_single label {
    font-size: 12px !important;
  }

  .lesson_generator_body_sec_left h6,
  .lesson_generator_body_sec_right h6,
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 13px !important;
  }

  .chat-with-ai-lession-container {
    width: 100%;
    height: 421px !important;
  }

  .editor-container {
    height: 430px !important;
  }

  .promote_part_sec textarea {
    padding: 14px 12px 9px 12px;
    font-size: 14px;
    height: 50px;
  }

  .go_cls_btn {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .proc_btn_new {
    font-size: 14px;
  }

  .signup-btn {
    margin: 20px 0px 12px 0px;
  }

  .signup-or-title,
  .login-or-title {
    margin: 10px 0px 14px 0px;
  }

  .Already-have-an-accout-folder span {
    margin: 11px 0px 0px 0px;
  }

  .create-course-text {
    font-size: 13px;
  }

  .welcome-polly-popup-container h2 {
    font-size: 18px;
  }

  .studentDashboar-wrapper h2 {
    font-size: 21px;
  }

  .studentDashboar-actives-folder {
    padding: 9px 0px;
  }

  .studentDashboar-actives-course-count h2 {
    font-size: 22px;
  }

  .studentDashboar-actives-course-count p {
    font-size: 13px;
  }

  .studentDashboar-search-filter input {
    font-size: 12px;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span {
    font-size: 14px;
  }

  .studentDashboar-profile-image img {
    width: 30px;
    height: 30px;
  }

  .tudentDashboar-profile-card-title {
    font-size: 13px !important;
  }

  .studentDashboar-profile-course-title {
    font-size: 14px !important;
  }

  .studentDashboar-profile-btn-folder button {
    font-size: 13px;
  }
  .StudentViewCourseDetails-lesson-toggle-btn h2 {
    font-size: 16px;
  }
  .StudentViewCourseDetails-lesson-inner-assignments-container h2 {
    font-size: 18px;
  }

  .studentDashboardSidePannel button {
    display: flex;
    font-size: 14px !important;
    align-items: center;
  }
  .StudentViewCourseDetails-lesson-inner-assignments-folder h2{
    font-size: 16px !important;
  }

  .student-Active-Lessons {
    text-align: left;
    font-size: 18px;
  }

  .Student-Active-Lesson-title-folder h2 {
    margin: 0px 0px 0px 0px;
    font-size: 16px;
  }

  .dashboard-popup-welcome-image img {
    width: 89%;
  }

  .dashboard-popup-welcome-folder h2 {
    margin: 20px 0px 7px 0px;
    font-size: 28px;
  }

  .dashboard-popup-welcome-folder button {
    font-size: 13px;
  }

  .dashboard-popup-teacher-profile-details-title h2 {
    font-size: 17px;
  }

  .dashboard-popup-teacher-profile-details-input label {
    font-size: 12px;
  }

  .dashboard-popup-teacher-profile-details-input input {
    font-size: 12px;
  }

  .dashboardHeaderProfileText {
    font-size: 14px !important;
  }

  .search-input {
    padding: 9px;
  }

  .overview-lesson-read-left-des p {
    font-size: 13px;
  }

  .coming-soon {
    position: absolute;
    top: 37px;
    right: 59px;
  }

  .course-file-image {
    height: 222px;
  }

  .Student-Active-start-Lesson {
    font-size: 12px;
  }

  .Overview-Lesson-date span {
    font-size: 13px;
  }

  .Overview-Lesson-date-counts p {
    font-size: 12px;
  }

  .Create-Course-des-wrapper {
    margin: 13px 0px 0px 0px;
  }
  .AssingmentMCQs-header-main-contaiiner h2, 
  .AssingmentMCQs-mock-test-question-answer-container h2 {
    font-size: 16px !important;
  }
  .AssingmentMCQs-Time-remaining-folder p {
    font-size: 14px;
  }
  .AssingmentMCQs-Time-remaining {
    font-size: 12px;
  }
  .step-container {
    margin: 29px auto 0px auto;
  }
  .AssingmentMCQs-mock-test-question-answer-container {
    margin: 49px auto 0px auto;
  }
  .AssingmentMCQs-mock-test-answer-folder {
    font-size: 12px;
  }
  .AssingmentMCQs-mock-test-answer-btn-folder button {
    font-size: 12px;
  }
  .LessonAnalytics-wrapper p {
    font-size: 16px;
  }
  .LessonViewProgress-table-container table tbody tr td {
    font-size: 13px;
  }
  .LessonAnalytics-profile h2, 
  .LessonAnalytics-profile-folder p {
    font-size: 13px !important;
  }
  .LessonAnalytics-profile a {
    font-size: 11px;
  }
  .LessonAnalytics-profile-folder h2 , 
  .LessonAnalytics-wrapper p{
    font-size: 13px !important;
  }
  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 80px !important;
  }
  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }
  .lessonanalystics-date {
    font-size: 10px;
  }
  .View-Progress-btn {
    font-size: 13px;
  }
  .msg_sec {
    font-size: 20px !important;
  }
  .msg_data_sec h6, 
  .right_chat_sec_new_head h6, 
  .right_chat_sec_right span, 
  .right_chat_sec_left span, 
  .msg_sec_new__left_single_inner span, 
  .right_chat_sec_new_head h6 {
    font-size: 12px !important;
  }
  .msg_data_sec p {
    font-size: 10px !important;
  }
  .security_alerts_sec_new_single_left h6 {
    font-size: 15px !important;
  }
  .security_alerts_sec_new_single_left p {
    font-size: 12px !important;
  }
  .student-score {
    font-size: 18px;
  }
} */

/* @media (min-width: 1600px) and (max-width: 1699px) {
  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 9%;
    right: 3%;
  }

  .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    bottom: 5px;
    right: 117px;
  }
} */

/* @media screen and (min-width: 1801px) and (max-width: 1920px) {
  .dashboard-name-title {
    font-size: 18px;
  }
  .dashborad-Courses-count h2 {
    font-size: 18px;
  }

  .dashborad-Courses-count p {
    font-size: 12px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 12px;
  }
  .created-my-courses-title-wrapper h2 {
    font-size: 18px;
  }

  .created-my-courses-ratings-folder p {
    font-size: 11px;
  }

  .created-my-courses-ratings-folder a {
    font-size: 10px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 10px !important;
  }

  .Students-courses-details-counts p,
  .view-Students-course-details-counts p {
    font-size: 10px;
  }

  .Students-des {
    font-size: 11px;
    line-height: 16px;
  }

  .dashboardHeaderProfileText {
    font-size: 13px;
  }

  .dashboardHeaderProfileNameInitial {
    font-size: 12px;
    width: 40px;
    height: 40px;
  }
  .view-course-details-right-section h2 {
    font-size: 18px;
  }

  .view-course-details-right-section p {
    font-size: 11px;
    line-height: 18px;
  }
  .view-course-details-Create-lesson-container-image img {
    width: 196px;
  }

  .view-course-details-Create-lesson-container p {
    font-size: 14px;
  }

  .view-course-details-Create-lesson-container button,
  .add-lesson-btn button,
  .Create-Course-btn-wrapper button,
  .Create-Course-uplode-image-container button,
  .Create-Course-uplode-outer-image button,
  .Regenerate-course-btn,
  .View-Course,
  .Start-by-creating-your-course-container button,
  .student-request-btn-folder button , 
  .Student-Active-start-Lesson, 
  .Update-Publish-btn {
    font-size: 10px;
  }

  .view-lessons-btn-container button {
    font-size: 12px;
  }

  .view-lessons-btn-container button {
    font-size: 12px;
  }

  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th {
    font-size: 11px;
  }

  .view-course-details-share-folder h2 {
    font-size: 17px;
  }

  .view-course-details-share-folder p {
    font-size: 14px;
  }

  .copy-link-or-path-folder button {
    font-size: 11px;
  }

  .invite-student-close-btn {
    position: absolute;
    top: -40px;
  }

  .Subjects-you-teach-feilds-folder {
    margin: 11px 0px;
  }

  .Create-Courses-wrapper h2 {
    font-size: 17px;
  }

  .Create-Course-inner-input-container {
    display: flex;
    gap: 10px;
  }

  .Create-Course-inner-input label,
  .Create-Course-des-folder label {
    font-size: 11px;
  }

  .Create-Course-inner-input input,
  .Create-Course-inner-input select,
  .edit-Course-inner-input textarea,
  .edit-Course-inner-input label{
    font-size: 11px;
    padding: 12px 20px;
  }
  .edit-Course-inner-input input{
    font-size: 10px
  }
  #outlined-select-currency {
    font-size: 11px;
    padding: 7px 11px;
  }

  .Course-description-inner-ai-feilds-folder p,
  .Course-description-inner-ai-feilds span {
    font-size: 11px;
  }

  .dashboardSidePannel button {
    display: flex;
    font-size: 11px;
    align-items: center;
    padding: 7px 20px;
  }

  #outlined-multiline-static {
    font-size: 12px;
    line-height: 21px;
    height: 77px !important;
  }

  .edit-Course-inner-input label {
    padding: 0px;
  }

  .edit-course-details-right-section {
    display: flex;
    gap: 9px;
  }
  .Update-Publish-btn {
    margin: 17px 0px;
  }

  .edit-image-courses {
    position: absolute;
    top: 8%;
    right: 5%;
  }

  .edit-image-courses p {
    font-size: 10px;
  }

  .view-course-Details-back-btn-folder h2 {
    font-size: 20px;
  }

  .view-course-Details-back-btn-folder a,
  .view-course-Details-back-btn-folder a {
    font-size: 22px;
  }

  .my-course-title {
    font-size: 18px;
  }

  .assign_main_box_left_single label,
  .lesson_generator_body_sec_left h6,
  .lesson_generator_body_sec_right h6 {
    font-size: 11px !important;
  }

  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 11px;
  }

  .go_cls_btn {
    position: absolute !important;
    top: 13px !important;
    right: 20px !important;
  }

  .promote_part_sec textarea {
    padding: 18px 12px 16px 12px !important;
    font-size: 11px !important;
    height: 54px !important;
  }

  .editor-container {
    height: 503px !important;
  }

  .chat-with-ai-lession-container {
    height: 476px !important;
  }

  .welcome-polly-popup-container h2 {
    font-size: 13px;
  }

  .welcome-polly-popup-container p {
    font-size: 13px;
  }

  .tudentDashboar-profile-card-title {
    font-size: 12px !important;
  }

  .studentDashboar-Suggested-Courses-profile-title p {
    font-size: 11px;
  }

  .studentDashboar-profile-course-title {
    font-size: 13px !important;
  }

  .studentDashboar-profile-btn-folder button,
  .tooltip_btn {
    font-size: 10px;
  }

  .studentDashboardSidePannel button {
    display: flex;
    font-size: 11px;
    align-items: center;
    padding: 7px 20px;
  }
  .studentDashboardSidePannel button.active {
    padding: 6px 42px !important;
  }

  .teacher_tooltip p {
    font-size: 12px;
  }

  .studentDashboar-actives-course-count h2 {
    font-size: 15px;
  }

  .studentDashboar-actives-course-count p {
    font-size: 11px;
  }

  .studentDashboar-actives-folder {
    padding: 8px 0px;
  }

  .create-course-text {
    font-size: 11px;
  }

  .view-course-details-edit-and-share-folder {
    padding: 5px 22px;
  }

  .view-Students-course-details-counts {
    padding: 4px 0px;
  }

  .list-of-students-wrappper table tbody tr td {
    font-size: 10px;
    padding: 14px 0px;
  }

  .list-of-students-wrappper table tbody tr td a {
    padding: 5px 10px;
    font-size: 10px;
  }

  .proc_btn_new {
    padding: 8px 0px;
    font-size: 11px;
  }

  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 12px;
  }

  .welcome-polly-popup-image img {
    width: 127px;
  }

  .studentDashboar-wrapper h2 {
    font-size: 19px;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-container p {
    font-size: 15px;
  }

  .studentDashboar-search-filter input,
  .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span {
    font-size: 11px;
  }

  .search-input {
    padding: 9px;
    font-size: 12px;
  }

  .studentDashboar-course-filter {
    font-size: 11px;
  }
  .Register-container {
    margin: 15px auto 0px auto;
  }
  .signup-right-section h2 {
    font-size: 27px;
    margin: 8px 0px 0px 0px;
  }
  .Register-title {
    font-size: 11px;
  }
  .signup-tab-folder button {
    font-size: 10px;
  }
  .coming-soon {
    top: 37px;
    right: 101px;
  }
  .coming-soon img {
    width: 55px;
  }
  .signup-form {
    margin: 29px auto 0px auto;
  }
  .forget-password-btn {
    font-size: 10px;
  }
  .signup-btn button {
    font-size: 10px;
    padding: 9px 45px;
  }
  .signup-or-title, .login-or-title {
    font-size: 10px !important;
    margin: 0px 0px 10px 0px;
  }
  .login-btns p {
    font-size: 10px;
  }
  .login-btns {
    padding: 5px 9px;
  }
  .Already-have-an-accout-folder span {
    font-size: 11px !important;
    margin: 10px 0px 0px 0px;
  }
  .terms-and-Privacy-policy {
    font-size: 9px;
    margin: 6px 0px 2px 0px;
  }
  .signup-btn {
    margin: 5px 0px 8px 0px;
  }
  .signup-right-section span {
    font-size: 13px;
  }
  .Register-tab-section {
    margin: 12px 0px 0px 0px;
  }
  .student-Active-Lessons {
    font-size: 14px;
  }
  .student-Active-Lessons {
    font-size: 14px;
  }
  .Student-Active-Lesson-folder {
    padding: 19px;
  }
  .Student-Active-Lesson-title-folder h2 {
    font-size: 12px;
  }
  .Student-Active-Lesson-folder p {
    font-size: 12px;
  }
  .search-icon svg {
    width: 15px;
  }
  .search-input {
    width: 100%;
    padding: 8px;
    font-size: 11px;
  }
  .overview-lesson-read-left-box-folder {
    padding: 12px 10px;
  }
  .overview-lesson-read-left-des p {
    font-size: 10px;
  }
  .read-min-folder a, .read-min-folder p {
    font-size: 9px;
  }
  .Overview-Lesson-date span {
    font-size: 10px;
  }
  .Overview-Lesson-date-counts p {
    padding: 4px 0px;
    font-size: 10px;
  }
  .editor-heading-h2 {
    font-size: 10px;
  }
  .editor-listitem {
    font-size: 10px;
  }
} */

/* @media (min-width: 2700px) and (max-width: 2799px) {

  .signup-right-section span,
  .Already-have-an-accout-folder span,
  .Register-title {
    font-size: 17px;
  }

  .signup-tab-folder button {
    font-size: 18px;
  }

  .signup-tab-folder,
  .signup-tab-folder button.active {
    padding: 15px 0px;
    font-size: 18px;
  }

  .forget-password-btn {
    font-size: 14px;
  }

  .signup-btn button {
    font-size: 17px;
  }

  .signup-or-title,
  .login-or-title {
    font-size: 17px !important;
  }

  .login-btns p {
    font-size: 16px;
  }

  .terms-and-Privacy-policy {
    font-size: 14px;
  }

  .coming-soon {
    position: absolute;
    top: 64px;
    right: 145px;
  }

  .coming-soon img {
    width: 72px;
  }

  .created-my-courses-des-wrapper,
  .edit-course-details-right-section {
    width: 88%;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 11%;
  }

  .edit-Course-inner-input label,
  .edit-Course-inner-textarea label {
    font-size: 16px;
  }

  .Update-Publish-btn {
    margin: 4px 0px;
    font-size: 15px;
  }

  .dashboardSidePannel button.active,
  .dashboardSidePannel button {
    font-size: 16px;
  }

  .create-course-text {
    font-size: 15px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 15px;
  }

  .dashborad-Courses-count p {
    font-size: 14px;
  }

  .dashborad-Courses-count h2 {
    font-size: 24px;
  }

  .Start-by-creating-your-course-container button,
  .View-Course,
  .Create-Course-btn-wrapper button,
  .view-course-details-Create-lesson-container button,
  .add-lesson-btn button,
  .proc_btn_new , 
  .Create-Assignment-btn , 
  .Preview-lesson-btn, 
  .LessonViewProgress-student-status, 
  .LessonViewProgress-header-section button {
    font-size: 14px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 66px;
  }
  .TeacherProfile-Language-folder p {
    font-size: 14px;
  }
  #TeacherProfile-section .dashboard-popup-teacher-profile-details-input .teacher-create-popup-gender-input-folder .form-icon, 
  #TeacherProfile-section .dashboard-popup-teacher-profile-details-input .teacher-create-popup-gender-input-folder .location-icon {
    position: absolute;
    top: 40px;
    left: 2.6%;
  }
  #TeacherProfile-section .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input .form-icon {
    position: absolute;
    top: 40px;
    left: 1.6%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .created-my-courses-title-wrapper h2, 
  .view-course-details-right-section h2  {
    font-size: 24px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 14px !important;
  }

  .Students-courses-details-counts p,
  .view-Students-course-details-counts p {
    font-size: 13px !important;
  }

  .Students-des,
  .view-course-details-right-section p,
  .lesson_generator_body_sec_left h6,
  .lesson_generator_body_sec_right h6,
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 14px;
  }

  .Create-Course-inner-input label,
  .Create-Course-des-folder label,
  .assign_main_box_left_single label {
    font-size: 16px;
  }

  .view-course-details-Create-lesson-container-image img {
    width: 266px;
  }

  .teacher-create-popup-gender-input {
    position: absolute !important;
    top: 32.8px !important;
    left: 1% !important;
  }

  .studentDashboar-Suggested-Courses-container {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    margin: 30px 0px 0px 0px;
  }
  .created-my-courses-folder-image, 
  .view-course-details-image {
    width: 100%;
    height: 232px;
  }
  .right_chat_sec_right span, 
  .right_chat_sec_left span , 
  .right_chat_sec_new_head h6, 
  .msg_data_sec h6, 
  .msg_sec_new__left_single_inner span {
    font-size: 14px !important;
  }
  .right_chat_sec_new_head p, 
  .msg_data_sec p{
    font-size: 12px !important;
  }
  .settings_main_sec_head ul li a {
    font-size: 14px;
  }
  .Teacher-student-edit-profile-btn {
    position: absolute !important;
    bottom: -5px !important;
    right: 79px !important;
  }
  .Update-Publish-btn {
    margin: 29px 0px;
    font-size: 14px;
  }
  .LessonAnalytics-table-container .completedat-time img {
    position: absolute !important;
    top: 0px !important;
    right: 208px !important;
  }
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .teacher-create-popup-gender-input {
    position: absolute !important;
    top: 26px !important;
    left: 1% !important;
  }
  .studentDashboar-Suggested-Courses-folder {
    width: 335px;
  }
  .studentDashboardSidePannel button.active {
    font-size: 14px;
  }
  .studentDashboar-profile-course-title {
    font-size: 15px !important;
  }
  .enroll_now_btn {
    width: 5% !important;
  }
  .Student-Active-Lesson-title-folder h2 {
    font-size: 15px;
  }
  .Student-Active-start-Lesson {
    font-size: 14px;
  }
  .overview-lesson-mark-complete-btn button {
    font-size: 13px;
  }    
} */

/* .overview-lesson-mark-complete-btn {
  padding: 10px !important;
} */

/* .editor-paragraph {
  padding: 10px !important;
} */
.editor-text-bold {
  /* margin-bottom: 15px !important; */
}

.editor-heading-h2 {
  color: black !important;
  font-weight: 700;
  padding: 10px !important;
}

.editor-list-ol {
  padding: 5px !important;
}

.editor-listitem {
  padding: 10px !important;
}

.editor-paragraph span {
  color: unset;
}

.errorState {
  color: red !important;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px !important;
  width: 100%;
  font-size: 10px !important;
  border-radius: 0px !important;
  border: none !important;
}

.errorState2 {
  padding: 10px 0px !important;
}

#teacher-create-profile-input {
  padding: 8px 10px 8px 93px;
}

.teacher-create-popup-gender-input {
  padding: 4px 0px 4px 34px !important;
  width: 87px !important;
  position: absolute;
  top: 28.2px;
  left: 1%;
  border: none;
  border-right: 1px solid #bdbdbd;
  background-color: transparent !important;
  /* -webkit-appearance: none; */
}

.loading-chat-answer h2,
.loading-chat-answer h1 {
  /* margin: 0px; */
  margin: 25px 0px 5px 0px;
}

/* .iti__flag {background-image: url("path/to/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("path/to/flags@2x.png");}
} */

/* Style the loading spinner */
.view-course-details-loader {
  border: 4px solid white;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: loading 1s linear infinite;
  /* Apply the animation */
  margin: 0 auto;
  /* Center the spinner */
  margin-top: 20px;
  /* Adjust as needed */
}

.dashboard-popup-teacher-profile-details-image-plus-btn {
  position: absolute;
  bottom: 3px;
  right: 126px;
}

.dashboard-popup-teacher-profile-details-image-plus-btn.forprofile {
  left: 193px !important;
}

.dashboard-popup-teacher-profile-details-image-plus-btn img {
  width: 20px;
  height: 20px;
}

.black-plus-icon {
  position: absolute;
  bottom: 5px;
  right: 18px;
}

.black-plus-icon span {
  background-color: #0068ff;
  color: white;
  /* width: 10px; */
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 13px;
}

.create-all-btns-container {
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 0px 0px 0px 0px;
  align-items: flex-end;
}

button.create-all-btns-folder1 {
  background-color: transparent !important;
  border: 2px solid #0068ff;
  color: #0068ff !important;
}

.create-all-btns {
  width: 100%;
}

.create-all-btns p,
.Create-Course-uisng-ai p {
  text-align: center;
  padding: 4px 0px;
  font-size: 12px;
  color: black;
  background-color: #3f3f3fcc;
  width: 94px;
  color: white;
  margin: 0px auto 7px auto;
  border-radius: 5px;
  text-transform: uppercase;
}

.Beta {
  width: 60px !important;
}

.create-all-btns-container button {
  width: 35%;
  padding: 8px 0px;
  font-size: 14px;
  color: #0068ff;
  background-color: transparent;
  border: 2px solid #0068ff;
  font-weight: 500;
}

.create-all-btns-container button:focus {
  border: 2px solid #0068ff;
}

.create-all-btns-container button:last-child {
  background-color: #0068ff;
  color: white;
  width: 50%;
}

.create-all-btns-container button:first-child {
  background-color: #0068ff;
  color: white;
  width: 100%;
}

.create-all-btns-container button:last-child:disabled {
  background-color: grey;
  border: 2px solid grey;
}

.create-all-btns-container button:first-child:disabled {
  background-color: grey;
  border: 2px solid grey;
}

.create-Assignment-container-popup-folder1 {
  display: flex;
  width: 100%;
  gap: 7px;
}

.create-Assignment-input-feild {
  width: 60%;
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.create-Assignment-input-feild label,
.create-Assignment-input-feild2 label,
.create-Assignment-popup-input-feild label,
.Create-Assignment-dropdown label {
  font-size: 11px !important;
  font-weight: 500;
}

.Create-Assignment-dropdown {
  width: 40%;
}

.create-Assignment-container-popup-folder2 {
  display: flex;
  gap: 10px;
  margin: 13px 0px 0px 0px;
}

.create-Assignment-container-popup {
  /* padding: 10px; */
  background-color: #f2f2f2;
  border-radius: 10px;
  /* display: none; */
}

.create-Assignment-popup-input-feild input {
  height: 35px !important;
}

.create-Assignment-popup-input-feild .assing_cal_sec_new .cal_img_sec {
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 11;
  display: flex;
  align-items: center;
  width: auto;
  bottom: 0;
  width: 21px;
}

.slide-up {
  transform: translateY(-100%);
  /* move the element up by its height */
  transition: transform 0.3s ease-in-out;
  /* add a transition effect */
}

.create-Assignment-container-popup {
  /* ...existing styles */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.create-Assignment-container-popup.show {
  max-height: 500px;
  /* or whatever your maximum height should be */
}

.edit-active-lesson-btn-container button {
  border: 1px solid;
  padding: 3px 30px;
  font-size: 13px;
  border-radius: 21px;
}

.edit-active-lesson-btn-container {
  display: flex;
  gap: 17px;
}

.edit-active-lesson-btn-container {
  display: flex;
  gap: 17px;
  /* margin: 0px 0px 12px 0px; */
  /* display: none; */
}

.edit-active-lesson-btn-container button {
  padding: 3px 30px;
  font-size: 13px;
  border-radius: 21px;
  background-color: transparent;
  border: 1px solid black;
  outline: none;
}

.edit-active-lesson-btn-container.active {
  /* your active styles here, e.g., */
  background-color: #cfe5fb !important;
  color: #0068ff;
  border: none;
  outline: none;
}

.studentDashboar-profile-btn-folder {
  justify-content: end;
}

.enroll_now_btn {
  width: 10% !important;
}

.current-time,
.current-sec {
  font-size: 12px;
}

.dashboard-popup-teacher-profile-select-Language-container {
  margin: 4px 0px 0px 0px;
}

.dashboard-popup-teacher-profile-select-Language-container .Subjects-you-teach-suggestions-folder a {
  border: 1px solid;
  padding: 6px 15px;
  border-radius: 36px;
  font-size: 12px;
  color: black;
}

.dashboard-popup-teacher-profile-select-Language-container .Subjects-you-teach-suggestions-folder {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
}

.dashboard-popup-teacher-profile-selected-Language-folder .Subjects-you-teach-feilds-folder .Subjects-you-teach-feilds p {
  font-size: 12px;
}

div#student-share-btn {
  background-color: transparent;
  border: 1px solid black;
}

#student-share-btn span,
#student-share-btn p {
  color: black;
}

.edit-active-lesson-btn-container .active {
  background-color: #cfe5fb;
  color: #0068ff !important;
  border: none;
}

.studentrequest-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: 100%;
  gap: 10px;
  cursor: pointer;
}

.studentrequest-profile-image {
  width: 20%;
}

.studentrequest-profile-name {
  width: 36%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.download-popup {
  width: 488px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  text-align: left;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid;
  padding: 0px 4px;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-section label {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}

/* .input-section input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 7px 0px;
} */
.input-section input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  margin: 7px 0px;
  background-color: #F5F5F5;
}

.button-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

button.pdf-btn,
button.spreadsheet-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
}

button.pdf-btn {
  background: red;
  color: #fff;
  width: 100%;
}

button.spreadsheet-btn {
  background: green;
  color: #fff;
  width: 100%;
}

.Download-File-popup-wrappper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #00000078;
  background-color: #00000042;
  width: 100%;
  height: 100%;
  z-index: 113;
}

.Download-File-popup-container {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  display: flex;
  justify-content: center;
}

#create-lesson-share-canle-btn {
  padding: 5px 7px !important;
}

.download-popup h2 {
  text-align: left;
  font-size: 18px;
}

.student-score {
  font-size: 15px;
  margin: 0px;
}

#AssignmentViewProgress-section {
  margin-bottom: 10px;
}

.AssignmentViewProgress-table-container table thead tr th:nth-child(7) {
  text-align: center;
}

.date-inner-wrapper {
  display: flex;
  flex-direction: row;
}

/* TeacherProfile start */
.TeacherProfile-btn-container {
  background-color: #f5fbff;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  max-width: 438px;
  padding: 10px;
}

.TeacherProfile-btn-container button.active {
  color: #0068ff;
  border-radius: 30px;
  background-color: #cfe5fb;
}

.TeacherProfile-btn-container button {
  padding: 8px 50px;
  background-color: transparent;
  border: none;
  font-size: 14px;
}

/* TeacherProfile end */

.lesson-Completed-btn-wrapper {
  display: flex;
  gap: 14px;
  margin: 13px 0px;
  display: none;
}

.lesson-overview-Completed-btn {
  background-color: transparent !important;
  border: 1px solid #0068ff !important;
  color: #0068ff !important;
  padding: 8px 21px !important;
}

.compeletd_btn_icon {
  width: 17px;
  margin-right: 10px;
}

.Teacher-student-edit-profile-wrapper {
  margin: 40px 0px 0px 0px;
  padding: 0 50px;
}

.Teacher-student-edit-profile-back-btn {
  display: flex;
  align-items: center;
  gap: 14px;
  margin: 0px 0px 26px 0px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 18px;
}

.Teacher-student-edit-profile-back-btn i,
.Teacher-student-edit-profile-back-btn h2 {
  font-size: 18px;
}

.Teacher-student-edit-profile-container {
  display: flex;
  text-align: left;
  gap: 18px;
  width: 100%;
}

.Teacher-student-edit-profile-left-section {
  width: 10%;
  text-align: center;
}

.Teacher-student-edit-profile-right-section {
  width: 91%;
}

.Teacher-student-edit-profile-left-section span {
  margin: 6px 0px 0px 0px;
  display: flex;
  color: #303972;
  font-weight: 600;
  font-size: 14px;
  justify-content: center;
}

.Teacher-student-edit-profile-right-section h2 {
  font-size: 19px;
  margin: 0px 0px 0px 0px;
}

.Teacher-student-edit-profile-Joining-date {
  margin: 0px 0px 7px 0px !important;
  color: black;
  font-size: 12px !important;
  font-weight: 500;
}

.Teacher-student-edit-profile-Joining-date span {
  font-weight: 400;
}

.Teacher-student-edit-profile-right-section p {
  font-size: 14px;
  color: black;
  width: 100%;
}

.Teacher-student-edit-profile-btn {
  position: absolute;
  bottom: -5px !important;
  right: 25px !important;
}

.Teacher-student-edit-profile-btn img {
  width: 25px !important;
  height: 25px !important;
}

.Teacher-student-edit-profile-left-section .Personalize-your-AI-Assistant-uplode-image #choos-profile-image {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  margin: auto;
  width: 125px;
  height: 109px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
  width: 54%;
  background-color: white;
  border-radius: 10px;
  /* margin: 30px 0px 0px 18px; */
  margin: 30px 0px 0px 0px;
}

.TeacherProfile-Language-folder {
  font-size: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin: 25px 0px 0px 0px;
}

.TeacherProfile-Subject-folder {
  margin: 0px 0px 24px 0px;
}

.TeacherProfile-Language-folder label {
  font-weight: 600;
}

.TeacherProfile-Language-folder p {
  padding: 8px 36px;
  border-radius: 28px;
  background-color: #d1eeff;
  border: none;
  color: black;
}

.TeacherProfile-Language-inner-folder {
  display: flex;
  gap: 11px;
  flex-wrap: wrap;
}

.Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-details-input input,
.Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-gender-folder select {
  padding: 8px 10px 8px 42px;
}

/* LessonAnalytics section start */
#LessonAnalytics-section {
  text-align: left;
}

.LessonAnalytics-wrapper p {
  font-size: 18px;
  color: black;
}

.LessonAnalytics-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 22px 0px 0px 0px;
  padding: 12px;
  box-shadow: 0px 1px 9px #d2d2d294;
  border-radius: 11px;
}

.LessonAnalytics-profile {
  background-color: #f4f9ff;
  padding: 13px;
  border-radius: 10px;
}

.LessonAnalytics-profile h2 {
  font-size: 15px;
  margin: 9px 0px 0px 0px;
}

.LessonAnalytics-profile a {
  font-size: 12px;
}

.LessonAnalytics-profile-folder p {
  font-size: 15px;
}

.LessonAnalytics-profile-folder h2 {
  font-size: 19px;
  margin: 12px 0px 0px 0px;
}

.lessonanalystics-date {
  font-size: 11px;
  font-weight: 500;
}

.LessonAnalytics-profile-massege-image {
  padding: 5px;
  border-radius: 100%;
  background-color: #d6e6fd;
}

.LessonAnalytics-profile-massege-image img {
  width: 25px;
}

.LessonAnalytics-table-container table thead tr th,
.LessonAnalytics-table-container table tbody tr td {
  text-align: center;
}

.LessonAnalytics-table-container .completedat-time img {
  position: absolute;
  top: 0px;
  right: 95px;
}

.completed-lesson-inner-popup {
  border: 1px solid;
  padding: 10px 20px;
  width: 275px;
  text-align: left;
  border-radius: 5px;
  background-color: #000000ba;
  position: absolute;
  top: -58px;
  right: 100px;
  display: none;
}

.hover-wrapper:hover .completed-lesson-inner-popup {
  display: block;
}

.completed-lesson-inner-popup p {
  font-size: 11px;
  color: white;
}

.hover-wrapper {
  position: relative;
}

/* LessonAnalytics section end */

/* AssignmentAnalytics section start */
.AssignmentAnalytics-table-container .completedat-time img {
  position: absolute;
  top: 0px;
  right: 14px;
}

.AssignmentAnalytics-answer-status {
  background-color: #e6ffe5;
  color: #28991f;
  border: none;
}

.AssignmentAnalytics-answer-status i {
  margin-left: 10px;
}

.Submitted-Answer-list,
.CorrectAnswerr-list {
  list-style: upper-alpha;
}

.stdent-question-and-answer-popup {
  position: absolute;
  top: -58px;
  right: 24px;
}

.AssignmentAnalytics-answer-status.inactive {
  color: #b24747;
  background-color: #ffd6d6;
}

.AssignmentAnalytics-answer-status.partial {
  color: #ffa500;
  background-color: #ffe3b1b5;
}

.AssignmentViewProgress-mock-test-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: black;
  width: 100%;
  height: 100%;
  border: none;
  background-color: #00000042;
  z-index: 113;
}

.AssignmentViewProgress-mock-test-container {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  max-width: 975px;
  background-color: white;
  padding: 16px;
  border-radius: 28px;
}

.AssignmentViewProgress-mock-test-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.AssignmentViewProgress-mock-test-status-folder {
  display: flex;
  align-items: center;
  gap: 12px;
}

.AssignmentViewProgress-mock-test-status-folder p {
  /* font-size: 14px; */
  font-size: 12px;
  color: #807f7f;
}

.AssignmentViewProgress-mock-test-status-correction {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 2px 15px;
  border-radius: 33px;
  background-color: #e6ffe5;
  color: #28991f;
}

.AssignmentViewProgress-mock-test-status-correction.Partial.Credit{
  color: #ffa500;
  background-color: #ffe3b1b5;
}

.AssignmentViewProgress-mock-test-status-correction.Incorrect {
  color: #b24747;
  background-color: #ffd6d6;
}

.AssignmentViewProgress-mock-test-status-correction p {
  color: #28991f;
  font-size: 13px;
}

.AssignmentViewProgress-mock-test-status-correction.Incorrect p {
  color: #ffa500;
}


.AssignmentViewProgress-mock-test-question-answer-container h2 {
  font-size: 17px;
  margin: 26px 0px;
  text-align: center;
}

.AssignmentViewProgress-mock-test-answer-container {
  margin: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.AssignmentViewProgress-mock-test-answer-folder {
  display: flex;
  justify-content: flex-start;
  /* padding: 12px 38px; */
  padding: 9px 38px;
  /* font-size: 13px; */
  font-size: 12px;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 5px #dbdbdb;
  border-radius: 6px;
}

.AssignmentViewProgress-mock-test-answer-folder.correct {
  background-color: #e6ffe5;
  color: #28991f;
}

.AssignmentViewProgress-mock-test-answer-folder.incorrect {
  color: #b24747;
  background-color: #ffd6d6;
}

.AssignmentViewProgress-mock-test-answer-folder ul li {
  list-style: upper-latin;
}

.pagination {
  display: flex;
  justify-content: center;
  /* font-size: 14px; */
  font-size: 12px;
}

.AssignmentViewProgress-mock-test-status-time-duration {
  background-color: transparent !important;
}

.AssignmentViewProgress-mock-test-status-time-duration p {
  color: black !important;
}

.AssignmentViewProgress-mock-test-close-btn {
  position: absolute;
  top: -3px;
  right: -3px;
  border: 2px solid;
  padding: 5px 7px;
  font-size: 13px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AssignmentViewProgress-mock-test-status-time-duration img {
  width: 18px;
}

/* AssignmentAnalytics section end */

.container {
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.question {
  margin-bottom: 30px;
}

.AssingmentMCQs-main-wrapper .progress-bar {
  display: flex;
  flex-direction: row;
  margin: 50px 0px 0px 0px;
  gap: 113px;
  height: auto;
  background-color: transparent;
}

.step {
  width: 25px;
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: #e7e7e7;
}

.step.active {
  background-color: #65b741;
  color: #fff;
  border: none;
}

.AssingmentMCQs-mock-test-question-answer-container {
  max-width: 912px;
  margin: 10px auto 0px auto;
}

.AssingmentMCQs-mock-test-question-answer-container h2 {
  /* font-size: 17px; */
  font-size: 15px;
  /* margin: 20px 0px; */
  margin: 10px 0px;
  text-align: left;
  display: flex;
}

.AssingmentMCQs-mock-test-answer-container {
  margin: 0px 0px 9px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AssingmentMCQs-mock-test-answer-folder {
  display: flex;
  justify-content: space-between;
  padding: 14px 38px;
  font-size: 13px;
  border-radius: 6px;
  background-color: #eef9ff;
  box-shadow: 0px 1px 3px #d2d2d2;
}

.AssingmentMCQs-mock-test-answer-btn-folder {
  display: flex;
  justify-content: space-between;
  /* margin: 50px 0px 15px 0px; */
  margin: 23px 0px 5px 0px;
}

.AssingmentMCQs-mock-test-answer-btn-folder button {
  padding: 6px 30px;
  font-size: 14px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
}

.AssingmentMCQs-mock-test-answer-btn-folder button:nth-child(1) {
  background-color: transparent;
  color: #747474;
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  position: relative;
  margin: 25px auto 0px auto;
}

.step-container::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 2px;
  background-color: #d4d4d4;
  z-index: 0;
}

.step {
  align-items: center;
  background-color: #fff;
  border: 2px solid #d4d4d4;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  position: relative;
  width: 30px;
  z-index: 1;
}

/* .step.active {
  background-color: #f35a7a;
  color: #fff;
  border-color: #f35a7a;
} */
.AssingmentMCQs-mock-test-answer-folder input {
  width: 18px;
  height: 18px;
}

.AssingmentMCQs-mock-test-answer-left-arrow-btn {
  margin-right: 5px;
}

.AssingmentMCQs-mock-test-answer-folder.selected {
  background-color: #2e83ff;
  color: white;
}

.dashboardHeaderProfile {
  position: relative;
}

.notifications-wrapper {
  position: absolute;
  top: 70px;
  right: 39px;
  width: 400px;
  text-align: left;
  padding: 18px;
  background-color: white;
  box-shadow: 0px 1px 9px #e3e3e3;
  border-radius: 10px;
}

.notifications-title {
  display: flex;
  gap: 10px;
  align-items: center;
}

.notifications-title p {
  font-size: 14px;
  color: black;
}

.notifications-all-container {
  margin: 17px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notifications-folder {
  padding: 9px;
  border-radius: 8px;
  background-color: #ecf5fe;
  position: relative;
  cursor: pointer;
}

.notifications-folder::after {
  content: "";
  position: absolute;
  top: 0%;
  right: 0%;
  width: 10px;
  height: 10px;
  background-color: #0068ff;
  border-radius: 100%;
}

.notifications-inner-folder {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0px 0px 13px 0px;
}

.notifications-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.notifications-image img {
  width: 100%;
  border-radius: 100%;
}

.notifications-inner-folder p {
  color: black;
  font-size: 12px;
}

.notification-time-folder {
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.notifications-all-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  overflow-y: scroll;
}

.lesson_generator_editor_toos-folder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.all-editer-tools-section {
  display: flex;
  gap: 15px;
  position: relative;
}

.all-editer-tools-section span {
  color: #9a9797;
  cursor: pointer;
}

#mr-and-ms-select,
#select-phone-number {
  position: relative;
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e7e7e7;
  background: #fff;
  border-radius: 4px;
  padding-left: 50px;
  color: #000;
  position: absolute;
  top: 55px;
  left: 5px;
  transform: translateY(-50px);
}

#Account-Settings-field-name {
  text-align: left;
  margin-bottom: 20px;
}

#teacherDashboard-AI-Assistants-wrapper {
  display: flex;
  justify-content: space-between;
  width: 74%;
  text-align: center;
  margin: 0px auto;
  padding: 22px;
  background-color: white;
  border-radius: 10px;
}

/* .StudentCourseCard-main-container{
  width: 100%;
} */
.Student-pannding-assignment-image img {
  width: 12px;
}

.Student-pannding-assignment {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

#StudentCourse-section {
  margin: 30px 0px 0px 0px;
  text-align: left;
}

.msg_sec2 {
  color: black;
  font-weight: 500;
  font-size: 20px;
}

.msg_sec2 i {
  margin-right: 10px;
}

.inner-lesson-and-inner-Assignment-main-container {
  /* margin: 11px 0px 0px 0px; */
}

.overview-Assignment-read-body {
  margin: 30px 0px 0px 0px;
}

.overview-Assignment-read-inner-body {
  position: relative;
  padding: 0px;
  text-align: left;
}

.AssingmentMCQs-header-main-contaiiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AssingmentMCQs-header-main-contaiiner h2 {
  font-size: 18px;
  margin: 0px 0px 0px 0px;
}

/* .AssingmentMCQs-Time-remaining-folder p {
  font-size: 15px;
  color: black;
} */

.AssingmentMCQs-Time-remaining {
  margin: 4px 0px 0px 0px;
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
}

#AssingmentMCQs-back-btn {
  margin: 0px 0px 0px 0px;
}

.start-Assignment-btnnton {
  display: flex;
  justify-content: flex-end;
  margin: 0px 0px 20px 0px;
}

.start-Assignment-btnnton button {
  background-color: #0068ff;
  color: white;
  font-size: 13px;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
}

.Teacher-student-edit-profile-inner-des-wrapper {
  padding: 14px;
  background-color: #f5fbff;
  border-radius: 8px;
  margin-top: 12px;
}

/* button.teacher-description-container-btn {
  color: #0068ff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background: transparent;
  float: right;
  clear: both;
}

.teacher-description-container-head {
  font-size: 18px;
  font-weight: 600;
}

.teacher-description-container-head img {
  margin-right: 10px;
}

.teacher-description-wrapper {
  margin-bottom: 20px;
}

.teacher-description-container {
  border-radius: 20px;
  background: #e9f2ff;
  padding: 25px 30px;
}

p.teacher-description-container-p {
  font-size: 13px;
  color: black;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
} */

.AssignmentMCQs-mock-test-answer-folder textarea {
  background-color: #eef9ff;
  border: none;
  border-radius: 10px;
  font-size: 13px;
  padding: 16px;
  resize: none;
  width: 100%;
  border: none;
  height: 121px;
}

.Overview-Lesson-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3rem;
}

#create-lesson-downloode-wrapper .Download-File-popup-container .download-popup .pdf-btn {
  background: #d15454;
}

#create-lesson-downloode-wrapper .Download-File-popup-container .download-popup .spreadsheet-btn {
  background: #0068ff;
}

.Student-Active-start-Lesson svg {
  width: 16px;
}

.Submitted-Answer-list li,
.CorrectAnswerr-list li {
  width: 243px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form_sec_settings .dashboard-popup-teacher-profile-details-form {
  margin: 22px 0px 0px 0px;
}

.Student-Active-Lesson-des {
  /* width: 458px; */
  width: 318px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #645e5e !important;
  font-size: 12px;
}

.pdf_box_sec_inner_single span h5 {
  font-size: 12px;
  width: 102px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.AssingmentMCQs-Congratulations-popup-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  writing-mode: 0%;
  right: 0%;
  bottom: 0%;
  color: black;
  z-index: 113;
  background-color: #00000033;
}

.AssingmentMCQs-Congratulations-popup-container {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  border: 1px solid white;
  max-width: 630px;
  background-color: white;
  text-align: center;
  padding: 41px;
  border-radius: 18px;
}

.AssingmentMCQs-Congratulations-popup-container h2 {
  font-size: 20px;
  margin: 0px 0px 0px 0px;
}

.your-score-title {
  font-size: 14px;
  margin: 11px 0px;
  display: flex;
  /* margin: 0px auto; */
  justify-content: center;
  color: #9c9c9c;
}

.your-score-folder {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin: 20px 0px;
}

.your-score-check {
  font-size: 22px;
  color: #0aa06e;
}

.your-score span {
  font-size: 17px;
  font-weight: 600;
}

.AssingmentMCQs-Congratulations-popup-container p {
  max-width: 400px;
  margin: 17px auto 30px auto;
  font-size: 14px;
  color: black;
}

.AssingmentMCQs-Congratulations-popup-container button {
  border: none;
}

.AssingmentMCQs-section {
  position: relative;
}

.AssingmentMCQs-Congratulations-popup-image {
  margin: 0px auto 17px auto;
  width: 175px;
}

#delete-AssingmentMCQs-form .delete-course-form-btn-folder button:nth-child(2) {
  background-color: #2e83ff;
}

.LessonAnalytics-profile-image img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

.StudentViewCourseDetails-assignments0-icon img {
  width: 12px;
}

.StudentViewCourseDetails-lesson-time-Duration p i {
  color: #9d9d9d;
}

.view-course-details-image {
  width: 100%;
  height: 244px;
}

.teacherviewcoursedetails-des {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5 !important;
  max-height: 7.5em;
  white-space: normal;
  margin: 8px 0px 11px 0px !important;
}

.LessonViewProgress-shotby-wrapper {
  position: absolute;
  top: 36px;
  right: 12px;
  width: 146px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #acacac91;
}

.LessonViewProgress-shotby-wrapper h2 {
  text-align: center;
  font-size: 14px;
  margin: 0px 0px 15px 0px;
}

.LessonViewProgress-shotby-list {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.LessonViewProgress-shotby-list li {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.LessonViewProgress-shotby-list li input {
  width: 13px;
  height: 13px;
}

.LessonViewProgress-filter-folder p {
  font-size: 11px;
  color: black;
  margin: 0px 0px 10px 0px;
  font-weight: 500;
}

.LessonViewProgress-filter-Assignment-Status {
  font-size: 12px;
  width: 100%;
  padding: 5px 4px;
}

.LessonViewProgress-filter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.account_setting_tab_sec .dashboard-popup-teacher-profile-details-form .Personalize-your-AI-Assistant-left-section {
  text-align: center;
}

#my-tooltip-click {
  left: 11px !important;
  top: -19.94999px !important;
  z-index: 2 !important;
  font-size: 12px !important;
}

.Asset_teacher img {
  width: 79px;
}

.Asset_teacher2 img {
  width: 44px;
}

#default-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px 0px 37px 0px;
}

#default-card-wrapper .created-my-courses-folder .created-my-courses-folder-image {
  width: 100%;
  height: 184px;
}

#default-card-wrapper .created-my-courses-folder .created-my-courses-title-wrapper h2 {
  font-size: 22px;
  margin: 0px;
  width: auto;
  text-overflow: inherit;
  overflow: inherit;
  white-space: inherit;
}

#default-card-wrapper .created-my-courses-folder {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 13px 36px;
}

#default-card-wrapper .Students-des {
  margin: 14px 0px 4px 0px;
}

.default-card-image {
  width: 249px;
}

#default-card-wrapper .created-my-courses-folder .created-my-courses-folder-image-left-folder {
  width: 22%;
}

@keyframes rotate-full {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  & .infinite {
    animation-iteration-count: infinite;
  }
}

.rotate {
  animation: rotation-full 5s infinite linear;
}

.rotate-full {
  animation: infinite-spinning 20s infinite linear;
}

.empower_sec_new_inner_right_circle_image_background {
  /* margin: 0px auto; */
  display: flex;
  justify-content: center;
}

/* .empower_sec_new_inner_right_circle_image_background img{
  width: 460px;
} */

.empower_sec_new_inner_right_circle_background.rotate-full {
  position: absolute;
  top: -35px;
  left: -4px;
  right: 10px;
  bottom: 0px;
  width: 700px;
  height: 700px;
}

#SpeedDialbasicexample-actions button img {
  border-radius: 100%;
  width: 50px;
  height: 40px;
}

.asset_book_image {
  width: 52px;
}

.studentviewcoursedetails-des {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.7;
  white-space: normal;
}

.view-more {
  font-size: 13px;
  padding: 4px 21px;
  color: #0068ff;
  position: absolute;
  right: 4px;
  bottom: 0px;
  background: #fff;
  margin: 0 5px;
  padding: 0px 7px;
  cursor: pointer;
}

.AI-disclamer {
  color: #7c7c7c;
  margin: 0px 0px 0px 6px;
  text-align: center;
}

.dashboard-setting-inner-forget-password {
  width: 93%;
  margin: 6px 0px 0px 0px;
}

.Create-Course-image-disclamer {
  display: flex;
  align-items: baseline;
  gap: 7px;
}

.Create-Course-image-disclamer span {
  font-size: 10px;
}

.ganrate-lesson-text-feild-suggestion {
  position: relative;
  position: absolute;
  bottom: 103px;
  right: 29px;
  margin: auto;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: white;
}

.ganrate-lesson-text-feild-suggestion h2 {
  margin: 0px;
  text-align: end;
  font-size: 14px;
}

.teacherviewcoursedetails-inner-popup-wrappper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 100%;
  background-color: #00000024;
  z-index: 113;
}

.teacherviewcoursedetails-inner-popup-folder {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%) !important;
  width: 656px;
  margin: 0px auto;
  background-color: white;
  padding: 15px 25px 15px 25px;
  margin: 22px auto 25px auto;
  border-radius: 10px;
}

.teacherviewcoursedetails-inner-popup-folder textarea {
  height: 300px;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 17px;
  border-radius: 10px;
  resize: none;
  font-size: 13px;
  line-height: 21px;
}

.teacherviewcoursedetails-inner-popup-folder p {
  height: 300px;
  width: 100%;
  border: 1px solid #e8e8e8;
  padding: 17px;
  border-radius: 10px;
  resize: none;
  font-size: 13px;
  line-height: 21px;
}

.teacherviewcoursedetails-inner-popup-folder h2 {
  text-align: center;
  margin: 8px auto 19px auto;
  font-size: 21px;
}

.my-course-close-btn {
  position: absolute;
  top: 14px;
  right: 17px;
  border: 1px solid;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 12px;
}

#Customize-inner-AI-assistant {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 0px 6px;
  justify-content: flex-start !important;
}

.assigmentMCQs-inner-des textarea {
  width: 100%;
  resize: none;
  height: 256px;
  border: 1px solid;
  font-size: 13px;
  padding: 16px;
  background-color: #e6ffe5;
  border-radius: 10px;
}

#assigmentMCQs-inner-des-section {
  display: none;
}

.studentDashboar-popup-container .dashboard-popup-teacher-profile-details-folder .dashboard-popup-teacher-profile-details-form .Personalize-your-AI-Assistant-uplode-image .dashboard-popup-teacher-profile-details-image-plus-btn {
  position: absolute;
  bottom: 2px !important;
  right: 252px !important;
}

.profile-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  position: relative;
  /* z-index: 200; */
  z-index: 112;
}

#Create-Course-cancle-btn-wrapper {
  display: none;
}

.view-course-Details-back-btn-folder h2 {
  text-align: left;
  margin: 0px;
  font-size: 20px;
  /* max-width: 401px; */
  font-weight: 500;
  width: 667px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.subjecr_special {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

span.profile-plus {
  background-color: #0068ff;
  color: white !important;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 13px;
}

.blank-div {
  margin-bottom: 20px;
}

.teacherviewcoursedetails-inner-popup-folder h2,
#AssingmentMCQs-back-btn h2 {
  width: auto;
}

.sub_special {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0px 0px 0px;
}

.modal-content-btn {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  justify-content: center;
  /* Align items horizontally in the center */
  height: 100%;
  /* Adjust as needed */
}

.modal-content-btn button {
  /* Size and Padding */
  padding: 5px 12px;
  font-size: 16px;

  /* Color and Background */
  background-color: #b7e5ff;
  /* Change to your preferred color */
  color: black;
  border: 2px solid transparent;
  /* Adjust or remove border as needed */
  border-radius: 5px;

  /* Font and Text Styling */
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  /* Shadow and Depth */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  /* Transition Effects */
  transition: all 0.3s ease-in-out;

  /* Hover and Focus Effects */
  cursor: pointer;
}

.modal-content-btn button:focus {
  background-color: #00a2ff;
  /* Darker shade on hover/focus */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
  /* Larger shadow on hover/focus */
  border-color: black;
  /* Optional: Change border color on hover/focus */
}

.modal-content-btn button:hover {
  background-color: #00a2ff;
  /* Darker shade on hover/focus */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
  /* Larger shadow on hover/focus */
  border-color: black;
  /* Optional: Change border color on hover/focus */
}

.modal-content-btn button:active {
  background-color: #00a2ff;
  /* Even darker shade when clicked */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* Smaller shadow when clicked */
}

/* Responsive Design */
@media screen and (max-width: 600px) {
  .modal-content-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #gendar-select-feild {
    /* Adjust the height as needed */
    /* Add any other styling you need */
    /* For example, adjusting the font size or padding */
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px 10px 38px;
  }
}

@media only screen and (max-device-width: 480px) {
  #gendar-select-feild {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px 10px 38px;
  }

  /* .add-lesson-btn button {
    position: absolute;
    top: 37px !important;
  } */

  /* Style the arrow icon (if needed) */
  #gendar-select-feild::after {
    content: "\25BC";
    /* Unicode character for a downward arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

/* #gendar-select-feild{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
} */

.error-page-wrapper {
  text-align: center;
}

.error-page-image {
  width: 699px;
  margin: 0px auto;
}

.error-page-image img {
  width: 100%;
}

.error-page-text {
  margin: -85px 0px 0px 0px;
}

.error-page-text h1 {
  color: #000;
  font-family: Poppins;
  font-size: 93px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.error-page-text p {
  color: #000;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 730px;
  margin: 6px auto 24px auto;
}

.error-page-text button {
  color: #fafafa;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #0068ff;
  padding: 7px 70px;
  border: none;
  outline: none;
}

.error-page-section {
  background: url(../src/assets/images/errorpage-background.png) 0 0 no-repeat;
  background-size: cover;
}

.invite-student-popup-folder button:disabled {
  background-color: #a1a1a1;
}

.Subjects-you-teach {
  display: flex;
  align-items: baseline;
  gap: 10px;
  width: 100%;
}

.add_subject_feild_btn {
  font-size: 13px;
  width: 15%;
  height: 38px;
  padding: 0px 25px;
  border-radius: 5px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
}

.or-title {
  text-align: center;
}

.text-field-chip-input {
  border: 1px solid lightgray;
  padding: 8px;
  border-radius: 4px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chip {
  background-color: #e0e0e0;
  margin: 4px;
  padding: 5px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.chip button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.text-field-chip-input-field {
  flex: 1;
  min-width: 120px;
  border: none;
  outline: none;
}

.text-field-chip-input-field.error {
  border-bottom: 2px solid red;
}

.error-message {
  color: red;
  margin-top: 8px;
}

.forprofile span {
  background-color: #0068ff;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 13px;
  position: absolute;
  /* bottom: 4px;
  right: 113px; */
  bottom: 4px;
  right: 92px;
}

#error-page-not-found-section .error-page-wrapper .error-page-image {
  width: 410px;
}

#error-page-not-found-section .error-page-text h1 {
  font-size: 46px;
}

#error-page-not-found-section .error-page-text span {
  font-size: 21px;
  font-weight: 600;
}

#error-page-not-found-section .error-page-text p {
  font-size: 14px;
}

#error-page-not-found-section .error-page-text {
  margin: 0px;
}

#error-page-not-found-section {
  background: none;
}

#setting-Personalize-AI {
  width: 116px;
  margin: 0px auto;
}

.regiterAsNote {
  color: red;
}

.Analytics-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LessonViewProgress-tab-filter-downlode-folder {
  cursor: pointer;
}

/* .studentDashboard-chatbot-tooltip .MuiTooltip-tooltip {
  font-size: 16px !important;
  background: #EEF9FF !important;
  color: black !important;
  margin-left: -95px !important;
  padding: 10px 20px !important;
  margin-top: -70px !important;
  border-radius: 5px !important;
} */

.custom-tooltip {
  background-color: #eef9ff;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -56px;
  left: -132px;
  right: 35px;
  line-break: 0;
  transform: translateX(-50%);
  width: 300px;
  text-align: end;
  font-size: 13px;
  text-transform: none;
  z-index: 2 !important;
}

#custom_chatbot_close_btn {
  position: absolute !important;
  top: -14px;
  right: -8px;
  font-size: 12px;
  color: black;
  background-color: white;
  width: 25px !important;
  min-width: 25px !important;
  height: 25px !important;
  border-radius: 100%;
  box-shadow: 0px 2px 6px #adadad;
}

.Analytics-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LessonViewProgress-tab-filter-downlode-folder {
  cursor: pointer;
}

.sort-popup-assignprocess {
  position: absolute;
  right: 10px;
  z-index: 1000;
  margin-top: 35px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.apply-button-assignprocess {
  margin-top: 20px;
  width: 100%;
  padding: 8px 0;
  background-color: #007bff;
  color: white;
  border: "none";
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  border: none;
}

.sort-popup-assignprocess h3 {
  margin: 0px 0px 0px 0px !important;
  /* padding-bottom: 10px; */
  /* border-bottom: 1px solid #eaeaea; */
  font-size: 14px;
  text-align: center;
}

.sort-popup-assignprocess label {
  display: block;
  margin: 10px 0;
  color: black;
  font-size: 14px;
}

.sort-popup-assignprocess input[type="radio"] {
  margin-right: 10px;
}

.sort-popup-assignprocess select {
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  color: #333;
  -webkit-appearance: none;
  /* Removes default chrome and safari style */
  -moz-appearance: none;
  /* Removes default style Firefox */
  appearance: none;
  /* Removes default browser style*/
  /* Add a background image for the arrow down icon */
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  cursor: pointer;
}

/* CSS for Warning Message */
.warning-message {
  color: #d32f2f;
  font-size: 14px;
  margin-top: 5px;
}

.dropdown-time-picker {
  position: relative;
  width: 300px;
}

.input-field {
  display: flex;
  flex-direction: column;
  background: white;
  cursor: pointer;
}

.input-field label {
  margin-bottom: 5px;
}

.input-field input {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.time-field {
  display: flex;
  border-radius: 6px;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.time-field button {
  background: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  margin: 4.3px;
}

.time-field span {
  text-align: center;
  color: #252525;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.time-field-inline {
  display: flex;
  margin: 0 10px;
  align-items: baseline;
  justify-content: flex-start;
}

.time-field-inline p {
  margin-left: 10px;
}

.Request-Verification-btn {
  font-size: 13px;
  background-color: transparent;
  color: #0068ff;
  padding: 9px 45px;
  border-radius: 30px;
  font-weight: 600;
  border: none;
  border: 2px solid #0068ff;
}

.Request-Verification-Code-popup-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: #00000042;
  padding: 27px 0px;
  z-index: 113;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Request-Verification-Code-inner-popup {
  background-color: white;
  border-radius: 30px;
  margin: auto;
  width: 100%;
  padding: 28px;
}

.Request-Verification-Code-inner-popup h2 {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

.Request-Verification-Code-inner-input label {
  font-size: 15px;
}

.send-Verification-btn {
  font-size: 13px;
  background-color: #0068ff;
  color: white;
  padding: 11px 45px;
  border-radius: 30px;
  border: none;
  width: 100%;
  margin: 10px 0px 0px 0px;
  box-shadow: 0px 3px 5px #cccccc;
}

.Back-To-Sign-In {
  margin: 10px 0px 0px 0px;
}

.Back-To-Sign-In a {
  font-size: 13px;
  text-decoration: underline;
}

.pop_up_for_lesson_start_date_note p {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  width: 30%;
  color: black;
  /* background-color: #000000; */
  background-color: #eef9ff;
  color: black;
  font-size: 13px;
  padding: 10px 0px;
  border-radius: 10px;
  position: fixed;
  top: 28%;
  left: 0%;
  right: 0%;
  margin: auto;
}

.pop_up_for_lesson_start_date_note {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0%;
  /* background-color: #73737330; */
  width: 100%;
  height: 100%;
  z-index: 113;
}

.universal-chatbot-importantSize {
  width: 35px !important;
  height: 35px !important;
}

.list-dropdown-icon {
  position: absolute;
  top: 27.9px;
  right: 203px;
}

.add_feild-btn {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
}

/* .setect-genter-list-dropdown{
  position: relative;
} */
.Course-close-btn {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.Course-close-btn button {
  padding: 6px 35px;
  border: none;
  border-radius: 6px;
}

.Course-close button {
  padding: 6px 35px;
}

.Course-close-btn button:nth-child(2) {
  background-color: #0068ff;
  color: white;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  /* For Safari and Chrome */
  /* .dashboard-popup-teacher-profile-details-input select {
    -webkit-appearance: none;
  } */
  .teacher-create-popup-gender-input {
    height: 27px;
  }
}

.invite-copy-link span {
  color: #0068ff;
  font-weight: 500;
  margin-bottom: 21px;
}

.logo,
.header_logo , 
.proschool-logo{
  display: flex;
  align-items: baseline;
  gap: 7px;
  cursor: pointer;
}

.logo span {
  font-size: 13px;
  color: black;
  font-family: Object sans-serif;
  text-transform: uppercase;
}

.custom-tooltip2 {
  width: 329px;
}

.StudentViewCourseDetails_view_Score {
  background-color: #0068ff;
  background-color: initial;
  border: 2px solid #2bcc00;
  border-radius: 6px;
  color: #2bcc00;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  margin-left: auto;
  outline: none;
  padding: 7px 41px;
}

.StudentViewCourseDetails-lesson-inner-assignments-folder {
  display: flex;
  align-items: center;
}

.lesson-dropdown-tab {
  display: flex;
  flex-direction: row;
}

#AssingmentMCQs-close-btn {
  position: absolute;
  top: 4%;
  right: 2%;
  border: 1px solid;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 11px;
  font-size: 13px;
}




/* superadmin login section start */
.superadmin-login-section p {
  font-size: 14px;
  color: black;
}

.superadmin-login-section h2 {
  margin: 9px 0px 34px 0px;
  font-size: 31px;
  color: #0068FF;
}

.superadmin-login-container {
  text-align: center;
  width: 605px;
}

.superadmin-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
}

.superadmin-login-form {
  background-color: #F5F9FF;
  padding: 60px;
  border-radius: 15px;
  box-shadow: 0px 4px 11px #f3f3f3;
}

/* superadmin login section end */




.table_gap {
  margin-bottom: 13px;
}
.Dashboard-List {
  padding: 0px 15px;
}

.Teacher_Student_Table_container .Dashboard-List-Recently-Added-table tbody tr {
  background-color: #F5FBFF;
}

.Teacher_Student_Table_container .Dashboard-List-Recently-Added-table button {
  padding: 5px 19px;
  border: 1px solid;
}

.Dashboard-List-Recently-Added-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.Dashboard-List-Recently-Added-table thead tr {
  text-align: center;
}

.Dashboard-List-Recently-Added-table th,
.Dashboard-List-Recently-Added-table td {
  padding: 12px 15px;
  font-size: 13px;
}

.Dashboard-List-Recently-Added-table tbody tr {
  text-align: center;
  border-bottom: 0.5px solid #AAAFB6;
}

.Dashboard-List-Recently-Added-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.Dashboard-List-Recently-Added-table img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.Dashboard-List-Recently-Added-table button {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  color: #0068FF;
}

/* .view-lessons-wrapper {
  padding: 20px;
} */
.Dashboard-List-Table {
  box-shadow: 0px 2px 15px #f1f1f1;
}





/* Custom_Tabs_section start */
.Custom_Tabs_section {
  display: flex;
  flex-direction: revert;
  margin: 0px auto;
  /* width: 94%; */
  width: 100%;
  border-radius: 63px;
  justify-content: center;
  gap: 123px;
  margin: 0px auto 0px auto;
  background-color: #f5f9ff;
  flex-direction: row;
  padding: 14px;
  margin: 37px auto;
}

/* Custom_Tabs_section end */
/* For Webkit browsers like Chrome and Safari */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}




/* school_list section start */
.search-list {
  width: 656px;
  position: relative;
}

.search_header-container {
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-wrapper {
  display: flex;
  align-items: center;
}

/* school_list section end */

.span.userName {
  width: 119px;
  text-align: left;
}

.Profile_wrapper {
  display: flex;
  align-items: center;
}

.Terms-and-Conditions-image-title,
.Privacy_Policy_title {
  margin: 37px auto 0px auto;
  width: 394px;
}

.Privacy_Policy_title {
  width: 338px;
}

.Terms-and-Conditions-image-title img,
.Terms-and-Conditions-image img {
  width: 100%;
}

.Terms-and-Conditions-image {
  margin: 54px auto 38px auto;
  /* display: flex; */
  justify-content: center;
  width: 200px;
}

.Terms-and-Conditions-list-foldre h2 {
  font-size: 21px;
  margin: 0px 0px 11px 0px;
}

.Terms-and-Conditions-list-foldre p {
  font-size: 13px;
  color: black;
}

.Terms-and-Conditions-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 39px;
}

.Terms-and-Conditions-section,
.Benefits-Beyond-Boundaries {
  background: url(../src/assets/images/tearms-condition-bg.png) 0 0;
  margin: 0px 0px 47px 0px;
}

.Terms-and-Conditions-wrapper {
  position: relative;
}

.half-circle-bg-image {
  position: absolute;
  top: 357px;
  right: -60px;
}

.half-circle-bg-image2 {
  position: absolute;
  top: 166px;
  left: -59px;
}

.book-logo3 {
  position: absolute;
  top: 222px;
  right: -60px;
}


/* ul.footer-Quick-Links {
  display: flex;
  flex-direction: column;
  gap: 13px;
} */



.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.footer-contact-folder span a {
  color: black;
}

.footer-contact-folder a {
  color: black;
  font-size: 15px;
}



/* aboutus landing page start */
.aboutUs-conatiner {
  display: flex;
  /* align-items: center; */
  width: 100%;
  margin: 50px 0px;
}

.aboutUs-left-section {
  width: 50%;
}

.aboutUs-left-section-image img {
  width: 100%;
}

.aboutUs-left-section-image {
  width: 253px;
}

.aboutUs-left-section h2 {
  font-size: 24px;
  margin: 31px 0px 22px 0px;
  line-height: 31px;
}

.aboutUs-left-section p {
  font-size: 15px;
  color: black;
  line-height: 24px;
}

.aboutUs-right-section {
  width: 50%;
  display: flex;
  justify-content: center;
}

.aboutUs-right-section-image,
.aboutUs-for-teacher-right-section-image {
  width: 430px;
}

.aboutUs-right-section-image img,
.aboutUs-for-teacher-right-section-image img {
  width: 100%;
}

.aboutUs-for-teacher-conatiner,
.aboutUs-for-student-conatiner,
.aboutUs-for-parent-conatiner {
  display: flex;
  width: 100%;
  margin: 30px 0px 50px 0px;
  align-items: center;
}

.aboutUs-for-teacher-right-section {
  width: 40%;
  display: flex;
  justify-content: center;
  position: relative;
}

.aboutUs-for-aboutUs-for-student-left-section-left-section {
  width: 60%;
  position: relative;
}

.aboutUs-for-teacher-list-container,
.aboutUs-for-student-list-container,
.aboutUs-for-parent-list-container {
  padding: 18px;
  border-radius: 14px;
  background-color: #EBF3FF;
  display: flex;
  flex-direction: column;
  gap: 13px;
  box-shadow: 0px 2px 8px #dddddd;
}

.aboutUs-for-teacher-list-list-folder,
.aboutUs-for-student-list-list-folder,
.aboutUs-for-parent-list-list-folder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.aboutUs-for-teacher-list-list-folder p,
.aboutUs-for-student-list-list-folder p,
.aboutUs-for-parent-list-list-folder p {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.aboutUs-aboutus-teacher-logo-image {
  width: 276px;
}

.aboutUs-logo-image-folder {
  background: linear-gradient(90deg, #AFD0FF 0.19%, rgba(217, 217, 217, 0.00) 116.91%);
  width: 371px;
  display: flex;
  justify-content: center;
  margin-left: -60px;
}

.aboutUs-for-student-wrapper .aboutUs-logo-image-folder {
  margin-left: auto;
}

.aboutUs-aboutus-teacher-logo-image img {
  width: 100%;
}

.aboutUs-for-teacher-book-image {
  position: absolute;
  top: -18%;
  right: 0%;
}

.aboutUs-for-teacher-book-image img {
  width: 97px;
}

.aboutUs-for-teacher-left-section,
.aboutUs-for-student-left-section,
.aboutUs-for-parent-left-section {
  width: 60%;
  position: relative;
}

.aboutUs-for-student-book-image {
  position: absolute;
  top: -15%;
  left: 2%;
}

.aboutUs-for-parent-book-image {
  position: absolute;
  top: -15%;
  right: 2%;
}

.aboutUs-for-student-book-image img,
.aboutUs-for-parent-book-image img {
  width: 70px;
}

.aboutUs-for-student-book-image {
  position: absolute;
  top: -15%;
  left: 2%;
}

.aboutUs-our-vision-container {
  display: flex;
  width: 100%;
  margin: 37px 0px 0px 0px;
  /* GAP: 104px; */
  justify-content: space-between;
}

.aboutUs-our-vision-folder {
  border: 1px solid;
  padding: 52px 37px;
  width: 40%;
  background-color: #0068FF;
  color: white;
  border-radius: 0px 20px 20px 0px;
  position: relative;
}

.aboutUs-our-vision-folder:nth-child(2) {
  border: none;
  background-color: white;
  color: #0068FF;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 2px 8px #dddddd;
  position: relative;
  width: 42%;
}

.aboutUs-our-vision-folder:nth-child(2) p {
  color: black;
}

.aboutUs-our-vision-folder h2 {
  font-size: 32px;
}

.aboutUs-our-vision-folder p {
  color: white;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
}

.aboutus_our_Vision_image {
  position: absolute;
  top: -20%;
  right: -24%;
}

.aboutus_our_Vision_image2 {
  position: absolute !important;
  top: 0% !important;
  left: -24% !important;
}

.aboutus_our_Vision_image2 {
  position: absolute;
  top: -20% !important;
  left: -102px !important;
}

.Educational-Revolution-conatiner {
  margin: 70px 0px;
  position: relative;
}

.Educational-Revolution-conatiner h2 {
  color: #002469;
  font-size: 30px;
  margin-bottom: 52px;
}

.Educational-Revolution-inner-conatiner {
  box-shadow: 0px 2px 10px #b9b9b9;
  border-radius: 15px;
}

.Educational-Revolution-inner-conatiner p {
  font-size: 15px;
  color: black;
  padding: 16px;
  font-weight: 500;
}

.aboutus_Educational-Revolution_image img {
  width: 100%;
}

.aboutus_Educational-Revolution_image-bg {
  position: absolute;
  top: 7%;
  left: 0px;
}

.aboutus_Educational-Revolution_image-bg img {
  width: 508px;
}

.aboutus_Educational-Revolution_image-bg2 {
  position: absolute;
  top: -5%;
  right: -25px;
}

.aboutus_title {
  font-size: 27px;
  color: #002469;
  font-weight: 600;
}

.Benefits-Beyond-Boundaries {
  position: relative;
}

.benefits-boundaries-half-circle-bg-image2 {
  position: absolute !important;
  top: 65px !important;
  left: 0px !important;
}

.benefits-boundaries-half-circle-bg-image1 {
  position: absolute !important;
  top: 522px !important;
  right: 0px !important;
}

.benefits-boundaries-half-circle-bg-image3 {
  position: absolute !important;
  bottom: 418px !important;
  right: 0px !important;
}

.benefits-boundaries-half-circle-bg-image3 {
  position: absolute;
  top: 1029px;
  left: 0px;
}

.benefits-boundaries-half-circle-bg-image4 {
  position: absolute !important;
  top: 1550px !important;
  right: 0px !important;
}

.Benefits-Beyond-Boundaries-logo1 {
  position: absolute;
  top: -2%;
  right: 0%;
}

.Benefits-Beyond-Boundaries-logo2 {
  position: absolute;
  top: 6%;
  left: 89%;
}

.Benefits-Beyond-Boundaries-logo3 {
  position: absolute;
  top: -15%;
  right: 2%;
}

.Benefits-Beyond-Boundaries-logo4 {
  position: absolute;
  top: 5%;
  left: 89%;
}

.aboutUs-right_arrow_bg {
  position: absolute;
  top: -19%;
  left: 0%;
}

.Benefits-Beyond-Boundaries-logo3 img {
  width: 116px;
}

/* aboutus landing page end*/

.back-home-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin: 50px 0 0;
}

.back-home-btn span {
  font-size: 13px;
  font-weight: 600;
}

.enrolled-courses {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.LessonViewProgress-back-btn-folder,
.view-course-Details-back-btn-folder {
  align-items: center;
  color: #0068ff;
  cursor: pointer;
}

.Invited_students_btn {
  border: 1px solid #0068ff;
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 11px;
  outline: none;
  padding: 7px 22px;
  background-color: transparent;
  margin: 0px;
}

.Invited_students_btn p {
  margin: 0px;
  color: #0068ff;
}

.notification-view-all-btn {
  display: flex;
  justify-content: flex-end;
  color: #0068ff;
  font-size: 13px;
  margin: 15px 15px 0px 0px;
}

.notificationPage-folder .notifications-folder {
  padding: 17px;
}

.notificationPage-folder .notifications-folder .notifications-inner-folder p {
  font-size: 13px;
  font-weight: 500;
}

.notification-time-folder span {
  font-size: 12px;
  font-weight: 500;
}

.invited_bg {
  background-color: #d3efff;
  color: #156897;
}

.enrolled_bg {
  background-color: #e6ffe5;
  color: #28991f;
}

#courseDate .form-control,
.edit-Course-inner-input .form-control {
  background-color: #f5f5f5;
  border: 1px solid #00000040 !important;
  border-radius: 12px;
  color: rgba(0, 0, 0, .92);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: .00938em;
  line-height: 1.4375em;
  text-transform: capitalize;
}

.filter-popup {
  right: 142px;
}

.studentDashboardSidePannel .dashboardlink.active {
  align-self: center;
  background-color: #cfe5fb;
  border-radius: 36px;
  color: #0068ff;
  display: flex;
  font-size: 16px;
  gap: 10px;
  padding: 10px 42px;
}

.studentDashboardSidePannel .dashboardlink {
  align-items: center;
  background-color: #f5f9ff;
  border: none;
  border-radius: 36px;
  color: grey;
  display: flex;
  font-size: 15px;
  gap: 10px;
  padding: 10px 20px;
}

.overview-lesson-mark-complete-btn .status-disabled-button {
  border: 2px solid #2bcc00;
  color: #2bcc00;
}

.dashboard-popup-teacher-profile-details-input label:after,
.introduce-yourself-title:after {
  color: red;
  content: "*";
  margin-left: 2px;
}

/* width */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Student-Active-main-wrapper {
  margin: 25px 0px 0px 0px;
}

.forprofile span i {
  color: white;
}

svg.uplode-icon-generator {
  transform: rotate(56deg);
  width: 25px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  top: -3px;
  position: relative;
}

.button_tab_edit_btn {
  align-items: center;
  background-color: initial;
  border: 1px solid #0068ff;
  border-radius: 4px;
  display: flex;
  gap: 11px;
  outline: none;
  padding: 5px 28px;
}

.button_tab_edit_btn p,
.button_tab_edit_btn span {
  color: #0068ff;
  font-size: 14px;
  margin: 0;
}

.course-inner-info-image img {
  border-radius: 100%;
}
.view-course-Details-back-btn-folder h2 {
  font-size: 16px !important;
  font-weight: 400;
}
.userName {
  color: black;
}

.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar {
  width: 10px;
}
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* Trial Code dbi */
@media only screen and (min-resolution: 125dpi) {
  .Create-Course-inner-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 15px;
  }

  .course-file-image {
    width: 100%;
    height: 200px;
  }

  .Create-Course-des-wrapper .MuiFormControl-root textarea {
    height: 153px !important;
  }

  .course-uploded-image {
    height: 177px;
  }

  .back-home-btn {
    margin: 19px 0px 0px 0px;
  }

  .studentDashboar-Suggested-Courses-container {
    display: flex;
    gap: 19px;
  }

  .AssignmentViewProgress-mock-test-container {
    padding: 9px 25px;
  }

  .AssignmentViewProgress-mock-test-close-btn {
    position: absolute;
    top: -1px;
    right: -16px;
    padding: 4px 6px;
    font-size: 10px;
  }

  .tab-content-courses {
    display: flex;
    gap: 19px;
  }

  .overview-lesson-read-left-section {
    height: 393px;
  }

  .overview-lesson-read-left-container {
    height: 357px;
  }

  .overview-lesson-read-right-inner-body {
    height: 344px;
  }

  .back-home-btn {
    margin: 25px 0 0 0px !important;
  }

  .form-buttons {
    margin: 0px auto 10px auto;
  }

  .dashboard-popup-teacher-profile-details-form-container {
    display: flex;
    gap: 8px;
  }

  .dashboard-popup-teacher-profile-details-form {
    margin: 10px auto 10px auto;
  }

  .Subjects-you-teach-feilds-folder {
    margin: 10px 0px;
  }

  .dot-indicator {
    margin: 0px 0px 0px 0px !important;
  }

  .BulkUplode-image img {
    width: 52% !important;
  }

  .BulkUplode-button-wrapper {
    margin: 22px 0px 0px 0px !important;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 16% !important;
    right: 3% !important;
    max-width: 358px !important;
    max-height: 472px !important;
    background-color: white;
    border-radius: 34px;
  }
  .studentDashboard-universal-chatbot-inner-chat {
    height: 321px !important;
  }
  .create-course {
    gap: 5px !important;
  }

  .Create-Course-image-disclamer span {
    max-width: 268px !important;
  }
  .button_tab_wrapper {
    align-items: center;
    display: flex;
    gap: 35px;
  }

  .course-file-image {
    height: 202px !important;
  }

  .button_tab_edit_btn {
    padding: 5px 26px;
  }

  .view-lessons-created-course-inner-preview-title p {
    width: 692px;
  }
  .msg_sec_new__left_single_inner img {
    width: 45px;
  }
  .account_setting_tab_sec .form_sec_settings .dashboard-popup-teacher-profile-details-form{
    width: 65% !important;
  }
  .cropper-container.cropper-bg{
    max-height: 433px !important;
  }
  .forget_modal_sec_new_body_sec div{
    max-height: 431px !important;
  }
  .studentDashboar-profile-btn-folder button {
    font-size: 12px !important;
  }
  .add-school {
    margin: 0px 0px 11px 0px !important;
    padding-bottom: 5px !important;
  }
  .Add-school-inner-input-feilds-folder .Add-school-inner-input {
    gap: 3px !important;
  }
  .Add-school-inner-input-feilds-folder {
    row-gap: 0px !important;
  }
  .Add-school-wrapper .Create-Course-inner-input-container {
    gap: 3px !important;
  }
  .add-school-file-image {
    min-height: 355px;
  }
  #add-school-image-container #uplode_image_btn .course-main-image .course-uploded-image {
    /* height: 390px !important; */
    /* height: 390px !important; */
  }
  .Add-school-inner-input {
    gap: 3px !important;
  }
  .Add-school-inner-des .MuiFormControl-root .MuiFormControl-marginNormal{
    margin-top: 3px !important;
    margin-bottom: 6px !important;
  }
  #add-school-image-container #uplode_image_btn {
    position: absolute;
    top: 146px;
  }
  .superadmin-login-section h2 {
    margin: 9px 0px 17px 0px;
  }
  .superadmin-login-form {
    padding: 35px;
  }
  .superadmin-login-form .signup-form {
    margin: 24px auto 0px auto;
  }
  .topratingreview-left-section {
    min-width: 23%;
  }
  .table-container td {
    padding: 9px;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    right: 260px !important;
  }
  .sort-popup-assignprocess {
    top: 168px !important;
  }
  /* .course-sortby-popup-wrapper .sort-popup-assignprocess {
    top: 113px !important;
  } */
}
/* Trial Code dbi */


@media (min-width: 280px) and (max-width: 320px) {
  .empower_sec_new_inner_left h3 {
    font-size: 25px !important;
    margin-bottom: 27px;
    line-height: 30px !important;
    max-width: 100% !important;
  }

  .line_text_img {
    position: absolute !important;
    right: 6px !important;
    bottom: -17px !important;
    width: 205px !important;
  }

  .empower_sec_new_inner_left p {
    max-width: 247px !important;
    font-size: 11px !important;
  }

  .pre_stu_sec,
  .pre_stu_sec_main {
    width: 100% !important;
    height: 68px !important;
    padding: 25px !important;
  }

  .pre_stu_sec span,
  .pre_stu_sec_main span {
    font-size: 17px !important;
  }

  .stu_img_pro {
    position: absolute !important;
    width: 35px !important;
    top: -26px !important;
    right: 11px !important;
  }

  .lets_explore_btn a {
    height: 38px;
    width: 153px;
    font-size: 11px;
  }

  .revolution_sec_new_head h5,
  .customize_learing_journey_inner_head h5 {
    font-size: 22px !important;
  }

  .revo_img {
    position: absolute;
    top: -24px;
    width: 82px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .revolution_sec_new_single h6 {
    font-size: 17px !important;
  }

  .revolution_sec_new_single p {
    font-size: 11px !important;
  }

  .customize_learing_journey_right_inner_folder h2 {
    font-size: 15px !important;
  }

  .customize_learing_journey_right_inner_folder p {
    font-size: 10px !important;
  }

  .customize_learing_journey_right_inner_folder {
    padding: 14px 0px 14px 40px;
  }

  .choose-your-role-wraper h2 {
    text-align: center;
    font-size: 19px;
  }

  .choose-your-role-btn-folder button p {
    font-size: 13px !important;
  }

  .transforming-learning-experiences-left-section h2 {
    font-size: 21px !important;
    max-width: 391px !important;
    line-height: 27px !important;
  }

  .transforming-learning-experiences-list-folder p {
    margin: 0px 0px 0px 0px;
    font-size: 13px !important;
  }

  .transforming-learning-experiences-left-section {
    padding: 29px 16px !important;
  }

  .footer-link-folder p {
    font-size: 18px;
  }

  .footer-link-folder ul li a {
    font-size: 13px;
  }

  .footer-Join-our-newsletter-folder p {
    font-size: 14px;
  }

  .footer-Join-our-newsletter-search-folder button {
    width: 35%;
    padding: 7px 13px;
    font-size: 11px;
  }

  .footer-get-in-touch-folder span {
    font-size: 15px;
  }

  .blue-line-image2 {
    position: absolute;
    top: 98px;
    left: 9px !important;
    width: 234px;
  }

  .orange-line-image2 {
    position: absolute;
    top: 90px;
    left: 20px !important;
  }

  .indi_cls_main img,
  .customize_learing_journey_light_image img {
    display: none;
  }

  .Students-courses-details-folder,
  .view-Students-course-details-folder {
    /* width: 47% !important; */
    min-width: 47% !important;
  }
  .date-inner-wrapper .view-Students-course-details-folder {
    width: 48% !important;
  }

  .Students-courses-details-folder span {
    font-size: 10px !important;
  }

  /* .dashborad-Courses-folder {
    padding: 16px 18px !important;
  } */
  .dashborad-Courses-folder {
    width: 48% !important;
    padding: 16px 10px !important;
  }

  .dashborad-Courses-container {
    display: flex;
    gap: 9px;
  }

  .dashborad-Courses-count {
    padding: 11px 0px !important;
  }

  .created-my-courses-title-wrapper h2 {
    font-size: 12px !important;
    /* width: 114px !important; */
    max-width: 112px !important;
  }

  .dashboard-name-title {
    font-size: 18px !important;
  }

  .view-more {
    font-size: 13px;
    position: absolute;
    right: -6px;
    bottom: -5px;
    font-size: 10px !important;
  }

  .view-course-details-share-folder {
    width: 258px !important;
  }

  .copy-link-or-path-folder button {
    font-size: 12px !important;
    padding: 8px 20px !important;
  }

  .edit-course-date-and-price-container {
    display: flex;
    align-items: flex-end;
  }

  .edit-course-date-and-price-container .edit-Course-inner-input {
    width: 48.2% !important;
  }

  .edit-active-lesson-btn-container button {
    padding: 2px 5px !important;
    font-size: 9px !important;
  }

  .notifications-wrapper {
    width: 259px !important;
  }

  .Create-Course-inner-input-container {
    display: flex;
    align-items: flex-end;
  }

  .Create-Courses-wrapper {
    height: 992px;
  }

  .Create-Course-btn-wrapper button {
    font-size: 11px;
    padding: 7px 14px !important;
    width: 47% !important;
  }

  .view-lessons-created-course-inner-preview-title {
    display: flex;
    gap: 4px;
  }

  .view-lessons-created-course-wrapper {
    padding: 10px 1px !important;
  }

  .assignments-sec-container {
    padding: 0px 11px;
  }

  .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    bottom: 3px;
    right: 56px !important;
  }

  .settings_main_sec_head ul li a {
    display: flex;
    text-align: center;
  }

  /* .AssignmentViewProgress-mock-test-container {

  .AssignmentViewProgress-mock-test-container {
    max-width: 262px !important;
    padding: 20px;
  } */
  .AssignmentViewProgress-mock-test-container {
    width: 262px !important;
    padding: 14px !important;
  }

  .AssignmentViewProgress-mock-test-close-btn {
    position: absolute;
    top: -3px;
    right: -5px !important;
  }

  .AssignmentViewProgress-mock-test-answer-folder {
    padding: 14px 10px;
  }

  .AssignmentViewProgress-mock-test-status-container {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .profile-wrapper {
    display: flex;
    gap: 12px;
  }

  .studentDashboar-actives-container {
    display: flex;
    gap: 8px;
  }

  .profile-wrapper {
    display: flex;
    gap: 12px;
  }

  .lesson_generator_editor_toos-folder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .lesson_generator_body_sec_left_body {
    padding: 20px 8px 10px 8px !important;
  }

  .lesson_generator_inner {
    margin-bottom: 18px !important;
  }

  .subject_special h5 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .sub_special {
    display: flex;
    flex-wrap: wrap;
    gap: 7px !important;
  }

  .sub_special li {
    /* font-size: 9px; */
    padding: 7px 18px !important;
  }

  .LessonViewProgress-student-course-folder .view-Students-course-details-counts {
    padding: 6px 20px;
  }

  .list-dropdown-icon {
    position: absolute !important;
    top: 27.9px !important;
    right: 169px !important;
  }

  .custom-tooltip {
    position: absolute;
    top: -64px !important;
    left: -67px !important;
    width: 204px !important;
  }

  .View-Progress-btn {
    /* padding: 5px 11px !important; */
    padding: 5px 4px !important;
  }

  .filter-popup {
    right: 94px !important;
  }

  .button_tab_wrapper .Student-Active-start-Lesson {
    padding: 9px 8px;
    width: 97%;
  }

  .button_tab_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px !important;
  }

  .step-container {
    gap: 4px !important;
  }

  #Lesson_Container .view-course-details-container .view-Students-course-details-folder {
    width: 100% !important;
  }

  #Lesson_Container .view-course-details-container .view-Students-course-details-folder .view-Students-course-details-counts p {
    justify-content: center;
    padding: 3px 0px;
  }

  .dashboard-popup-teacher-profile-details-input #teacher-create-profile-input {
    padding: 8px 10px 8px 39px !important;
  }

  .add-profile-save-btn,
  .add-profile-save-btn {
    width: 50% !important;
    padding: 7px 0px !important;
    font-size: 11px;
  }

  .BulkUplode-button-folder {
    width: 100% !important;
  }

  .BulkUplode-button-folder p {
    font-size: 10px;
  }

  .DashboardPaechart div {
    width: 100% !important;
    height: 208px !important;
  }

  .admin-dashboard-graph-dropdown {
    width: 100%;
  }

  .admin-dashboard-graph-dropdown-menu {
    width: 50%;
  }

  .admin-dashboard-graph-dropdown-menu .css-yh2y7a {
    min-width: 100%;
  }

  .add-lesson-btn button {
    top: 46px !important;
  }

  .header-filter-container {
    display: flex;
    justify-content: flex-start !important;
    justify-content: flex-end !important;
  }
  .course-sortby-popup-wrapper .sort-popup-assignprocess {
    top: 0px !important;
  }
  .invite-copy-link {
    margin-bottom: 0px !important;
  }
  .studentDashboar-profile-btn-folder {
    display: flex;
    /* flex-direction: column; */
  }
  .react-responsive-modal-modal{
    min-width: 86% !important;
  }
  .Create-Course-uplode-outer-image , 
  .Regenerate-Course-image{
    width: 50%;
  }
  .Create-Course-uplode-outer-image .MuiButtonBase-root{
    width: 100% !important; 
    padding: 4px 6px !important;
  }
  .Course-created-successfully-folder h2 {
    font-size: 15px !important;
  }
  .Course-created-successfully-container {
    max-width: 262px !important;
    padding: 34px 15px !important;
  }
  .sort-popup-assignprocess {
    top: 155px !important;
  }
  .copy-link-or-path-folder input {
    font-size: 11px;
  }
  /* .AssingmentMCQs-mock-test-answer-folder {
    padding: 8px 10px !important;
  } */
  .list-of-students-wrappper table tbody tr td a {
    font-size: 9px;
  }
  .assign_main_box_left {
    margin-top: 0px !important;
  }
  .overview-lesson-mark-complete-btn button {
    padding: 7px 7px !important;
  }
  .Dashboard-List-Recently-Added-table th, 
  .Dashboard-List-Recently-Added-table td {
    padding: 12px 0px;
    font-size: 11px;
  }
  .Dashboard-List-Table-wrapper .view-lessons-wrapper .tab-content {
    margin: 15px 0px 0px 0px;
  }
  .AssingmentMCQs-mock-test-answer-btn-folder {
    margin: 0px 0px 10px 0px !important;
  }
  .studentDashboard-chatbot-container-close-image {
    top: 3% !important;
    right: 4% !important;
  }
  .studentDashboard-chatbot-title-folder h2 {
    font-size: 14px !important;
  }
  .studentDashboard-chatbot-container-close-image img{
    width: 20px;
  }
  /* .pdf_box_sec_inner_single span h5 {
    width: 55px;
  } */
  .pdf_box_sec_inner {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
  .profile_linkedin {
    display: flex;
    gap: 5px;
    margin-left: 0px;
    margin: 10px 0px 0px 1px;
  }
  .profile_linkedin a {
    font-size: 8.8px !important;
  }
  .lesson-stats-student-name, 
  .lesson-stats-course-name, 
  .assignment-student-name {
    padding-bottom: 0px;
    font-size: 11px !important;
    margin: 0px;
  }
  .course-discount-input p {
    font-size: 11px;
  }
  .superadmin-login-container {
    width: 256px !important;
  }
  .superadmin-login-section h2 {
    font-size: 26px !important;
  }
  .superadmin-login-section p {
    font-size: 13px !important;
  }
  .Overview-Lesson-ai-voice-animation-folder {
    display: flex;
    margin-left: auto;
  }
  .profile_linkedin input {
    width: 192px !important;
  }
  .reset_password_body_single label {
    font-size: 10px !important;
  }
  .Congratulations-assignment-btns {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
  .Congratulations-assignment-btns .View-Course {
    width: 100%;
    padding: 7px 31px;
  }
  .Congratulations-assignment-btns .view-result {
    padding: 7px 31px;
  }
  .AssignmentViewProgress-mock-test-marks-obtained {
    display: flex;
    flex-direction: column;
    gap: 5px !important;
  }
  .View-Progress-btn {
    font-size: 11px !important;
    padding: 5px 0px !important;
  }
}

@media only screen and (min-width: 830px) and (max-width:  900px){
  .view-lessons-created-course-inner-preview-title p {
    max-width: 200px;
  }
  .LessonAnalytics-profile h2 {
    font-size: 13px;
  }
  .LessonAnalytics-profile-folder p {
    font-size: 13px;
  }
  .LessonAnalytics-profile-folder h2 {
    font-size: 14px;
  }
  .Teacher-student-edit-profile-left-section {
    width: 15%;
  }
  .Create-Course-des-folder {
    width: 70%;
  }
  .uploded-image-section {
    width: 28%;
  }
  .AssignmentViewProgress-mock-test-container {
    max-width: 838px;
  }
  .dashboardHeaderProfile {
    gap: 25px !important;
  }
  .notifications-all-container-wrapper {
    max-height: 420px;
  }
  .lesson-lsit-wrapper .Student-Active-start-Lesson {
    padding: 7px 20px;
  }
  .studentDashboar-Suggested-Courses-folder {
    width: 266px;
  }
  .studentDashboar-Suggested-Courses-container{
    display: flex;
    justify-content: initial;
  }
  .monetize-course-input-toggle {
    min-width: 51%;
  }
  .Regenerate-Course-image {
    display: flex;
    align-items: center;
  }
  .edit-course-details-right-section .Create-Course-disscount-wrapper .Create-Course-inner-input {
    width: 31%;
  }
  .edit-course-details-right-section .Create-Course-disscount-wrapper{
    flex-wrap: wrap;
  }
}

/* @media only screen and (min-width: 717px) and (max-width: 720px)  */
@media only screen and (min-width: 653px) and (max-width: 720px) {

  /* .Create-Courses-wrapper {
    height: 1129px !important;
    top: 115% !important;
    position: absolute;
  } */
  .view-lessons-created-course-inner-prewview-container {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: space-between;
    gap: 13px;
  }

  .black-plus-icon {
    right: 272px !important;
  }

  .create-course-text {
    font-size: 13px !important;
  }

  .msg_left_search i {
    position: absolute;
    left: 47px !important;
    top: 10px;
  }

  .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    bottom: 3px;
    right: 282px !important;
  }

  .LessonViewProgress-tab-filter-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: end;
  }

  .dashboard-header-search {
    width: 400px !important;
  }

  .create-course svg {
    width: 22px;
    height: 22px;
  }

  .create-course {
    height: 40px;
  }

  .studentrequest-profile-name {
    width: 12% !important;
  }

  .studentrequest-profile-image {
    width: 11% !important;
  }

  .coming-soon {
    right: 95px !important;
  }

  /* .studentDashboar-Suggested-Courses-folder {
    width: 48% !important;
  } */
  .msg_sec_new__left_body {
    height: 421px !important;
  }
  .Overview-Lesson-description {
    max-height: 1.6rem !important;
  }
  .teacherviewcoursedetails-inner-popup-folder {
    width: 468px !important;
  }
  .list-dropdown-icon {
    position: absolute !important;
    top: 27.9px !important;
    right: 572px !important;
  }
  .view-course-details-share-folder {
    min-width: 482px !important;
  }
  .Subjects-you-teach input {
    padding: 8px 20px;
    font-size: 10px;
  }
  .add_subject_feild_btn {
    font-size: 11px !important;
    height: 30px !important;
  }
  .notifications-all-container-wrapper {
    max-height: 319px !important;
  }
  #Lesson_Container .view-course-details-container .view-Students-course-details-folder .view-Students-course-details-counts p {
    justify-content: center;
    padding: 3px 0px;
  }
  .AssignmentViewProgress-mock-test-container {
    min-width: 488px !important;
  }
  .create-course {
    /* margin-left: 125px !important; */
    min-width: 135px !important;
  }
  .dashboardSidePannel .dashboardlink {
    padding: 10px 14px !important;
  }
  .admin-dashboard-graph-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row !important;
  }
  .admin-dashboard-graph-left-section {
    min-width: 66% !important;
  }
  .admin-dashboard-graph-right-section {
    max-width: 33% !important;
  }
  .admin-dashboard-graph-dropdown {
    width: 100%;
  }
  .admin-dashboard-graph-dropdown-menu {
    width: 50%;
  }
  .admin-dashboard-graph-dropdown-menu .css-yh2y7a {
    max-width: 73px !important;
  }
  .DashboardPaechart div {
    width: 100% !important;
    max-height: 137px !important;
    display: flex;
    justify-content: center;
  }
  .course-revenue-price-wrapper p {
    font-size: 11px !important;
  }
  .Dashboard-Review-Card-foler {
    max-width: 47% !important;
  }
  .sort-popup-assignprocess {
    top: 0px !important;
  }
  .student-sortby-popup-wrapper {
    position: relative;
  }
  .student-sortby-popup-wrapper .student-shortby-popup .sort-popup-assignprocess {
    top: 18px !important;
    right: 0px !important;
  }
  .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-details-input .form-icon {
    position: absolute;
    /* top: 40px; */
    top: 37px;
    left: 1.6%;
  }
  .form_sec_settings .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input .grade-icon {
    position: absolute;
    top: 42px !important;
  }
  .dot-indicator {
    margin: 10px 0px 0px 0px !important;
  }
  /* .studentDashboar-Suggested-Courses-folder {
    max-width: 48%;
    padding: 10px;
  } */
  .tab-content-courses {
    display: flex !important;
  }
  .StudentCourseCard-main-container {
    width: 48%;
  }
  .studentDashboar-Suggested-Courses-wrapper .studentDashboar-Suggested-Courses-container .studentDashboar-Suggested-Courses-folder {
    width: 48%;
    padding: 10px;
  }
  .gray_house_sec_inner {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
  .enrolled-courses .studentDashboar-Suggested-Courses-folder {
    width: 48%;
  }
  .sort-popup-assignprocess {
    top: 0px;
  }
  .filter-wrapper {
    position: relative;
  }
  .lesson-lsit-wrapper, 
  .assignment-list-wraper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
  .lesson-lsit-wrapper .Student-Active-Lesson-container, 
  .assignment-list-wraper .Student-Active-Lesson-container{
    width: 48%;
    margin-bottom: 0px;
  }
  .LessonAnalytics-profile {
    width: 17% !important;
  }
  .LessonAnalytics-profile-container {
    display: flex;
    flex-direction: row !important;
  }
  .msg_sec_new_body_inner {
    grid-template-columns: 2fr 3fr !important;
    grid-gap: 19px !important;
    display: inline-grid !important;
  }
  .msg_left_search i {
    left: 29px !important;
  }
  .msg_data_sec h6 {
    font-size: 12px !important;
  }
  .right_chat_sec_new_head {
    padding-bottom: 11px !important;
    margin-bottom: 10px !;
  }
  .right_chat_sec_new_head img {
    width: 45px !important;
    height: 45px;
  }
  .right_chat_sec_right img, 
  .right_chat_sec_left img , 
  .msg_sec_new__left_single_inner img{
    width: 40px !important;
    height: 40px !important;
  }
  .right_chat_sec_left img {
    left: 10px;
    top: -29px;
  }
  .right_chat_sec_left {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 35px;
  } 
  .right_chat_sec_right span, 
  .right_chat_sec_left span {
    font-size: 11px !important;
    margin: 24px 0px 0px 0px !important;
  }
  .studentDashboardSidePannel {
    display: flex;
    flex-direction: row !important;
  }
  .studentDashboardSidePannel .dashboardlink.active {
    width: auto !important;
  }
  .StudentCourseCard-wrapper .view-lessons-btn-container button {
    font-size: 12px;
  }
  .created-my-courses-title-wrapper h2 {
    min-width: 492px !important;
  }
  .right_chat_sec-profile i {
    display: none !important;
    padding-bottom: 20px !important;
  }
  .StudentCourseCard-lessons-tab-container {
    display: flex;
    flex-wrap: nowrap !important;
  }
  .assignments_count_details {
    font-size: 11px !important;
    margin: 6px 0px 0px 0px !important;
  }
  /* .view-lessons-created-course-inner-prewview-container {
    display: flex;
    flex-direction: row;
  } */
}

@media only screen and (max-width: 767px) {
  body{
    user-zoom:fixed;
  }
  .container-fluid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .dashboard-name-title {
    font-size: 18px;
  }

  .dashborad-Courses-folder-image img {
    width: 52px;
  }

  .dashborad-Courses-count h2 {
    font-size: 19px;
  }

  /* .dashborad-Courses-folder {
    width: 100%;
    padding: 20px 18px;
    display: flex;
    align-items: center;
  } */
  .dashborad-Courses-folder {
    /* width: 46%; */
    max-width: 46%;
    padding: 20px 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;
  }
  .light_logo {
    position: absolute;
    left: 0px;
    width: 63px;
  }

  /* .dashborad-Courses-folder-image {
    width: 30%;
  } */
  .dashborad-Courses-folder-image {
    width: 47%;
  }

  /* .dashborad-Courses-count {
    margin: 0px 0px 0px 0px;
    width: 70%;
    padding: 16px 0px;
  } */
  .dashborad-Courses-count {
    margin: 0px 0px 0px 0px;
    width: 100%;
    padding: 9px 0px;
  }

  /* .dashborad-Courses-container {
    display: flex;
    flex-direction: column;
  } */
  .dashborad-Courses-container {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: center;
  }

  .dashboardMainBody {
    padding: 0px !important;
    margin: 20px 0px 0px 0px;
  }
  .view-course-details-folder {
    margin: 0px 0px 0px 0px;
  }

  .dashboard-name-title {
    font-size: 18px;
  }

  .Start-by-creating-your-course-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 19px;
    margin: 27px 0px;
    gap: 9px;
  }

  .created-my-courses-folder,
  .view-course-details-folder {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section {
    width: 100% !important;
  }
  .created-my-courses-folder-image-left-folder {
    flex: auto;
  }

  .created-my-courses-des-wrapper,
  .view-course-details-right-section {
    width: 100% !important;
  }

  .created-my-courses-title-wrapper h2,
  .view-course-details-right-section h2 {
    font-size: 17px !important;
    /* width: 176px !important; */
  }

  .view-course-Details-back-btn-folder,
  .LessonViewProgress-back-btn-folder {
    margin: 0px 0px 10px 0px;
  }

  .profile-wrapper {
    display: flex;
    gap: 10px !important;
  }

  .Students-courses-details-folder,
  .view-Students-course-details-folder {
    min-width: 48%;
  }

  .edit-course-or-delete-course i {
    width: 20px;
    height: 30px;
  }

  button.View-Course {
    width: 100%;
  }

  .created-my-courses-ratings-folder p {
    font-size: 10px;
  }

  .Students-courses-details-container,
  .view-course-details-container {
    margin: 13px 0px 12px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 11px;
  }

  .Students-courses-details-counts p,
  .view-Students-course-details-counts p,
  .assignments-sec-container .Overview-Lesson-date .Overview-Lesson-date-counts p {
    margin: 0px;
    font-size: 10px !important;
  }
  .aboutUs-right_arrow_bg {
    position: absolute;
    top: -17%;
    left: 52% !important;
  }
  .aboutUs-right_arrow_bg img{
    width: 124px;
  }

  /* .Create-Courses-wrapper {
    background-color: white;
    border-radius: 30px;
    padding: 28px;
    position: fixed;
    top: 56%;
    left: 0%;
    right: 0%;
    transform: translateY(-50%) !important;
  } */
  .Create-Courses-wrapper h2 {
    margin: 0px 0px 15px 0px;
    padding-bottom: 7px;
    font-size: 18px !important;
  }

  /* .Create-Course-inner-input {
    width: 47.9% !important;
  } */
  .Create-Course-inner-input {
    width: 100% !important;
  }

  .Create-Course-inner-input-container {
    display: flex;
    gap: 8px !important;
  }

  .Create-Courses-wrapper {
    padding: 15px !important;
    height: auto;
    /* position: absolute; */
    /* top: 82% !important; */
    /* overflow-y: scroll; */
    /* margin-bottom: 20px; */
  }

  .Personalize-your-AI-Assistant-left-section {
    border-right: none;
  }

  .teacherviewcoursedetails-inner-popup-folder h2 {
    font-size: 16px !important;
    width: 212px !important;
  }

  .create-course-Grade-input-folder {
    width: 100% !important;
    gap: 8px;
  }

  .dashboardSidePannel button,
  .dashboardSidePannel button.active {
    font-size: 13px !important;
    width: 100%;
  }

  .create-course-text {
    font-size: 13px;
  }

  .dashboard-name-title {
    font-size: 18px !important;
  }

  .dashborad-Courses-count h2 {
    font-size: 18px !important;
  }

  .dashborad-Courses-count p {
    font-size: 11px !important;
  }

  .Create-Course-inner-input label,
  .edit-Course-inner-input label,
  .edit-Course-inner-textarea label {
    font-size: 10px !important;
  }

  .create-course-enhance-course-folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
  }

  .Course-description-inner-ai-feilds-folder {
    display: flex;
    align-items: baseline;
    width: 100%;
  }

  .Course-description-inner-ai-feilds {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: center;
  }

  .Create-Course-btn-wrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }

  .Create-Course-btn-wrapper button {
    width: 48%;
    margin-right: 5px;
  }

  .Create-Course-des-wrapper {
    display: flex;
    flex-direction: column;
  }

  .Create-Course-des-folder {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    min-width: 100%;
  }

  .uploded-image-section {
    /* width: 100%; */
    min-width: 100%;
  }

  .Create-Course-image-disclamer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 7px;
    margin: 0px 0px 11px 0px;
  }

  .Create-Course-image-disclamer label::after {
    content: "*";
    color: red;
    margin-left: 2px;
  }

  .Create-Course-image p {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .course-file-image {
    width: 100%;
    height: 236px !important;
  }

  .studentDashboard-chatbot-title-folder h2 {
    font-size: 15px;
    margin: 0px;
  }

  .studentDashboard-universal-chatbot-inner-chat {
    width: 100%;
    height: 320px;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 15%;
    right: 3%;
    width: 94%;
    height: 472px;
  }

  .teacherviewcoursedetails-inner-popup-folder textarea {
    height: 226px;
    width: 100%;
  }

  .teacherviewcoursedetails-inner-popup-folder p {
    height: 226px;
    width: 100%;
  }

  .teacherviewcoursedetails-inner-popup-folder {
    position: fixed;
    top: 50%;
    left: 0%;
    right: 0%;
    transform: translateY(-50%) !important;
    width: 336px;
  }

  .view-course-details-edit-and-share-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 0px 12px 0px;
    gap: 8px;
  }

  .Invited_students_btn {
    justify-content: center;
    padding: 3px 22px !important;
  }

  .view-course-details-share-folder {
    max-width: 336px !important;
  }

  .share-course-social-media-btn {
    position: absolute;
    top: -37px !important;
    right: 2%;
    font-size: 13px;
  }

  .invite-student-close-btn {
    padding: 5px 7px !important;
    font-size: 13px !important;
  }

  .view-course-details-edit-and-share-folder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .edit-course-details-folder {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .edit-course-details-left-section {
    width: 100% !important;
  }

  .edit-course-details-right-section {
    width: 100%;
  }

  .edit-course-details-inner-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .edit-course-details-inner-input-container .create-course-Grade-input-folder,
  .edit-course-date-and-price-container {
    display: flex;
    gap: 8px;
  }

  .Update-Publish-btn {
    transform: translateX(0px) !important;
  }
  .Update-Publish-btn-disable {
    transform: translateX(0px) !important;
  }
  .edit-Course-inner-input {
    width: 100%;
  }

  .edit-course-date-and-price-container .edit-Course-inner-input {
    width: 48.5%;
  }

  .view-lessons-wrapper {
    width: 1004px;
  }

  .view-lessons-section {
    margin: 0px 0px;
    /* overflow-x: scroll; */
  }

  .list-of-students-wrappper table tbody tr td {
    text-align: center;
  }

  .list-of-students-wrappper thead {
    display: none;
  }

  .studentrequest-profile-name {
    width: 28%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .list-of-students-wrappper tbody td:last-child {
    margin: 0 auto;
  }

  .view-lessons-created-course-inner-prewview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
  }

  .list-of-students-wrappper table tbody tr td:nth-child(1) {
    cursor: pointer;
    width: 100%;
  }

  /* .view-lessons-created-course-wrapper p {
    font-size: 13px !important;
  } */
  .Created-assignments-date.Created-assignments-due-date p {
    font-size: 11px !important;
  }

  .view-lessons-btn-container {
    margin-top: 14px;
    gap: 4px;
  }

  .Create-Lesson-section-wrapper {
    /* width: 905px; */
    width: 100%;
  }

  #CreateLesson-section {
    overflow-x: scroll;
    margin-bottom: 10px;
  }

  .editor-heading-h1 {
    font-size: 18px !important;
  }

  .list-of-students-wrappper table tbody tr td img {
    width: 50px !important;
    height: 50px !important;
    margin: 0px 0px 0px 0px !important;
  }

  .assign_main_box_right,
  .assign_main_box {
    display: grid;
    grid-template-columns: 1fr !important;
  }

  .assign_main_box_left_single label,
  .assign_main_box_right_single label {
    font-size: 12px !important;
  }

  .header_logo {
    position: relative;
    width: 120px;
  }

  .empower_sec_new_inner_left h3 {
    font-size: 31px;
    margin-bottom: 30px;
    line-height: 40px;
    max-width: 324px;
  }

  .line_text_img {
    position: absolute;
    right: 10px;
    bottom: -18px;
  }

  .empower_sec_new_inner_left p {
    font-size: 14px;
    max-width: 321px;
  }

  .pre_stu_sec,
  .pre_stu_sec_main {
    width: 310px;
    height: 68px;
    padding: 25px;
  }

  .pre_stu_sec_main {
    left: 0px;
    margin-left: auto;
  }

  .stu_img_pro {
    position: absolute;
    width: 35px;
    top: -26px;
    right: 20px;
  }

  .lesson_generator_body_sec {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
  }

  .lesson_generator_body_sec_left {
    width: 100% !important;
  }

  .lesson_generator_body_sec_right {
    width: 100% !important;
  }

  .pre_stu_sec p,
  .empower_sec_new_inner_left p {
    font-size: 13px;
  }

  .pre_stu_sec span,
  .pre_stu_sec_main span {
    font-size: 18px;
  }

  .lets_explore_btn a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    height: 38px;
    width: 166px;
    justify-content: center;
    font-size: 13px;
  }

  .empower_sec_new {
    position: relative;
    padding: 0px 0px 20px 0px;
  }

  .revolution_sec_new_head h5,
  .customize_learing_journey_inner_head h5 {
    font-size: 26px;
  }

  .img_revo {
    position: absolute;
    left: 6px;
    top: -59px;
    width: 45px;
  }

  .revolution_sec_new_body_sec {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding: 50px 20px 20px 20px;
  }

  .revolution_sec_new_single h6 {
    font-size: 18px;
  }

  .revo_img {
    position: absolute;
    top: -33px;
    width: 93px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .empower_sec_new_inner {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
  }

  .customize_learing_journey-folder {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }

  .footer-contact-folder a {
    font-size: 14px;
  }

  .customize_learing_journey_left_section {
    width: 100%;
    position: relative;
  }

  .customize_learing_journey_right_section {
    width: 100%;
  }

  .customize_learing_journey_right_icon svg {
    width: 81%;
  }

  .customize_learing_journey_right_inner_folder h2 {
    font-size: 16px;
  }

  .customize_learing_journey_right_inner_folder p {
    font-size: 11px;
  }

  .choose-your-role-btn-folder {
    padding: 5px 0px;
  }

  .customize_learing_journey_light_image {
    position: absolute;
    right: 18px;
    top: -68px;
    width: 61px;
  }

  .customize_learing_journey_arrow {
    position: absolute;
    top: -88px;
    left: -12px;
  }

  .customize_learing_journey_half_circle {
    position: absolute;
    top: -90px;
    right: -17px;
  }

  .customize_learing_journey_half_circle svg {
    width: 30px;
  }

  .choose-your-role-wraper h2 {
    font-size: 20px;
  }

  .line_text_image2 {
    position: absolute;
    right: 40px;
    bottom: -10px;
    width: 60%;
  }

  .customize_learing_journey_arrow svg {
    width: 80px;
  }

  .blue-line-image2 {
    position: absolute;
    top: 56px;
    left: 74px;
    width: 228px;
  }

  .transforming-learning-experiences-circle-bg-icon svg {
    width: 130px;
  }

  .transforming-learning-experiences-doted-image svg {
    width: 70px;
  }

  .transforming-learning-experiences-doted2-image {
    position: absolute;
    bottom: -37px;
    left: -10px;
  }

  .choose-your-role-half-circle-image {
    position: absolute;
    top: 88px;
    left: -17px;
  }

  .choose-your-role-doted4-image svg {
    width: 37px;
  }

  .choose-your-role-btn-folder button p {
    font-size: 15px;
  }

  .choose-your-role-doted3-image svg {
    width: 140px;
    position: relative;
    top: -41px;
    left: 95px;
  }

  .choose-your-role-half-circle-image svg {
    width: 30px;
    display: none;
  }
  .customize_learing_journey_right_inner_folder {
    padding: 14px 9px 14px 30px;

  }

  .choose-your-role-book-image {
    position: absolute;
    top: -56px;
    right: 6px;
  }

  .choose-your-role-book-image svg {
    width: 35px;
  }

  .choose-your-role-bg-star svg {
    width: 36px;
  }

  .choose-your-role-bg-star {
    position: absolute;
    top: 24px;
    left: 0px;
  }

  .choose-your-role-doted4-image svg {
    width: 52px;
  }

  .choose-your-role-doted3-image {
    position: absolute;
    top: 39%;
    left: -110px;
  }

  .choose-your-role-doted4-image {
    position: absolute;
    top: 956px;
    right: -21px;
  }

  .transforming-learning-experiences-left-section h2 {
    font-size: 28px;
    max-width: 391px;
    line-height: 33px;
  }

  .orange-line-image2 {
    position: absolute;
    top: 101px;
    left: 103px;
    width: 187px;
  }

  .transforming-learning-experiences-list-folder p {
    margin: 0px 0px 0px 0px;
    font-size: 15px;
  }

  .transforming-learning-experiences-list-icon svg {
    width: 32px;
  }

  .transforming-learning-experiences-left-section {
    width: 100%;
    padding: 29px 29px;
  }

  .transforming-learning-experiences-right-section {
    width: 100%;
    position: relative;
  }

  .transforming-learning-experiences-wrapper {
    display: flex;
    flex-direction: column;
    gap: 66px;
  }

  /* .footer-wraper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 31px 21px;
    gap: 23px;
  } */

  .footer-link-folder p {
    font-size: 20px;
  }

  .course-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    position: initial !important;
  }

  .footer-Join-our-newsletter-folder p {
    font-size: 15px;
  }

  .footer-Join-our-newsletter-search-folder input {
    width: 61%;
  }

  .footer-Join-our-newsletter-search-folder {
    display: flex;
    gap: 12px;
    width: 100%;
  }

  .footer-get-in-touch-folder span {
    font-size: 16px;
  }

  .footer-social-link-folder a {
    font-size: 22px;
  }

  .footer-Join-our-newsletter-search-folder button {
    width: 35%;
    padding: 7px 18px;
  }

  .indi_cls_main {
    position: absolute;
    width: 70px;
    right: 170px;
    bottom: 60px;
    /* z-index: 2; */
    z-index: 1;
  }

  .transforming-learning-experiences-circle-bg-icon {
    position: absolute;
    top: -110px;
    right: -11px;
  }

  .logo img {
    width: 75px;
  }

  .dashboardHeader {
    gap: 8px !important;
    padding: 8px 11px 0px 15px !important;
  }

  .dashboardHeaderProfile svg {
    width: 20px;
  }

  .search-container {
    width: 200px !important;
  }

  .search-icon svg {
    width: 16px !important;
  }

  .search-input {
    padding: 7px 7px 7px 45px !important;
    padding-left: 49px;
    font-size: 11px !important;
  }

  .dashboardHeaderProfile {
    display: flex;
    /* gap: 11px !important; */
    gap: 8px !important;
  }

  .notifications-all-container-wrapper {
    height: 399px !important;
    overflow-y: scroll;
  }

  .notifications-wrapper {
    position: fixed;
    top: 70px;
    left: 0px;
    right: 0px;
    width: 331px;
    margin: 0px auto;
    text-align: left;
    padding: 18px;
    background-color: white;
    box-shadow: 0px 1px 9px #e3e3e3;
    border-radius: 10px;
  }

  .notifications-title p {
    font-size: 12px;
    color: black;
  }

  .notifications-inner-folder p {
    color: black;
    font-size: 9px !important;
  }

  .notification-time-folder {
    font-size: 9px;
    display: none;
  }

  .dashboardHeaderProfileNameInitial,
  .dashboardHeaderProfileText,
  .dashboardHeaderProfileNameInitial {
    display: none;
  }

  .dashboard-header-search {
    width: 200px !important;
  }

  .dashboardSearch {
    display: flex;
    gap: 10px !important;
  }

  .dashboardBody {
    display: flex;
    flex-direction: column;
  }

  .dashboardSidePannel {
    width: 100% !important;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  #responsive-header .header_menu-container {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background: #dce8ff;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 20px 0px;
    align-items: center;
    justify-content: center;
  }

  #responsive-header .header_menu {
    position: relative;
    background: transparent;
    width: 100%;
    padding: 0px;
    border-radius: 80px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  #responsive-header .header_menu ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    position: relative;
    flex-direction: column;
  }

  #responsive-header .header_menu ul li a.active {
    font-size: 19px;
    position: relative;
  }

  #responsive-header.header_menu ul li a {
    font-size: 18px;
  }

  #responsive-header .header_login .login_btn {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    font-weight: 400;
    border-radius: 50px;
    /* border: 1.5px solid #000; */
    padding: 9px 35px;
    color: #002469;
    background-color: transparent;
    box-shadow: none;
  }

  #responsive-header .header_login .sign_btn {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    border-radius: 50px;
    padding: 9px 35px;
  }

  .header_menu,
  .header_login,
  #responsive-header .header_logo {
    display: none;
  }

  #responsive-header .header_login {
    display: block;
  }

  #responsive-main-header {
    padding: 0px;
  }

  /* .nevbar-toggle-btn {
    position: absolute;
    top: 31%;
    right: 4%;
  } */
  .nevbar-toggle-btn {
    /* position: absolute;
    top: 76%;
    right: 4%; */
  }
  .header-mob-folder {
    width: 100%;
  }
  .nevbar-toggle-btn a {
    font-size: 22px;
  }

  .navbar-toggle-btn {
    position: absolute;
    /* top: -52px; */
    /* top: -185px; */
    top: 20px;
    right: 15px;
    z-index: 4;
    border: 1px solid;
    width: 29px;
    height: 28px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 100%;
    background-color: white;
    border: none;
  }
  .Marketplace-btn {
    margin: 0px 0px 0px 0px !important;
  }
  .Marketplace-btn button{
    width: 77% !important;
    text-align: left;
  }

  .navbar-toggle-btn a {
    color: black;
    font-size: 12px;
  }

  .AI-disclamer {
    font-size: 10px;
  }

  .pdf_box_sec {
    position: absolute;
    bottom: 46px !important;
    left: 0px;
    padding: 0px 15px 0px 15px !important;
  }

  .promote_part_sec textarea {
    width: 81% !important;
    padding: 10px 0px 10px 0px !important;
    font-size: 12px !important;
    padding-left: 0px !important;
    height: 22px !important;
  }
  /* .chat-with-ai-lession-AI-message .chat-with-ai-lession-user-sned-massege-container p {
    margin: 0px 0px 18px 0px !important;
  }  */

  .go_cls_btn {
    position: absolute;
    bottom: 12px !important;
    /* right: 1px !important; */
    right: -5px !important;
    display: flex;
    align-items: center;
  }

  #studentDashboard-universal-chatbot-send-btn {
    position: absolute;
    top: 50%;
    right: 6% !important;
  }

  /* .add-lesson-btn button {
    padding: 6px 16px !important;
    font-size: 13px;
    position: absolute;
    top: -2px;
    right: 0px;
    font-size: 10px !important;
  } */
  .add-lesson-btn button {
    padding: 6px 16px !important;
    font-size: 13px;
    position: absolute;
    top: 45px !important;
    right: 0px;
    font-size: 10px !important;
    width: 100%;
    justify-content: center;
  }
  .Back_button{
    margin-bottom: 0px !important;
    font-size: 14px !important;
  }
  .Back_button i{
    font-size: 13px;
  }

  /* .view-lessons-btn-container {
    margin-top: 50px;
    margin-bottom: 50px;
  } */
  /* .tab-content {
    margin-top: 50px;
  } */
  .lesson_generator {
    margin-bottom: 20px;
  }

  .editor-container {
    margin-bottom: 15px;
  }

  .view-more {
    font-size: 12px;
  }

  .LessonAnalytics-profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 17px;
  }

  .LessonAnalytics-profile-image img {
    width: 74px !important;
    height: 74px !important;
  }

  .LessonAnalytics-profile {
    width: 100%;
  }

  .settings_main_sec {
    padding: 0px 15px !important;
  }

  /* .msg_sec_new_body_inner_left {
    height: 100%;
  } */
  .Create-Courses-section {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    padding: 27px 0px;
    z-index: 113;
    overflow-y: scroll;
    margin-bottom: 10px;
  }

  .my-course-title {
    font-size: 20px;
  }

  .crop_btn_sec a {
    font-size: 15px;
  }

  .crop_btn_sec a span {
    padding-left: 6px;
  }

  .Student-Active-Lesson-title-folder {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .css-1rrfrxi::before {
    border: none;
  }

  /* .teacehr-tab-setion {
    margin: 0px 0px 55px 0px;
  } */
  #AddProfilepopup .dashboard-popup-teacher-profile-details-form {
    overflow-y: auto;
    height: auto;
  }
  .studentDashboar-popup-container .dashboard-popup-teacher-profile-details-folder .dashboard-popup-teacher-profile-details-form .Personalize-your-AI-Assistant-uplode-image .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    bottom: 8px !important;
    right: 126px !important;
  }

  #Customize-your-AI-dropdown .Customize-your-AI-assistant-tone-dropdown-inner-folder .Customize-your-AI-assistant-tone-dropdown-select-option-container .Customize-your-AI-assistant-tone-dropdown-select-option {
    width: 81px;
  }

  /* .save_btn_set {
    padding: 7px 23px !important;
    font-size: 9px !important;
  } */

  .edit-course-details-container {
    margin: 15px 0px 0px 0px;
  }

  .assign_main_box_left {
    margin-top: 16px;
  }

  .assign_main_box {
    display: grid;
    grid-gap: 15px;
  }

  .lesson_generator_body_sec_right h6,
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 12px;
  }

  .Create-Course-btn-wrapper {
    display: none !important;
  }

  #Create-Course-cancle-btn-wrapper {
    /* display: block !important; */
    display: flex !important;
  }

  #Create-Course-cancle-btn-wrapper {
    /* display: block !important; */
    margin: 32px 0px 0px 0px;
  }

  /* .view-course-Details-back-btn-folder h2 {
    width: 110px !important;
  } */
  .step-container {
    display: flex;
    width: 80%;
    gap: 10px;
  }

  .dashboardHeaderProfile div span {
    height: 18px !important;
    width: 18px !important;
    font-size: 10px !important;
  }

  .LessonViewProgress-tab-filter-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .LessonViewProgress-header-section {
    display: flex;
    gap: 0px !important;
  }

  .Students-courses-details-folder {
    display: flex;
    flex-direction: column !important;
    gap: 6px !important;
  }

  .Student-Active-Lesson-container {
    margin-bottom: 20px;
  }

  .svg-inactive svg,
  .svg-active svg {
    width: 25px;
  }

  .custom-tooltip {
    position: absolute;
    top: -45px !important;
    left: -104px !important;
    width: 262px !important;
    font-size: 11px !important;
  }

  .preview-assignments-inner-folder ol li {
    font-size: 12px;
    width: auto;
  }

  .input-section input {
    font-size: 12px;
  }

  .TeacherProfile-btn-container {
    margin: 0px auto;
  }

  .Teacher-student-edit-profile-right-section {
    width: 100% !important;
  }

  .Teacher-student-edit-profile-right-section h2, 
  .TeacherProfile-Language-folder label {
    /* font-size: 16px; */
    font-size: 14px;
  }
  .AssignmentMCQs-mock-test-answer-folder textarea {
    font-size: 11px;
  }

  .Teacher-student-edit-profile-wrapper {
    margin: 15px 0px 0px 0px;
    padding: 0px 11px;
  }

  .LessonViewProgress-table-container {
    margin: 23px 0px 0px 0px !important;
  }

  .sort-popup-assignprocess {
    padding: 14px !important;
    width: 173px !important;
  }

  .sort-popup-assignprocess h3,
  .sort-popup-assignprocess label {
    font-size: 13px !important;
  }

  .sort-popup-assignprocess select {
    padding: 7px 15px !important;
    font-size: 12px !important;
  }

  .apply-button-assignprocess {
    margin-top: 13px !important;
    padding: 7px 0 !important;
    font-size: 11px !important;
  }

  .LessonAnalytics-profile-folder {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .LessonAnalytics-profile-folder h2 {
    margin: 0px 0px 0px 0px !important;
  }

  #LessonAnalytics-section .LessonAnalytics-wrapper p {
    margin: 0px 0px 0px 0px;
  }

  .AssignmentViewProgress-mock-test-status-folder p {
    font-size: 10px !important;
  }

  .AssignmentViewProgress-mock-test-container {
    max-width: 344px !important;
    padding: 19px;
    /* position: initial;
    top: 0%;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(0px) !important;
    max-width: 837px; */
  }

  .AssignmentViewProgress-mock-test-close-btn {
    position: absolute;
    top: -13px;
    right: -11px;
    padding: 4px 5px;
    font-size: 10px;
  }

  .AssignmentViewProgress-mock-test-status-correction {
    display: flex;
    gap: 7px;
    padding: 2px 11px !important;
  }

  .AssignmentViewProgress-mock-test-status-correction.Incorrect {
    font-size: 11px;
  }

  .AssignmentViewProgress-mock-test-status-correction.Partial.Credit{
    font-size: 11px;
  }
  span.AssingmentMCQs-mock-test-answer-right-arrow-btn {
    margin-left: 5px;
  }

  .Analytics-profile-container {
    margin: 20px 0px;
  }

  .pagination {
    font-size: 12px;
  }

  .LessonAnalytics-profile-massege-image img {
    width: 20px !important;
    height: 20px !important;
  }

  /* .view-course-Details-back-btn-folder h2 {
    width: 293px !important;
  } */
  .student-score {
    font-size: 13px !important;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 25px !important;
  }

  .AssignmentViewProgress-mock-test-status-folder {
    display: flex;
    gap: 2px;
  }

  .view-course-Details-back-btn-folder h2 {
    width: 189px !important;
  }

  .dashboard-popup-teacher-profile-details-image-underage {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .dashboard-popup-teacher-profile-details-image-underage .profile-image-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
  }

  .dashboard-popup-teacher-profile-details-image-underage .profile-image-section2 {
    width: 100%;
  }

  .list-dropdown-icon {
    position: absolute !important;
    top: 27.9px !important;
    right: 241px !important;
  }

  .add_subject_feild_btn {
    width: 28% !important;
    font-size: 10px !important;
    height: 31px !important;
  }

  .Subjects-you-teach input {
    padding: 8px 20px;
    font-size: 11px;
  }

  .Course-created-successfully-container {
    max-width: 338px;
    padding: 34px 28px;
  }

  .Course-created-successfully-folder h2 {
    font-size: 18px;
    margin: 0px 0px 15px 0px;
  }

  .Course-close-btn button {
    /* padding: 6px 35px; */
    font-size: 12px;
  }

  .create-all-btns-container button,
  .Subjects-you-teach-feilds p {
    font-size: 11px !important;
  }

  .invite-copy-link {
    margin-bottom: 10px;
    font-size: 13px;
  }

  .teacher-create-popup-gender-input,
  .student_grade_select {
    background-position: right 3px center !important;
    background-size: 9px !important;
  }

  .Subjects-you-teach-feilds {
    display: flex;
    align-items: baseline;
    gap: 1px;
  }

  .created-my-courses-wrapper {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .created-my-courses-container {
    gap: 24px;
    margin-bottom: 20px;
  }

  .subject_special h5 {
    margin-bottom: 0px !important;
  }

  .aboutUs-conatiner {
    display: flex;
    flex-direction: column;
    gap: 33px;
    margin: 0px 0px 30px 0px !important;
  }

  .aboutUs-left-section-image {
    width: 158px !important;
  }

  .aboutUs-left-section h2 {
    font-size: 18px !important;
    margin: 18px 0px 12px 0px !important;
    line-height: 25px !important;
  }

  .aboutUs-left-section p {
    font-size: 13px !important;
    line-height: 19px !important;
  }

  .aboutUs-left-section,
  .aboutUs-right-section,
  .aboutUs-for-teacher-right-section,
  .aboutUs-for-teacher-left-section,
  .aboutUs-for-student-left-section,
  .aboutUs-for-parent-left-section {
    width: 100% !important;
  }

  .aboutUs-logo-image-folder {
    width: 272px !important;
    margin-left: -60px;
  }

  .aboutUs-aboutus-teacher-logo-image {
    width: 187px !important;
  }

  .aboutUs-for-teacher-conatiner,
  .aboutUs-for-student-conatiner,
  .aboutUs-for-parent-conatiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px 33px 0px !important;
    /* gap: 67px !important; */
    gap: 20px !important;
  }

  .aboutUs-for-teacher-book-image img {
    width: 66px !important;
  }

  .aboutUs-for-teacher-book-image {
    position: absolute;
    top: -24% !important;
    right: 0%;
    display: none;
  }

  .aboutUs-for-student-book-image img{
    display: none;
  }
  .aboutUs-for-teacher-list-list-folder,
  .aboutUs-for-student-list-list-folder,
  .aboutUs-for-parent-list-list-folder {
    display: flex;
    /* gap: 0px !important; */
    gap: 8px !important;
  }

  .aboutUs-for-teacher-list-container,
  .aboutUs-for-student-list-container,
  .aboutUs-for-parent-list-container {
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
  }

  .aboutUs-for-teacher-list-list-folder p,
  .aboutUs-for-student-list-list-folder p,
  .aboutUs-for-parent-list-list-folder p {
    font-size: 12px !important;
  }

  .aboutUs-our-vision-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    justify-content: center;
    gap: 56px;
    padding: 0px 12px;
  }

  .aboutUs-our-vision-folder {
    padding: 32px 25px !important;
    width: 100% !important;
    border-radius: 20px !important;
  }

  .aboutUs-our-vision-folder h2 {
    font-size: 26px !important;
    margin: 0px 0px 10px 0px !important;
  }

  .aboutUs-our-vision-folder p {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .aboutus_our_Vision_image {
    position: absolute;
    top: -12% !important;
    right: -3% !important;
  }

  .aboutus_our_Vision_image img {
    width: 98px;
  }

  .aboutus_our_Vision_image2 {
    position: absolute;
    top: -17% !important;
    left: -9px !important;
  }

  .aboutus_our_Vision_image2 img {
    width: 123px;
    margin-left: 90px !important;
  }

  .aboutus_Educational-Revolution_image-bg {
    position: absolute;
    top: 17% !important;
    left: 0px;
  }

  .aboutus_Educational-Revolution_image-bg2 {
    position: absolute;
    top: 17% !important;
    right: 0px !important;
  }

  .aboutus_Educational-Revolution_image-bg2 img {
    width: 104px !important;
  }

  .Educational-Revolution-conatiner h2 {
    font-size: 26px !important;
  }

  .Educational-Revolution-inner-conatiner p {
    font-size: 13px !important;
  }

  .Educational-Revolution-conatiner {
    margin: 33px 0px;
  }

  .student-Active-Lessons {
    text-align: left;
    font-size: 20px;
    margin: 10px 0px 12px 0px;
  }

  .active_assignment_folder {
    display: flex;
    flex-direction: column;
  }

  .Student-Active-Lesson-des,
  .Student-Active-Assignment-des {
    text-align: center;
  }

  .forget_modal_sec_new_body_sec {
    font-size: 12px;
  }

  .overview-lesson-read-left-section {
    position: inherit;
    top: 13%;
  }

  .Student-Active-Lesson-title-folder h2 {
    text-align: center;
  }

  .button_tab_edit_btn p,
  .button_tab_edit_btn span {
    font-size: 11px !important;
  }

  .notificationPage-folder .notifications-folder {
    padding: 10px !important;
  }

  /* .Teacher-student-edit-profile-left-section {
    width: 24% !important;
  } */
  .Teacher-student-edit-profile-left-section {
    width: 100% !important;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
  }
  .about_teacher_img {
    margin: 0px auto;
  }
  .studentDashboard-chatbot-container-close-image {
    position: absolute;
    top: 5%;
    right: 6%;
  }

  .search_header-container {
    margin: 15px 0px 20px 0px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-end;
    align-items: flex-end !important;
    gap: 8px !important;
  }

  .search-list {
    width: 100% !important;
  }

  .Overview-Lesson-date-container {
    display: flex;
    gap: 11px;
  }

  #Lesson_Container .view-course-details-container .view-Students-course-details-folder .view-Students-course-details-counts p {
    justify-content: center;
    padding: 3px 0px;
  }

  .studentDashboardSidePannel .dashboardlink.active {
    padding: 10px 21px !important;
    width: 100%;
    font-size: 14px !important;
  }

  .studentDashboardSidePannel .dashboardlink {
    font-size: 14px !important;
  }

  .logo span {
    display: none;
  }

  .studentDashboar-search-filter {
    width: 100% !important;
  }

  .created-my-courses-title-folder {
    padding: 0px 0px 0px 0px;
  }

  .dashboard-popup-teacher-profile-details-form span {
    font-size: 10px !important;
  }

  /* admin screen start */
  .AdminDashboard-summary-tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .studentDashboar-actives-course-count {
    padding: 6px 0px;
  }

  .Dashboard-List-Recently-Added-table {
    min-width: 100%;
  }

  .Dashboard-List-Recently-Added-table tbody tr td {
    display: block;
  }

  .Dashboard-List-Recently-Added-table thead {
    display: none;
  }

  .Dashboard-List-Recently-Added-table tbody tr {
    margin-bottom: 10px;
  }
  .userprofile {
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
  }

  .Dashboard-List-Recently-Added-table tbody tr td {
    position: relative;
    text-align: left;
  }

  .Dashboard-List-Recently-Added-table tbody tr td:before {
    position: absolute;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-th) ": ";
    font-weight: bold;
    display: contents;
  }

  .course-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .header-filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-end;
  }

  .header_inner_wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  #grade,
  #month {
    padding: 3px 33px 3px 3px !important;
    font-size: 11px !important;
  }

  .add-profile-btn-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100% !important;
  }

  .add-profile-save-btn,
  .add-profile-save-btn {
    width: 50% !important;
    padding: 8px 0px !important;
  }

  .Add-school-left-inner-input {
    width: 100%;
  }

  .Add-school-inner-input,
  .Add-school-inner-des,
  .Add-school-inner-input-feilds-folder .Add-school-inner-input {
    min-width: 100% !important;
  }

  .Add-school-wrapper {
    display: flex;
    flex-direction: column;
  }

  .Add-school-wrapper .Create-Course-inner-input-container {
    min-height: 100% !important;
  }

  .Add-school-right-section {
    min-width: 100% !important;
  }

  .Add-school-wrapper .Create-Course-inner-input-container {
    width: 100% !important;
  }

  #uplode_image_btn {
    position: absolute;
    top: 100px !important;
    left: 0%;
    right: 0%;
  }

  #uplode_image_btn .course-main-image {
    width: 100%;
    height: 237px !important;
  }

  .superadmin-login-form {
    padding: 19px;
  }

  .Student_Review_Table_Data_section {
    overflow-x: auto;
    /* Enables horizontal scrolling */
    margin-bottom: 20px;
  }

  #Student_Review_Table_Data {
    width: 100%;
    /* Make the table width responsive */
    width: 826px;
    /* Optional: if you want to limit the maximum width */
  }

  .logo span {
    display: none;
  }

  #Student_Review_Table_Data thead tr th,
  #Student_Review_Table_Data tbody tr td {
    font-size: 12px !important;
  }

  .admin-dashboard-graph-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 17px 0px !important;
  }

  .admin-dashboard-graph-left-section,
  .admin-dashboard-graph-right-section {
    width: 100% !important;
  }

  .admin-dashboard-graph-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 10px;
  }

  .admin-dashboard-graph-dropdown {
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .admin-bar {
    max-height: 202px !important;
    width: 100% !important;
  }

  .Dashboard-Review-Card-foler {
    width: 100% !important;
  }

  .Custom_Tabs_section {
    margin: 0px auto;
    width: 100% !important;
  }

  .course-inner-info-wrapper {
    display: flex;
    flex-direction: column;
    position: initial !important;
  }

  /* admin screen end */
  .invited-student-list-table-wrapper .student-request-wrapper {
    width: 100% !important;
    margin: 0px 0px 0px 0px;
  }
  .Create-Course-image-disclamer span {
    width: 100% !important;
  }
  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
    width: 100% !important;
  }
  .uplode-icon-generator {
    width: 19px !important;
    height: 18px !important;
    top: 1px !important;
    left: -5px !important;
    position: relative;
  }
  .pdf_box_sec_inner {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    /* grid-gap: 15px !important; */
    grid-gap: 8px !important;
    /* margin: 10px 0px 0px 0px; */
  }
  /* .pdf_box_sec_inner_single {
    gap: 6px !important;
    padding: 4px 7px;
    margin-top: 13px !important;
  } */
  /* .topratingreview-wrapper {
    margin: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
  }
  .topratingreview-left-section {
    width: 100% !important;
    position: inherit !important;
  }
  .topratingreview-right-section {
    width: 100% !important;
  }
  .topratingreview_btn-container {
    gap: 10px !important;
  }
  .topratingreview-reply-btn {
    font-size: 11px !important;
    padding: 6px 12px !important;
  }
  .topratingreview-card-des-folder p {
    font-size: 12px !important;
  }
  .topratingreview-card-massege-wrapper {
    display: flex;
    align-items: flex-start !important;
  }
  .topratingreview-card-massege-wrapper textarea {
    height: 46px !important;
    font-size: 10px !important;
  }
  .send-message-icon {
    position: absolute;
    top: 11px !important;
    right: 5px !important;
  }
  .send-message-icon img{
    width: 19px;
  }
  .topratingreview-card-massege-edit-folder button {
    font-size: 11px;
  }
  .topratingreview-card-date-folder span {
    font-size: 9px;
  } */
  .form_sec_settings .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input .grade-icon{
    position: absolute;
    /* top: 60px; */
    top: 43px;
  }
  .profile_plus_icon {
    position: absolute;
    /* right: 94px !important; */
    right: 10px !important;
  }
  .add-school-btn-folder {
    display: block !important;
    margin: 0px 0px 0px 0px !important;
  }
  .add-school-btn{
    width: 100% !important;
  }
  /* .AdminDashboard-summary-tabs .studentDashboar-actives-folder:nth-child(2) .studentDashboar-actives-image img{
    width: 44px;
  } */
  .delete-course-alert-image img {
    width: 50px;
  }
  .react-responsive-modal-modal .dashboard-popup-teacher-profile-details-form {
    overflow-y: auto;
    height: auto;
  }
  .promote_part_sec::after {
    position: absolute;
    top: 9px !important;
    left: 15px !important;
    width: 1px;
    height: 32px !important;
  }
  .AssignmentViewProgress-filter-popup-wrapper .sort-popup-assignprocess ,
  .lessonViewProgress-filter-popup-wrapper .sort-popup-assignprocess {
    top: 10px !important;
  }
  .view_analytics_btn .MuiButtonBase-root{
    font-size: 12px;
  }
  #CourseList-section .CourseList-wrapper .created-my-courses-wrapper{
    margin-top: 15px;
  }
  .button_tab_wrapper {
    display: flex;
    flex-direction: column;
  }
  .button_tab_wrapper .button_tab_edit_btn p, 
  .button_tab_wrapper .Student-Active-start-Lesson{
    width: 100%;
  }
  .assignment-list-section .view-course-Details-back-btn-folder{
    margin: 0px 0px 15px 0px;
  }
  .button_tab_wrapper .Student-Active-start-Lesson{
    padding: 8px 12px;
  }
  .assignment-list-section .Student-Active-Lesson-folder .Student-Active-Lesson-title-folder, 
  .lesson-lsit-section .Student-Active-Lesson-folder .Student-Active-Lesson-title-folder {
    width: 100% !important;
  }
  .introduce-yourself-text {
    /* height: 301px; */
    height: 174px !important;
    font-size: 10px !important;
  }
  /* .Personalize-your-AI-Assistant-uplode-image {
    width: 310px;
    margin: 0px auto;
  }  */
  .Personalize-your-AI-Assistant-uplode-image {
    /* width: auto;
    margin: 0px auto; */
    width: 95px;
    margin: 0px auto;
  }
  .Custom_Tabs_section a {
    display: flex;
    justify-content: center;
  }
  .search_header-container .search-container{
    margin-right: auto;
  }
  .Add-school-wrapper .Create-Course-inner-input-container , 
  .Add-school-inner-input-feilds-folder, 
  .Add-school-inner-des {
    gap: 10px !important;
  }
  .admin-setting{
    margin-bottom: 20px;
  }
  #add-admim-popup{
    height: 140px;
  }
  #add-admim-popup .add-profile-save-btn {
    font-size: 11px;
    margin: 10px 0px 0px 0px;
  }
  .BulkUplode-button-folder p {
    font-size: 11px !important;
  }
  .footer-wraper {
    display: flex;
    flex-direction: column;
    gap: 23px;
    justify-content: space-between;
    padding: 31px 21px;
  }
  .crop_btn_sec a span {
    padding-left: 8px;
    font-size: 15px;
  }
  .react-responsive-modal-modal{
    width: 66% !important;
  }
  .tab-content {
    margin: 51px 0px 0px 0px;
  }
  .AdminDashboard-summary-tabs .studentDashboar-actives-folder {
    padding: 10px 0px;
  }
  .AdminDashboard-summary-tabs .studentDashboar-actives-folder .studentDashboar-actives-image img {
    width: 54px;
  }
  #add-admim-popup .tab-content{
    margin: 0px;
  }
  .student-sortby-popup-wrapper ,.filter-wrapper {
    position: relative;
  }
  .sort-popup-assignprocess {
    top: 0px !important;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    top: 20px !important;
    right: 0px !important;
  }
  #AddProfilepopup .tab-content, 
  .Dashboard-List-Table-wrapper .tab-content, 
  #OverviewLesson-section .tab-content {
    margin: 0px;
  }
  #AddProfilepopup .view-lessons-btn-container {
    margin-top: 0px !important;
  }
  .invite-student-feilds-folder {
    padding: 6px 17px;
  }
  .Teacher-student-edit-profile-back-btn {
    border-bottom: 0px;
    padding-bottom: 9px;
  }
  .AssignmentViewProgress-mock-test-answer-folder {
    padding: 14px 12px;
  }
  .marketplace-shopping-cart-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin: 16px 0px 0px 0px;
  }
  .marketplace-shopping-cart-right-section {
    width: 100%;
  }
  .marketplace-shopping-cart-left-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .marketplace-shopping-cart-left-section .course-bg-image{
    width: 100%;
  }
  .marketplace-price p {
    font-size: 13px;
  }
  .marketplace-price h2 {
    font-size: 17px;
  }
  .marketplace-price-header {
    gap: 10px;
    padding-bottom: 13px;
    margin-bottom: 13px;
  }
  .cupen-Applied-btn {
    padding: 10px 7px;
    margin: 10px 0px 10px 0px;
  }
  .cupen-Applied-btn span {
    font-size: 11px;
  }
  .cupen-Applied-btn button {
    font-size: 10px;
    padding: 5px 20px;
  }
  .price-checkout-btn {
    font-size: 12px;
    padding: 10px 0px;
  }
  .course-name{
    font-size: 13px;
  }
  .benefits-boundaries-half-circle-bg-image2, 
  .benefits-boundaries-half-circle-bg-image1, 
  .benefits-boundaries-half-circle-bg-image3, 
  .benefits-boundaries-half-circle-bg-image4, 
  .half-circle-bg-image {
    display: none;
  }
  .add-cart {
    font-size: 17px !important;
  }
  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
    overflow-y: visible;
  }
  .profile_linkedin a {
    font-size: 10px !important;
  }
  .Terms-and-Conditions-image-title, 
  .Privacy_Policy_title {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .book-logo3 {
    position: absolute;
    top: 222px;
    right: -19px !important;
  }
  .Privacy_Policy_title img{
    width: 240px;
  }
  .book-logo3 img, 
  .half-circle-bg-image2 img{
    width: 40px;
  }
  .half-circle-bg-image2 {
    position: absolute;
    top: 166px;
    left: -21px !important;
  }
  .Terms-and-Cond.itions-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Terms-and-Conditions-list-foldre h2 {
    font-size: 18px !important;
  }
  .settings_main_sec .account_setting_tab_sec .dashboard-popup-teacher-profile-details-form {
    overflow-y: hidden;
    height: auto;
  }
  .Cancel-Popup {
    width: 86% !important;
  }
  .Personalize-your-AI-Assistant-right-section h2 {
    font-size: 16px;
  }
  .Custom_Tabs_section a.active, 
  .Custom_Tabs_section a {
    font-size: 14px !important;
  }
  .enrolled-courses {
    margin: 24px 0px 0px 0px;
  }
  .lesson-stats-student-name, 
  .lesson-stats-course-name{
    padding-bottom: 10px;
    font-size: 13px;
  }
  .Create-Course-disscount-wrapper {
    display: flex;
    flex-direction: column;
  }
  .superadmin-login-container {
    width: 319px;
  }
  .superadmin-login-section h2 {
    font-size: 30px;
  }
  .add-cart span {
    position: absolute;
    top: -5px !important;
    height: 18px !important;
    width: 18px !important;
    font-size: 8px !important;
  }
  .Overview-Lesson-ai-voice-animation-folder {
    display: flex;
    gap: 11px !important;
  }
  .Congratulations-Popup-folder {
    max-width: 330px;
    padding: 28px 18px;
  }
  .check-image{
    width: 70px;
    height: 70px;
    margin: 0px auto;
  }
  .Congratulations-Popup-folder h2 {
    font-size: 20px;
  }
  .profile_linkedin input {
    font-size: 14px !important;
    font-size: 10px !important;
    width: 261px !important;
  }
  .NotificationPage-wrapper {
    margin: 0px 0px 20px 0px;
  }
  .right_chat_sec-profile i {
    display: block !important;
  }
  .Customize-your-AI-assistant-tone-dropdown-select-option label {
    font-size: 10px !important;
  }
  .Customize-your-AI-assistant-tone-dropdown-select-option-container {
    gap: 8px;
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
  .Customize-your-AI-assistant-tone-dropdown-select-option {
    display: flex;
    justify-content: flex-start !important;
  }
  .Customize-your-welcome-message .Customize-your-AI-assistant-tone-dropdown-select-option-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
  .Customize-your-AI-assistant-tone-dropdown-inner-folder {
    position: absolute;
    top: 72%;
    left: 0%;
  }
  .chat-with-ai-lession-user-sned-massege-container .css-25t525-MuiListItem-root{
    height: auto !important;
  }
  .dashboard-popup-container {
    background-color: #00000042 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-bottom: 10px !important;
    overflow-y: scroll !important;
    padding: 27px 0 !important;
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 113 !important;
  }
  .dashboard-popup-form-wrapper.aos-init.aos-animate {
    height: auto !important;
    margin: auto !important;
  }
  .dashboard-popup-teacher-profile-details-form {
    overflow-y: visible !important;
    height: auto !important;
    margin: auto !important;
    margin-top: 3px !important;
  }
  .dashboard-popup-teacher-profile-details-input label {
    font-size: 12px;
  }
  .Personalize-your-AI-Assistant-right-section h2 {
    margin: 0px 0px 12px 0px;
  }
  .Personalize-your-AI-Assistant-input-folder {
    display: flex;
    /* gap: 10px !important; */
    gap: 7px !important;
  }
  .form-icon {
    position: absolute;
    top: 36px;
  }
  .location-icon {
    position: absolute;
    top: 36px !important;
  }
  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form-container {
    gap: 6px;
  }
  #StudentActiveLesson-section .Student-Active-Lesson-title-folder {
    width: 46%;
  }
  #StudentActiveLesson-section .lesson-dropdown-tab h2 {
    text-align: left;
    width: 75px;
  }
  .Congratulations-assignment-btns .View-Course {
    /* width: 47%;
    padding: 7px 31px; */
    width: 22%;
    padding: 7px 3px;
  }
  .Congratulations-assignment-btns .view-result {
    /* padding: 7px 31px; */
    padding: 7px 13px;
  }
  .AssingmentMCQs-Congratulations-popup-image {
    margin: 0px auto 17px auto;
    width: 113px;
  } 
  .AssignmentViewProgress-mock-test-marks-obtained {
    display: flex;
    gap: 5px !important;
  }
  .AssignmentViewProgress-mock-test-status-duration-folder p {
    font-size: 12px !important;
    color: #807f7f !important;
  }
  .AssignmentViewProgress-mock-test-status-duration-folder span{
    font-size: 12px;
  }
  .AssingmentMCQs-mock-test-answer-container {
    display: flex;
    gap: 9px;
  }
  .AssingmentMCQs-answer-title {
    font-size: 12px !important;
  }
  .AssingmentMCQs-mock-test-Ai-feedback-answer {
    height: 60px !important;
    margin-bottom: 13px;
  }
  .AssignmentViewProgress-mock-test-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    padding: 27px 0px;
    z-index: 113;
    overflow-y: scroll;
    margin-bottom: 10px;
  }
  .AssingmentMCQs-answer .fa-check, 
  .AssingmentMCQs-answer .fa-x {
    font-size: 11px !important;
  }
  .AssingmentMCQs-mock-test-Ai-feedback-answer p {
    font-size: 10px !important;
    line-height: 15px;
  }
  .delete-course-form-container h2 {
    font-size: 18px !important;
    margin: 12px 0px 16px 0px !important;
  }
  .delete-course-form-btn-folder button {
    font-size: 13px !important;
  }
  .AssingmentMCQs-question {
    text-align: center;
    display: flex;
    justify-content: flex-start;
    width: 12% !important;
  }
  .View-Course, .practice-assignment-btn, .view-result {
    font-size: 9px;
    padding: 7px 9px;
  }
  .course-toggle-uplode-folder {
    width: 100%;
  }
  .View-Progress-btn {
    font-size: 11px !important;
  }
  .empower_sec_new_inner .header-book-demo{
    width: 174px;
    margin: 0px auto;
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .view-course-Details-back-btn-folder h2 {
    font-size: 17px;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 35%;
  }

  .view-course-details-right-section {
    width: 75%;
  }

  .view-course-details-image {
    width: 100%;
    height: 198px !important;
  }

  .view-course-details-right-section h2,
  .created-my-courses-title-wrapper h2 {
    font-size: 20px;
    /* width: 224px !important; */
    width: auto !important;
  }

  .view-course-details-Create-lesson-container {
    padding: 29px 0px;
  }

  .teacherviewcoursedetails-des,
  .Students-des {
    font-size: 12px !important;
    margin: 9px 0px 5px 0px !important;
    line-height: 20px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .view-course-details-right-section p {
    font-size: 11px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    /* font-size: 10px !important; */
    font-size: 8px !important;
  }

  .view-Students-course-details-counts {
    margin: 4px 0px 0px 0px;
    padding: 1px 0px;
  }

  .view-Students-course-details-counts p {
    font-size: 10px;
  }

  .view-course-details-edit-and-share-folder {
    padding: 5px 14px;
  }

  .view-course-details-edit-and-share-folder span {
    font-size: 12px;
  }

  .viewcourse-share-btn p {
    margin: 0px;
    font-size: 11px;
  }

  .view-lessons-btn-container button {
    font-size: 12px;
  }

  .Create-Assignment-btn,
  .Preview-lesson-btn,
  .View-Progress-btn,
  .preview-assignments-inner-folder button {
    padding: 5px 18px;
    font-size: 11px;
  }

  .view-lessons-created-course-inner-preview-btn-folder {
    display: flex;
    gap: 20px;
  }

  .view-lessons-created-course-wrapper p {
    font-size: 11px;
    /* width: 143px !important; */
  }

  .Create-Assignment-btn,
  .Preview-lesson-btn {
    padding: 5px 8px !important;
  }

  .assignments-sec-container .Overview-Lesson-date span {
    font-size: 11px;
  }

  .editor-paragraph span {
    font-size: 12px;
  }

  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th {
    font-size: 12px;
    padding: 0px 0px 18px 0px;
    font-weight: 500;
  }

  .list-of-students-wrappper table tbody tr td {
    font-size: 11px;
    padding: 8px 0px;
  }

  .LessonViewProgress-table-container table tbody tr td {
    padding: 22px 0px;
    font-size: 11px;
  }

  .student-request-btn-folder button {
    padding: 6px 17px;
    font-size: 9px;
  }

  .startedAt-time span:nth-child(2),
  .completedat-time span:nth-child(2) {
    font-size: 11px;
  }

  .add-lesson-btn button {
    padding: 10px 26px;
    display: flex;
    align-items: baseline;
    margin: 0px auto 20px auto;
    font-size: 10px;
    padding: 9px 15px !important;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .view-course-details-share-btn p {
    font-size: 11px;
  }

  .list-of-students-wrappper table tbody tr td img {
    width: 60px;
    height: 60px;
  }

  .student-request-btn-folder {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .view-course-details-share-folder {
    max-width: 505px;
  }

  .view-course-details-share-folder h2 {
    padding: 13px 20px;
    font-size: 17px;
  }

  .view-course-details-inner-wrapper {
    padding: 14px 20px;
    display: flex;
    gap: 12px;
  }

  .view-course-social-links-image img {
    width: 39px;
  }

  .view-course-social-links-folder {
    display: flex;
    gap: 10px;
  }

  .copy-link-or-path-folder input {
    padding: 8px 15px;
    font-size: 11px;
  }

  .copy-link-or-path-folder button,
  .edit-image-courses p,
  .LessonViewProgress-header-section button,
  .LessonViewProgress-serach-filter input,
  .LessonViewProgress-student-status,
  .LessonViewProgress-table-container table tbody tr td a,
  .AssignmentViewProgress-mock-test-answer-folder,
  .View-Course {
    font-size: 11px;
  }

  .pdf-btn,
  .spreadsheet-btn {
    font-size: 11px !important;
  }

  .search-icon svg {
    width: 17px;
  }

  .LessonViewProgress-tab-filter-downlode-folder span {
    width: 29px;
    height: 29px;
  }

  .view-course-details-share-folder p {
    font-size: 14px;
  }

  .invite-student-close-btn {
    position: absolute;
    top: -39px;
    padding: 5px 8px;
  }

  .Update-Publish-btn {
    margin: 21px auto;
    font-size: 11px;
    padding: 8px 28px;
    transform: translateX(-42px);
  }
  .Update-Publish-btn-disable {
    margin: 21px auto;
    font-size: 11px;
    padding: 8px 28px;
    transform: translateX(-42px);
  }
  .edit-Course-inner-input label,
  .Create-Course-inner-input label,
  .Create-Course-des-folder label {
    font-size: 12px;
  }

  .create-course-Grade-input-folder {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 33px;
  }

  .AssingmentMCQs-header-main-contaiiner h2 {
    font-size: 15px;
    margin: 0px 0px 0px 0px;
  }

  .AssingmentMCQs-mock-test-question-answer-container h2 {
    font-size: 15px;
    margin: 26px 0px;
  }

  .step-container {
    margin: 14px auto 0px auto;
  }

  .AssingmentMCQs-mock-test-question-answer-container {
    max-width: 429px;
    margin: 32px auto 0px auto;
  }

  .AssingmentMCQs-mock-test-answer-folder {
    padding: 14px 38px;
    font-size: 11px;
  }

  .AssingmentMCQs-mock-test-answer-folder input {
    width: 13px;
    height: 13px;
  }

  .AssingmentMCQs-mock-test-answer-btn-folder button {
    padding: 6px 30px;
    font-size: 12px;
  }

  .AssingmentMCQs-mock-test-answer-btn-folder {
    margin: 24px 0px 0px 0px;
  }

  .LessonViewProgress-header-section {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .lessonviewprogress-filder-tab p {
    font-size: 12px;
  }

  .lessonviewprogress-filder-tab span {
    font-size: 13px;
  }

  .LessonViewProgress-table-container table {
    width: 1104px;
  }

  .LessonViewProgress-table-container {
    margin: 58px 0px 0px 0px;
    overflow-x: scroll;
  }

  .LessonViewProgress-table-container table thead tr th:nth-child(7) {
    text-align: center;
  }

  .download-popup h2 {
    font-size: 15px;
    margin: 0px 0px 13px 0px;
  }

  .download-popup {
    width: 447px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }

  .student-score {
    font-size: 17px;
  }

  .LessonAnalytics-table-container table {
    width: 717px !important;
  }

  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px !important;
    right: 35px !important;
    width: 12px !important;
  }

  .LessonAnalytics-profile-container {
    margin: 14px 0px 0px 0px;
  }

  .LessonAnalytics-wrapper p {
    font-size: 13px;
  }

  .LessonAnalytics-profile-image img {
    width: 56px;
  }

  .LessonAnalytics-profile h2,
  .LessonAnalytics-profile-folder p {
    font-size: 11px !important;
  }

  .LessonAnalytics-profile a {
    font-size: 10px;
  }

  .LessonAnalytics-profile-folder h2 {
    font-size: 13px !important;
    margin: 2px 0px -4px 0px !important;
  }

  .lessonanalystics-date {
    font-size: 9px;
  }

  .LessonAnalytics-profile-massege-image {
    padding: 3px 5px;
  }

  .LessonAnalytics-profile-massege-image img {
    width: 15px;
    height: 15px;
  }

  .AssignmentAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 19px;
    width: 19px;
  }

  .right_chat_sec_new_head h6 {
    font-size: 12px;
  }

  .right_chat_sec_new_head img {
    width: 40px !important;
    height: 40px !important;
  }
  .msg_sec_new__left_single_inner img {
    width: 40px;
    height: 40px;
  }

  .AssingmentMCQs-Time-remaining-folder p {
    font-size: 13px;
  }

  .AssingmentMCQs-Time-remaining {
    font-size: 12px;
  }

  .AssignmentViewProgress-mock-test-answer-container {
    margin: 0px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  .AssignmentViewProgress-mock-test-question-answer-container h2 {
    font-size: 14px;
  }

  .AssignmentViewProgress-mock-test-container {
    max-width: 561px;
  }

  .TeacherProfile-btn-container button {
    font-size: 13px;
  }

  .Teacher-student-edit-profile-left-section {
    min-width: 16%;
  }

  .Teacher-student-edit-profile-right-section p {
    font-size: 13px;
  }

  .Teacher-student-edit-profile-left-section .Personalize-your-AI-Assistant-uplode-image #choos-profile-image {
    width: 113px;
    height: 99px;
  }

  .TeacherProfile-Language-folder p {
    padding: 6px 31px;
    font-size: 12px;
  }

  .dashboard-popup-teacher-profile-details-input {
    width: 48.1%;
  }

  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
    width: 71%;
    margin: 30px 0px 0px 0px;
  }

  .Start-by-creating-your-course-container button {
    font-size: 12px;
  }

  .dashboard-name-title {
    font-size: 20px;
  }

  .dashborad-Courses-count h2 {
    font-size: 20px;
  }

  .dashborad-Courses-count p {
    font-size: 12px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 13px;
  }

  .Start-by-creating-your-course-container button {
    font-size: 11px;
  }

  .created-my-courses-folder-image {
    width: 100%;
    height: 237px;
  }

  .Create-Courses-wrapper h2 {
    font-size: 22px;
  }

  .Create-Course-inner-input {
    width: 48.9%;
  }

  .create-course-Grade-input-folder {
    display: flex;
    gap: 12px;
  }

  .Create-Course-image p {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .Create-Course-btn-wrapper button {
    font-size: 11px;
    padding: 8px 16px !important; 
  }
  .Create-Course-image-disclamer span {
    display: none;
  } 

  .Create-Course-uplode-image-container button,
  .Create-Course-uplode-outer-image button,
  .Regenerate-course-btn {
    font-size: 11px;
  }

  .Course-description-inner-ai-feilds-folder p,
  .Course-description-inner-ai-feilds span {
    font-size: 11px;
  }

  .Course-description-inner-ai-feilds {
    padding: 6px 11px;
  }

  .reset_password_body_single label,
  .reset_password_body_single input {
    font-size: 12px;
  }

  .reset_password_body_single label::after {
    content: "*";
    color: red;
    margin-left: 2px
  }

  .reset_pass button {
    font-size: 12px;
  }

  .password_req ul li {
    font-size: 12px;
  }

  .student-Active-Lessons {
    font-size: 18px;
  }

  .Student-Active-Lesson-title-folder h2 {
    font-size: 14px;
  }

  .Student-Active-Lesson-des,
  .Student-Active-Assignment-des {
    font-size: 11px;
  }

  .Student-Active-Lesson-main-container {
    overflow-x: scroll;
  }

  .Student-Active-Lesson-container {
    width: 919px;
  }

  /* .Student-Active-start-Lesson {
    padding: 7px 35px;
    font-size: 11px;
  } */
  .Student-Active-start-Lesson {
    padding: 7px 21px;
    font-size: 11px;
  }

  p.studentviewcoursedetails-des {
    font-size: 11px;
    line-height: 20px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 0px 6px 0px;
  }

  .studentDashboar-profile-btn-folder {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .studentDashboar-profile-btn-folder button {
    padding: 8px 0px;
    font-size: 11px;
  }

  .StudentViewCourseDetails-lesson-des {
    width: 145px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }

  .StudentViewCourseDetails-lesson-toggle-btn h2 {
    font-size: 14px;
  }

  .StudentViewCourseDetails-lesson-toggle-dropdown-section p {
    font-size: 13px;
  }

  .StudentViewCourseDetails-lesson-inner-assignments-container h2 {
    font-size: 19px;
  }

  .StudentViewCourseDetails-lesson-inner-assignments-folder h2 {
    font-size: 14px;
  }

  .StudentViewCourseDetails-lesson-inner-assignments-folder p {
    font-size: 13px;
  }

  .about_teach_sec_new_right textarea {
    height: 105px;
  }

  /* .create-course-Grade-input-folder {
    width: 100% !important;
  } */
  .view-course-details-Create-lesson-container-image img {
    width: 169px;
  }

  .view-course-details-Create-lesson-container p {
    margin: 16px 0px;
    font-size: 15px;
  }

  .view-course-details-Create-lesson-container button {
    font-size: 11px;
  }

  .dashboardBody {
    height: auto !important;
  }

  /* .search-container {
    width: 310px !important;
  } */
  .course-file-image {
    width: 100%;
    height: 272px;
  }

  .preview-assignments-inner-folder {
    padding: 18px 0px;
    width: 1024px;
  }

  .preview-assignments-inner-section {
    overflow-x: scroll;
  }

  .assignments-sec-container .Overview-Lesson-date .Overview-Lesson-date-counts p {
    width: auto !important;
  }

  .search-container {
    width: 321px !important;
  }

  .view-course-Details-back-btn-folder h2 {
    width: 550px;
  }

  .Create-Course-des-wrapper {
    display: flex;
    gap: 16px;
  }

  .Create-Course-image-disclamer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .Overview-Lesson-description {
    overflow: hidden !important;
  }

  .studentrequest-profile-image {
    width: 29%;
  }

  .date-inner-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .lesson_generator_body_sec_left,
  .lesson_generator_body_sec_right {
    width: 100%;
  }

  .lesson_generator_body_sec {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    right: 118px;
  }

  .Overview-Lesson-description {
    max-height: 1.5rem;
  }

  .assignments-sec-container {
    display: flex;
    gap: 12px;
  }

  .preview-assignments-wrapper h2 {
    font-size: 14px;
    margin: 0px 0px 13px 0px;
  }

  /* .view-course-Details-back-btn-folder h2 {
    width: 303px !important;
  } */
  .Invited_students_btn {
    padding: 3px 22px !important;
  }
  .Students-courses-details-container, 
  .view-course-details-container {
    display: flex;
    /* gap: 7px; */
  }
  .Students-courses-details-folder, 
  .view-Students-course-details-folder {
    width: 82px;
  }

  .invited-student-list-table-wrapper {
    overflow: auto;
  }

  .invited-student-list-table-wrapper .student-request-wrapper {
    width: 935px;
  }

  .search-list {
    width: 529px;
  }
  .DashboardPaechart div {
    width: 100% !important;
    height: 202px !important;
    display: flex;
    justify-content: center;
  }
  .admin-dashboard-graph-dropdown-menu {
    width: 50% !important;
  }
  .admin-dashboard-graph-dropdown-menu .css-yh2y7a {
    min-width: 95px !important;
  }
  .course-revenue-price-wrapper p {
    font-size: 12px !important;  
  }
  .dashboardSidePannel {
    overflow: auto !important;
  }
  .create-course {
    margin-left: 158px;
    min-width: 139px;
  }
  #grade, 
  #month{
    padding: 3px 17px 3px 3px !important;
  }
  .Student_Review_Table_Data_section {
    overflow: auto;
  }
  #Student_Review_Table_Data {
    width: 921px;
  }
  .button_tab_wrapper {
    display: flex;
    align-items: center;
    gap: 13px !important;
  }
  #Lesson_Container .view-course-details-container .view-Students-course-details-folder {
    width: 104px;
    text-align: center;
  }
  .msg_sec_new_hidden {
    display: none;
  }
  .msg_sec_new_visible {
      display: block;
  }
  /* .Create-Course-inner-input-container {
    gap: 0px 14px !important;
  } */
}

@media only screen and (max-width: 820px) {

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 25%;
  }

  .created-my-courses-folder {
    display: flex;
    justify-content: space-between;
    gap: 13px;
  }

  .Create-Course-inner-input {
    /* width: 31.9%; */
    width: 30.9%;
  }
  .Create-Course-disscount-wrapper {
    margin: 11px 0px 0px 0px;
  }

  .Create-Course-btn-wrapper button {
    font-size: 10px;
    padding: 8px 23px;
  }

  .Create-Course-uplode-image-container button,
  .Create-Course-uplode-outer-image button,
  .Regenerate-course-btn {
    font-size: 11px;
    padding: 7px 15px;
  }

  .Create-Course-btn-wrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .create-course-inner-input-grade {
    width: 51% !important;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 35%;
  }

  .view-course-details-right-section {
    width: 75%;
  }

  p.teacherviewcoursedetails-des {
    line-height: 20px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 9px 0px 5px 0px !important;
  }

  .Create-Assignment-btn,
  .Preview-lesson-btn,
  .View-Progress-btn {
    padding: 5px 18px;
    font-size: 11px;
  }

  .view-course-details-image {
    width: 100%;
    height: 226px;
  }

  .LessonViewProgress-table-container table {
    text-align: left;
    width: 1104px;
  }

  .LessonViewProgress-table-container {
    overflow-x: scroll;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }

  .LessonAnalytics-profile h2 {
    font-size: 14px;
    margin: 9px 0px 0px 0px;
  }

  .LessonAnalytics-profile-folder p {
    font-size: 13px;
  }

  .LessonAnalytics-profile-folder h2 {
    font-size: 16px;
    margin: 6px 0px 0px 0px;
  }

  .lessonanalystics-date {
    font-size: 11px;
  }

  .LessonAnalytics-profile h2 {
    font-size: 14px;
    margin: 9px 0px 0px 0px;
  }

  .LessonAnalytics-profile a {
    font-size: 11px;
  }

  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
    width: 79%;
  }

  .Teacher-student-edit-profile-left-section .Personalize-your-AI-Assistant-uplode-image #choos-profile-image {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    margin: auto;
    width: 107px;
    height: 109px;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }

  .Teacher-student-edit-profile-left-section {
    width: 13%;
    text-align: center;
  }

  /* .Teacher-student-edit-profile-right-section {
    width: 79% !important;
  } */
  .LessonViewProgress-table-container {
    margin: 58px 0px 0px 0px;
    overflow-x: scroll;
  }

  .LessonViewProgress-table-container table {
    text-align: left;
    width: 1332px;
  }

  /* .create-course-Grade-input-folder {
    width: 100% !important;
  } */
  .form_sec_settings .dashboard-popup-teacher-profile-details-form {
    width: 88% !important;
  }

  .create-course-Grade-input-folder {
    width: 32%;
    gap: 14px;
  }

  .Create-Course-inner-input-container {
    display: flex;
    gap: 14px;
  }

  .view-lessons-created-course-inner-preview-btn-folder {
    display: flex;
    /* gap: 10px; */
    gap: 6px;
  }

  .view-course-details-right-section h2 {
    font-size: 21px;
    width: 208px;
  }

  .dashboardBody {
    height: auto !important;
  }

  .preview-assignments-inner-section {
    overflow-x: scroll;
  }

  .preview-assignments-inner-folder {
    width: 832px;
  }

  /* .created-my-courses-title-wrapper h2 {
    width: 362px !important;
  } */

  .view-lessons-created-course-inner-preview-title p {
    /* width: 200px !important; */
    width: 154px !important;
  }

  .studentrequest-profile-image {
    width: 30%;
  }

  .lesson_generator_body_sec {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .lesson_generator_body_sec_left,
  .lesson_generator_body_sec_right {
    width: 100% !important;
  }

  .notifications-inner-folder {
    margin: 0px 0px 0px 0px;
  }

  .invited-student-list-table-wrapper {
    overflow: auto;
  }

  .invited-student-list-table-wrapper .list-of-students-wrappper {
    width: 993px;
  }
  .dashborad-Courses-folder { 
    width: 100%; 
    padding: 10px;
  }
  .dashborad-Courses-container{
    display: flex;
    /* gap: 4px; */
  }
  .Create-Course-des-folder {
    width: 70%;
  }
  .uploded-image-section {
    width: 30%;
  }
  .Students-courses-details-folder span, 
  .view-Students-course-details-folder span {
    font-size: 8px !important;
  }
  .Students-courses-details-container, .view-course-details-container {
    display: flex;
    gap: 7px;
  }
  .Create-Course-disscount-wrapper .Create-Course-inner-input {
    width: 31%;
  }
  .Create-Course-disscount-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .LessonViewProgress-header-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: -3px;
  }

  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 40px;
  }

  .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form {
    width: 76%;
  }

  .Create-Assignment-btn,
  .Preview-lesson-btn,
  .preview-assignments-inner-folder button {
    padding: 5px 18px;
  }

  .view-lessons-created-course-inner-preview-title p {
    width: 222px;
  }

  .teacherviewcoursedetails-des {
    font-size: 13px;
    margin: 8px 0px 7px 0px !important;
    line-height: 20px !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .view-course-details-image {
    width: 100%;
    height: 227px;
  }

  /* .create-course-Grade-input-folder {
    width: 100%;
  } */
  .LessonViewProgress-table-container table {
    text-align: left;
    width: 1329px;
  }

  .LessonViewProgress-table-container {
    overflow-x: scroll;
  }

  .dashboardBody {
    height: auto !important;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 23%;
    position: relative;
  }

  .form_sec_settings .dashboard-popup-teacher-profile-details-input {
    width: 48.1%;
  }

  /* .Create-Course-btn-wrapper button {
    font-size: 13px;
    padding: 8px 25px;
  } */
  /* .created-my-courses-title-wrapper h2 {
    width: 469px;
  } */

  .date-inner-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .view-course-details-right-section h2 {
    width: 332px;
  }

  .studentrequest-profile-image {
    width: 26%;
  }

  .studentDashboar-Suggested-Courses-container {
    display: flex;
    gap: 16px;
  }
  .Students-courses-details-folder span, .view-Students-course-details-folder span {
    font-size: 8px !important;
  }
  .Create-Course-disscount-wrapper {
    margin: 15px 0px 0px 0px;
  }
  .promote_part_sec textarea {
    height: 25px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1198px) {
  .dashboardBody {
    height: auto !important;
  }
}

@media (min-width: 1198px) and (max-width: 1279px) {
  .created-my-courses-title-wrapper h2 {
    /* width: 600px; */
    width: auto;
  }
}

@media (min-width: 1280px) and (max-width: 1299px) {
  .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
    display: flex;
    gap: 24px !important;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-container p {
    font-size: 18px;
  }

  .studentDashboar-search-filter {
    width: 461px !important;
  }

  .studentDashboar-Suggested-Courses-folder {
    width: 279px;
  }

  .studentDashboar-Suggested-Courses-image img {
    width: 100%;
    height: 157px;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 17%;
    right: 3%;
    width: 397px;
    height: 566px;
  }

  .studentDashboard-universal-chatbot-inner-chat {
    width: 100%;
    height: 414px;
  }

  .course-file-image {
    width: 100%;
    height: 273px;
  }

  .dashboardSidePannel button,
  .dashboardSidePannel button.active {
    display: flex;
    font-size: 14px !important;
    align-items: center;
  }

  .dashboard-name-title {
    font-size: 21px;
  }

  .dashborad-Courses-count h2 {
    font-size: 20px;
  }

  .dashborad-Courses-count p {
    font-size: 12px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 13px;
  }

  .Start-by-creating-your-course-container button {
    padding: 8px 41px;
    font-size: 12px;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 22%;
  }

  .created-my-courses-des-wrapper,
  .edit-course-details-right-section {
    width: 75%;
  }

  /* .created-my-courses-title-wrapper h2 {
    width: 670px;
  } */

  .created-my-courses-title-wrapper h2,
  .view-course-details-right-section h2 {
    font-size: 20px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 11px !important;
  }

  .Students-courses-details-counts p,
  .view-Students-course-details-counts p {
    font-size: 11px;
  }

  .Students-des,
  .teacherviewcoursedetails-des {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .teacherviewcoursedetails-des {
    margin: 11px 0px 8px 0px !important;
  }

  .view-course-details-image {
    width: 100%;
    height: 231px;
  }

  .view-lessons-btn-container button {
    font-size: 15px;
  }

  .coming-soon {
    position: absolute;
    top: 37px;
    right: 54px;
  }

  .edit-course-details-right-section {
    display: flex;
    gap: 7px;
  }

  .edit-image-courses p {
    font-size: 12px;
  }

  .edit-image-courses {
    padding: 8px 9px;
  }

  .Update-Publish-btn {
    /* margin: 19px 0px; */
    font-size: 12px;
  }
  .Update-Publish-btn-disable {
    font-size: 12px;
  }
  .view-course-Details-back-btn-folder h2 {
    font-size: 21px;
    width: 500px;
  }

  .studentDashboardSidePannel button.active,
  .studentDashboardSidePannel button {
    display: flex;
    font-size: 15px;
    align-items: center;
  }

  .studentDashboar-profile-btn-folder button {
    font-size: 13px;
  }

  .studentDashboar-profile-course-title {
    font-size: 16px !important;
  }

  .studentDashboar-wrapper h2 {
    font-size: 22px;
  }

  .AssingmentMCQs-header-main-contaiiner h2,
  .AssingmentMCQs-mock-test-question-answer-container h2 {
    font-size: 16px;
  }

  .AssingmentMCQs-Time-remaining-folder p {
    font-size: 13px;
  }

  .AssingmentMCQs-Time-remaining {
    font-size: 12px;
  }

  .AssingmentMCQs-mock-test-answer-folder {
    font-size: 12px;
  }

  .AssingmentMCQs-mock-test-answer-folder input {
    width: 15px;
    height: 15px;
  }

  .AssingmentMCQs-mock-test-answer-btn-folder button {
    font-size: 14px;
  }

  .settings_main_sec_head ul li a {
    font-size: 15px;
  }

  .View-Course,
  .Create-Course-btn-wrapper button,
  .Create-Course-uplode-image-container button,
  .Create-Course-uplode-outer-image button,
  .Regenerate-course-btn,
  .Create-Assignment-btn,
  .Preview-lesson-btn,
  .View-Progress-btn,
  .add-lesson-btn button {
    font-size: 12px;
  }

  .created-my-courses-folder-image {
    width: 100%;
    height: 227px;
  }

  .Create-Courses-wrapper h2 {
    font-size: 21px;
  }

  .assignments-sec-container .Overview-Lesson-date span {
    font-size: 12px;
  }

  .view-lessons-created-course-wrapper p {
    font-size: 13px;
  }

  .edit-Course-inner-input label {
    font-size: 13px;
  }

  .edit-Course-inner-input input,
  .edit-Course-inner-textarea label {
    font-size: 12px;
  }

  .msg_sec {
    font-size: 20px;
  }

  .msg_left_search input {
    font-size: 12px;
  }

  .msg_data_sec h6 {
    font-size: 12px !important;
  }

  .msg_data_sec p {
    font-size: 10px;
  }

  .msg_sec_new__left_single_inner span,
  .right_chat_sec_new_head h6,
  .right_chat_sec_right span,
  .right_chat_sec_left span {
    font-size: 12px !important;
  }

  .right_chat_sec_new_head p {
    font-size: 11px;
  }

  .msg_sec {
    font-size: 19px;
  }

  .settings_main_sec_head ul li a {
    font-size: 14px !important;
    padding: 10px 40px !important;
  }

  .security_alerts_sec_new_single_left h6 {
    font-size: 15px;
  }

  .LessonViewProgress-table-container table tbody tr td,
  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th,
  .LessonViewProgress-table-container table tbody tr td a {
    font-size: 13px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }

  .LessonAnalytics-wrapper p {
    font-size: 16px !important;
  }

  .LessonAnalytics-profile-folder h2 {
    font-size: 17px !important;
    margin: 9px 0px 0px 0px !important;
  }

  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 70px !important;
  }

  .step-container {
    margin: 23px auto 0px auto !important;
  }

  .AssingmentMCQs-mock-test-question-answer-container {
    margin: 35px auto 0px auto !important;
  }

  .signup-right-section h2 {
    font-size: 30px;
  }

  .signup-tab-folder,
  .signup-tab-folder button.active,
  .signup-tab-folder button {
    padding: 9px 0px;
    font-size: 10px;
  }

  .forget-password-btn {
    font-size: 11px;
    margin: 0px 0px 0px 0px;
  }

  .signup-form {
    margin: 40px auto 0px auto;
  }

  .signup-btn {
    margin: 13px 0px 9px 0px;
  }

  .signup-btn button {
    font-size: 11px;
  }

  .signup-or-title,
  .login-or-title {
    font-size: 12px !important;
    margin: 10px 0px 13px 0px;
  }

  .login-btns {
    padding: 6px 9px;
    font-size: 13px;
  }

  .login-btns p {
    font-size: 11px;
  }

  .Already-have-an-accout-folder span {
    font-size: 13px !important;
    margin: 15px 0px 0px 0px;
  }

  .terms-and-Privacy-policy {
    font-size: 9px;
    margin: 8px 0px 5px 0px;
  }

  .Register-tab-section {
    margin: 12px 0px 0px 0px;
  }

  .create-course-text {
    font-size: 13px !important;
  }

  .Student-Active-Lesson-des {
    width: 271px;
  }

  .overview-lesson-read-right-inner-body {
    height: 375px;
  }

  .overview-lesson-read-left-container {
    height: 412px;
  }

  .overview-lesson-read-left-section {
    height: 427px;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
    display: flex;
    gap: 24px !important;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-container p {
    font-size: 16px;
  }

  .studentDashboar-Suggested-Courses-folder {
    width: 293px;
  }

  .studentDashboar-Suggested-Courses-image img {
    width: 100%;
    height: 157px;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 17%;
    right: 3%;
    width: 419px;
    height: 550px;
  }

  .studentDashboard-universal-chatbot-inner-chat {
    width: 100%;
    height: 398px;
  }

  .dashboard-name-title {
    font-size: 20px;
  }

  .dashborad-Courses-folder {
    width: 156px;
  }

  .dashborad-Courses-count h2 {
    font-size: 18px;
  }

  .dashborad-Courses-count p {
    font-size: 11px;
  }

  .Start-by-creating-your-course-container {
    padding: 14px 32px;
    margin: 31px 0px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 12px;
  }

  .Start-by-creating-your-course-container button {
    padding: 8px 41px;
    font-size: 11px;
  }

  .my-course-title {
    font-size: 19px;
  }

  .created-my-courses-title-wrapper h2 {
    /* width: 700px; */
    width: auto;
  }

  .created-my-courses-title-wrapper h2,
  .view-course-details-right-section h2 {
    font-size: 19px;
  }

  .created-my-courses-ratings-folder a {
    font-size: 9px;
  }

  .created-my-courses-ratings-folder p {
    font-size: 11px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 11px !important;
  }

  .Students-courses-details-counts,
  .view-Students-course-details-counts {
    padding: 3px 0px;
    font-size: 12px;
  }

  .view-Students-course-details-counts {
    margin: 5px 0px 0px 0px;
  }

  .view-course-details-image,
  .created-my-courses-folder-image {
    width: 100%;
    height: 223px;
  }

  .edit-image-courses {
    position: absolute;
    top: 3%;
    right: 5%;
  }

  .Students-des {
    font-size: 12px;
    line-height: 20px;
    margin: 11px 0px 0px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .View-Course {
    font-size: 10px;
  }

  .dashboardSidePannel button,
  .create-course-text,
  .settings_main_sec_head ul li a {
    font-size: 14px !important;
  }

  .Personalize-your-AI-Assistant-right-section h2 {
    font-size: 17px;
  }

  .dashboardHeaderProfileText {
    font-size: 14px;
  }

  .Create-Courses-wrapper h2 {
    font-size: 20px;
  }

  .Create-Course-inner-input label,
  .Create-Course-des-folder label {
    font-size: 12px;
  }

  .Create-Course-btn-wrapper button {
    font-size: 11px;
  }

  .Create-Course-uplode-image-container button {
    width: 185px;
    font-size: 11px;
  }

  .Course-description-inner-ai-feilds-folder p,
  .Course-description-inner-ai-feilds span {
    font-size: 11px;
  }

  .Course-description-inne img {
    width: 22px;
  }

  .Create-Course-inner-input-container {
    display: flex;
    gap: 10px;
  }

  .view-course-Details-back-btn-folder h2 {
    font-size: 20px !important;
  }

  .view-course-details-right-section p {
    margin: 0px 0px 0px 0px;
  }

  .teacherviewcoursedetails-des,
  .studentviewcoursedetails-des {
    font-size: 12px !important;
    /* margin: 3px 0px 4px 0px !important; */
    margin: 6px 0px 8px 0px !important;
    line-height: 20px !important;
    max-height: 8.3em !important;
  }

  #StudentViewCourseDetails-section .studentDashboar-profile-btn-folder .view-course-details-edit-and-share-folder p {
    font-size: 13px;
  }

  .view-course-details-Create-lesson-container-image img {
    width: 222px;
  }

  .view-course-details-Create-lesson-container button {
    font-size: 11px;
  }

  .view-course-details-Create-lesson-container p {
    font-size: 14px;
    margin: 14px 0px;
  }

  .view-lessons-btn-container button {
    font-size: 13px;
  }

  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th {
    font-size: 13px;
  }

  .student-request-btn-folder button {
    font-size: 11px;
  }

  .view-course-details-share-folder h2 {
    font-size: 16px;
  }

  .view-course-details-share-folder p {
    font-size: 14px;
  }

  .copy-link-or-path-folder input {
    font-size: 12px;
  }

  /* .copy-link-or-path-folder button {
    position: absolute;
    top: 50%;
    right: 0%;
    font-size: 12px;
  } */

  .Subjects-you-teach-feilds-folder {
    margin: 13px 0px;
  }

  .invite-student-close-btn {
    position: absolute;
    top: -37px;
  }

  .add-lesson-btn button {
    font-size: 11px;
  }

  .edit-course-details-right-section {
    display: flex;
    gap: 10px;
  }

  .edit-Course-inner-input label {
    font-size: 12px;
  }

  .edit-Course-inner-input input {
    font-size: 11px;
  }

  #outlined-multiline-static {
    font-size: 11px;
  }

  .Update-Publish-btn {
    margin: 22px auto;
    font-size: 11px;
  }
  .Update-Publish-btn-disable {
    margin: 22px auto;
    font-size: 11px;
  }
  .edit-image-courses p {
    font-size: 11px;
  }

  .invite-student-popup-folder button {
    position: inherit;
  }

  .assign_main_box_left_single label,
  .assign_main_box_right_single label {
    font-size: 12px !important;
  }

  .lesson_generator_body_sec_left h6,
  .lesson_generator_body_sec_right h6,
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 13px !important;
  }

  .chat-with-ai-lession-container {
    width: 100%;
    height: 421px !important;
  }

  .editor-container {
    height: 411px !important;
  }

  .promote_part_sec textarea {
    padding: 14px 12px 9px 12px;
    font-size: 14px;
    height: 50px;
  }

  .go_cls_btn {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .proc_btn_new {
    font-size: 14px;
  }

  .signup-btn {
    margin: 20px 0px 12px 0px;
  }

  .signup-or-title,
  .login-or-title {
    margin: 10px 0px 14px 0px;
  }

  .Already-have-an-accout-folder span {
    margin: 11px 0px 0px 0px;
  }

  .create-course-text {
    font-size: 13px;
  }

  .welcome-polly-popup-container h2 {
    font-size: 18px;
  }

  .studentDashboar-wrapper h2 {
    font-size: 21px;
  }

  .studentDashboar-actives-folder {
    padding: 9px 0px;
  }

  .studentDashboar-actives-course-count h2 {
    font-size: 22px;
  }

  .studentDashboar-actives-course-count p {
    font-size: 13px;
  }

  .studentDashboar-search-filter input {
    font-size: 12px;
  }

  .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span {
    font-size: 14px;
  }

  .studentDashboar-profile-image img {
    width: 30px;
    height: 30px;
  }

  .tudentDashboar-profile-card-title {
    font-size: 13px !important;
  }

  .studentDashboar-profile-course-title {
    font-size: 14px !important;
  }

  .studentDashboar-profile-btn-folder button {
    font-size: 13px;
  }

  .StudentViewCourseDetails-lesson-toggle-btn h2 {
    font-size: 16px;
  }

  .StudentViewCourseDetails-lesson-inner-assignments-container h2 {
    font-size: 18px;
  }

  .studentDashboardSidePannel button {
    display: flex;
    font-size: 14px !important;
    align-items: center;
  }

  .StudentViewCourseDetails-lesson-inner-assignments-folder h2 {
    font-size: 16px !important;
  }

  .student-Active-Lessons {
    text-align: left;
    font-size: 18px;
  }

  .Student-Active-Lesson-title-folder h2 {
    margin: 0px 0px 0px 0px;
    font-size: 16px;
  }

  .dashboard-popup-welcome-image img {
    width: 89%;
  }

  .dashboard-popup-welcome-folder h2 {
    margin: 20px 0px 7px 0px;
    font-size: 28px;
  }

  .dashboard-popup-welcome-folder button {
    font-size: 13px;
  }

  .dashboard-popup-teacher-profile-details-title h2 {
    font-size: 17px;
  }

  .dashboard-popup-teacher-profile-details-input label {
    font-size: 12px;
  }

  .dashboard-popup-teacher-profile-details-input input {
    font-size: 12px;
  }

  .dashboardHeaderProfileText {
    font-size: 14px !important;
  }

  .search-input {
    padding: 9px;
  }

  .overview-lesson-read-left-des p {
    font-size: 13px;
  }

  .coming-soon {
    position: absolute;
    top: 37px;
    right: 59px;
  }

  .course-file-image {
    height: 222px;
  }

  .Student-Active-start-Lesson {
    font-size: 12px;
  }

  .Overview-Lesson-date span {
    font-size: 13px;
  }

  .Overview-Lesson-date-counts p {
    font-size: 12px;
  }

  .Create-Course-des-wrapper {
    margin: 13px 0px 0px 0px;
  }

  .AssingmentMCQs-header-main-contaiiner h2,
  .AssingmentMCQs-mock-test-question-answer-container h2 {
    font-size: 16px !important;
  }

  .AssingmentMCQs-Time-remaining-folder p {
    font-size: 14px;
  }

  .AssingmentMCQs-Time-remaining {
    font-size: 12px;
  }

  .step-container {
    margin: 29px auto 0px auto;
  }

  .AssingmentMCQs-mock-test-question-answer-container {
    margin: 49px auto 0px auto;
  }

  .AssingmentMCQs-mock-test-answer-folder {
    font-size: 12px;
  }

  .AssingmentMCQs-mock-test-answer-btn-folder button {
    font-size: 12px;
  }

  .LessonAnalytics-wrapper p {
    font-size: 16px;
  }

  .LessonViewProgress-table-container table tbody tr td {
    font-size: 13px;
  }

  .LessonAnalytics-profile h2,
  .LessonAnalytics-profile-folder p {
    font-size: 13px !important;
  }

  .LessonAnalytics-profile a {
    font-size: 11px;
  }

  .LessonAnalytics-profile-folder h2,
  .LessonAnalytics-wrapper p {
    font-size: 13px !important;
  }

  .LessonAnalytics-table-container .completedat-time img {
    position: absolute;
    top: 0px;
    right: 80px !important;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 2px;
  }

  .lessonanalystics-date {
    font-size: 10px;
  }

  .View-Progress-btn {
    font-size: 13px;
  }

  .msg_sec {
    font-size: 20px !important;
  }

  .msg_data_sec h6,
  .right_chat_sec_new_head h6,
  .right_chat_sec_right span,
  .right_chat_sec_left span,
  .msg_sec_new__left_single_inner span,
  .right_chat_sec_new_head h6 {
    font-size: 12px !important;
  }

  .msg_data_sec p {
    font-size: 10px !important;
  }

  .security_alerts_sec_new_single_left h6 {
    font-size: 15px !important;
  }

  .security_alerts_sec_new_single_left p {
    font-size: 12px !important;
  }

  .student-score {
    font-size: 18px;
  }

  .Student-Active-Lesson-des {
    width: 319px;
  }

  .form-icon {
    position: absolute;
    top: 40px;
    left: 2.2%;
  }

  .overview-lesson-read-right-inner-body {
    height: 430px;
  }

  .overview-lesson-read-left-container {
    height: 466px;
  }

  .overview-lesson-read-left-section {
    height: 483px;
  }

  .Submitted-Answer-list li,
  .CorrectAnswerr-list li {
    width: 202px;
  }
  #Student_Review_Table_Data td:nth-child(2){
    text-align: left;
  }
  #Student_Review_Table_Data td {
    padding: 5px !important;
  }

  /* .Create-Course-image-disclamer {
    width: 98px !important;
    font-size: 11px !important;
  } */
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .overview-lesson-read-right-inner-body {
    height: 430px;
  }

  .overview-lesson-read-left-container {
    height: 450px;
  }

  .overview-lesson-read-left-section {
    height: 484px;
  }
}

@media (min-width: 1600px) and (max-width: 1699px){
  .student-lesson-preview-container {
    height: 750px !important;
   }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 9%;
    right: 3%;
  }

  .dashboard-popup-teacher-profile-details-image-plus-btn {
    position: absolute;
    bottom: 5px;
    right: 126px;
    /* right: 18px; */
  }

  .form-icon {
    position: absolute;
    top: 40px;
    left: 2.1%;
  }

  .LessonViewProgress-student-status {
    width: 150px;
  }

  .overview-lesson-read-left-section {
    height: 644px !important;
  }

  .overview-lesson-read-left-container {
    height: 614px !important;
  }

  .overview-lesson-read-right-inner-body {
    height: 590px !important;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    right: 530px !important;
  }
  .studentDashboar-Suggested-Courses-folder {
    width: 358px;
  }
}

@media (min-width: 1800px) and (max-width: 1820px) {
  .studentDashboar-Suggested-Courses-folder {
    width: 316px;
  }

  .created-my-courses-folder-image {
    width: 100%;
    height: 235px;
  }

  #TeacherProfile-section .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input .location-icon {
    position: absolute;
    left: 3.3%;
  }

  .form-icon {
    position: absolute;
    top: 40px;
    left: 2%;
  }

  .chat-with-ai-lession-container {
    height: 600px !important;
  }

  .editor-container {
    height: 590px !important;
  }

  .overview-lesson-read-right-inner-body {
    height: 685px !important;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    right: 725px !important;
  }
  .overview-lesson-read-left-section {
    height: 736px !important;
  }
  .overview-lesson-read-left-container {
    height: 704px !important;
  }
}

@media screen and (min-width: 1918px) and (max-width: 1921px) {
  .studentDashboar-Suggested-Courses-folder {
    width: 340px;
  }

  .view-course-details-right-section p {
    font-size: 14px;
  }

  .LessonViewProgress-table-container table tbody tr td {
    font-size: 15px;
  }

  .list-of-students-wrappper table thead tr th,
  .LessonViewProgress-table-container table thead tr th {
    font-size: 15px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 28px;
  }

  .signup-right-section h2 {
    font-size: 38px;
    margin: 8px 0px 0px 0px;
  }

  .signup-tab-folder,
  .signup-tab-folder button.active,
  .signup-tab-folder button {
    padding: 12px 0px;
    font-size: 14px;
  }

  .signup-btn button,
  .login-btns p {
    font-size: 14px;
  }

  .Already-have-an-accout-folder span {
    font-size: 16px !important;
  }

  .terms-and-Privacy-policy {
    font-size: 12px;
    margin: 8px 0px 5px 0px;
  }

  .coming-soon {
    position: absolute;
    top: 50px;
    right: 90px;
  }

  .form-icon {
    position: absolute;
    top: 40px;
    left: 1.8%;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    right: 846px !important;
  }
  /* .created-my-courses-folder-image-left-folder {
    flex: 0 0 265px !important;
  } */

  /* .overview-lesson-read-right-inner-body {
    height: 460px !important;
  }
  .overview-lesson-read-left-section {
    height: 522px !important;
  }
  .overview-lesson-read-left-container {
    height: 516px !important;
  } */
}

@media (min-width: 2700px) and (max-width: 2799px) {

  .signup-right-section span,
  .Already-have-an-accout-folder span,
  .Register-title {
    font-size: 17px;
  }

  .signup-tab-folder button {
    font-size: 18px;
  }

  .signup-tab-folder,
  .signup-tab-folder button.active {
    padding: 15px 0px;
    font-size: 18px;
  }

  .forget-password-btn {
    font-size: 14px;
  }

  .signup-btn button {
    font-size: 17px;
  }

  .signup-or-title,
  .login-or-title {
    font-size: 17px !important;
  }

  .login-btns p {
    font-size: 16px;
  }

  .terms-and-Privacy-policy {
    font-size: 14px;
  }

  .coming-soon {
    position: absolute;
    top: 64px;
    right: 145px;
  }

  .coming-soon img {
    width: 72px;
  }

  .created-my-courses-des-wrapper,
  .edit-course-details-right-section {
    width: 88%;
  }

  .created-my-courses-folder-image-left-folder,
  .view-course-details-left-section,
  .edit-course-details-left-section {
    width: 11%;
  }

  .edit-Course-inner-input label,
  .edit-Course-inner-textarea label {
    font-size: 16px;
  }

  .Update-Publish-btn {
    margin: 4px 0px;
    font-size: 15px;
  }
  .Update-Publish-btn-disable {
    margin: 4px 0px;
    font-size: 15px;
  }
  .dashboardSidePannel button.active,
  .dashboardSidePannel button {
    font-size: 16px;
  }

  .create-course-text {
    font-size: 15px;
  }

  .Start-by-creating-your-course-container p {
    font-size: 15px;
  }

  .dashborad-Courses-count p {
    font-size: 14px;
  }

  .dashborad-Courses-count h2 {
    font-size: 24px;
  }

  .Start-by-creating-your-course-container button,
  .View-Course,
  .Create-Course-btn-wrapper button,
  .view-course-details-Create-lesson-container button,
  .add-lesson-btn button,
  .proc_btn_new,
  .Create-Assignment-btn,
  .Preview-lesson-btn,
  .LessonViewProgress-student-status,
  .LessonViewProgress-header-section button {
    font-size: 14px;
  }

  .completedat-time img {
    position: absolute;
    top: 0px;
    right: 66px;
  }

  .TeacherProfile-Language-folder p {
    font-size: 14px;
  }

  #TeacherProfile-section .dashboard-popup-teacher-profile-details-input .teacher-create-popup-gender-input-folder .form-icon,
  #TeacherProfile-section .dashboard-popup-teacher-profile-details-input .teacher-create-popup-gender-input-folder .location-icon {
    position: absolute;
    top: 40px;
    left: 2.6%;
  }

  #TeacherProfile-section .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input .form-icon {
    position: absolute;
    top: 40px;
    left: 1.6%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .created-my-courses-title-wrapper h2,
  .view-course-details-right-section h2 {
    font-size: 24px;
  }

  .Students-courses-details-folder span,
  .view-Students-course-details-folder span {
    font-size: 14px !important;
  }

  .Students-courses-details-counts p,
  .view-Students-course-details-counts p {
    font-size: 13px !important;
  }

  .Students-des,
  .view-course-details-right-section p,
  .lesson_generator_body_sec_left h6,
  .lesson_generator_body_sec_right h6,
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 14px;
  }

  .Create-Course-inner-input label,
  .Create-Course-des-folder label,
  .assign_main_box_left_single label {
    font-size: 16px;
  }

  .view-course-details-Create-lesson-container-image img {
    width: 266px;
  }

  .teacher-create-popup-gender-input {
    position: absolute !important;
    top: 32.8px !important;
    left: 1% !important;
  }

  .studentDashboar-Suggested-Courses-container {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    margin: 30px 0px 0px 0px;
  }

  .created-my-courses-folder-image,
  .view-course-details-image {
    width: 100%;
    height: 232px;
  }

  .right_chat_sec_right span,
  .right_chat_sec_left span,
  .right_chat_sec_new_head h6,
  .msg_data_sec h6,
  .msg_sec_new__left_single_inner span {
    font-size: 14px !important;
  }

  .right_chat_sec_new_head p,
  .msg_data_sec p {
    font-size: 12px !important;
  }

  .settings_main_sec_head ul li a {
    font-size: 14px;
  }

  .Teacher-student-edit-profile-btn {
    position: absolute !important;
    bottom: -5px !important;
    right: 79px !important;
  }

  .Update-Publish-btn {
    margin: 29px auto;
    font-size: 14px;
  }
  .Update-Publish-btn-disable {
    margin: 29px auto;
    font-size: 14px;
  }
  .LessonAnalytics-table-container .completedat-time img {
    position: absolute !important;
    top: 0px !important;
    right: 208px !important;
  }

  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .teacher-create-popup-gender-input {
    position: absolute !important;
    top: 29px !important;
    left: 1% !important;
    width: 66px !important;
    padding: 4px 0px 4px 23px !important;
  }

  .studentDashboar-Suggested-Courses-folder {
    /* width: 335px; */
    width: 360px;
  }

  .studentDashboardSidePannel button.active {
    font-size: 14px;
  }

  .studentDashboar-profile-course-title {
    font-size: 15px !important;
  }

  .enroll_now_btn {
    width: 5% !important;
  }

  .Student-Active-Lesson-title-folder h2 {
    font-size: 15px;
  }

  .Student-Active-start-Lesson {
    font-size: 14px;
  }

  .overview-lesson-mark-complete-btn button {
    font-size: 13px;
  }

  .Students-des,
  .teacherviewcoursedetails-des {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 7.5em;
    white-space: normal;
  }

  .view-course-details-right-section {
    width: 100%;
  }

  .studentviewcoursedetails-des {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 7.5em;
    white-space: normal;
    margin: 8px 0px 11px 0px !important;
  }

  .form-icon {
    position: absolute;
    top: 40px;
    left: 1.6%;
  }

  .form_sec_settings .dashboard-popup-teacher-profile-details-form {
    width: 40% !important;
  }

  /* .dashboard-popup-teacher-profile-details-input {
    width: 37.8%;
  } */
  /* .dashboard-popup-teacher-profile-details-input {
    width: 48.8%;
  } */
  #teacher-create-profile-input {
    padding: 8px 10px 8px 76px;
  }

  .overview-lesson-read-right-inner-body {
    height: 663px !important;
  }

  .overview-lesson-read-left-section {
    height: 717px !important;
  }

  .overview-lesson-read-left-container {
    height: 681px !important;
  }

  .teacher-create-popup-gender-input {
    background-position: right 2px center !important;
    background-size: 9px !important;
  }

  .Teacher-student-edit-profile-left-section {
    width: 6%;
    text-align: center;
  }
  .student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    left: 872px;
  }
  .Students-courses-details-folder, 
  .view-Students-course-details-folder {
    /* width: 140px; */
    width: 245px;
  }
}


.span.userName {
  width: 119px;
  text-align: left;
}

.Profile_wrapper {
  display: flex;
  align-items: center;
}

.Terms-and-Conditions-image-title,
.Privacy_Policy_title {
  margin: 37px auto 0px auto;
  width: 394px;
}

.Privacy_Policy_title {
  width: 338px;
}

.Terms-and-Conditions-image-title img,
.Terms-and-Conditions-image img {
  width: 100%;
}

.Terms-and-Conditions-image {
  margin: 54px auto 38px auto;
  /* display: flex; */
  justify-content: center;
  width: 200px;
}

.Terms-and-Conditions-list-foldre h2 {
  font-size: 21px;
  margin: 0px 0px 11px 0px;
}

.modal-content-btn {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  justify-content: center;
  /* Align items horizontally in the center */
  height: 100%;
  /* Adjust as needed */
}

.modal-content-btn button {
  /* Size and Padding */
  padding: 5px 12px;
  font-size: 16px;

  /* Color and Background */
  background-color: #b7e5ff;
  /* Change to your preferred color */
  color: black;
  border: 2px solid transparent;
  /* Adjust or remove border as needed */
  border-radius: 5px;

  /* Font and Text Styling */
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;

  /* Shadow and Depth */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  /* Transition Effects */
  transition: all 0.3s ease-in-out;

  /* Hover and Focus Effects */
  cursor: pointer;
}

.modal-content-btn button:focus {
  background-color: #00a2ff;
  /* Darker shade on hover/focus */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
  /* Larger shadow on hover/focus */
  border-color: black;
  /* Optional: Change border color on hover/focus */
}

.modal-content-btn button:hover {
  background-color: #00a2ff;
  /* Darker shade on hover/focus */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
  /* Larger shadow on hover/focus */
  border-color: black;
  /* Optional: Change border color on hover/focus */
}

.modal-content-btn button:active {
  background-color: #00a2ff;
  /* Even darker shade when clicked */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /* Smaller shadow when clicked */
}

/* Responsive Design */
@media screen and (max-width: 600px) {
  .modal-content-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #gendar-select-feild {
    /* Adjust the height as needed */
    /* Add any other styling you need */
    /* For example, adjusting the font size or padding */
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px 10px 38px;
  }
}

@media only screen and (max-device-width: 480px) {
  #gendar-select-feild {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px 10px 38px;
  }

  /* .add-lesson-btn button {
    position: absolute;
    top: 37px !important;
  } */

  /* Style the arrow icon (if needed) */
  #gendar-select-feild::after {
    content: "\25BC";
    /* Unicode character for a downward arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

/* #gendar-select-feild{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
} */

.error-page-wrapper {
  text-align: center;
}

.error-page-image {
  width: 699px;
  margin: 0px auto;
}

.error-page-image img {
  width: 100%;
}

.error-page-text {
  margin: -85px 0px 0px 0px;
}

.error-page-text h1 {
  color: #000;
  font-family: Poppins;
  font-size: 93px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.error-page-text p {
  color: #000;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 730px;
  margin: 6px auto 24px auto;
}

.error-page-text button {
  color: #fafafa;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #0068ff;
  padding: 7px 70px;
  border: none;
  outline: none;
}

.error-page-section {
  background: url(../src/assets/images/errorpage-background.png) 0 0 no-repeat;
  background-size: cover;
}

.invite-student-popup-folder button:disabled {
  background-color: #a1a1a1;
}

.Subjects-you-teach {
  display: flex;
  align-items: baseline;
  gap: 10px;
  width: 100%;
}

.add_subject_feild_btn {
  font-size: 13px;
  width: 15%;
  height: 38px;
  padding: 0px 25px;
  border-radius: 5px;
  background-color: #0068ff;
  color: white;
  border: none;
  outline: none;
}

.or-title {
  text-align: center;
}

.text-field-chip-input {
  border: 1px solid lightgray;
  padding: 8px;
  border-radius: 4px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chip {
  background-color: #e0e0e0;
  margin: 4px;
  padding: 5px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.chip button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.text-field-chip-input-field {
  flex: 1;
  min-width: 120px;
  border: none;
  outline: none;
}

.text-field-chip-input-field.error {
  border-bottom: 2px solid red;
}

.error-message {
  color: red;
  margin-top: 8px;
}

.forprofile span {
  background-color: #0068ff;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 13px;
  position: absolute;
  /* bottom: 4px;
  right: 113px; */
  bottom: 4px;
  right: 92px;
}

#error-page-not-found-section .error-page-wrapper .error-page-image {
  width: 410px;
}

#error-page-not-found-section .error-page-text h1 {
  font-size: 46px;
}

#error-page-not-found-section .error-page-text span {
  font-size: 21px;
  font-weight: 600;
}

.Terms-and-Conditions-list-foldre p {
  font-size: 13px;
  color: black;
  text-align: justify;
}

#error-page-not-found-section .error-page-text p {
  font-size: 14px;
}

#error-page-not-found-section .error-page-text {
  margin: 0px;
}

#error-page-not-found-section {
  background: none;
}

#setting-Personalize-AI {
  width: 116px;
  margin: 0px auto;
}

.regiterAsNote {
  color: red;
}

.Analytics-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LessonViewProgress-tab-filter-downlode-folder {
  cursor: pointer;
}

/* .studentDashboard-chatbot-tooltip .MuiTooltip-tooltip {
  font-size: 16px !important;
  background: #EEF9FF !important;
  color: black !important;
  margin-left: -95px !important;
  padding: 10px 20px !important;
  margin-top: -70px !important;
  border-radius: 5px !important;
} */

.custom-tooltip {
  background-color: #eef9ff;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -56px;
  left: -132px;
  right: 35px;
  line-break: 0;
  transform: translateX(-50%);
  width: 300px;
  text-align: end;
  font-size: 13px;
  text-transform: none;
  z-index: 2 !important;
}

#custom_chatbot_close_btn {
  position: absolute !important;
  top: -14px;
  right: -8px;
  font-size: 12px;
  color: black;
  background-color: white;
  width: 25px !important;
  min-width: 25px !important;
  height: 25px !important;
  border-radius: 100%;
  box-shadow: 0px 2px 6px #adadad;
}

.Terms-and-Conditions-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 39px;
}

.Analytics-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LessonViewProgress-tab-filter-downlode-folder {
  cursor: pointer;
  padding: 0px 13px;
}

.sort-popup-assignprocess,
.filter-popup {
  position: absolute;
  z-index: 1000;
  right: 10px;
  margin-top: 35px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 2;
}
/* .sort-popup-assignprocess{
  top: 175px;
} */

.filter-popup {
  right: 142px;
}

.apply-button-assignprocess {
  margin-top: 20px;
  width: 100%;
  padding: 8px 0;
  background-color: #007bff;
  color: white;
  border: "none";
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  border: none;
}

.sort-popup-assignprocess h3 {
  margin: 0px 0px 0px 0px !important;
  /* padding-bottom: 10px; */
  /* border-bottom: 1px solid #eaeaea; */
  font-size: 14px;
  text-align: center;
}

.sort-popup-assignprocess label {
  display: block;
  margin: 10px 0;
  color: black;
  font-size: 14px;
}
.sort-popup-assignprocess label:hover{
  color: #0068FF;
}

.sort-popup-assignprocess input[type="radio"] {
  margin-right: 10px;
}

.sort-popup-assignprocess select {
  width: 100%;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  color: #333;
  -webkit-appearance: none;
  /* Removes default chrome and safari style */
  -moz-appearance: none;
  /* Removes default style Firefox */
  appearance: none;
  /* Removes default browser style*/
  /* Add a background image for the arrow down icon */
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  cursor: pointer;
}

/* CSS for Warning Message */
.warning-message {
  color: #d32f2f;
  font-size: 14px;
  margin-top: 5px;
}

.dropdown-time-picker {
  position: relative;
  width: 300px;
}

.input-field {
  display: flex;
  flex-direction: column;
  background: white;
  cursor: pointer;
}

.Terms-and-Conditions-section,
.Benefits-Beyond-Boundaries {
  background: url(../src/assets/images/tearms-condition-bg.png) 0 0;
  margin: 0px 0px 47px 0px;
}

.Terms-and-Conditions-wrapper {
  position: relative;
}

.half-circle-bg-image {
  position: absolute;
  top: 357px;
  right: -60px;
}

.half-circle-bg-image2 {
  position: absolute;
  top: 166px;
  left: -59px;
}

.book-logo3 {
  position: absolute;
  top: 222px;
  right: -60px;
}


.time-field-inline {
  display: flex;
  margin: 0 10px;
  align-items: baseline;
  justify-content: flex-start;
}

.time-field-inline p {
  margin-left: 10px;
}

.Request-Verification-btn {
  font-size: 13px;
  background-color: transparent;
  color: #0068ff;
  padding: 9px 45px;
  border-radius: 30px;
  font-weight: 600;
  border: none;
  border: 2px solid #0068ff;
}

/* ul.footer-Quick-Links {
  display: flex;
  flex-direction: column;
  gap: 13px;
} */

.Request-Verification-Code-popup-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-color: #00000042;
  padding: 27px 0px;
  z-index: 113;
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.footer-contact-folder {
  display: flex;
  gap: 13px;
  align-items: center;
  max-width: 494px;
}

.footer-contact-folder span a {
  color: black;
}

.footer-contact-folder a {
  color: black;
  font-size: 15px;
  max-width: 399px;
}

.proschool-logo {
  width: 130px;
  margin: 0px 0px 20px;
}


.aboutUs-conatiner {
  display: flex;
  /* align-items: center; */
  width: 100%;
  margin: 114px 0px 50px 0px;
}

.Request-Verification-Code-inner-popup {
  background-color: white;
  border-radius: 30px;
  margin: auto;
  width: 100%;
  padding: 28px;
}

.Request-Verification-Code-inner-popup h2 {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

.Request-Verification-Code-inner-input label {
  font-size: 15px;
}

.send-Verification-btn {
  font-size: 13px;
  background-color: #0068ff;
  color: white;
  padding: 11px 45px;
  border-radius: 30px;
  border: none;
  width: 100%;
  margin: 10px 0px 0px 0px;
  box-shadow: 0px 3px 5px #cccccc;
}

.aboutUs-left-section {
  width: 50%;
}

.aboutUs-left-section-image img {
  width: 100%;
}

.aboutUs-left-section-image {
  width: 253px;
}

.aboutUs-left-section h2 {
  font-size: 24px;
  margin: 31px 0px 22px 0px;
  line-height: 31px;
}

.aboutUs-left-section p {
  font-size: 15px;
  color: black;
  line-height: 24px;
}

.aboutUs-right-section {
  width: 50%;
  display: flex;
  justify-content: center;
}

.Back-To-Sign-In {
  margin: 10px 0px 0px 0px;
}

.Back-To-Sign-In a {
  font-size: 13px;
  text-decoration: underline;
}

.pop_up_for_lesson_start_date_note p {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  width: 30%;
  color: black;
  /* background-color: #000000; */
  background-color: #eef9ff;
  color: black;
  font-size: 13px;
  padding: 10px 0px;
  border-radius: 10px;
  position: fixed;
  top: 28%;
  left: 0%;
  right: 0%;
  margin: auto;
}


.aboutUs-right-section-image,
.aboutUs-for-teacher-right-section-image {
  width: 430px;
}

.aboutUs-right-section-image img,
.aboutUs-for-teacher-right-section-image img {
  width: 100%;
}

.pop_up_for_lesson_start_date_note {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0%;
  width: 100%;
  height: 100%;
  z-index: 113;
  /* background-color: #73737330; */
}

.aboutUs-for-teacher-conatiner,
.aboutUs-for-student-conatiner,
.aboutUs-for-parent-conatiner {
  display: flex;
  width: 100%;
  margin: 30px 0px 50px 0px;
  align-items: center;
}

.aboutUs-for-teacher-right-section {
  width: 40%;
  display: flex;
  justify-content: center;
  position: relative;
}

.aboutUs-for-aboutUs-for-student-left-section-left-section {
  width: 60%;
  position: relative;
}

.aboutUs-for-teacher-list-container,
.aboutUs-for-student-list-container,
.aboutUs-for-parent-list-container {
  padding: 18px;
  border-radius: 14px;
  background-color: #EBF3FF;
  display: flex;
  flex-direction: column;
  gap: 13px;
  box-shadow: 0px 2px 8px #dddddd;
}

.aboutUs-for-teacher-list-list-folder,
.aboutUs-for-student-list-list-folder,
.aboutUs-for-parent-list-list-folder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.aboutUs-for-teacher-list-list-folder p,
.aboutUs-for-student-list-list-folder p,
.aboutUs-for-parent-list-list-folder p {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.aboutUs-aboutus-teacher-logo-image {
  width: 276px;
}

.aboutUs-logo-image-folder {
  background: linear-gradient(90deg, #AFD0FF 0.19%, rgba(217, 217, 217, 0.00) 116.91%);
  width: 371px;
  display: flex;
  justify-content: center;
  margin-left: -60px;
}

.aboutUs-for-student-wrapper .aboutUs-logo-image-folder {
  margin-left: auto;
}

.aboutUs-aboutus-teacher-logo-image img {
  width: 100%;
}

.aboutUs-for-teacher-book-image {
  position: absolute;
  top: -18%;
  right: 0%;
}

.aboutUs-for-teacher-book-image img {
  width: 97px;
}

.aboutUs-for-teacher-left-section,
.aboutUs-for-student-left-section,
.aboutUs-for-parent-left-section {
  width: 60%;
  position: relative;
}

.aboutUs-for-student-book-image {
  position: absolute;
  top: -15%;
  left: 2%;
}

.aboutUs-for-parent-book-image {
  position: absolute;
  top: -15%;
  right: 2%;
}

.aboutUs-for-student-book-image img,
.aboutUs-for-parent-book-image img {
  width: 70px;
}

.aboutUs-for-student-book-image {
  position: absolute;
  top: -15%;
  left: 2%;
}

.aboutUs-our-vision-container {
  display: flex;
  width: 100%;
  margin: 37px 0px 0px 0px;
  /* GAP: 104px; */
  justify-content: space-between;
}

.universal-chatbot-importantSize {
  width: 35px !important;
  height: 35px !important;
}

.list-dropdown-icon {
  position: absolute;
  top: 27.9px;
  right: 203px;
}

.add_feild-btn {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
}

/* .setect-genter-list-dropdown{
  position: relative;
} */
.Course-close-btn {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.Course-close-btn button:nth-child(1){
  border: 1px solid black;
  background-color: transparent;
}

.Course-close-btn button {
  padding: 6px 35px;
  border: none;
  border-radius: 6px;
}

.Course-close button {
  padding: 6px 35px;
}

.Course-close-btn button:nth-child(2) {
  background-color: #0068ff;
  color: white;
}

/* @media screen and (-webkit-min-device-pixel-ratio: 0) {
  For Safari and Chrome
  .dashboard-popup-teacher-profile-details-input select {
    -webkit-appearance: none;
  }
  .teacher-create-popup-gender-input {
    height: 27px;
  }
} */

.teacher-create-popup-gender-input,
.student_grade_select {
  -webkit-appearance: none;
  /* Hide default arrow in WebKit browsers */
  appearance: none;
  /* Hide default arrow in modern browsers */
  background-color: transparent;
  border: 1px solid #ccc;
  /* Positioning the background arrow image */
  background-image: url('../src/assets/images/down_arrow.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  /* Adjust size as needed */
  border: none;
  border-right: 1px solid;
}

/* Optional: Add a wrapper to position the select and arrow */
.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper::after {
  /* Create custom arrow with CSS */
  content: '';
  position: absolute;
  right: 10px;
  /* Adjust as per your design */
  top: 50%;
  transform: translateY(-50%);
  /* Styling for your arrow, e.g., a small triangle */
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* Adjust color and size as needed */
}


.invite-copy-link {
  margin-bottom: 5px;
  cursor: pointer;
}

.invite-copy-link span {
  color: #0068ff;
  font-weight: 500;
  margin-bottom: 21px;
}

.StudentViewCourseDetails_view_Score {
  margin-left: auto;
  display: flex;
  padding: 7px 41px;
  font-size: 10px;
  background-color: #0068ff;
  color: #2BCC00;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: transparent;
  border: 2px solid #2BCC00;
  font-weight: 600;
}

.StudentViewCourseDetails-lesson-inner-assignments-folder {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.lesson-dropdown-tab {
  display: flex;
  flex-direction: row;
}

#AssingmentMCQs-close-btn {
  position: absolute;
  top: 4%;
  right: 2%;
  border: 1px solid;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 11px;
  font-size: 13px;
}

.limiterrorMessage {
  padding-top: 20px;
  padding-left: 20px;
  color: "#dc3545"
}
.edit-course-details-folder .uploded-image-section .limiterrorMessage {
  padding-top: 12px;
  color: "#dc3545";
  font-size: 10px;
  color: red;
  text-align: center;
  padding-left: 0px;
}

.editor-paragraph {
  font-size: 16px;
}

.button_tab_wrapper {
  display: flex;
  align-items: center;
  gap: 35px;
}

.button_tab_edit_btn {
  display: flex;
  align-items: center;
  gap: 11px;
  border: 1px solid #0068ff;
  padding: 5px 28px;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
}

.button_tab_edit_btn p,
.button_tab_edit_btn span {
  margin: 0px;
  font-size: 14px;
  color: #0068ff;
}

.filter-wrapper {
  display: flex;
  align-items: center;
}

.search_header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}

.aboutUs-our-vision-folder {
  border: 1px solid;
  padding: 52px 37px;
  width: 40%;
  background-color: #0068FF;
  color: white;
  border-radius: 0px 20px 20px 0px;
  position: relative;
}

.search-list {
  width: 70%;
  position: relative;
}

.uploded-image-section {
  position: relative;
}

.loding {
  position: absolute;
  top: 15px;
  left: 0%;
  right: 0%;
  transform: translateY(-50%);
  margin: auto;
  width: 50px;
}

.mark-as-complete {
  left: 175px;
}

.back-home-btn {
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 50px 0px 0px 0px;
  cursor: pointer;
}

.back-home-btn span {
  font-size: 13px;
  font-weight: 600;
}

.aboutUs-section {
  overflow: hidden !important;
}

#courseDate .form-control,
.edit-Course-inner-input .form-control {
  border-radius: 12px;
  background-color: #F5F5F5;
  text-transform: capitalize;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgb(0 0 0 / 92%);
  border:
    1px solid #00000040 !important;
}

.MuiOutlinedInput-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
  /* Default color, or any color you want */
}

.Student-Active-start-Lesson-greyout-btn {
  /* Regular button styles */
  background-color: #007bff;
  /* Example primary color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 47px;
  font-size: 13px;
  outline: none;
}

.aboutUs-our-vision-folder:nth-child(2) {
  border: none;
  background-color: white;
  color: #0068FF;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 2px 8px #dddddd;
  position: relative;
  width: 42%;
}



.Student-Active-start-Lesson-greyout-btn:disabled {
  /* Styles for disabled button */
  background-color: #cccccc;
  /* Grey color */
  color: #666666;
  /* Darker grey for text */
  cursor: not-allowed;
  /* Shows a 'no action' cursor */
}

.notificationPage-folder .notifications-folder {
  padding: 17px;
}

.notificationPage-folder .notifications-folder .notifications-inner-folder p {
  font-size: 13px;
  font-weight: 500;
}

.notification-time-folder span {
  font-size: 12px;
  font-weight: 500;
}

.notificationPage-folder {
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.search-list {
  width: 656px;
  position: relative;
}

.aboutUs-our-vision-folder:nth-child(2) p {
  color: black;
}

.aboutUs-our-vision-folder h2 {
  font-size: 32px;
}

.aboutUs-our-vision-folder p {
  color: white;
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
}

.aboutus_our_Vision_image {
  position: absolute;
  top: -20%;
  right: -24%;
}

.search_header-container {
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-wrapper {
  display: flex;
  align-items: center;
}

.invited_bg {
  background-color: #D3EFFF;
  color: #156897;
}

.enrolled_bg {
  background-color: #E6FFE5;
  color: #28991F;
}

.rejected_bg {
  background-color: #FFD6D6;
  color: #BA2525;
}

.serach-logo {
  width: 20px;
  position: relative;
}

.serach-logo::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 1px;
  height: 5px;
  background-color: black;
}

.aboutus_our_Vision_image2 {
  position: absolute !important;
  top: 0% !important;
  left: -24% !important;
}

.aboutus_our_Vision_image2 {
  position: absolute;
  top: -20% !important;
  left: -102px !important;
}

.Educational-Revolution-conatiner {
  margin: 70px 0px;
  position: relative;
}

.Educational-Revolution-conatiner h2 {
  color: #002469;
  font-size: 30px;
  margin-bottom: 52px;
}

.Educational-Revolution-inner-conatiner {
  box-shadow: 0px 2px 10px #b9b9b9;
  border-radius: 15px;
}

.Educational-Revolution-inner-conatiner p {
  font-size: 15px;
  color: black;
  padding: 16px;
  font-weight: 500;
}

.aboutus_Educational-Revolution_image img {
  width: 100%;
}

.aboutus_Educational-Revolution_image-bg {
  position: absolute;
  top: 7%;
  left: 0px;
}

.serach-logo svg {
  width: 100%;
}

.serach-logo::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 1px;
  height: 5px;
  background-color: black;
}

.aboutus_Educational-Revolution_image-bg img {
  width: 508px;
}

.aboutus_Educational-Revolution_image-bg2 {
  position: absolute;
  top: -5%;
  right: -25px;
}

.aboutus_title {
  font-size: 27px;
  color: #002469;
  font-weight: 600;
}

.Benefits-Beyond-Boundaries {
  position: relative;
}

.benefits-boundaries-half-circle-bg-image2 {
  position: absolute !important;
  top: 65px !important;
  left: 0px !important;
}

.benefits-boundaries-half-circle-bg-image1 {
  position: absolute !important;
  top: 522px !important;
  right: 0px !important;
}

.benefits-boundaries-half-circle-bg-image3 {
  position: absolute !important;
  bottom: 418px !important;
  right: 0px !important;
}

.benefits-boundaries-half-circle-bg-image3 {
  position: absolute;
  top: 1029px;
  left: 0px;
}

.benefits-boundaries-half-circle-bg-image4 {
  position: absolute !important;
  top: 1550px !important;
  right: 0px !important;
}

.Benefits-Beyond-Boundaries-logo1 {
  position: absolute;
  top: -2%;
  right: 0%;
}

.Benefits-Beyond-Boundaries-logo2 {
  position: absolute;
  top: 6%;
  left: 89%;
}

.Benefits-Beyond-Boundaries-logo3 {
  position: absolute;
  top: -15%;
  right: 2%;
}

.Benefits-Beyond-Boundaries-logo4 {
  position: absolute;
  top: 5%;
  left: 89%;
}

.aboutUs-right_arrow_bg {
  position: absolute;
  top: -19%;
  left: 0%;
}

.Benefits-Beyond-Boundaries-logo3 img {
  width: 116px;
}

.notification-view-all-btn {
  display: flex;
  justify-content: flex-end;
  color: #0068ff;
  font-size: 13px;
  margin: 15px 15px 0px 0px;
}


/* Trial Code dbi */
@media only screen and (min-resolution: 125dpi) {
  .Create-Course-inner-input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 15px;
  }
  .course-file-image {
    width: 100%;
    height: 200px;
  }
  .Create-Course-des-wrapper .MuiFormControl-root textarea {
    /* height: 153px !important; */
    height: 122px !important;
    font-size: 14px;
  }
  .course-uploded-image {
    /* height: 160px; */
    height: 140px;
  }
  .Create-Course-uplode-outer-image label {
    width: 126px !important;
    padding: 4px 9px !important;
  }
  .Create-Courses-wrapper h2 {
    margin: 0px 0px 8px 0px !important;
  }
  .Create-Course-disscount-wrapper {
    margin: 2px 0px 0px 0px;
  }
  .back-home-btn {
    margin: 6px 0px 0px 0px;
  }
  .studentDashboar-Suggested-Courses-container {
    display: flex;
    gap: 16px;
    /* gap: 19px; */
  }
  .AssignmentViewProgress-mock-test-container {
    /* padding: 16px 23px; */
    padding: 10px 17px;
  }
  .AssingmentMCQs-mock-test-answer-container {
    display: flex;
    gap: 11px;
  }
  .AssingmentMCQs-mock-test-question-answer-container h2 {
    margin: 9px 0px;
  }
  .AssingmentMCQs-mock-test-Ai-feedback-answer {
    height: 60px !important;
    padding: 10px !important;
  }
  .AssingmentMCQs-mock-test-question-answer-container {
    margin: 10px auto 0px auto !important;
    max-width: 793px;
  }
  .AssignmentViewProgress-mock-test-close-btn {
    position: absolute;
    top: -1px;
    /* right: -16px; */
    right: -4px;
    padding: 4px 6px;
    font-size: 10px;
  }
  .tab-content-courses {
    display: flex;
    gap: 16px;
    /* gap: 19px; */
  }
  .overview-lesson-read-left-section {
    height: 393px;
  }
  .overview-lesson-read-left-container {
    height: 357px;
  }
  .overview-lesson-read-right-inner-body {
    height: 344px;
  }

  .back-home-btn {
    margin: 25px 0 0 0px !important;
  }

  .form-buttons {
    margin: 0px auto 10px auto;
  }

  .dashboard-popup-teacher-profile-details-form-container {
    display: flex;
    gap: 8px;
  }

  .dashboard-popup-teacher-profile-details-form {
    margin: 10px auto 10px auto;
  }

  .Subjects-you-teach-feilds-folder {
    margin: 10px 0px;
  }

  .dot-indicator {
    margin: 0px 0px 0px 0px !important;
  }

  .BulkUplode-image img {
    width: 52% !important;
  }

  .BulkUplode-button-wrapper {
    margin: 22px 0px 0px 0px !important;
  }

  .studentDashboard-chatbot-container {
    position: fixed;
    bottom: 11% !important;
    right: 3% !important;
    max-width: 358px !important;
    max-height: 472px !important;
    background-color: white;
    border-radius: 34px;
  }
  .studentDashboard-universal-chatbot-inner-chat {
    height: 321px !important;
  }
  .create-course {
    gap: 5px !important;
  }
  .Personalize-your-AI-Assistant-input-folder {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
  
  .Personalize-your-AI-Assistant-input-folder label {
    font-size: 12px;
    color: black;
  }
  .introduce-yourself-text {
    line-height: 1.6;
    font-size: 11px;
  }
  .you-can-update-the-details-title {
    margin: 0px 0px 20px 0px;
    display: flex;
    justify-content: center;
    font-size: 13px;
  }
   
  .profile-image-section2 {
    display: flex;
    gap: 5px;
  }

  .Create-Course-image-disclamer span {
    max-width: 268px !important;
  }
  .button_tab_wrapper {
    align-items: center;
    display: flex;
    gap: 35px;
  }

        .AssignmentMCQs-mock-test-answer-folder textarea {
          height: 121px !important;
        }
  .course-file-image {
    /* height: 202px !important; */
    height: 140px !important;
  }

  .button_tab_edit_btn {
    padding: 5px 26px;
  }

  .view-lessons-created-course-inner-preview-title p {
    /* width: 692px; */
    /* width: 622px; */
    width: 421px;
  }
  .msg_sec_new__left_single_inner img {
    width: 45px;
  }
  .account_setting_tab_sec .form_sec_settings .dashboard-popup-teacher-profile-details-form{
    width: 62% !important;
  }
  .cropper-container.cropper-bg{
    max-height: 433px !important;
  }
  .forget_modal_sec_new_body_sec div{
    max-height: 431px !important;
  }
   
  .introduce-yourself-title {
    margin: 0px 0px 10px 0px;
  }
  .dashboard-popup-teacher-profile-details-form span {
    font-size: 11px !important;
  }
  .speech-to-text {
    position: absolute;
    top: 40%;
    right: 6% !important;
  }
  .go_cls_btn {
    position: absolute;
    right: -4px !important;
  }
  .AssignmentMCQs-mock-test-answer-folder textarea {
    height: 121px !important;
  }
  /* .Course-created-successfully-folder{
    height: 499px !important;
  } */
  /* .created-my-courses-folder-image-left-folder {
    flex: 0 0 209px !important;
  } */
}

/* Trial Code dbi */

.back-home-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin: 50px 0 0;
}

.back-home-btn span {
  font-size: 13px;
  font-weight: 600;
}

.enrolled-courses {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.LessonViewProgress-back-btn-folder,
.view-course-Details-back-btn-folder {
  align-items: center;
  color: #0068ff;
  cursor: pointer;
}

.Invited_students_btn {
  border: 1px solid #0068ff;
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 11px;
  outline: none;
  padding: 7px 22px;
  background-color: transparent;
  margin: 0px;
}

.Invited_students_btn p {
  margin: 0px;
  color: #0068ff;
}

.notification-view-all-btn {
  display: flex;
  justify-content: flex-end;
  color: #0068ff;
  font-size: 13px;
  margin: 15px 15px 0px 0px;
}

.notificationPage-folder .notifications-folder {
  padding: 17px;
}

.notificationPage-folder .notifications-folder .notifications-inner-folder p {
  font-size: 13px;
  font-weight: 500;
}

.notification-time-folder span {
  font-size: 12px;
  font-weight: 500;
}

.invited_bg {
  background-color: #d3efff;
  color: #156897;
}

.enrolled_bg {
  background-color: #e6ffe5;
  color: #28991f;
}

#courseDate .form-control,
.edit-Course-inner-input .form-control {
  background-color: #f5f5f5;
  border: 1px solid #00000040 !important;
  border-radius: 12px;
  color: rgba(0, 0, 0, .92);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: .00938em;
  line-height: 1.4375em;
  text-transform: capitalize;
}

.filter-popup {
  right: 142px;
}

.studentDashboardSidePannel .dashboardlink.active {
  align-self: center;
  background-color: #cfe5fb;
  border-radius: 36px;
  color: #0068ff;
  display: flex;
  font-size: 16px;
  gap: 10px;
  padding: 10px 42px;
}

.studentDashboardSidePannel .dashboardlink {
  align-items: center;
  background-color: #f5f9ff;
  border: none;
  border-radius: 36px;
  color: grey;
  display: flex;
  font-size: 15px;
  gap: 10px;
  padding: 10px 20px;
}

.overview-lesson-mark-complete-btn .status-disabled-button {
  /* border: 2px solid #2bcc00; */
  border: 1px solid #2bcc00;
  color: #2bcc00;
}

.dashboard-popup-teacher-profile-details-input label:after,
.introduce-yourself-title:after {
  color: red;
  content: "*";
  margin-left: 2px;
}
.dashboard-popup-teacher-profile-details-input:last-child label:after{
  content: "";
}

/* width */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.studentDashboard-universal-chatbot-inner-chat::-webkit-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Student-Active-main-wrapper {
  margin: 25px 0px 0px 0px;
}
/* Trial Code dbi */

.delete-course-form-container p {
  font-size: 22px;
  color: black;
  margin-bottom: 18px;
}
  /* font-size: 19px;
} */

.overview-lesson-mark-complete-btn .status-disabled-button-assign {
  border: 2px solid #828282;
  color: #828282;
}
.Create-Course-image-disclamer span {
  min-width: 361px !important;
}

#studentCourseCard-folder span, 
.course-price{
  font-size: 19px;
  /* color: #EE7139; */
  font-weight: 600;
}
.Marketplace-btn {
  display: flex;
  align-items: center;
  gap: 1px;
  border: 1px solid #0068FF;
  justify-content: center;
  padding: 6px 0px;
  border-radius: 34px;
  /* transform: translateY(109px); */
  margin: 150px 0px 0px 0px;
}
.Marketplace-btn button{
  background-color: transparent;
  font-size: 14px;
  border: none;
  color: #0068FF;
  font-weight: 600;
}
.Marketplace-btn svg{
  width: 20px;
}
.Purchases_course {
  margin-top: -15px;
  text-align: center;
}
#assignment-stats-section .LessonViewProgress-header-section {
  display: flex;
  flex-direction: column;
}
.profile_plus_icon{
  position: absolute;
  bottom: 0px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.profile_plus_icon span {
  background-color: #0068ff;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 13px;
  padding-top: 3.9px;
}
.enroll-container{
  display: flex;
  gap: 8px;
  align-items: baseline;
}
.Enrolled-btn .MuiChip-root .MuiChip-label{
  /* font-size: 13px !important;
  padding: 4px 17px !important; */
  font-size: 11px !important;
  padding: 1px 9px !important;
  font-weight: 600;
}
.Enrolled-btn .css-gavykb-MuiChip-root {
  height: 27px !important;
}
.Switch_tab_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
.teacehr-tab-setion {
  position: relative;
  padding: 10px 0px 0px 0px;
}
.teacehr-tab-setion .Dashboard-List-Table-view-all {
  position: absolute;
  top: 9px;
  right: 0%;
  font-size: 14px;
  color: #0068FF;
}
.view-course-details-edit-and-share-folder:focus, 
#grade:focus, 
#month:focus, .add-profile-close-btn:focus, 
.studentDashboar-course-filter:focus, 
.close-btn:focus, .introduce-yourself-text:focus, 
.dashboard-popup-teacher-profile-details-input select:focus{
  border: 1px solid black;
}
.Invited_students_btn:focus, 
.Preview-lesson-btn:focus, 
.list-of-students-wrappper table tbody tr td a:focus, 
.Preview-lesson-btn:focus,.preview-assignments-inner-folder button:focus, 
.button_tab_edit_btn:focus, 
.view_course_sec:focus{
  border: 1px solid #0068ff;
}

.Lesson_Stats_name-wrapper , 
.assignment_Stats_name_wrapper{
  width: 32%;
}
.lesson_name, 
.assignment_name {
  width: 301px;
  word-wrap: break-word;
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;  
}
.Lesson_Stats_name, 
.assignment_Stats_name {
  display: flex;
  gap: 10px;
}
.assignment_analytics_question{
  display: flex;
  text-align: left;
  word-wrap: break-word;
  width: 339px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Custom_Tabs_section a.active {
  display: flex;
  gap: 10px;
  padding: 10px 42px;
  border-radius: 36px;
  background-color: #cfe5fb;
  font-size: 16px;
  align-self: center;
  color: #0068ff;
}
.Custom_Tabs_section a {
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 36px;
  background-color: #f5f9ff;
  border: none;
  font-size: 15px;
  align-items: center;
  color: black;
}
#Student_Review_Table_Data tbody tr td:nth-child(2){
  cursor: pointer;
}

.studentdashboardHeaderProfileNameInitial {
  /* flex: 0 0 45px; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}
.student-sortby-popup-wrapper .student-shortby-popup .sort-popup-assignprocess {
  top: 191px;
}
.set_passcode_Show_icon {
  position: absolute;
  top: 54%;
  right: 3%;
}
.set_passcode_Show_icon .fa-eye{
  display: none;
}
.AssignmentViewProgress-filter-popup-wrapper .sort-popup-assignprocess, 
.lessonViewProgress-filter-popup-wrapper .sort-popup-assignprocess {
  top: 20px;
}
.course-sortby-popup-wrapper .sort-popup-assignprocess {
  top: 129px;
}
.pagination_top_wrapper .MuiGrid-root {
  display: flex;
  justify-content: flex-end;
}
#courseDate .MuiInputBase-input, 
.Course-Start-Date .MuiInputBase-input{
  padding: 10px 0px !important;
}
#courseDate fieldset {
  border-color: #ECECEC;
  border: 1px solid #ECECEC !important;
}

/* set_passcode start */
.set_passcode_eyes_btn {
  position: absolute;
  top: 50px;
  right: 13px;
  transform: translateY(-50%);
  font-size: 11px;
}
.set_passcode_eyes_btn svg{
  width: 20px;
}
/* set_passcode end */

.add-cart {
  font-size: 19px;
  position: relative;
}
.loading-text {
  font-size: 15px;
  text-align: center;
  color: black;
  margin: 20px 0px;
}
.loading-gif .css-pqfhys-MuiGrid-root {
  height: 8vh !important;
}
.loading-gif {
  /* margin: 50px 0px 0px 0px; */
  margin: 50px auto 0px auto;
}
.add-cart span {
  position: absolute;
  top: -10px;
  left: 10px;
  /* height: 21px; */
  height: 25px;
  /* width: 21px; */
  width: 25px;
  background-color: #0068ff;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.profile_linkedin a{
  color: #0076B2;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.profile_linkedin {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-left: 0px;
  margin: 10px 0px 0px 1px;
}
.profile_linkedin input {
  font-size: 14px;
  padding: 5px 10px 5px 11px;
  font-size: 12px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
  width: 500px;
}
.about_teach_main_profile {
  border-bottom: 1px solid #E8E8E8;
  padding: 0px 0px 10px 0px;
}
.about_teach_main_profile .about_teach_sec_new{
  padding: 0px;
  border: none;
}
.profile_linkedin_image img{
  width: 22px;
}
.assignment_loading_text{
  margin: 20px 0px 0px 0px;
}
.s3_file_uploade_failed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  height: 79%;
  justify-content: center;
  font-size: 12px;
  color: red;
}
/* .Your-feedback-appreciated-popup {
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
} */
.Your-feedback-appreciated-container {
  /* width: 31%; */
  padding: 31px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Your-feedback-appreciated-inner-folder {
    display: flex;
    align-items: baseline;
    gap: 11px;
}
.Your-feedback-appreciated-inner-folder {
  display: flex;
  align-items: center;
  gap: 11px;
  margin: 0px 0px 20px 0px;
}
.Your-feedback-appreciated-inner-folder h2 {
  font-size: 19px;
  margin: 0px;
  font-weight: 500;
}
.Your-feedback-appreciated-container button{
  width: 100%;
  background-color: #0068FF;
  font-size: 15px;
  color: white;
  padding: 7px 0px;
  border: none;
  border-radius: 5px;
}
/* .Your-feedback-appreciated-popup-wrapper{
  position: absolute;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
  background-color: #00000040;
  width: 100%;
  height: 100%;
  z-index: 113;
} */
.Your-feedback-appreciated-image{
  width: 26px;
}
.Congratulations-assignment-btns {
  display: flex;
  justify-content: center;
  gap: 11px;
}
.practice-assignment {
  display: flex;
  align-items: center;
}
.practice-assignment span{
  padding: 7px 8px;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 1px 0px 3px #d0d0d0ad;
  font-size: 13px;
  color: #0068FF;
  font-weight: 600;
}
.Your-feedback-appreciated-popup, 
.assignment-rating-popup, .Cancel-Popup{
  border-radius: 10px !important;
  box-shadow: white !important;
}
.assignment-rating-popup{
  padding: 0rem;
}
.Course-created-successfully-folder {
  /* padding: 31px; */
  text-align: center;
}
/* .linkedin-inner-input {
  margin: 10px 0px 0px 0px;
}

.linkedin-inner-input .dashboard-popup-teacher-profile-details-input {
  width: 100%;
}

.linkedin-inner-input .dashboard-popup-teacher-profile-details-input .form-icon {
  position: absolute;
  left: 1.6%;
} */
.dashboard-popup-teacher-profile-details-input:last-child label:after{
  content: "";
}
.play-pause-btn-Overview-lesson-generator .fa-circle-play, 
.play-pause-btn-Overview-lesson-generator .fa-circle-play {
  font-size: 28px;
}
.Overview-Lesson-ai-voice-animation-folder {
  display: flex;
  align-items: center;
  gap: 23px;
  position: relative;
  z-index: 113;
}
.created-my-courses-title-wrapper .studentDashboar-Suggested-Courses-profile-rate a{
  color: #ffbb33;
}
.ratingDetails-wrapper .course-inner-info-without-image .course-inner-info-without-image{
  display: block;
}
.ratingDetails-wrapper .course-inner-info-without-image .course-inner-info-without-image img{
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.right_chat_sec-profile {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #D7D7D7;
  margin-bottom: 15px;
}
.right_chat_sec-profile i{
  display: none;
}
.right_chat_sec-profile i{
  padding-bottom: 6px;
}
h2.AssingmentMCQs-mock-test-title {
  text-align: left;
  margin: 0px 0px 10px 0px;
  /* font-size: 13px; */
  font-size: 12px;
}
.AssingmentMCQs-mock-test-Ai-feedback-answer {
  background-color: #F5F5F5;
  /* padding: 20px; */
  padding: 8px;
  overflow-y: scroll;
  /* height: 116px; */
  height: 85px;
}
.AssingmentMCQs-mock-test-Ai-feedback-answer p{
  color: black;
  line-height: 18px;
  font-size: 12px !important;
}

.msg_sec_new_body_inner_left,
.msg_sec_new_body_inner_right {
    display: block;
}
.view-lessons-created-course-inner-preview-btn-folder i,
.preview-assignments-inner-folder i {
  color: #0068ff;
  cursor: pointer;
}

.view-lessons-created-course-inner-preview-btn-folder i:hover,
.preview-assignments-inner-folder i:hover {
  color: #f0183d;
}
.subscription-plan-Pricing {
  /* display: flex;
  align-items: flex-end;
  gap: 7px; */
  display: flex;
  /* align-items: flex-end; */
  align-items: baseline;
  gap: 7px;
  border: none;
  background-color: #0068FF;
  /* color: white; */
  padding: 9px 19px;
  border-radius: 31px;
}
.subscription-plan-Pricing p{
  /* font-size: 15px; */
  font-size: 13px;
  color: white;
}
.chat-with-ai-lession-user-sned-massege-container .css-1sa9xwe {
  height: auto;
}
.AssingmentMCQs-mock-test-question-answer-container h2 span{
  /* display: flex; */
}
.AssingmentMCQs-answer-title {
  margin: 0px 0px 10px 0px;
  /* font-size: 15px; */
  font-size: 13px  !important;
  color: #28991F !important;
  background-color: #e6ffe5;
  padding: 5px 10px;
  width: 139px;
  border-radius: 5px;
}
.AssignmentViewProgress-mock-test-marks-obtained {
  display: flex;
  /* align-items: center; */
  margin: 0px auto;
  gap: 142px; 
}
.AssignmentViewProgress-mock-test-status-duration-folder {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 12px;
}
.AssignmentViewProgress-mock-test-status-duration-folder p{
  /* font-size: 14px !important; */
  font-size: 12px !important;
  color: #807f7f !important;
}
.AssignmentViewProgress-mock-test-status-duration-folder span{
  font-size: 13px;
}
.AssingmentMCQs-question {
  text-align: center;
  display: flex;
  justify-content: center;
}
.AssingmentMCQs-answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.AssingmentMCQs-answer .fa-check, 
.AssingmentMCQs-answer .fa-x{
  color: #00BC14;
  font-size: 14px;
}
.AssingmentMCQs-answer .fa-x{
  color: #FF0000;
}

.AssingmentMCQs-wrong-answer-title {
  margin: 0px 0px 10px 0px;
  /* font-size: 15px; */
  font-size: 13px;
  background: #ffd6d6;
  color: #b24747 !important;
  padding: 5px 10px;
  /* border-radius: 35px; */
  border-radius: 5px;
  width: 140px;
}
.AssingmentMCQs-question-title{
  width: 87%;
}
.AssingmentMCQs-answer-title-teacher{
  margin: 0px 0px 10px 0px;
  /* font-size: 15px; */
  font-size: 13px !important;
  color: #28991F !important;
  background-color: #e6ffe5 !important;
  padding: 5px 10px;
  width: 200px;
  border-radius: 5px;
}
.AssingmentMCQs-wrong-answer-title-teacher {
  margin: 0px 0px 10px 0px;
  /* font-size: 15px; */
  font-size: 13px !important;
  background: #ffd6d6;
  color: #b24747 !important;
  padding: 5px 10px;
  /* border-radius: 35px; */
  border-radius: 5px;
  width: 200px;
}
.AssingmentMCQs-question {
  margin-right: 5px;
}
.filter-teacher-type-folder {
  display: flex;
  align-items: center;
  gap: 12px;
}
.filter-teacher-type-folder p{
  font-size: 13px;
  color: black;
}
.filter-teacher-type-folder select {
  padding: 5px 13px;
  font-size: 13px;
  border: 1px solid;
  border-radius: 4px;
}
.AssingmentMCQs-question {
  width: 5%;
}
span.AssingmentMCQs-answers {
  width: 95%;
}
.header-book-demo {
  font-size: 15px;
  font-weight: 500;
  border-radius: 50px;
  border: 1.7px solid #0068ff;
  padding: 9px 35px;
  background: white;
  color: #0068ff;
  transition: box-shadow 0.3s ease;
}
.header-book-demo:focus {
  border: 2px solid #0068ff;
}
.footer-link-folder p{
  font-size: 15px;
  max-width: 459px;
  color: black;
}
.disscount-course-price-folder {
  display: flex;
  align-items: center;
  gap: 10px;
}
.disscount-course-price-folder p{
  font-size: 14px;
  text-decoration: line-through;
}
.card-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.gray_house_teacher_cont_sec_new .disscount-course-price-folder{
  margin: 10px 0px;
}
#StudentActiveLesson-section .view-course-Details-back-btn-folder .Back_button, 
.Overview-Lesson-top-header .Back_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgb(0, 104, 255);
  margin-bottom: 12px;
}
#StudentActiveLesson-section .view-course-Details-back-btn-folder .Back_button span , 
.Overview-Lesson-top-header .Back_button span{
  padding: 0px 5px;
}
.card-course-price {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0px 0px 10px 0px;
}
.card-course-price .course-price{
  margin: 0px 0px !important;
  font-size: 13px;
}
.course-cut-price{
  margin: 0px 0px !important;
  font-size: 13px;
  text-decoration: line-through;
  color: #A19B99 !important;
}
.card-course-price span{
  font-size: 16px;
  color: #EE7139;
  font-weight: 600;
}
.edit-course-image-uplode-btn-folder .reuplode-image-folder .Regenerate-Course-image .Regenerate-course-btn {
  padding: 7px 15px;
}
.no-lessons-available{
  text-align: center;
  color: black;
  font-size: 14px;
}
.assignments_count_details{
  font-size: 14px;
  color: #0068ff;
  cursor: pointer;
  font-weight: 500;
}
.assignments_count_details img{
  width: 17px;
}
.monetize-course-input-toggle{
  width: 50%;
}
.monetize-course-input-toggle-folder{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #DEDEDE;
  padding: 8px 13px;
  border-radius: 5px;
  margin: 5px 0px 0px 0px;
}
.monetize-course-input-toggle p{
  font-size: 13px;
  color: black;
}
.monetize-course-input-toggle label{
  font-size: 13px;
  font-weight: 500;
}
.button_tab_wrapper .fa-solid.fa-trash {
  color: #0068ff;
  cursor: pointer;
}
#AssignmentViewProgress-section{
  padding: 0px 60px;
}
.lesson-assignment-analyticsTab-wrapper #AssignmentViewProgress-section{
  padding: 0px;
}
.lesson-assignment-analyticsTab-wrapper #AssignmentViewProgress-section .view-course-Details-wrapper .LessonViewProgress-header-section{
  display: none;
}
.empower_sec_new_inner .header-book-demo{
  display: none;
}
.current-plan-title {
  text-align: left;
}
.set_passcode_sec_single_eye{
  position: absolute;
  top: 50px;
  right: 19px;
}
.moving-loader{
  text-align: center;
}
/* .disscount-course-price-folder p::after {
  content: "";
  position: absolute;
  top: 9px;
  left: -4px;
  width: 34px;
  height: 1px;
  background-color: #909090;
} */