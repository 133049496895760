.ai-student-assessment-container{
    margin: 40px 0px 0px 0px;
}
button.teacher-description-container-btn {
    color: #0068ff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: transparent;
    float: right;
    clear: both;
  }
  
  .teacher-description-container-head {
    font-size: 16px;
    font-weight: 600;
  }
  
  .teacher-description-container-head img {
    margin-right: 10px;
    width: 23px;
}
  
  .teacher-description-wrapper {
    margin-bottom: 20px;
  }
  
  .teacher-description-container {
    border-radius: 20px;
    background: #e9f2ff;
    padding: 25px 20px;
  }
  
  p.teacher-description-container-p {
    font-size: 13px;
    color: black;
    margin-bottom: 15px;
  }
  .ai-assessment-headre {
    display: flex;
    justify-content: space-between;
}
.ai-assessment-regenerate-btn {
  gap: 6px;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
}
.ai-assessment-regenerate-btn span{
  font-size: 13px;
  }
  
  .ai-assessment-regenerate-img {
    width: 19px;
}

  @media only screen and (max-width: 767px){
    .teacher-description-container-head {
        /* font-size: 14px; */
        font-size: 11px;
        font-weight: 600;
    }
    .teacher-description-container {
        border-radius: 20px;
        background: #e9f2ff;
        padding: 13px 15px 25px 15px;
    }
    .teacher-description-container-p {
        font-size: 12px;
    }
    .ai-student-assessment-container {
        margin: 25px 0px 0px 0px;
    }
    .teacher-description-container-head img {
      margin-right: -1px;
    }
    .ai-assessment-regenerate-btn span {
      font-size: 11px;
    }
  }

  @media (min-width: 280px) and (max-width: 320px) {
    .ai-assessment-headre {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    .ai-assessment-regenerate-btn {
      gap: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }