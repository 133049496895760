.error-page-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px){
    .error-page-image {
        width: 333px;
        margin: 0px auto;
    }
    .error-page-text {
        margin: -65px 0px 0px 0px;
    }
    .error-page-text h1 {
        font-size: 58px;
    }
    .error-page-text p {
        font-size: 18px;
    }
    .error-page-text button {
        font-size: 12px;
    }
}