.dropdown-container {
  position: relative;
  width: 64px;
}

.dropdown-tiles {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.dropdown-list-container {
  position: absolute;
  left: 12px;
  width: 68%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  margin: 10px 0px 0px 0px;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-list-item:hover {
  background-color: #f0f0f0;
}

.dropdown-image {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.selected-image {
  width: 25px;
  height: 25px;
}

.dropdown-arrow {
  font-size: 10px;
}