.google-drive-popup-container {
    /* border: 1px solid; */
    padding: 18px;
    box-shadow: 0px 2px 8px #dbdbdb;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .google-drive-popup-folder {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .google-drive-image {
    width: 19px;
    height: 21px;
  }
  .google-drive-image img{
    width: 100%;
  }
  .google-drive-popup-folder p{
    color: black;
    font-size: 13px;
  }
  .google-drive-popup-wrapper {
    position: absolute;
    /* top: -118px; */
    top: -77px;
    z-index: 113;
  }

  @media only screen and (max-width: 767px){
    .google-uplode-popup-wrapper .select-Files-allow-download-inner-popup-folder {
      padding: 30px 18px 30px 18px;
    }
    .select-Files-allow-download-inner-popup-folder .upload-files-container{
      padding: 12px;
      max-width: 257px;
    }
    .select-Files-allow-download-popup-container {
      max-width: 306px;
    }
  }