.datetime {
    width: 100%;
}

.datetime-icon {
    padding-bottom: 12px;
}

.datetime-error {
    border: 1px solid red;
    border-radius: 6px;
    width: 100%;
}

.rdtPicker thead tr:first-of-type th{
    font-size: 11px;
}
.rdtPicker .dow{
    font-size: 11px;
}
.rdtPicker td{
    font-size: 12px;
    height: 26px;
}
.rdtPrev span, .rdtNext span{
    font-size: 25px;
}