.practice-assignment-payment-section .paymentStripe-course-folder {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: auto;
    padding: 15px 4px 15px 0px;
    border-bottom: 1px dashed;
}
.practice-assignment-payment-section .paymentStripe-section .Back_button{
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgb(0, 104, 255);
    margin-bottom: 26px;
}
.practice-assignment-payment-section .paymentStripe-section .Back_button span{
    padding: 0px 5px;
}

@media only screen and (max-width: 767px){
    .benefits-practice-assignments-feature-title, 
    .benefits-practice-assignments-inner-feature-folder h2 , 
    .practice-assignment-popup h2 span  {
        font-size: 12px !important;
    }
}