.table-container {
    width: 100%;
    border-collapse: collapse;
  }
.table-container th,
.table-container td {
    text-align: left;
    padding: 16px;
    /* border-bottom: 1px solid #eee; */
    text-align: center;
    font-size: 13px;
}
.table-row {
    background-color: #F5FBFF;
    box-shadow: 0px 2px 5px #e1e1e187;
}
.header-cell {
    font-weight: bold;
}
.checkbox-cell {
    width: 50px; /* Adjust width as necessary */
}

.name-cell {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    background-color: #f56a00;
    border-radius: 50%;
    margin-right: 16px;
}

.action-cell {
    cursor: pointer;
    color: #1890ff;
    text-align: center;
}

.dashboard-name-title {
    margin-bottom: 20px;
}
#Student_Review_Table_Data thead > tr > th input {
    width: 14px;
    height: 14px;
}
.header-filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.filter_inner_folder {
    display: flex;
    align-items: center;
    gap: 8px;
}
.filter_inner_folder p {
    font-size: 14px;
    color: black;
}
#grade, 
#month {
    padding: 3px 60px 3px 3px;
    font-size: 13px;
}
.header_inner_wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
#grade, #month {
    font-size: 13px;
    padding: 3px 60px 3px 9px;
    height: 26px;
    -webkit-appearance: none;
    appearance: none;
    background-color: initial;
    background-image: url('../../assets/images/down_arrow.svg');
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 12px;
}