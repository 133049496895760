.mic {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: white;
    color: #fff;
    font-size: 31px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.25s;
    position: absolute;
    bottom: 16px;
    right: 51px;
    box-shadow: 0px 1px 5px #c2c2c2;
}
  
  .mic::before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: inherit;
    z-index: 0;
  }
  
  .listening {
    background-color: #0068ff;
  }
  .listening::before {
    animation: listening 1.3s infinite;
  }
  .mic-btn i{
    position: absolute;
    left: 8px !important;
    z-index: 11;
    top: 7px !important;
    font-size: 14px !important;
    border-right: 0px !important;
    padding-right: 0px !important;
  }
  .boxContainer {
    display: flex;
    justify-content: space-between;
    height: 16px;
    --boxSize: 8px;
    --gutter: 4px;
    width: 24px;
    margin-left: -1px;
}
.box {
    transform: scaleY(.4);
    height: 100%;
    width: 4px;
    background: white;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}
#mic-btn:focus {
    box-shadow: 0px 1px 5px #c2c2c2;
}
.hidden {
  display: none;
}
  
  @keyframes listening {
    from {
      opacity: 0.25;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  

  @keyframes waveAnimation {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  .wave {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0,123,255,0.5); /* Adjust color to fit your design */
    animation: waveAnimation 2s infinite ease-out;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }