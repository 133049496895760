.add-to-cart-wrapper {
    display: flex;
    width: 100%;
    gap: 29px;
}
.add-to-cart-left-section {
    width: 73%;
    /* border: 1px solid; */
}
.add-to-cart-card-wrapper {
    padding: 19px;
    background-color: white;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}
.add-to-cart-card-wrapper h2{
    font-size: 25px;
    margin: 6px 0px 10px 0px;
    word-wrap: break-word;
    width: 740px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.add-to-cart-card-wrapper span{
    font-size: 14px;
    font-weight: 600;
}
.des{
    font-size: 13px;
    color: black;
    margin: 10px 0px 21px 0px;
    line-height: 22px;
}
.add-to-cart-rating-wrapper {
    display: flex;
    align-items: center;
    gap: 26px;
    margin: 0px 0px 30px 0px;
}
.add-to-cart-rating {
    display: flex;
    align-items: center;
    gap: 9px;
}
.add-to-cart-rating span{
    font-size: 13px;
    color: #FAAF19;
}
p.ratting-numbers {
    color: #898989;
    font-size: 13px;
}
.add-to-cart-rating-wrapper {
    font-size: 13px;
    color: black;
    font-weight: 600;
}
.add-to-cart-rating-wrapper button{
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: #0068FF;
    font-weight: 500;
    text-decoration: underline;
}
.add-to-cart-right-section {
    width: 25%;
}
.add-to-cart-card {
    text-align: center;
    background-color: white;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}
.add-to-cart-card-iamge{
    width: 100%;
    height: 261px;
}
.add-to-cart-card-iamge img {
    width: 100%;
    height: 100%;
}
.add-to-cart-card h2{
    margin: 20px 0px;
    font-size: 29px;
}
.add-to-cart-card-btn-folder {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.add-to-cart-card-btn-folder button {
    background-color: #0068FF;
    color: white;
    font-size: 12px;
    padding: 9px 0px;
    border: none;
}
.add-to-cart-card-btn-folder button:nth-child(2){
    background-color: transparent;
    color: black;
    border: 2px solid;
    font-weight: 500;
}
.course-includes {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: left;
}
.course-includes li{
    font-size: 14px;
    margin-left: 27px;
    list-style: disc;
}
.course-includes h2{
    font-size: 17px;
    margin: 20px 0px 0px 0px;
}
.add-to-cart-card-des-wrapper {
    padding: 0px 20px 20px 20px;
}

@media only screen and (max-width: 767px){
    .add-to-cart-wrapper {
        margin: 15px 0px 0px 0px;
        display: flex;
        flex-direction: column-reverse;
    }
    .course-includes li {
        font-size: 13px;
    }
    .add-to-cart-card h2 {
        font-size: 27px;
    }
    .add-to-cart-left-section, 
    .add-to-cart-right-section {
        width: 100%;
    }
    .add-to-cart-card-wrapper span {
        font-size: 12px;
    }
    .des {
        font-size: 12px;
    }
    .add-to-cart-rating-wrapper button, 
    .ratting-numbers {
        font-size: 12px;
    }
    .add-to-cart-card-wrapper h2, 
    .add-to-cart-card h2 {
        font-size: 17px;
        width: 100%;
    }
    .add-to-cart-rating-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 26px;
        margin: 0px 0px 30px 0px;
    }
    .add-to-cart-profile-section .view-course-details-container {
        margin: 13px 0px 12px 0px;
        display: flex;
        flex-wrap: nowrap;
        gap: 11px;
    }
    .add-to-cart-profile-section .view-Students-course-details-counts {
        padding: 6px 29px;
        margin: 0px 0px 0px 0px;
    }
}