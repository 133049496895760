
    .chat-footer {
        padding: 20px;
        border-top: 1px solid #ccc;
    }
    .form-control {
        border-radius: 20px;
    }
    .send-button {
        background-color: #0068FF;
        color: #fff;
        border: none;
        border-radius: 20px;
        padding: 8px 15px;
        cursor: pointer;
        transition: background-color 0.3s;
    }
  
    
    