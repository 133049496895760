
.container-fluid{padding-left: 60px;padding-right: 60px;}

.settings_main_sec{position: relative;padding: 0 15px; text-align: left;}
.settings_main_sec_head{position: relative;background: #F5FBFF;border-radius: 50px;padding: 25px 30px;}
.settings_main_sec_head ul{position: relative;display: flex; align-items: center;justify-content: center;gap: 50px;}
.settings_main_sec_head ul li a{position: relative;color: #000;font-size: 16px;font-weight: 500;padding: 10px 40px;border-radius: 30px;}
.settings_main_sec_head ul li a.active{background: #CFE5FB; color: #0068FF;}

.account_setting_tab_sec{position: relative; 
    background: #F5F9FF;
    padding: 25px 25px;
    border-radius: 20px;
    margin-top: 20px;
}
.account_setting_tab_sec_head{position: relative;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #E8E8E8;padding-bottom: 20px; margin-bottom: 20px;}

.edit_btn_set {
    position: relative;
    display: inline-block;
    border: 2px solid #6d6d6d;
    border-radius: 6px;
    padding: 9px 28px;
    color: #000;
    font-size: 13px;
    background-color: transparent;
    /* margin-right: 5px; */
}
.edit_btn_set i{
    margin-right: 5px;
}
.edit_btn_set:focus{
    border: 1px solid black;
}
.save_btn_set{position: relative;display: inline-block;border: none;border-radius: 6px;padding: 9px 28px;background-color: #0068FF; color: #fff; font-size: 13px;}
.account_setting_tab_sec_head h6{position: relative;color: #000000;font-size: 14px;font-weight: 500;margin-bottom: 0px;}

.about_teach_sec_new{position: relative;padding: 25px 0;border-bottom: 1px solid #E8E8E8;display: flex;align-items: center;gap:30px;}
.about_teach_sec_new_left{position: relative;width: 130px;}
.about_left_pic{position: relative;width: 130px;}
.about_left_pic img{width: 110px;
    height: 105px;
    object-fit: cover;
    border-radius: 100%;}
.about_left_pic_edit {position: absolute;width: 24px;height: 24px;box-shadow: 0px 4px 4px 0px #0000001A;background: #fff;
    display: flex;align-items: center;justify-content: center;border-radius: 50%;right: 5px;bottom: 10px;}
.about_left_pic_edit img{width: 14px;}
.about_teach_sec_new_left h5{position: relative;margin: 14px 0 0 0px;color: #000000;font-weight: 500;font-size: 14px; text-align: center;}

.about_teach_sec_new_right{position: relative;width: 100%;text-align: left;}
.about_teach_sec_new_right h4{position: relative;font-size: 18px;margin: 0 0 15px 0;color: #000000;}
.about_teach_sec_new_right h4::after{  content: "*";
    color: red;
    margin-left:2px}
.about_teach_sec_new_right textarea{position: relative;width: 100%;border: 1px solid #E8E8E8;border-radius: 10px;font-size: 13px;color: #000000;font-weight: 400;padding: 15px;}

.subject_special{position: relative;padding: 25px 0;border-bottom: 1px solid #E8E8E8;text-align: left;}
.subject_special h5{position: relative;font-size: 14px;font-weight: 500;margin-bottom: 17px;}

.sub_special{position: relative;display: block;}
.sub_special li{display: inline-block;margin-right: 15px;background: #D1EEFF;color: #000000;font-size: 12px;font-weight: 400;border-radius: 20px; padding: 7px 26px; text-transform: capitalize; margin-bottom: 15px;}

.form_sec_settings{position: relative;padding: 0px 0 70px 0;border-bottom: 1px solid #E8E8E8;}
.form_sec_settings_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 26px; text-align: left;}
.form_sec_settings_single{position: relative;}
.form_sec_settings_single label{position: relative;display: block;color: #000;font-size: 13px;font-weight: 400;margin-bottom: 10px;}
.form_sec_settings_single_box{position: relative;}
.form_sec_settings_single_box input{position: relative;width: 100%;height: 40px;font-size: 13px;font-weight: 400;border: 1px solid #E7E7E7;background: #fff;border-radius: 4px;padding-left: 50px;color: #000;}
.form_sec_settings_single_box select{position: relative;width: 100%;height: 45px;font-size: 14px;font-weight: 400;border: 1px solid #E7E7E7;background: #fff;border-radius: 4px;padding-left: 50px;color: #000;}
.form_sec_settings_single_box img{position: absolute;left: 15px;top: 10px;display: flex;align-items: center;z-index: 11;
    width: 24px;bottom: 0;}

.form_sec_settings_single_box input::-webkit-input-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.form_sec_settings_single_box input::-moz-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.form_sec_settings_single_box input::-ms-input-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.form_sec_settings_single_box input::-moz-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}

.linked_accounts_sec{position: relative;padding: 25px 0 0 0; text-align: left;}
.linked_accounts_sec h6{position: relative;color: #000;font-size: 19px;font-weight: 500;margin-bottom: 30px;}
.linked_accounts_sec_single{position: relative;display: flex;align-items: center;gap:20px;margin-bottom: 30px;}
.linked_accounts_sec_single:last-child(){margin-bottom: 0px;}
.linked_accounts_sec_single img{width: 24px;}
.linked_accounts_sec_single p{position: relative;margin-bottom: 0px;font-size: 14px;font-weight: 400;color: #000;}
.linked_accounts_sec_single button{position: relative; display: inline-block;border: 1px solid #0068FF;
    color: #0068FF;border-radius: 6px; padding: 8px 24px;background: none;font-weight: 500;font-size: 12px;}

.cust_inst_single{position: relative;padding-top: 30px;margin-bottom:0px;}    
.cust_inst_single label{position: relative;color: #515151;font-weight: 400;font-size: 18px;margin-bottom: 10px;}
.cust_inst_single textarea{position: relative;width: 100%;padding: 12px;border: 1px solid #BEBEBE;border-radius: 20px;}
.cust_inst_single span{position: relative;color: #515151;font-weight: 400;font-size: 16px;margin-bottom: 10px;}

.save_btn_blue_sec{position: relative;text-align: center;margin: 15px 0;}
.save_btn_blue{position: relative;display: inline-block;color: #FFFFFF;font-size: 13px;font-weight: 500;border: 2px solid #0068FF; background: #0068FF;padding: 6px 40px;border-radius: 6px;}
.reset_password_body{position: relative;margin: 20px 0 0 0;}
.reset_password_body_single{position: relative;margin-bottom: 25px;}
.reset_password_body_single label{position: relative;display: block;color: #000;font-size: 13px;font-weight: 400;margin-bottom: 10px;}
.reset_password_body_single label::after{
    content: "*";
  color: red;
  margin-left:2px
}
.reset_password_body_single input{position: relative;width: 420px;height: 40px;border: 1px solid #E7E7E7;border-radius: 4px;font-weight: 300;color: #000000;font-weight: 300;padding-left: 15px; font-size: 13px;}

.reset_password_body_single input::-webkit-input-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.reset_password_body_single input::-moz-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.reset_password_body_single input::-ms-input-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.reset_password_body_single input::-moz-placeholder {color: #000 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}

.error-text{position: relative;color: red;font-size: 13px;font-weight: 400;margin-top: 5px;}
.reset_pass{position: relative;margin-top: 15px;}
.reset_pass button{position: relative;display: inline-block;border: 2px solid #0068FF;background: #0068FF;
    color: #FFFFFF;border-radius: 6px;padding: 9px 30px; font-size: 13px;}
.password_req{position: relative;width: 100%;margin-top: 40px;margin-bottom: 20px;}
.password_req ul{display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;}
.password_req ul li{display: inline-block;font-size: 13px;font-weight: 400;color: #000;position: relative;padding-left: 30px;}
.password_req ul li:after{position: absolute;content: '';background: url(../../../assets/images/check-icn.png);height: 17px;
    width: 17px; left: 0;}

.security_alerts_sec_new{position: relative;padding-top:30px;}   
.security_alerts_sec_new_single{position: relative;display: flex;align-items: center;justify-content: space-between;margin-bottom: 30px;} 
.security_alerts_sec_new_single:last-child{margin-bottom: 0px;}
.security_alerts_sec_new_single_left{position: relative;}
.security_alerts_sec_new_single_left h6{position: relative;color: #000;font-weight: 500;font-size: 16px;margin-bottom: 5px;}
.security_alerts_sec_new_single_left p{position: relative;margin-bottom: 0px;font-size: 13px;font-weight: 300;color: #000;}

.security_alerts_sec_new_single_right{position: relative;}

.security_alerts_sec_new_single_right .switch {position: relative;display: inline-block; width: 48px; height: 28px;}
.security_alerts_sec_new_single_right .switch input {  opacity: 0; width: 0; height: 0;}
.security_alerts_sec_new_single_right .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0;background-color: #ccc;-webkit-transition: .4s; transition: .4s;}
.security_alerts_sec_new_single_right .slider:before {position: absolute; content: ""; height: 20px; width: 20px; left: 4px; bottom: 4px; background-color: white;
    -webkit-transition: .4s;transition: .4s;  }
.security_alerts_sec_new_single_right input:checked + .slider { background-color: #2196F3; } 
.security_alerts_sec_new_single_right input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
.security_alerts_sec_new_single_right input:checked + .slider:before {-webkit-transform: translateX(20px);-ms-transform: translateX(20px);transform: translateX(20px);}
.security_alerts_sec_new_single_right .slider.round {border-radius: 34px;}
.security_alerts_sec_new_single_right .slider.round:before {border-radius: 50%;}
.reset_password_body_inner{width: 450px;}
.eye_sec {position: absolute;right: 45px;top: 38px;}
.eye_sec i{color: #A19A9A;}

.msg_sec_new{position: relative; text-align: left;}
.msg_sec_new_head{position: relative;margin-bottom: 20px;}
.msg_sec{position: relative;font-size: 23px;color: #000;font-weight: 600; cursor: pointer;}
.msg_sec i{padding-right: 10px;font-size: 22px;}
.msg_sec_new_body_inner{position: relative;display: grid;grid-template-columns: 1fr 3fr;grid-gap: 35px; height: 100vh;}
.msg_sec_new_body_inner_left{position: relative;box-shadow: 0px 0px 4px 0.10000000149011612px #00000040;background: #F7FCFF;padding: 15px;}
.msg_left_search{position: relative;text-align: center;width: 100%;margin-bottom: 30px;}
.msg_left_search input {position: relative;width: 90%; margin: 0 auto;background: #FFFFFF;box-shadow: 0px 0px 4px 0.10000000149011612px #00000040;height: 34px;color: #B4B4B4;font-weight: 400;font-size: 13px; border: none;
    padding-left: 45px;border-radius: 50px;}
.msg_left_search i{position: absolute; left: 28px; top: 10px;z-index: 11;color: #C5C5C5;font-size: 16px;}
.msg_sec_new__left_body{position: relative;height: 100vh;overflow-y: scroll;}
.msg_sec_new__left_body::-webkit-scrollbar {width: 20px;display: none;}
.msg_sec_new__left_body::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px;} 
.msg_sec_new__left_body::-webkit-scrollbar-thumb {background: red; border-radius: 10px;}
.msg_sec_new__left_body::-webkit-scrollbar-thumb:hover {background: #b30000;}
.msg_sec_new__left_single{position: relative;padding: 15px 10px 0 10px;}
.msg_sec_new__left_single_inner{position: relative;display: flex;align-items: center;gap:10px;border-bottom: 1px solid #D7D7D7;padding-bottom: 15px;z-index: 111;}
.msg_sec_new__left_single:last-child .msg_sec_new__left_single_inner{border: none;}
.msg_sec_new__left_single_inner img{width: 45px; height: 45px; border-radius: 100%;}
.msg_data_sec{position: relative;width: 70%;}
.msg_data_sec h6{position: relative;color: #000;font-size: 13px;font-weight: 400;margin-bottom: 5px;}
.msg_data_sec p{position: relative;margin-bottom:0px;color: #919191;font-size: 11px;font-weight: 300;}
.msg_sec_new__left_single_inner span{position: absolute;right: 0;top: 0;color: #919191;font-size: 13px;font-weight: 400;}

.msg_sec_new__left_single.active:after{position: absolute;content:'';left: -15px;top: 0;height: 100%;width: 111%;background: #E2F4FF;z-index: 1;}

.msg_sec_new_body_inner_right{position: relative;background: #EDF9FF;box-shadow: 0px 0px 4px 0.10000000149011612px #00000040;}
.right_chat_sec_new{position: relative;padding: 25px;}
.right_chat_sec_new_head{
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    /* border-bottom: 1px solid #D7D7D7;
    padding-bottom: 20px; */
    margin-bottom: 10px;
}
.right_chat_sec_new_head span{position: relative;}
.right_chat_sec_new_head img{width: 50px;
    height: 50px;
    border-radius: 50%;}
.right_chat_sec_new_head h6{position: relative;color: #000;font-size: 13px;font-weight: 400;margin-bottom: 5px;}
.right_chat_sec_new_head p{position: relative;color: #919191;font-weight: 300;font-size: 11px;padding-left: 15px;margin: 0px;}
.right_chat_sec_new_head p:before{position: absolute;content: '';width: 8px;height: 8px;background: #07C10E;border-radius: 50%;left: 0;top: 3px;}
.right_chat_sec_new_body_sec{position: relative;padding: 20px 0;height: 100vh;overflow-y: scroll;}
.right_chat_sec_left{position: relative;display: flex;align-items: center;gap:20px;margin-bottom: 35px;clear: both;float: left;}
.right_chat_sec_left img{    width: 48px;
    height: 48px;
    border-radius: 50%;}
.right_chat_sec_left span{position: relative;display: block;color: #000;font-weight: 400;font-size: 13px;border-radius: 15px;padding: 10px 25px;background: #C7E9FD;max-width: 325px;}

.right_chat_sec_right{position: relative;display: flex;align-items: center;gap:20px;margin-bottom: 35px;float: right;clear: both;}
.right_chat_sec_right img{    width: 48px;
    height: 48px;
    border-radius: 50%;}
.right_chat_sec_right span{position: relative;display: block;color: #fff;font-weight: 400;font-size: 13px;border-radius: 15px;padding: 13px 25px;background: #0068FF;max-width: 325px;}

.right_chat_sec_new_body_sec::-webkit-scrollbar {width: 20px;display: none;}
.right_chat_sec_new_body_sec::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px;} 
.right_chat_sec_new_body_sec::-webkit-scrollbar-thumb {background: red; border-radius: 10px;}
.right_chat_sec_new_body_sec::-webkit-scrollbar-thumb:hover {background: #b30000;}

.msg_type_box{position: absolute;bottom: 0px;width: 95%;background: #edf9ff;}
.msg_type_box input{    margin: 15px;position: relative;width: 100%;height: 55px;padding-left: 30px;background: #fff;box-shadow: 0px 4px 31px -13px #00000033;color: #898989;font-size: 14px;font-weight: 300;border-radius: 50px;border: none;}

.msg_cin_sec{position: absolute;right: 30px; top: 0; display: flex; align-items: center; gap: 20px; height: 100%;}
.msg_cin_sec button{border: none;background: none;}

.student_teacher_chat_sec{position: relative;padding-bottom: 30px;}
.student_teacher_chat_sec .msg_sec_new_body_inner{grid-template-columns: 1fr;}
.student_teacher_chat_sec .msg_type_box{bottom: 15px;}

.teacher_profile_set_sec{position: relative;padding: 10px 0 20px 0;}
.teacher_profile_set_sec_inner{position: relative;}
.back_btn_st{position: relative;display: flex;align-items: center;gap: 8px;color: #000;font-weight: 500;font-size: 22px;margin-bottom: 20px;}
.back_btn_st i{font-size: 18px;}
.teacher_profile_body{position: relative;padding: 0 30px;}
.teacher_profile_body h6{position: relative;font-size: 18px;font-weight: 500;padding-bottom: 10px;color: #000;}
/* .teacher_profile_about_teacher{
    position: relative;
    display: flex;
    align-items: center;
    gap:30px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 25px 0;
} */
.teacher_profile_about_teacher {
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
    border-top: 1px solid #E8E8E8;
    /* border-bottom: 1px solid #E8E8E8; */
    padding: 20px 0px 10px 0px;
}
.about_teacher_img{position: relative;}
.about_teacher_cont{position: relative;}
.about_teacher_cont h5{position: relative;font-size: 18px;font-weight: 500;color: #000;margin-bottom: 12px;}
.about_teacher_cont p{position: relative;font-size: 13px;font-weight: 300;color: #000;margin-bottom: 0px;}

.subject_special_sec{position: relative;padding-top: 25px;}
.subject_special_sec h5{position: relative;font-size: 16px;font-weight: 500;color: #000;margin-bottom: 16px;}
.subject_special_sec ul{display: flex;align-items: center;gap: 10px;margin-bottom: 35px; flex-wrap: wrap;}
.subject_special_sec ul li{position: relative;display: inline-block;background: #D1EEFF;border-radius: 20px;color: #000000;font-size: 12px;font-weight: 400;padding: 8px 25px;}
.gray_house_sec{position: relative;}
.gray_house_sec h5{position: relative;font-size: 17px;font-weight: 500;color: #000;margin-bottom: 25px;}
.gray_house_sec_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 20px;}
.gray_house_sec_single{position: relative;background: #fff;border-radius: 10px;box-shadow: 0px 2px 8px #e0e0e0;padding: 15px;}
.gray_house_sec_single_img{position: relative;}
.gray_house_sec_single_img img{width: 100%;object-fit: cover;border-radius: 12px;margin-bottom: 20px; height: 192px; }
.gray_house_sec_single_cont{position: relative;}
.gray_house_teacher{position: relative;display: flex;align-items: center;gap:12px;margin-bottom: 15px;}
.gray_house_teacher img{    width: 32px;
    height: 30px;
    border-radius: 50%;}
.gray_house_teacher span h6{position: relative;color: #0068FF;font-size: 12px;font-weight: 400;margin: 0 0 5px 0;padding: 0px;}
.gray_house_teacher span p{position: relative;font-size: 10px;color: #898989;font-weight: 400;margin-bottom: 0px;}

.gray_house_teacher_cont_sec_new{position: relative;}

.teacherDashboard-AI-Assistants-plus-icon {
    position: absolute;
    bottom: 15%;
    right: 41.6%;
}
.teacherDashboard-AI-Assistants-plus-icon img {
    width: 20px;
    height: 20px;
}
.gray_house_teacher_cont_sec_new h4 {position: relative;color: #898989;font-size: 12px;font-weight: 400;
    margin: 0 0 5px 0;line-height: inherit;}
.gray_house_teacher_cont_sec_new h3{
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 18px;
    line-height: inherit;
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
    text-overflow: ellipsis; 
    /* line-height: 1.2em;
    max-height: 4.8em; */
    margin: 0px 0px 10px 0px;
}
.view_cose_det_sec{position: relative;width: 100%;display: flex;align-items: center;justify-content: space-between;gap:10px}
.view_course_sec {position: relative;display: inline-block;border: 1px solid #0068FF;border-radius: 4px;color: #0068FF;
    font-size: 14px; background: none; padding: 8px 24px; font-weight: 400; width: 100%;}
.enroll_btn_sec{ position: relative;display: inline-block;border: 1px solid #0068FF;border-radius: 4px; color: #FFFFFF;
    font-size: 14px; background: #0068FF; padding: 8px 35px; font-weight: 400;}
    
.fundat_sec_new{position: relative;padding: 10px 0 20px 0;}    
.fundat_sec_new_inner{position: relative;}
.fundat_sec_new_inner h6{position: relative;font-size: 26px;font-weight: 500;color: #000;margin-bottom: 12px;}
.fundat_sec_new_inner p{position: relative;font-size: 16px;font-weight: 300;color: #000;padding-bottom: 20px;margin: 0px;border-bottom: 1px solid #D0D0D0;}

.fundat_sec_new_inner_text{position: relative;padding-top: 25px;}
.fundat_sec_new_inner_text h5{position: relative;font-size: 14px;font-weight: 500;margin-bottom: 15px;color: #000;}
.fundat_sec_new_inner_text span{position: relative;font-size: 14px;font-weight: 400;margin-bottom: 15px;color: #555555;}
.fundat_sec_new_inner_text ul{display: block;margin-bottom: 15px;    list-style-type: disc;padding-left: 15px;}
.fundat_sec_new_inner_text ul li{position: relative;font-size: 14px;font-weight: 400;margin-bottom: 5px;color: #555555;}

.forget_modal_sec_new {
    position: relative;
    text-align: center;
    padding: 25px;
    background: #fff;
    border-radius: 15px;
    /* height: 100%; */
    max-width: 561px;
    position: fixed;
    top: 50%;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
}
/* .react-responsive-modal-closeButton{display: none !important;} */
.forget_modal_sec_new h6{position: relative;font-size: 18px;font-weight: 500;margin-bottom: 20px;}
.forget_modal_sec_new img{width: 160px;margin: 0 auto 30px;}
.forget_modal_sec_new_body_sec{position: relative;}
.email_add{position: relative;}
.email_add label{position: relative;font-size: 14px;color: #000000;font-weight: 400;margin-bottom: 8px;display: block;text-align: left;}
.email_add input{position: relative;width: 100%;border: 1px solid #969696;border-radius: 30px;color: #666666;font-size: 12px;font-weight: 300;padding-left: 25px;height: 45px;}
.email_add_btn{position: relative;margin-top: 30px;text-align: center;}
.email_add_btn button {position: relative;box-shadow: 0px 0px 4.765957355499268px 0px #00000033;background: #0068FF;
    padding: 10px 60px;border-radius: 20px;border: none;color: #fff;text-transform: uppercase;}
.chen_back_left{position: absolute;left: 0px;color: #000000;font-size: 12px;display: flex;align-items: center;gap:5px;left: 25px;z-index: 111;}    

.verification_code{position: relative;margin: 0 auto;}
.verification_code label{position: relative;font-size: 14px;color: #000000;font-weight: 400;margin-bottom: 8px;display: block;text-align: left;}
.verification_code_input{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 5px;}
.verification_code_input input{position: relative;width: 100%;height: 45px;border-radius: 12px;border: 1px solid #969696;text-align: center;}

.set_passcode_sec{position: relative;}
.set_passcode_sec_single{position: relative;margin-bottom: 15px;}
.set_passcode_sec_single label{position: relative;font-size: 14px;color: #000000;font-weight: 400;margin-bottom: 8px;display: block;text-align: left;}
.set_passcode_sec_single input {
    position: relative;
    width: 100%;
    border: 1px solid #969696;
    border-radius: 30px;
    color: #666666;
    font-size: 13px;
    font-weight: 300;
    padding-left: 25px;
    height: 45px;
    color: black;
    font-weight: 400;
}
.passwor_update_succ{position: relative;text-align: center;padding: 25px 0;}
.passwor_update_succ img{margin-bottom: 10px;}
.passwor_update_succ p{margin: 0px;position: relative;font-size: 24px;color: #252525;font-weight: 500;}

.msg_sec_new_inner_teacher{position: relative;padding-bottom: 60px;}
.choose-your-role-wraper h2{text-align: center;}

.dashboardHeaderProfileNameInitial{flex: 0 0 45px;}
.css-1hjw3i1-MuiGrid-root{background: #F5FBFF;border-radius: 30px;}
.lesson_generator_editor_toos-folder{gap: 10px;}




@media only screen and (min-width:1801px) and (max-width:3400px) {

}

@media only screen and (min-width:1801px) and (max-width:1900px) {

}
@media only screen and (min-width:1701px) and (max-width:1800px) {

}
@media only screen and (min-width:1601px) and (max-width:1700px) {

}
@media only screen and (min-width:1365px) and (max-width:1800px) {

}
@media only screen and (min-width:1501px) and (max-width:1600px) {

}

@media only screen and (min-width:1401px) and (max-width:1500px) {

}
@media only screen and (min-width:1301px) and (max-width:1400px) {

   /* Home Page */

   .header_logo{width: 120px;}
   .login_btn {font-size: 15px;padding: 9px 30px;}
   .sign_btn{font-size: 15px;padding: 9px 30px;}
   .empower_sec_new_inner_left h3 {font-size: 45px;margin-bottom:20px;line-height: inherit;}
   .line_text_img {right: 210px;bottom: -15px;width: 300px;}
   .empower_sec_new_inner_left p {font-size: 20px; margin-bottom: 25px;}
   .pre_stu_sec p{font-size: 14px;margin-bottom: 0;}
   .pre_stu_sec span{font-size: 18px;}
   .stu_img_pro {width: 40px;top: -25px;right: 20px;}   
   .indi_cls_main {width: 88px;right: 110px;bottom: 140px;}
   .pre_stu_sec_main p{font-size: 14px;margin-bottom: 0;}
   .pre_stu_sec_main span{font-size: 18px;} 
   .revolution_sec_new_head h5, .customize_learing_journey_inner_head h5{font-size: 35px;}
   .revolution_sec_new_single h6{font-size: 18px;}
   .revolution_sec_new_single p{font-size: 12px;}
   .revolution_sec_new_body_sec{grid-gap: 20px;}
   .lets_explore_btn a {position: relative;display: flex;align-items: center;gap: 10px;background: #0068FF;border-radius: 50px;
       height: 45px; width: 180px;justify-content: center;font-size: 15px;color: #FFFFFF;font-weight: 500;}
   .img_revo {left: 0;top: -40px; width: 60px;}     
   .customize_learing_journey {margin: 30px 0px 0px 0px;} 
   .customize_learing_journey_light_image {right: 130px;top: -45px;width: 50px;}
   .customize_learing_journey_arrow svg {width: 100px;}
   .customize_learing_journey_right_inner_folder {padding: 14px 15px 14px 40px;}    
   .customize_learing_journey_right_inner_folder h2{font-size: 20px;}    
   .customize_learing_journey_right_inner_folder p{font-size: 13px;}
   .customize_learing_journey_right_icon {left: -3%;width: 35px;}
   .choose-your-role-wraper h2{font-size: 25px; color: #002469;}
   .blue-line-image2 {left: 290px;width: 270px;}
   .choose-your-role-bg-star svg{width: 45px;}
   .choose-your-role-book-image svg{width: 60px;}
   .footer-wraper {padding: 40px 45px;}
   .footer-link-folder p{font-size: 20px;}
   .footer-link-folder ul li a{font-size: 13px;}
   .footer-Join-our-newsletter-folder p{font-size: 14px;}
   .footer-social-link-folder a{font-size: 22px;}
   .transforming-learning-experiences-wrapper {margin: 70px auto 50px;}
   .choose-your-role-btn-folder button p{font-size: 16px;}
   .choose-your-role-btn-folder button span {font-size: 13px;width: 30px;height: 30px;}
   .transforming-learning-experiences-left-section{padding: 29px 45px;}




}
@media only screen and (min-width:1200px) and (max-width:1300px) {

    .container-fluid{padding-left: 45px;padding-right: 45px;}

    .gray_house_sec_inner{grid-template-columns: 1fr 1fr 1fr;}
    .dashboardSidePannel{margin-left: 0px;width: 185px;padding: 15px}
    .dashboardSidePannel button{font-size: 13px;}
    .dashboardSidePannel button.active{font-size: 13px;}
    .dashboardMainBody {padding: 0 15px 15px 15px;}
    .msg_sec{font-size: 22px;}
    .msg_sec i{font-size: 18px;}
    .msg_sec_new_body_inner {grid-template-columns: 1.2fr 3fr;grid-gap: 20px;}
    .msg_left_search{margin-bottom: 20px;}
    .msg_sec_new__left_single_inner img{width: 40px;}
    .msg_data_sec h6{font-size: 12px;}
    .msg_data_sec p{font-size: 10px;}
    .right_chat_sec_new{padding: 15px;}
    .settings_main_sec_head ul li a{font-size: 15px;padding: 10px 25px;}
    .account_setting_tab_sec {padding: 20px 15px;}
    .account_setting_tab_sec_head{padding-bottom: 15px;}
    .account_setting_tab_sec_head h6{font-size: 17px;}
    .edit_btn_set{font-size: 14px;}
    .about_left_pic{width: 120px;}
    .about_teach_sec_new_left h5{font-size: 16px;text-align: center;}


    /* Home Page */

    .header_logo{width: 120px;}
    .login_btn {font-size: 15px;padding: 9px 30px;}
    .sign_btn{font-size: 15px;padding: 9px 30px;}
    .empower_sec_new_inner_left h3 {font-size: 45px;margin-bottom:20px;line-height: inherit;}
    .line_text_img {right: 210px;bottom: -15px;width: 300px;}
    .empower_sec_new_inner_left p {font-size: 20px; margin-bottom: 25px;}
    .pre_stu_sec p{font-size: 14px;margin-bottom: 0;}
    .pre_stu_sec span{font-size: 18px;}
    .stu_img_pro {width: 40px;top: -25px;right: 20px;}   
    .indi_cls_main {width: 88px;right: 110px;bottom: 140px;}
    .pre_stu_sec_main p{font-size: 14px;margin-bottom: 0;}
    .pre_stu_sec_main span{font-size: 18px;} 
    .revolution_sec_new_head h5, .customize_learing_journey_inner_head h5{font-size: 35px;}
    .revolution_sec_new_single h6{font-size: 18px;}
    .revolution_sec_new_single p{font-size: 12px;}
    .revolution_sec_new_body_sec{grid-gap: 20px;}
    .lets_explore_btn a {position: relative;display: flex;align-items: center;gap: 10px;background: #0068FF;border-radius: 50px;
        height: 45px; width: 180px;justify-content: center;font-size: 15px;color: #FFFFFF;font-weight: 500;}
    .img_revo {left: 0;top: -40px; width: 60px;}     
    .customize_learing_journey {margin: 30px 0px 0px 0px;} 
    .customize_learing_journey_light_image {right: 130px;top: -45px;width: 50px;}
    .customize_learing_journey_arrow svg {width: 100px;}
    .customize_learing_journey_right_inner_folder {padding: 14px 15px 14px 40px;}    
    .customize_learing_journey_right_inner_folder h2{font-size: 20px;}    
    .customize_learing_journey_right_inner_folder p{font-size: 13px;}
    .customize_learing_journey_right_icon {left: -3%;width: 35px;}
    .choose-your-role-wraper h2{font-size: 25px;}
    .blue-line-image2 {left: 245px;width: 270px;}
    .choose-your-role-bg-star svg{width: 45px;}
    .choose-your-role-book-image svg{width: 60px;}
    .footer-wraper {padding: 40px 45px;}
    .footer-link-folder p{font-size: 20px;}
    .footer-link-folder ul li a{font-size: 13px;}
    .footer-Join-our-newsletter-folder p{font-size: 14px;}
    .footer-social-link-folder a{font-size: 22px;}
    .transforming-learning-experiences-wrapper {margin: 70px auto 50px;}
    .choose-your-role-btn-folder button p{font-size: 16px;}
    .choose-your-role-btn-folder button span {font-size: 13px;width: 30px;height: 30px;}
    .transforming-learning-experiences-left-section{padding: 29px 45px;}


    .back_btn_st{font-size: 18px;}
    .teacher_profile_body h6 {font-size: 16px;padding-bottom: 5px;}
    .about_teacher_cont h5{font-size: 17px;}
    .about_teacher_cont p{font-size: 15px;}
    .subject_special_sec h5 {font-size: 15px; margin-bottom: 20px;}
    .gray_house_sec h5 {font-size: 16px; margin-bottom: 18px;}









}
@media only screen and (min-width:992px) and (max-width:1199px) {
 
    .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
    }   
    
    .Register-container{width: 90%;margin: 15px auto 0px auto;}
    .signup-right-section span{font-size: 14px;}
    .signup-right-section h2{font-size: 25px;}
    .Register-tab-section{margin: 10px 0px 0px 0px;}
    .Register-title {font-size: 12px;margin-left: 0;}
    .signup-tab-folder, .signup-tab-folder button.active { padding: 7px 0px; font-size: 10px;}
    .coming-soon img { width: 40px; }
    .signup-form{margin: 25px auto 0px auto;}
    .forget-password-btn{font-size: 10px;}
    .signup-btn {margin: 15px 0px 12px 0px;}
    .signup-btn button { font-size: 12px; padding: 7px 35px;}
    .signup-or-title, .login-or-title {font-size: 12px !important;margin: 10px 0px 15px 0px;}
    .login-btns p{font-size: 11px;}
    .Already-have-an-accout-folder span {font-size: 13px !important;margin: 15px 0px 0px 0px;}
    .terms-and-Privacy-policy{font-size: 10px;}
    .dashboard-popup-welcome-folder, .dashboard-popup-teacher-profile-details-folder{text-align: center;}
    .dashborad-Courses-count{text-align: center;}
    .Start-by-creating-your-course-container { padding: 11px 15px; margin: 25px 0px;}
    .dashboardBody {flex-direction: column;gap: 20px;}
    .studentDashboardSidePannel button{font-size: 14px;}
    .studentDashboardSidePannel button.active {padding: 10px 30px; font-size: 14px;}
    .studentDashboardSidePannel{gap:80px;}
    /* .view-lessons-btn-container button{padding: 3px 15px;} */
    .StudentCourseCard-lessons-tab-container button{font-size: 13px;}
    .studentDashboar-actives-folder {padding: 15px 10px;gap: 15px;}
    .studentDashboar-actives-image img{width: 90px;}
    .studentDashboar-actives-course-count {padding: 10px 5px;width: 100%;}
    .studentDashboar-actives-course-count h2{font-size: 18px;}
    .studentDashboar-actives-course-count p{font-size: 12px;}
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut{display: block !important;margin-top: 30px;}
    .settings_main_sec_head ul {gap: 15px;}
    .settings_main_sec_head ul li a {font-size: 14px;padding: 10px 30px;}
    .settings_main_sec_head {padding: 20px 15px;}
    .account_setting_tab_sec_head h6{font-size: 18px;}
    .studentDashboar-search-filter { margin: 15px 0 25px 0px;width: 400px !important;}




    .dashboardHeader { gap: 10px; padding: 20px 25px 0px 25px;}
    .fundat_sec_new_inner h6{font-size: 20px;}
    .fundat_sec_new_inner p{font-size: 13px;}
    .fundat_sec_new_inner_text ul li{font-size: 12px;}
    .dashboardSidePannel {width: 100%;margin-left: 0;padding: 12px 0;flex: 0 0 100%;flex-direction: row;justify-content: space-around; }
    .create-course svg { width: 22px;}
    .create-course-text{font-size: 12px;}
    .dashboardSidePannel button.active{font-size: 13px;}
    .dashboardMainBody{padding: 0 0px 15px 0px;}
    .msg_sec_new_head{margin-bottom: 15px;}
    .msg_sec{font-size: 16px;}
    .msg_sec i{font-size: 17px;}

    .back_btn_st{font-size: 18px;}
    .teacher_profile_body {padding: 0 10px;}
    .teacher_profile_body h6 {font-size: 18px;padding-bottom: 0;}
    .about_teacher_cont h5{font-size: 18px;margin-bottom: 10px;}
    .about_teacher_cont p{font-size: 14px;}
    .about_teach_sec_new_left h5{font-size: 14px;text-align: center;}
    .subject_special_sec h5{font-size: 18px;margin-bottom: 17px;}
    .subject_special_sec ul{margin-bottom: 20px;}
    .subject_special_sec ul li{font-size: 13px;}
    .gray_house_sec h5 {font-size: 18px; margin-bottom: 17px; }
    .gray_house_sec_inner { grid-template-columns: 1fr 1fr;grid-gap: 20px; }
    .view_course_sec{font-size: 12px;}
    .enroll_btn_sec{font-size: 12px;}

    .msg_sec_new_body_inner { grid-template-columns: 1.8fr 3fr; grid-gap: 20px;}
    .msg_sec_new_body_inner_left{padding: 10px;}
    .msg_data_sec h6{font-size: 12px;}
    .msg_data_sec p{font-size: 10px;}
    .msg_sec_new__left_single_inner span{font-size: 11px;}
    .msg_sec_new__left_single_inner img{width: 40px;}
    .right_chat_sec_new{padding: 15px;}
    .right_chat_sec_new_head{padding-bottom: 15px;}
    .right_chat_sec_left span{font-size: 11px;max-width: 300px;}
    .right_chat_sec_left img{width: 40px; height: 40px;}
    .right_chat_sec_right img{width: 40px; height: 40px;}
    .right_chat_sec_right span{font-size: 11px;max-width: 300px;}
    .msg_type_box{bottom: -10px;}

    .student_teacher_chat_sec .right_chat_sec_new_body_sec{height: 480px;}
    .create-course {
        padding: 2px 17px !important;
    }
    .dashboardSidePannel button {
        font-size: 13px;
    }
    .dashboard-name-title {
        font-size: 20px;
    }
    .dashborad-Courses-count h2 {
        font-size: 20px;
    }
    .dashborad-Courses-count p {
        font-size: 12px;
    }
    .created-my-courses-des-wrapper, 
    .edit-course-details-right-section {
        width: 75%;
    }

    /* HOME PAGE */

    .header_main_sec{padding: 10px 0;}
    .header_login{gap: 10px;flex: 0 0 210px;}
    .sign_btn {font-size: 12px;padding: 7px 28px;}
    .login_btn{font-size: 12px;padding: 7px 28px;}
    .header_main_sec_inner{gap: 25px;}
    .header_menu{width: 100%;padding: 12px 20px;}
    .header_menu ul li a.active{font-size: 14px;}
    .header_menu ul li a{font-size: 14px;}
    .header_menu ul{gap: 8px;}
    .empower_sec_new_inner_left h3{font-size: 40px;line-height: inherit;}
    .line_text_img {right: 160px;width: 300px;}
    .empower_sec_new_inner_left p{font-size: 18px;}
    .pre_stu_sec p{font-size: 12px;}
    .pre_stu_sec span{font-size: 16px;}
    .pre_stu_sec {width: 300px;height: 80px;padding: 15px 20px;}
    .stu_img_pro {width: 35px;top: -20px;}
    .pre_stu_sec_main p{font-size: 12px !important;}
    .pre_stu_sec_main span{font-size: 16px;}
    .pre_stu_sec_main {width: 370px;height: 100px;left: 50px;}
    .indi_cls_main {width: 70px;right: -24px;bottom: 140px;}
    .lets_explore_btn a {height: 42px;width: 170px;font-size: 14px;}
    .revolution_sec_new {padding: 15px 0;}
    .img_revo {left: 5px;top: -40px;width: 50px;}
    .revolution_sec_new_head h5, .customize_learing_journey_inner_head h5{font-size: 25px;}
    .customize_learing_journey .customize_learing_journey_inner_title{width: 90%;margin: 0 auto 30px;}
    .revolution_sec_new_head{margin-bottom: 30px;}
    .revolution_sec_new_body_sec {padding: 100px 20px 20px 20px;grid-template-columns: 1fr 1fr;grid-gap: 35px;}
    .customize_learing_journey { margin: 20px 0px 0px 0px;}
    .customize_learing_journey_light_image {right: 0;top: -43px;width: 40px;}
    .customize_learing_journey_arrow svg{width: 100px;}
    .customize_learing_journey_right_inner_folder h2{font-size: 15px;}
    .customize_learing_journey_right_inner_folder p{font-size: 12px;}
    .customize_learing_journey_right_icon{width: 32px;}
    .customize_learing_journey_right_inner_folder{padding: 14px 10px 14px 30px;}
    .customize_learing_journey_arrow{top: -80px;}
    .customize_learing_journey_half_circle svg{width: 40px;}
    .customize_learing_journey_half_circle{right: -30px;}
    .choose-your-role-wraper h2{font-size: 24px; text-align: center;}
    .choose-your-role-bg-star svg {width: 40px;}
    .choose-your-role-bg-star {top: -15px;left: 0;}
    .choose-your-role-book-image svg {width: 50px;}
    .choose-your-role-book-image {top: -35px; right: 0;}
    .blue-line-image2{top: 40px;}
    .choose-your-role-doted4-image {top: 80px;right: 0;}
    .choose-your-role-doted3-image {top: 55%;left: -20px;}
    .choose-your-role-btn-folder button p{font-size: 14px;}
    .choose-your-role-btn-folder button span { font-size: 15px; width: 25px; height: 25px;}
    .choose-your-role-btn-folder button span i{font-size: 12px;}
    .transforming-learning-experiences-wrapper{margin: 40px auto;}
    .transforming-learning-experiences-left-section{padding: 20px 20px;}
    .transforming-learning-experiences-list-folder p{font-size: 14px;}
    .transforming-learning-experiences-list-icon svg{width: 30px;}
    .footer-wraper {padding: 25px 25px;flex-wrap: wrap;gap: 15px;}
    .footer-link-folder p{font-size: 16px;}
    .footer-link-folder ul li a{font-size: 12px;}
    .revolution_sec_new_single:nth-child(3){margin-top: 45px;}
    .revolution_sec_new_single:nth-child(4){margin-top: 45px;}

    .studentDashboar-teacher-popup-wrapper{max-width: 70% !important;}
    .studentDashboar-teacher-popup-folder {
        width: 48% !important;
    }
    .studentDashboar-Suggested-Courses-folder {
        width: 308px;
    }
    .studentDashboar-teacher-popup-container{gap:20px !important;}
    .studentDashboard-chatbot-container{bottom: 25% !important;}
    .studentDashboar-course-filter{border: none !important;}
    .Student-Active-Lesson-container {width: 100%;}
    .Student-Active-Lesson-title-folder h2{width: auto;font-size: 12px;}
    .Student-Active-Lesson-folder p{font-size: 10px;}
    .Overview-Lesson-inner-header .view-course-Details-back-btn-folder h2{font-size: 16px !important;}
    .Overview-Lesson-date span{font-size: 12px;}
    .overview-lesson-read-left-des p { font-size: 11px;line-height: inherit;}
    .read-min-folder a, .read-min-folder p{font-size: 11px;}
    .overview-lesson-read-right-inner-body{padding: 10px;}
    .editor-paragraph span{font-size: 13px !important;}
    .editor-heading-h2{font-size: 13px !important;}
    .editor-listitem { margin: 0px 10px 8px 5px; padding: 5px !important;}
    .editor-list-ol{margin-left: 0px;}

    .dashboardHeaderProfile{gap: 8px;}
    .view-lessons-created-course-wrapper {padding: 20px 15px;}
    .edit-image-courses {top: 5%;right: 5%;padding: 6px;gap: 5px;font-size: 10px;}
    .shocial-cross-btn {font-size: 14px;padding: 5px 11px;height: 30px;width: 30px; display: flex;align-items: center;
        justify-content: center;}
    .assign_main_box_right_single label{font-size: 11px !important;}
    .assign_main_box_left_single label{font-size: 12px;}
    .lesson_generator_body_sec{overflow: scroll;}
    .security_alerts_sec_new_single_left h6{font-size: 14px;}
    .dashborad-Courses-folder { width: 100%; padding: 15px;}
    .LessonViewProgress-filter-Assignment-Status{border: 0.5px solid #000;}
    .list-of-students-wrappper{padding: 15px;margin: 20px 0px 0px 0px;}
    .chat-with-ai-lession-AI-message{display: block;}
    .chat-with-ai-lession-user-folder{display: flex;}
    .text-massege-user{font-size: 10px;}
    .loading-chat-answer h2, .loading-chat-answer h1 {font-size: 15px;line-height: inherit; margin: 0; padding: 0;}
    .view-lessons-created-course-wrapper p{width: auto !important;}
    /* .Overview-Lesson-description{overflow: scroll;} */
    .lesson_generator_body_sec_left{width: 320px;}
    .promote_part_sec textarea{padding: 10px 30px 15px 12px !important;}

    .edit-image-courses a {font-size: 10px;}
    .edit-image-courses p {font-size: 10px;}
    .go_cls_btn{right: 0px;}
    .dashboardHeaderProfileText{font-size: 12px;}

    .StudentViewCourseDetails-lesson-des {
        width: 238px;
    }
    .Student-Active-Lesson-des {
        width: 165px;
    }
    .overview-lesson-read-left-section {
        width: 35%;
    }



}

@media only screen and (min-width:820px) {
    .search-container {
        position: relative;
        width: 300px;
    }
    .StudentViewCourseDetails-lesson-des {
        width: 139px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {

    .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
    }   
    
    .Register-container{width: 90%;margin: 15px auto 0px auto;}
    .signup-right-section span{font-size: 14px;}
    .signup-right-section h2{font-size: 25px;}
    .Register-tab-section{margin: 10px 0px 0px 0px;}
    .Register-title {font-size: 12px;margin-left: 0;}
    .signup-tab-folder, .signup-tab-folder button.active { padding: 7px 0px; font-size: 10px;}
    .coming-soon img { width: 40px; }
    .signup-form{margin: 25px auto 0px auto;}
    .forget-password-btn{font-size: 10px;}
    .signup-btn {margin: 15px 0px 12px 0px;}
    .signup-btn button { font-size: 12px; padding: 7px 35px;}
    .signup-or-title, .login-or-title {font-size: 12px !important;margin: 10px 0px 15px 0px;}
    .login-btns p{font-size: 11px;}
    .Already-have-an-accout-folder span {font-size: 13px !important;margin: 15px 0px 0px 0px;}
    .terms-and-Privacy-policy{font-size: 10px;}
    .dashboard-popup-welcome-folder, .dashboard-popup-teacher-profile-details-folder{text-align: center;}
    .dashborad-Courses-count{text-align: center;}
    .Start-by-creating-your-course-container { padding: 11px 15px; margin: 25px 0px;}
    .dashboardBody {flex-direction: column;gap: 20px;}
    .studentDashboardSidePannel button{font-size: 14px;}
    .studentDashboardSidePannel button.active {padding: 10px 30px; font-size: 14px;}
    .studentDashboardSidePannel{
        /* gap:80px; */
        gap:65px;
    }
    /* .view-lessons-btn-container button{padding: 3px 15px;} */
    .StudentCourseCard-lessons-tab-container button{font-size: 12px;}
    .studentDashboar-actives-folder {padding: 15px 10px;gap: 15px;}
    .studentDashboar-actives-image img{width: 90px;}
    .studentDashboar-actives-course-count {padding: 10px 5px;width: 100%;}
    .studentDashboar-actives-course-count h2{font-size: 18px;}
    .studentDashboar-actives-course-count p{font-size: 12px;}
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut{display: block !important;margin-top: 30px;}
    .settings_main_sec_head ul {gap: 15px;}
    .settings_main_sec_head ul li a {font-size: 14px;padding: 10px 30px;}
    .settings_main_sec_head {padding: 20px 15px;}
    .account_setting_tab_sec_head h6{font-size: 18px;}
    .studentDashboar-search-filter { margin: 15px 0 25px 0px;width: 400px !important;}




    .dashboardHeader { gap: 10px; padding: 20px 25px 0px 25px;}
    .fundat_sec_new_inner h6{font-size: 20px;}
    .fundat_sec_new_inner p{font-size: 13px;}
    .fundat_sec_new_inner_text ul li{font-size: 12px;}
    .dashboardSidePannel {width: 100%;margin-left: 0;padding: 12px 0;flex: 0 0 100%;flex-direction: row;justify-content: space-around; gap: 0px; }
    .create-course svg { width: 22px;}
    .create-course-text{font-size: 12px;}
    .dashboardSidePannel button.active{font-size: 13px;}
    .dashboardMainBody{padding: 0 0px 15px 0px;}
    .msg_sec_new_head{margin-bottom: 15px;}
    .msg_sec{font-size: 16px;}
    .msg_sec i{font-size: 17px;}

    .back_btn_st{font-size: 18px;}
    .teacher_profile_body {padding: 0 10px;}
    .teacher_profile_body h6 {font-size: 18px;padding-bottom: 0;}
    .about_teacher_cont h5{font-size: 18px;margin-bottom: 10px;}
    .about_teacher_cont p{font-size: 14px;}
    .about_teach_sec_new_left h5{font-size: 14px;text-align: center;}
    .subject_special_sec h5{font-size: 18px;margin-bottom: 17px;}
    .subject_special_sec ul{margin-bottom: 20px;}
    .subject_special_sec ul li{font-size: 13px;}
    .gray_house_sec h5 {font-size: 18px; margin-bottom: 17px; }
    .gray_house_sec_inner { grid-template-columns: 1fr 1fr;grid-gap: 20px; }
    .view_course_sec{font-size: 12px;}
    .enroll_btn_sec{font-size: 12px;}

    .msg_sec_new_body_inner { grid-template-columns: 1.8fr 3fr; grid-gap: 20px;}
    .msg_sec_new_body_inner_left{padding: 10px;}
    .msg_data_sec h6{font-size: 12px;}
    .msg_data_sec p{font-size: 10px;}
    .msg_sec_new__left_single_inner span{font-size: 11px;}
    .msg_sec_new__left_single_inner img{width: 40px;}
    .right_chat_sec_new{padding: 15px;}
    .right_chat_sec_new_head{padding-bottom: 15px;}
    .right_chat_sec_left span{font-size: 11px;max-width: 300px;}
    .right_chat_sec_left img{width: 40px; height: 40px;}
    .right_chat_sec_right img{width: 40px; height: 40px;}
    .right_chat_sec_right span{font-size: 11px;max-width: 300px;}
    .msg_type_box{bottom: -10px;}

    .student_teacher_chat_sec .right_chat_sec_new_body_sec{height: 480px;}
    .create-course {
        padding: 2px 17px !important;
    }
    .dashboardSidePannel button {
        font-size: 13px;
    }
    .dashboard-name-title {
        font-size: 20px;
    }
    .dashborad-Courses-count h2 {
        font-size: 20px;
    }
    .dashborad-Courses-count p {
        font-size: 12px;
    }
    .created-my-courses-des-wrapper, 
    .edit-course-details-right-section {
        width: 75%;
    }

    /* HOME PAGE */

    .header_main_sec{padding: 10px 0;}
    .header_login{gap: 10px;flex: 0 0 210px;}
    .sign_btn {font-size: 12px;padding: 7px 28px;}
    .login_btn{font-size: 12px;padding: 7px 28px;}
    .header_main_sec_inner{gap: 25px;}
    .header_menu{width: 100%;padding: 12px 20px;}
    .header_menu ul li a.active{font-size: 14px;}
    .header_menu ul li a{font-size: 14px;}
    .header_menu ul{gap: 8px;}
    .empower_sec_new_inner_left h3{font-size: 40px;line-height: inherit;}
    .line_text_img {right: 160px;width: 300px;}
    .empower_sec_new_inner_left p{font-size: 18px;}
    .pre_stu_sec p{font-size: 12px;}
    .pre_stu_sec span{font-size: 16px;}
    .pre_stu_sec {width: 300px;height: 80px;padding: 15px 20px;}
    .stu_img_pro {width: 35px;top: -20px;}
    .pre_stu_sec_main p{font-size: 12px !important;}
    .pre_stu_sec_main span{font-size: 16px;}
    .pre_stu_sec_main {width: 370px;height: 100px;left: 50px;}
    .indi_cls_main {width: 70px;right: -24px;bottom: 140px;}
    .lets_explore_btn a {height: 42px;width: 170px;font-size: 14px;}
    .revolution_sec_new {padding: 15px 0;}
    .img_revo {left: 5px;top: -40px;width: 50px;}
    .revolution_sec_new_head h5, .customize_learing_journey_inner_head h5{font-size: 25px;}
    .customize_learing_journey .customize_learing_journey_inner_title{width: 90%;margin: 0 auto 30px;}
    .revolution_sec_new_head{margin-bottom: 30px;}
    .revolution_sec_new_body_sec {padding: 100px 20px 20px 20px;grid-template-columns: 1fr 1fr;grid-gap: 35px;}
    .customize_learing_journey { margin: 20px 0px 0px 0px;}
    .customize_learing_journey_light_image {right: 0;top: -43px;width: 40px;}
    .customize_learing_journey_arrow svg{width: 100px;}
    .customize_learing_journey_right_inner_folder h2{font-size: 15px;}
    .customize_learing_journey_right_inner_folder p{font-size: 12px;}
    .customize_learing_journey_right_icon{width: 32px;}
    .customize_learing_journey_right_inner_folder{padding: 14px 10px 14px 30px;}
    .customize_learing_journey_arrow{top: -80px;}
    .customize_learing_journey_half_circle svg{width: 40px;}
    .customize_learing_journey_half_circle{right: -30px;}
    .choose-your-role-wraper h2{font-size: 24px; text-align: center;}
    .choose-your-role-bg-star svg {width: 40px;}
    .choose-your-role-bg-star {top: -15px;left: 0;}
    .choose-your-role-book-image svg {width: 50px;}
    .choose-your-role-book-image {top: -35px; right: 0;}
    .blue-line-image2{top: 40px;}
    .choose-your-role-doted4-image {top: 80px;right: 0;}
    .choose-your-role-doted3-image {top: 55%;left: -20px;}
    .choose-your-role-btn-folder button p{font-size: 14px;}
    .choose-your-role-btn-folder button span { font-size: 15px; width: 25px; height: 25px;}
    .choose-your-role-btn-folder button span i{font-size: 12px;}
    .transforming-learning-experiences-wrapper{margin: 40px auto;}
    .transforming-learning-experiences-left-section{padding: 20px 20px;}
    .transforming-learning-experiences-list-folder p{font-size: 14px;}
    .transforming-learning-experiences-list-icon svg{width: 30px;}
    .footer-wraper {padding: 25px 25px;flex-wrap: wrap;gap: 15px;}
    .footer-link-folder p{font-size: 16px;}
    .footer-link-folder ul li a{font-size: 12px;}
    .revolution_sec_new_single:nth-child(3){margin-top: 45px;}
    .revolution_sec_new_single:nth-child(4){margin-top: 45px;}

    .studentDashboar-teacher-popup-wrapper{max-width: 70% !important;}
    /* .studentDashboar-teacher-popup-folder{width: 100% !important;} */
    .studentDashboard-chatbot-container{bottom: 25% !important;}
    .studentDashboar-course-filter{border: none !important;}
    .Student-Active-Lesson-container {width: 100%;}
    .Student-Active-Lesson-title-folder h2{width: auto;font-size: 12px;}
    .Student-Active-Lesson-folder p{font-size: 10px;}
    .Overview-Lesson-inner-header .view-course-Details-back-btn-folder h2{font-size: 16px !important;}
    .Overview-Lesson-date span{font-size: 12px;}
    .overview-lesson-read-left-des p { font-size: 11px;line-height: inherit;}
    .read-min-folder a, .read-min-folder p{font-size: 11px;}
    .overview-lesson-read-right-inner-body{padding: 10px;}
    .editor-paragraph span{font-size: 13px !important;}
    .editor-heading-h2{font-size: 13px !important;}
    .editor-listitem { margin: 0px 10px 8px 5px; padding: 5px !important;}
    .editor-list-ol{margin-left: 0px;}

    .dashboardHeaderProfile{gap: 10px;}
    .view-lessons-created-course-wrapper {padding: 20px 15px;}
    .edit-image-courses {top: 5%;right: 5%;padding: 6px;gap: 5px;font-size: 10px;}
    .shocial-cross-btn {font-size: 14px;padding: 5px 11px;height: 30px;width: 30px; display: flex;align-items: center;
        justify-content: center;}
    .assign_main_box_right_single label{font-size: 11px !important;}
    .assign_main_box_left_single label{font-size: 12px;}
    /* .lesson_generator_body_sec{overflow: scroll;} */
    .security_alerts_sec_new_single_left h6{font-size: 14px;}
    /* .dashborad-Courses-folder { width: 100%; padding: 15px;} */
    .LessonViewProgress-filter-Assignment-Status{border: 0.5px solid #000;}
    .list-of-students-wrappper{padding: 15px;margin: 20px 0px 0px 0px;}
    .chat-with-ai-lession-AI-message{display: block;}
    .chat-with-ai-lession-user-folder{display: flex;}
    .text-massege-user{font-size: 10px;}
    .loading-chat-answer h2, .loading-chat-answer h1 {font-size: 15px;line-height: inherit; margin: 0; padding: 0;}
    /* .view-lessons-created-course-wrapper p{width: auto !important;} */
    /* .Overview-Lesson-description{overflow: scroll;} */
    .Overview-Lesson-description {
        max-height: 6.5rem;
    }
    .lesson_generator_body_sec_left{width: 320px;}
    .promote_part_sec textarea{padding: 10px 30px 15px 12px !important;}

    .edit-image-courses a {font-size: 10px;}
    .edit-image-courses p {font-size: 10px;}
    .go_cls_btn{right: 0px;}
    .dashboardHeaderProfileText{font-size: 12px;}

    .studentDashboar-wrapper h2 {
        font-size: 22px;
    }
    .studentDashboar-Suggested-Courses-short-by-filter-container p {
        font-size: 20px;
    }
    .studentDashboar-Suggested-Courses-container, 
    .tab-content-courses  {
        display: flex;
        justify-content: center;
    }
    .StudentViewCourseDetails-lesson-folder {
        padding: 21px;
    }
    .Student-Active-Lesson-des {
        width: 100px;
    }
    .overview-lesson-read-left-section {
        width: 35%;
    }
    .studentDashboar-teacher-popup-container{
        gap:20px !important;
        padding: 0px 17px !important;
    }
    .studentDashboar-teacher-popup-folder {
        width: 48% !important;
    }
    .studentDashboar-teacher-image {
        width: 25%;
    }
    .studentDashboar-teacher-des-folder {
        width: 78%;
    }
    .start-Assignment-btnnton button {
        font-size: 11px;
    }
    .Student-Active-Lesson-des, 
    .Student-Active-Assignment-des {
        width: 95px;
    }
    .Student-Active-Lesson-title-folder h2 {
        width: 78px;
    }
    .view-course-details-image {
        width: 100%;
        height: 176px;
    }
    .view-course-details-right-section p {
        line-height: 22px;
        line-height: 20px !important;
        display: -webkit-box;
        /* -webkit-line-clamp: 4; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        /* margin: 5px 0px 6px 0px; */
    }

}
@media only screen and (max-width:767px) {
    .signup-left-section{display: none;}
    .signup-right-section{width: 100%;}
    .Register-container {width: 100%;margin: 10px auto 0px auto;padding: 0 15px;}
    .signup-right-section span{font-size: 12px !important;}
    .signup-right-section h2{font-size: 18px;margin-bottom: 30px;}
    .Register-title {width: 25%;font-size: 12px; margin-left: 0;position: absolute;top: 65px;}
    .signup-tab-section{width: 100%;}
    .signup-tab-folder button{font-size: 10px;}
    .signup-tab-folder button.active{font-size: 10px;}
    .coming-soon {
        right: 34px;
    }
    .email_add_btn {
        margin-top: 12px;
    }
    .coming-soon img{width: 40px;}
    .signup-form{margin-top: 25px;}
    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{font-size: 12px !important;}
    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input{font-size: 12px !important;}
    .css-i4bv87-MuiSvgIcon-root{font-size: 16px !important;}
    .forget-password-btn{font-size: 10px;
        width: 100%;
    }
    .signup-btn { margin: 10px 0;}
    .signup-btn button{font-size: 11px;}
    .signup-or-title{font-size: 11px !important;}
    .login-with-page-folder{width: 100%;}
    .login-btns p{font-size: 12px;}
    .Already-have-an-accout-folder span{font-size: 12px !important;}
    .terms-and-Privacy-policy{font-size: 10px;}
    .forget_modal_sec_new {
        padding: 35px 15px;
        position: fixed;
        top: 50% !important;
        max-width: 91%;
        transform: translateY(-50%);
        top: 40px;
    }
    .chen_back_left {font-size: 11px;left: 15px;top: 15px;}
    .forget_modal_sec_new img{width: 120px;}
    .email_add label{font-size: 12px;}
    .email_add_btn button {padding: 10px 40px;font-size: 10px;}


    /* .dashboardHeader{display: none;}
    .dashboard hr{display: none;}

    .dashboardHeader { gap: 20px; padding: 20px 15px 0px 15px;}
    .css-bdhsul-MuiTypography-root-MuiDialogTitle-root { font-size: 15px !important; padding: 10px !important;}
    .css-qfso29-MuiTypography-root-MuiDialogContentText-root{font-size: 12px;}
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root{font-size: 12px;}

    .studentDashboardbody{padding: 15px;}
    .studentDashboardSidePannel {display: flex;width: 100%;padding: 14px;flex-direction: column;border-radius: 10px;
        gap: 10px;}
    .studentDashboardSidePannel button{font-size: 13px !important;justify-content: center;}

    .dashboard .container-fluid { padding-left: 15px; padding-right: 15px;}
    .studentDashboar-actives-container { flex-direction: column;}
    .studentDashboar-actives-folder { width: 100%; display: flex;padding: 14px; gap: 15px;}
    .studentDashboar-actives-course-count{padding: 10px;width: 100%;}
    .studentDashboar-actives-course-count h2{font-size: 20px;}
    .studentDashboar-actives-course-count p{font-size: 12px;}
    .studentDashboar-Suggested-Courses-wrapper{margin-top: 30px;}
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {display: flex !important;align-items: flex-start !important;gap: 10px !important; flex-direction: column;}
    .studentDashboar-Suggested-Courses-short-by-filter-container p{font-size: 16px;}    
    .studentDashboar-Suggested-Courses-short-by-filter-container{flex-direction: column;align-items: flex-start;}
    .studentDashboar-search-filter{margin: 5px 0 20px 0;}
    .studentDashboar-search-filter input {width: 100%; padding: 9px 35px;font-size: 12px;}
    .serach-input-icon svg.injected-svg {width: 15px;}
    .serach-input-icon {left: 3.7%;}
    .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span{font-size: 12px;}
    .studentDashboar-course-filter{border: none;}
    .studentDashboar-search-filter{width: 100% !important;}
    .svg-active svg{width: 18px !important;}
    .svg-inactive svg{width: 18px !important;}
    .studentDashboar-Suggested-Courses-folder {width: 100%;padding: 12px; border-radius: 10px;}
    .studentDashboar-profile-course-title {font-size: 14px !important;min-height: auto;}

    .welcome-polly-popup-container { max-width: 100%;padding: 15px;}
    .welcome-polly-cross-btn{left: 130px;}
    .welcome-polly-popup-container h2{font-size: 18px;}
    .welcome-polly-popup-container p{font-size: 12px;}
    .studentDashboar-Suggested-Courses-container{margin: 15px 0 0 0;}
    .studentDashboar-profile-btn-folder{margin-bottom:15px;}
    .view-course-Details-wrapper .view-course-Details-back-btn-folder{margin-top: 15px;}
    .enroll_now_btn {width: 30% !important;}
    .student-view-course-Detaails-folder{flex-direction: column;}
    .view-course-details-left-section{width: 100%;}
    .view-course-details-right-section { width: 100%;}
    p.studentviewcoursedetails-des {-webkit-line-clamp: inherit;}


    
    .view-course-details-container {flex-direction: column;padding-bottom: 20px;}
    .view-lessons-section { margin: 15px 0px 10px 0;} */



     /* TEACHER MODULE */

     /* .dashboard-popup-teacher-profile-details-folder{width: 93%;top: 50%;} */
     .dashboard-popup-teacher-profile-details-folder {
        width: 93% !important;
        position: absolute;
        top: 50%;
        left: 0%;
        right: 0%;
        margin: auto;
        transform: translateY(-50%) !important;
        width: 656px;
        /* padding: 39px 0px; */
        background-color: white;
        border-radius: 12px;
        text-align: center;
    }
     .dashboard-popup-teacher-profile-details-image img{width: 20px;}
     .dashboard-popup-teacher-profile-details-image{flex: 0 0 20px;}
     .dashboard-popup-teacher-profile-details-title h2{font-size: 14px;}
     .dashboard-popup-teacher-profile-details-form {padding: 10px;margin: 5px auto 25px auto;border-radius: 10px;width: 100%;overflow-y: scroll; height: 368px;}
     .form_sec_settings .dashboard-popup-teacher-profile-details-form {
        height: auto;
    }
    .form_sec_settings {
        padding: 0px 0 0px 0;
    }
     .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-details-input{width: 100%;}
     .dashboard-popup-teacher-profile-details-form-container{gap: 10px;}
     .teacher-create-popup-gender-input{width: 75px !important; position: absolute; top: 23.5px; padding: 4px 0px 4px 30px !important;}
     .dot-indicator { margin: 30px 0px 0px 0px;}
     .dashboard-popup-teacher-profile-select-Language-container .Subjects-you-teach-suggestions-folder a{font-size: 10px;;}
     .you-can-update-the-details-title {font-size: 10px;padding: 0 10px;}
     .form-buttons {
        /* max-width: 90%; */
        max-width: 66%;
        margin-bottom: 10px;
    }
     .Subjects-you-teach-feilds-folder{margin: 10px 0;}
     .Personalize-your-AI-Assistant-left-section{width: 100%;}
     .Personalize-your-AI-Assistant-right-section{width: 100%;margin-top: 10px;padding-left: 0;}
     .dashboard-popup-teacher-profile-details-input #teacher-create-profile-input {
        padding: 8px 10px 8px 85px !important;
    }
     .dashboard-popup-welcome-image {width: 180px; margin: 0px auto;}
     .dashboard-popup-welcome-folder  .dashboard-popup-welcome-folder h2{font-size: 16px;}
     .dashboard-popup-welcome-folder p{font-size: 12px;}
     .dashboard-popup-welcome-folder button { padding: 10px 40px;  font-size: 10px;}
     .introduce-yourself-text:focus{border: 1px solid #E8E8E8;}
     .Subjects-you-teach-suggestions-folder a { padding: 4px 10px;  font-size: 10px;}
     .Personalize-your-AI-Assistant-wrapper{flex-direction: column;}
     /* .Personalize-your-AI-Assistant-uplode-image img{height: 80px !important;width: 80px !important;}
     .dashboard-popup-teacher-profile-details-image-plus-btn */

     .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-details-image-plus-btn{right: 114px;}
     .dashboard-popup-teacher-profile-details-form .dashboard-popup-teacher-profile-details-image-plus-btn img {width: 20px !important;
        height: 20px !important;}
    .form-buttons button{font-size: 12px;}   
    .settings_main_sec_head ul {gap: 15px;}
    .settings_main_sec_head ul li a{font-size: 11px !important;}
    .settings_main_sec_head ul {gap: 2px;}
    .settings_main_sec_head ul li a{font-size: 12px;}
    .dashboardSidePannel{padding: 10px;}
    .account_setting_tab_sec_head h6{font-size: 12px;}
    .edit_btn_set, 
    .save_btn_set{
        padding: 4px 18px;
        font-size: 10px;
    }
    .about_teach_sec_new { gap: 10px; flex-direction: column;}
    .about_teach_sec_new_left { width: 100%; margin: 0 auto; text-align: center;}
    .form_sec_settings .dashboard-popup-teacher-profile-details-form{min-width: 100% !important;}
     
    .dashboard-popup-teacher-profile-details-form::-webkit-scrollbar {width: 20px;display: none;}
    .dashboard-popup-teacher-profile-details-form::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey;  border-radius: 10px;} 
    .dashboard-popup-teacher-profile-details-form::-webkit-scrollbar-thumb {background: red; border-radius: 10px;}
    .dashboard-popup-teacher-profile-details-form::-webkit-scrollbar-thumb:hover {background: #b30000;}
    .password_req ul li{font-size: 11px;}
    .password_req ul li:after{background-size: 12px;background-repeat: no-repeat;}
    .reset_password_body_single input { width: 100%;height: 35px;font-size: 11px;}
    .eye_sec{top: 33px;}
    .eye_sec i{font-size: 12px;}
    .reset_pass button{font-size: 11px;}
    .settings_main_sec_head{border-radius: 70px;    width: 100%;}

    .dashboardSearch {gap: 5px !important;}
    .dashboard-header-search {width: 180px !important;}
    /* .dashboardHeaderProfile {gap: 5px !important;} */
    .dashboardHeaderProfileNameInitial {font-size: 10px;width: 30px;height: 30px;flex: 0 0 30px;}
    .dashboardHeader {padding: 8px 10px 0px 10px !important; }
    #demo-customized-button .injected-svg{width: 10px;}

    .created-my-courses-title-wrapper h2{
        font-size: 14px !important;
        /* width: 114px !important; */
        width: 198px !important;
    }
    .view-lessons-created-course-wrapper { margin: 18px 3px; padding: 10px 20px; box-shadow: 0px 1px 5px #dedede;}
    /* .view-course-details-edit-and-share-container{gap: 5px;} */

    .date-inner-wrapper{display: block;}
    .LessonViewProgress-header-section .view-course-details-container {display: flex;gap: 10px;}
    .LessonViewProgress-tab-folder-container { display: flex;margin: 15px 0px 0px 0px;flex-direction: column;
        gap: 15px;}
    .LessonViewProgress-serach-filter{width: 100%;}
    /* .view-lessons-btn-container {margin-top: 50px;} */
    /* .add-lesson-btn {top: -30px;position: relative;} */
    .list-of-students-wrappper{overflow: scroll;padding: 10px;margin: bottom 20px; box-shadow: 0px 0px 0px transparent;}
    /* .list-of-students-wrappper.student-request-wrapper table{width: 550px;overflow-y: scroll;} */
    /* .list-of-students-wrappper table{width: 600px;overflow: scroll;} */
    .Teacher-student-edit-profile-container {display: flex;text-align: left;gap: 15px; width: 100%;flex-direction: column;}
    #TeacherProfile-section .Teacher-student-edit-profile-left-section{width: 100%;}
    #TeacherProfile-section .Teacher-student-edit-profile-wrapper .dashboard-popup-teacher-profile-details-form{width: 100%;}
    .welcome-polly-popup-container {
        max-width: 323px;
        position: fixed;
        top: 50%;
        left: 0%;
        right: 0%;
        margin: auto;
        padding: 40px 20px;
    }
    .welcome-polly-popup-image img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        object-fit: cover;
    }
    .welcome-polly-popup-container h2 {
        font-size: 19px;
    }
    .welcome-polly-popup-container p {
        font-size: 14px;
        margin: 0px 0px 0px 0px;
    }
    .welcome-polly-cross-btn {
        position: absolute;
        top: -60px;
        left: 145px;
    }

    /* .view-lessons-created-course-bg-wrapper {width: 600px; overflow: scroll;} */



}   

@media only screen and (min-width:701px) and (max-width:767px) {

    .dashboardSidePannel {padding: 10px;flex-direction: row;justify-content: center; align-items: center; overflow: auto; gap: 0px;}    
    .settings_main_sec_head ul{flex-direction: row;}
    .dashboard-popup-teacher-profile-details-image-plus-btn img { width: 20px !important;height: 20px !important;}
    .form-icon {left: 1.6%;}
    .msg_sec_new_body_inner {display: grid; grid-template-columns: 1fr 2fr;}
    .right_chat_sec_new_head img { width: 45px !important;  height: 45px;}
    .dashborad-Courses-container {display: grid;grid-template-columns: 1fr 1fr;}
    /* .created-my-courses-container { display: grid;grid-template-columns: 1fr 1fr;} */
    /* .Create-Courses-wrapper { height: 490px;} */
    .view-lessons-created-course-bg-wrapper { width: 100%;overflow: inherit;}
    .empower_sec_new_inner {grid-template-columns: 1fr 1fr;}
    .line_text_img {right: 60px;width: 150px;}
    .indi_cls_main { position: absolute;width: 70px;right: -80px; bottom: 10px; z-index: 2;}
    .revolution_sec_new_body_sec { grid-template-columns: 1fr; grid-gap: 60px; padding: 50px 20px 20px 20px;}
    .customize_learing_journey-folder{flex-direction: inherit;}
    .revolution_sec_new_head h5, .customize_learing_journey_inner_head h5 {font-size: 20px;}
    .customize_learing_journey_light_image { position: absolute; right: 0;  top: -55px; width: 45px;}
    .line_text_image2{width: 150px;}
    .customize_learing_journey_arrow{top: -55px;}
    .choose-your-role-wraper h2{font-size: 20px;}
    .blue-line-image2 { position: absolute;  top: 35px; left: 100px; width: 150px;}
    .transforming-learning-experiences-wrapper{flex-direction: inherit;}
    .view-lessons-btn-container button {flex: 0 0 22%;}
    .view-lessons-btn-container{width: 100%;}
    .add-lesson-btn button{top: 38px !important;}
    .TeacherProfile-btn-container{margin: 0 auto;}




}
