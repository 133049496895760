.admin-dashboard-graph-wrapper {
    display: flex;
    width: 100%;
    gap: 18px;
    margin: 44px 0px 0px 0px;
}
.admin-dashboard-graph-left-section {
    width: 66%;
    padding: 17px;
    box-shadow: 0px 2px 13px #ecececb8;
    border-radius: 22px;
}
.admin-dashboard-graph-right-section {
    width: 33%;
    padding: 17px;
    box-shadow: 0px 2px 13px #ecececb8;
    border-radius: 22px;
}
.admin-dashboard-graph-header-wrapper p{
    font-size: 16px;
    color: black;
    font-weight: 600;
}
.admin-dashboard-graph-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin-dashboard-graph-dropdown {
    display: flex;
    align-items: center;
    gap: 11px;
}
.course-revenue-dropdown-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.course-revenue-dropdown-folder{
    margin: 10px 0px 20px 0px;
}
.course-revenue-price- {
    width: 15px;
    height: 15px;
    background-color: #2A35FB;
}
.course-revenue-price-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.course-revenue-price-wrapper p{
    font-size: 13px;
    font-weight: 600;
    color: black;
}
.admin-bar{
    height: 300px !important;
    width: 100% !important;
}
.admin-dashboard-graph-dropdown-menu .MuiBox-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #F8E6FF;
}   