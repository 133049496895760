/* .ratingPopup-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    padding: 27px 0px;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.ratingPopup-contaier {
    background-color: white;
    border-radius: 8px;
    margin: auto;
    width: 467px;
}
.ratingPopup-contaier h2 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    padding: 20px 0px;
    background-color: #D8F1FF;
    margin: 0px 0px 20px 0px;
    border-radius: 8px 8px 0px 0px;
}
.ratingPopup-contaier span{
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: black;
}
.ratingPopup-stars-folder {
    display: flex;
    justify-content: center;
    margin: 22px 0px;
    gap: 20px;
}
.ratingPopup-stars-folder i{
    font-size: 42px;
    color: #FFBB33;
}
.ratingPopup-des {
    background-color: #D8F1FF;
    color: black;
    padding: 9px;
    font-size: 13px;
    line-height: 21px;
    width: 100%;
    height: 128px;
    resize: none;
    border-radius: 7px;
    border: none;
}
.ratingPopup_btn {
    width: 100%;
    background-color: #0068FF;
    font-size: 15px;
    color: white;
    padding: 7px 0px;
    border: none;
    margin: 20px 0px 25px 0px;
} 
.ratingPopup-folder {
    margin: 0px 30px;
}

.ratingPopup-stars-folder .fa-star {
    color: gray; /* Default color */
    cursor: pointer;
}

.ratingPopup-stars-folder .fa-star.golden {
    color: gold; /* Golden color for selected stars */
}
.ratingPopup-header {
    text-align: center;
    background-color: #F9F8F8;
    padding: 26px 0px;
    border-radius: 10px 10px 0px 0px;
}
.ratingPopup-header span{
    font-size: 18px;
    color: #0068FF;
    font-weight: 600;
}
.ratingPopup-header p{
    font-size: 14px;
    color: black;
}
.assignment_rating {
    font-size: 17px !important;
    font-weight: 600 !important;
    margin: 18px 0px 33px 0px;
}
.assignment_rating a{
    margin-left: 5px;
}

@media only screen and (max-width: 767px){
    .assignment-rating-popup{
        width: 86% !important;
        padding: 0rem !important;
    }
    .ratingPopup-header span {
        font-size: 14px;
        color: #0068FF;
        font-weight: 600;
    }
    .ratingPopup-contaier {
        width: 100%;
    }
    .ratingPopup-header p {
        font-size: 11px;
        margin: 4px 0px 0px 0px;
    }
    .ratingPopup-header {
        padding: 17px 6px;
    }
    .ratingPopup-stars-folder {
        display: flex;
        gap: 14px;
    }
    .ratingPopup-stars-folder i {
        font-size: 25px;
    }
    .ratingPopup-des {
        font-size: 10px;
        line-height: 15px;
    }
    .ratingPopup_btn {
        font-size: 11px;
        padding: 7px 0px;
    }
    .ratingPopup-folder {
        margin: 0px 14px;
    }
}