.AssingmentMCQs-container {
    display: flex;
    width: 100%;
}

.student-lesson-preview-section {
    width: 50%;
    background-color: #F1F6FF;
}

.AssingmentMCQs-right-section {
    width: 50%;
    padding: 0px 15px;
}

.AssingmentMCQs-Time-remaining-folder p {
    font-size: 13px;
    color: black;
}

.AssingmentMCQs-show-lesson-preview h2 {
    font-size: 14px;
}

.AssingmentMCQs-show-lesson-preview {
    display: flex;
    align-items: center;
    gap: 15px;
}

.AssingmentMCQs-Time-remaining-folder {
    width: 180px;
    margin-left: auto;
}

.student-lesson-preview-wrapper {
    padding: 35px;
}

.student-lesson-preview-folder h2 {
    font-size: 17px;
    margin: 0px 0px 15px 0px;
}

.student-lesson-preview {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 31px;
}

.student-lesson-preview-list-title {
    font-size: 13px;
    color: #555555;
    font-weight: 500;
    margin: 0px 0px 5px 0px;
}

.student-lesson-preview li {
    list-style: disc;
    font-size: 13px;
    color: #555555;
    font-weight: 500;
}

.student-lesson-preview-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow-y: scroll;
    height: 507px;
    padding: 0px 10px 0px 0px;
}

.full-width {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .AssingmentMCQs-right-section {
        width: 50%;
    }

    .student-lesson-preview-section {
        width: 55%;
    }

    .full-width {
        width: 100%;
    }

    .AssingmentMCQs-container {
        display: flex;
        gap: 15px;
    }

    .student-lesson-preview-container {
        max-height: 507px !important;
    }
}

@media only screen and (max-width: 820px) {
    .AssingmentMCQs-container {
        display: flex;
        gap: 24px;
    }

    .student-lesson-preview-wrapper {
        padding: 20px;
    }

    .student-lesson-preview-container {
        height: 650px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .AssingmentMCQs-container {
        display: flex;
        gap: 25px;
    }
}

@media only screen and (max-width: 767px) {
    .AssingmentMCQs-container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        gap: 36px;
        margin: 25px 0px 20px 0px;
    }

    .AssingmentMCQs-right-section,
    .student-lesson-preview-section {
        width: 100%;
    }

    .student-lesson-preview-wrapper {
        padding: 20px;
    }

    .student-lesson-preview-folder h2 {
        font-size: 13px;
        margin: 0px 0px 10px 0px;
    }

    .student-lesson-preview-list-title,
    .student-lesson-preview li {
        font-size: 11px;
    }

    .delete-course-form-container p {
        font-size: 16px;
    }

    .AssingmentMCQs-header-main-contaiiner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
    }

    .AssingmentMCQs-show-lesson-preview {
        margin-left: auto;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px) {
    .AssingmentMCQs-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .student-lesson-preview-section {
        width: 55%;
    }

    .AssingmentMCQs-right-section {
        width: 50%;
    }

    .full-width {
        width: 100%;
    }

    .AssingmentMCQs-show-lesson-preview h2 {
        font-size: 13px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .AssingmentMCQs-header-main-contaiiner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .AssingmentMCQs-show-lesson-preview {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: auto;
    }

    .AssingmentMCQs-show-lesson-preview h2 {
        font-size: 12px;
    }
}

@media (min-width: 1800px) and (max-width: 1820px) {
    .student-lesson-preview-container {
        height: 820px !important;
    }
}

@media (min-width: 2700px) and (max-width: 2799px) {
    .student-lesson-preview-container {
        height: 700px !important;
    }
}

@media only screen and (min-resolution: 125dpi) {
    .student-lesson-preview-container {
        height: 555px;
    }
}
.step.answered {
    background-color: #28a745;
    color: white;
}