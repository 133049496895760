.lesson-andassignment-tab-container {
    width: 353px;
    display: flex;
    justify-content: center;
    margin: 0px auto 0px auto;
    background-color: #ECF8FF;
    padding: 7px;
    border-radius: 5px;
}
.lesson-andassignment-tab-container button.active {
    background-color: #0068FF;
    color: white;
    border-radius: 4px;
}
.lesson-andassignment-tab-container button {
    padding: 3px 32px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    text-transform: capitalize;
}
.Overview-Lesson-top-header {
    display: flex;
    align-items: baseline;
    margin-bottom: 26px;
}

@media only screen and (max-width: 767px){
    .Overview-Lesson-top-header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin: 47px 0px 0px 0px;
        position: relative;
    }
    .lesson-andassignment-tab-container {
        width: 100%;
        position: absolute;
        top: -51px;
        left: 0px;
        right: 0px;
    }
    .lesson-andassignment-tab-wrapper .tab-content {
        margin: 25px 0px 0px 0px;
    }
    .lesson-andassignment-tab-container button {
        font-size: 12px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .lesson-andassignment-tab-container {
        width: 353px;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .Overview-Lesson-top-header {
        display: flex;
        flex-direction: column;
        margin: 48px 0px 0px 0px;
    }
    .lesson-andassignment-tab-container {
        width: 100%;
        position: absolute;
        top: -50px;
        left: 0px;
        right: 0px;
    }
    .lesson-andassignment-tab-container button {
        font-size: 11px;
        padding: 3px 18px;
    }
}