.lesson-list-container {
    border-radius: 11px;
    box-shadow: 0 1px 9px #dedede;
    margin: 20px 0;
    padding: 33px 50px;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.lesson-list-preview-title-folder {
    align-items: center;
    display: flex;
    gap: 22px;
    margin: 0;
}
.lesson-list-btn-folder {
    align-items: center;
    display: flex;
    gap: 39px;
}
.View-Progress-btn {
    font-size: 14px;
    text-decoration: underline;
    color: #0068ff;
    cursor: pointer;
    font-weight: 500;
}
.lesson-list-edit-btn, 
.lesson-list-view-btn {
    padding: 7px 34px;
    font-size: 13px;
    border: 2px solid #0068ff;
    color: #0068ff;
    background-color: transparent;
    font-weight: 500;
    border-radius: 4px;
}
.lesson-delete-btn i{
    color: #8E8E8E;
    cursor: pointer;
}
.lesson-list-edit-course-title {
    display: flex;
    align-items: center;
    gap: 11px;
}
.lesson-list-edit-course-title input{
    border: 1px solid;
    /* width: 263px; */
}
.lesson-list-edit-course-title {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    display: inline-block;
}
.lesson-list-edit-course-title input{
    border: none;
    width: 234px;
    font-size: 14px;
    font-weight: 500;
}
.lesson-list-edit-course-title i{
    font-size: 13px;
    color: #8C8C8C;
}
.lesson-list-preview-title-folder p{
    font-size: 14px;
    color: black;
    font-weight: 500;
}
.lesson-list-assignment-count {
    display: flex;
    align-items: center;
    gap: 11px;
}
.lesson-list-assignment-count p{
    font-size: 14px;
    color: #0068ff;
    font-weight: 500;
}
.lesson-list-assignment-count-image img{
    width: 18px;
}
.lesson-list-preview-title-folder i{
    color: #BEBEBE;
}
.lesson-list-container::after {
    content: "";
    position: absolute;
    top: -5%;
    right: -0.3%;
    width: 12px;
    height: 12px;
    background-color: #FF0000;
    border-radius: 33px;
}
.lesson-list-view-btn img{
    width: 17px;
}

  
.lesson-list-edit-course-title .dynamic-input {
    max-width: 240px;
    min-width: 10px;
    width: auto;
}
  
.lesson-list-edit-course-title .hidden-span {
    visibility: hidden;
    white-space: pre;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;
}
  