.drawing-canvas-popup {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drawing-canvas-popup-wrapper {
    display: flex;
    flex-direction: column;
    width: 600px;
    /* justify-content: center; */
    /* border: 1px solid; */
    gap: 16px;
}
.convert-drawing {
    padding: 10px 0px;
    color: white;
    background-color: #0068FF;
    font-size: 12px;
    border: none;
    border-radius: 5px;
}
.drawing-canvas-popup-button-folde{
    width: 100%;
    width: 100%;
    gap: 10px;
    display: flex;
}
.drawing-canvas-popup-button-folde button{
    width: 50%;
}
.drawing-canvas-popup-button-folde button:nth-child(1){
    background-color: black;
}
.drawing-canvas-popup-button-folde button:nth-child(2){
    border: 2px solid #0068FF;
    color: #0068FF;
    background-color: transparent;
    font-weight: 500;
}
.drawing-canvas-popup-section{
    width: 600px !important;
    height: 600px !important;
}