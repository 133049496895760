@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(0.6);
  }
}

@keyframes loud {
  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  75% {
    transform: scaleY(1.2);
  }
}

.Ai-Voice-animation-container {
  /* background-color: #eeeeee; */
  background-color: white;
  position: fixed;
  top: 50%;
  position: absolute;
  /* top: 0%; */
  top: -62px;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #d9d9d9;
  padding: 11px 0px 11px 0px;
  z-index: 113;
}
.Ai-Voice-animation-image img {
  width: 207px;
}

.Ai-Voice-animation-play-progress-folder audio {
  height: 36px;
}
.Ai-Voice-animation-wrapper {
  position: absolute;
  /* top: 13%; */
  top: 14%;
  left: 0%;
  right: 0%;
  margin: auto;
  /* transform: translateY(-50%); */
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: #0000002b; */
  z-index: 113;
}
.Ai-Voice-animation-image {
  display: block;
}

/* .boxContainer {
    display: flex;
    justify-content: space-between;
    height: 64px;
    --boxSize: 8px;
    --gutter: 4px;

    width: 86px;
} */

/* .box {
    transform: scaleY(.4);
    height: 100%;
    width: 11px;
    background: #6CC2DD;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
} */

.box1 {
  animation-name: quiet;
}
.box2 {
  animation-name: normal;
}
.box3 {
  animation-name: quiet;
}
.box4 {
  animation-name: loud;
}
.box5 {
  animation-name: quiet;
}

.play-pause-btn-Overview-lesson-generator .fa-circle-pause,
.play-pause-btn-Overview-lesson-generator .fa-circle-pause {
  font-size: 28px;
}
.Ai-Voice-animation-play-progress-folder {
  width: 234px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.Ai-Voice-animation-close-btn {
  position: absolute;
  top: -4%;
  right: -3%;
  margin: auto;
  text-align: center;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 11px;
  background-color: white;
  box-shadow: 0px 2px 8px #d9d9d9;
}

/* .boxContainer {
  display: flex;
  justify-content: space-between;
  height: 64px;
  --boxSize: 8px;
  --gutter: 4px;
  width: 86px;
} */

/* .box {
  transform: scaleY(.4);
  height: 100%;
  width: 11px;
  background: #6CC2DD;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
} */

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}

.play-pause-btn-Overview-lesson-generator .fa-circle-pause,
.play-pause-btn-Overview-lesson-generator .fa-circle-pause,
.play-pause-btn-Overview-lesson-generator .fa-circle-stop {
  font-size: 28px;
}

.Ai-Voice-animation-play-progress-folder {
  width: 234px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

/* .Ai-Voice-animation-close-btn {
  position: absolute;
  top: -4%;
  right: -3%;
  margin: auto;
  text-align: center;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 13px;
  background-color: white;
  box-shadow: 0px 2px 5px #dcdcdc;
} */

.play-pause-btn-Overview-lesson-generator {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Ai-Voice-animation-progress {
  width: 100%;
}

.Ai-Voice-animation-progress::-webkit-progress-value {
  background-color: red;
}
.animation-voice {
  background-color: #0068ff;
}
.animation-voice {
  background-color: #0068ff;
  width: 27px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 100%;
  position: absolute;
  top: 12px;
  left: 46px;
}

@media (min-width: 2700px) and (max-width: 2799px){
  .Ai-Voice-animation-container {
    top: 50%;
    position: absolute;
    top: -111px;
  }
}

@media only screen and (min-width: 830px) and (max-width:  900px){
  .Ai-Voice-animation-wrapper {
    position: absolute;
    top: 17%;
  }
  .Ai-Voice-animation-container {
    width: 24%;
  }
}

@media only screen and (max-width: 767px) {
  /* .Ai-Voice-animation-container {
    width: 89%;
    position: absolute;
    top: 50%;
    left: 0%;
    left: 47%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 8px #d9d9d9;
    padding: 0px 0px 11px 0px;
    z-index: 113;
    padding: 10px 0px;
    transform: rotate(-90deg);
  } */
  .Ai-Voice-animation-container {
    width: 89%;
    position: absolute;
    top: 50%;
    left: 44%;
    /* left: 47%; */
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* gap: 10px; */
    /* border-radius: 10px; */
    /* box-shadow: 0px 2px 8px #d9d9d9; */
    /* padding: 0px 0px 11px 0px; */
    z-index: 113;
    /* padding: 10px 0px; */
    transform: rotate(-90deg);
  }

  .Ai-Voice-animation-wrapper {
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    /* top: 50%; */
    top: 0%;
    width: 100%;
      height: 100%;
      /* background-color: #0000002b; */
    /* -webkit-transform: translateY(-50%);
    transform: translateY(-50%); */
    z-index: 113;
  }
  .Ai-Voice-animation-close-btn {
    position: absolute;
    /* top: 59%; */
    /* top: -4%; */
    top: 72%;
    right: -3%;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .Ai-Voice-animation-container {
    position: absolute;
    top: 50%;
    left: 43%;
  }
}
