@media only screen and (max-width: 767px) {

    /* .dashboardHeader{
        display: none;
    } */
    .container-fluid {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .studentDashboar-wrapper h2,
    .view-course-details-right-section h2,
    .view-course-Details-back-btn-folder h2,
    .dashboard-popup-welcome-folder h2 {
        font-size: 18px;
    }
    .studentDashboar-wrapper h2,
    .editor-heading-h2,
    .editor-heading-h3 {
        font-size: 14px !important;
    }
    .studentDashboar-actives-container { 
        display: flex;
        gap: 12px;
    }
    .studentDashboar-teacher-des-inner-name,
    .about_teacher_cont h5,
    .editor-heading-h1,
    .editor-heading-h2 span,
    .teacher_profile_body h6,
    .subject_special_sec h5,
    .gray_house_sec h5,
    .gray_house_teacher_cont_sec_new h3 {
        font-size: 14px;
    }
    .studentDashboar-profile-course-title,
    .studentDashboar-teacher-title {
        font-size: 16px !important;
        min-height: 0px;
    }
    .studentDashboar-profile-course-title {
        margin: 9px 0px 16px 0px !important;
    }
    .studentDashboar-profile-btn-folder button:disabled,
    .editor-paragraph span,
    .about_teacher_cont p {
        font-size: 12px !important;
    }
    .studentDashboar-course-filter,
    .Overview-Lesson-date span,
    .Student-Active-start-Lesson,
    .view-lessons-btn-container button,
    .editor-listitem span,
    .start-Assignment-btnnton button,
    .subject_special_sec ul li,
    .AssingmentMCQs-mock-test-answer-btn-folder button {
        font-size: 10px;
    }
    .view-lessons-btn-container button {
        font-size: 11px;
    }
    .studentDashboar-actives-course-count p,
    .studentDashboar-search-filter input,
    .StudentViewCourseDetails-lesson-toggle-btn h2,
    .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span,
    .Student-Active-Lesson-title-folder h2,
    .editor-text-bold,
    .about_teach_sec_new_left h5,
    .view_course_sec,
    .AssingmentMCQs-mock-test-question-answer-container h2,
    .delete-course-form-btn-folder button {
        font-size: 12px;
    }
    .studentDashboar-actives-container,
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut,
    .studentDashboar-Suggested-Courses-short-by-filter-inner-folder,
    .overview-lesson-read-wrapper {
        display: flex;
        flex-direction: column;
    }
    .studentDashboar-search-filter {
        width: auto;
    }
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
        gap: 10px;
        margin-top: 20px;
    }
    .studentDashboar-search-filter {
        margin: 0px;
    }
    .studentDashboardSidePannel {
        gap: 20px;
        padding: 8px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        width: 95%;

    }
    .studentDashboardSidePannel button.active {
        display: flex;
        gap: 4px;
        padding: 6px 18px;
        border-radius: 36px;
        background-color: #CFE5FB;
        font-size: 14px;
        margin: 0 auto;
        width: 100%;
    }

    /* .svg-inactive svg,
    .svg-active svg{
        width: 14px;
    } */
    .studentDashboardSidePannel button {
        gap: 4px;
        padding: 5px 10px;
        border-radius: 36px;
        font-size: 13px;
    }

    .studentDashboar-Suggested-Courses-short-by-filter-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .studentDashboar-Suggested-Courses-short-by-filter-section {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 18px;
        justify-content: flex-end;
    }

    .studentDashboar-actives-folder {
        padding: 0px;
    }

    .studentDashboar-actives-image img {
        width: 80px;
    }

    .studentDashboar-Suggested-Courses-short-by-filter-container p {
        font-size: 16px;
        width: 100%;
    }

    .studentDashboar-search-filter input,
    .studentDashboar-search-filter,
    .Student-Active-Lesson-container,
    .Student-Active-Lesson-title-folder h2,
    .studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut,
    .overview-lesson-read-left-section,
    .overview-lesson-read-right-section {
        width: 100%;
    }

    .serach-input-icon svg {
        width: 20px;
    }

    .studentDashboar-Suggested-Courses-short-by-filter-inner-folder {
        margin-top: 10px;
        gap: 5px;
        display: flex;
        flex-direction: row;
    }

    .studentDashboar-search-filter input {
        padding: 6px 40px;
    }

    .studentDashboar-Suggested-Courses-container,
    .AssingmentMCQs-mock-test-question-answer-container {
        margin-top: 10px;
    }

    /* .studentDashboar-Suggested-Courses-folder {
        padding: 10px;
        width: 100%;
    } */

    .view-lessons-section {
        margin-top: 0px;
    }

    .msg_sec_new_head,
    .start-Assignment-btnnton,
    .reset_password_body_single {
        margin-bottom: 10px;
    }

    .studentDashboar-Suggested-Courses-folder {
        width: 100%;
        padding: 10px;
    }

    .tab-content-courses {
        display: grid;
        gap: 20px;
    }

    .studentDashboar-Suggested-Courses-profile-folder {
        /* margin: 8px 0px 8px 0px; */
        margin: 12px 0px 10px 0px;
    }
    .Your-feedback-appreciated-popup {
        width: 100% !important;
    }
    .Your-feedback-appreciated-popup-section{
        width: 86% !important;
    }
    .Your-feedback-appreciated-container {
        padding: 7px !important;
    }
    .Your-feedback-appreciated-container button {
        font-size: 13px;
    }
    .Your-feedback-appreciated-inner-folder {
        display: flex;
        gap: 3px;
    }
    .studentDashboar-profile-btn-folder button:nth-child(1) {
        border: 1px solid #0068ff;
        font-weight: 500;
    }
    .Your-feedback-appreciated-image {
        width: 26px;
    }
    .Your-feedback-appreciated-inner-folder h2 {
        font-size: 15px;
    }

    .studentDashboar-profile-btn-folder button,
    .teacher_profile_about_teacher {
        padding: 6px 0px;
    }

    .view-lessons-wrapper .studentDashboar-search-filter {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .view-lessons-section {
        margin-bottom: 0px;
    }

    .view-lessons-btn-container button.active {
        border-bottom: 3px solid #0068FF;
    }

    .msg_sec_new_body_inner {
        display: block;
    }

    /* .msg_sec_new_body_inner_left{
        display: block;
    }
    .msg_sec_new_body_inner_right{
        display: none;
    } */
    .studentDashboar-teacher-popup-wrapper {
        width: 90%;
    }

    .studentDashboar-teacher-popup-container {
        justify-content: center;
        padding: 0px !important;
    }

    .studentDashboar-teacher-popup-folder {
        width: auto !important;
        padding: 8px 0px 12px 14px;
        gap: 20px;
    }

    .studentDashboar-teacher-title {
        padding: 10px 0px 10px 0px !important;
    }

    .studentDashboar-teacher-popup-cross-btn i {
        width: 34px !important;
        height: 34px !important;
        top: -46px !important;
    }

    .view-lessons-btn-container button {
        padding: 4px 9px;
    }

    .account_setting_tab_sec,
    .Student-Active-Lesson-folder {
        padding: 15px 15px;
    }

    .account_setting_tab_sec_head,
    .about_teach_sec_new {
        padding-bottom: 10px;
    }

    .about_teach_sec_new {
        padding-bottom: 10px;
        gap: 15px;
    }

    .about_teach_sec_new_right h4 {
        font-size: 14px;
        margin: 0px 0 5px 0;
    }

    .about_teach_sec_new_right textarea,
    .subject_special {
        padding: 10px;
        font-size: 10px;
    }
    .inner-lesson-and-inner-Assignment-main-container,
    .overview-lesson-read-wrapper {
        margin-top: 10px !important;
    }

    .dashboard-popup-teacher-profile-details-input {
        width: 100% !important;
        gap: 5px !important;
    }

    .dashboard-popup-teacher-profile-details-form-container {
        /* gap: 6px */
        gap: 0px;
    }

    #teacher-create-profile-input {
        padding: 8px 10px 8px 40px;
    }

    .settings_main_sec_head ul li a {
        padding: 6px 8px;
        font-size: 12px;
    }

    .settings_main_sec_head {
        padding: 12px 13px;
    }

    .msg_sec_new_head {
        font-size: 13px;
    }

    .view-course-Details-back-btn-folder h2 {
        font-size: 14px !important;
    }

    .Overview-Lesson-date-counts p {
        padding: 3px 0px;
        color: #0FBA00;
        font-size: 10px;
    }

    .overview-lesson-read-left-box-folder,
    .subject_special_sec {
        padding: 8px 8px;
    }

    .read-min-folder,
    .overview-Assignment-read-body,
    .reset_pass {
        margin-top: 5px;
    }

    .gray_house_sec h5,
    .teacher_profile_body h6,
    .subject_special_sec ul,
    .about_teacher_cont h5,
    .subject_special_sec h5,
    .gray_house_teacher_cont_sec_new h3 {
        margin-bottom: 5px;
    }

    .overview-lesson-read-left-section,
    .overview-lesson-read-left-container {
        height: auto;
    }

    .editor-heading-h2,
    .editor-paragraph {
        padding: 5px !important;
    }

    .editor-heading-h1,
    .editor-heading-h3 {
        margin-bottom: 0px;
    }

    .editor-list-ol,
    .editor-listitem {
        margin-left: 0px;
    }

    .editor-listitem {
        margin: 0px 8px 0px 32px;
    }

    .overview-next-or-preview-btn-folder {
        margin-top: 16px;
        margin-bottom: 20px;
    }

    /* .overview-lesson-mark-complete-btn{
        left:205px;
    } */
    .overview-next-or-preview-btn-folder button:nth-child(2) {
        border: none;
    }

    /* .overview-next-or-preview-btn-folder button {
        padding: 8px 6px !important;
    } */

    .overview-lesson-mark-complete-btn {
        position: static;
    }

    .dashboard-popup-teacher-profile-details-image-plus-btn {
        position: absolute;
        bottom: 3px;
        /* right: 120px !important; */
        right: 10px !important;
    }

    .sub_special {
        display: flex;
        flex-wrap: wrap;
        gap: 9px;
    }

    .sub_special li {
        margin-right: 0px;
        font-size: 10px;
        right: 90px !important;
        margin-bottom: 0px;
    }

    .overview-next-or-preview-btn-folder {
        display: flex;
        justify-content: flex-start;
    }

    .teacher_profile_body {
        padding: 0px;
    }

    .Personalize-your-AI-Assistant-uplode-image img {
        width: 80px;
        height: 80px;
    }

    .dashboard-popup-teacher-profile-details-image-plus-btn img {
        width: 20px !important;
        height: 20px !important;
    }

    .reset_password_body_inner,
    .reset_password_body_single input {
        width: 100%;
    }

    .eye_sec {
        right: 14px;
    }

    .password_req ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .reset_pass button {
        padding: 5px 20px;
    }

    .password_req {
        margin-top: 30px;
    }

    .msg_sec,
    .msg_sec i,
    .back_btn_st,
    .back_btn_st i {
        font-size: 12px;
    }

    .teacher_profile_about_teacher {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
        text-align: left;
        align-items: flex-start;
    }

    .gray_house_sec_inner {
        grid-template-columns: 1fr;
    }

    .subject_special_sec ul li,
    .view_course_sec {
        padding: 5px 25px;
    }

    .dashboard-popup-welcome-folder {
        width: 90%;
    }

    .AssingmentMCQs-mock-test-answer-folder {
        padding: 8px 16px;
    }

    .AssingmentMCQs-mock-test-question-answer-container h2 {
        /* margin: 18px 0px; */
        margin: 7px 0px;
    }

    .step {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    .AssingmentMCQs-Time-remaining-folder p {
        font-size: 8px;
    }

    .AssingmentMCQs-header-main-contaiiner h2 {
        font-size: 12px;
        text-align: center;
    }

    .AssingmentMCQs-header-main-contaiiner {
        text-align: left;
    }

    .AssingmentMCQs-Time-remaining-folder {
        text-align: end;
    }

    /* .view-course-Details-back-btn-folder h2 {
        font-size: 10px !important;
    } */
    .delete-course-form-container h2 {
        font-size: 16px;
        margin: 11px 0px 14px 0px;
    }

    .delete-course-form-container {
        width: 90%;
        padding: 20px 22px;
    }

    .delete-course-form-btn-folder,
    .Student-Active-Lesson-container {
        gap: 10px;
    }

    .Student-Active-start-Lesson {
        padding: 3px 12px;
        width: 40%;
    }

    /* .student-view-course-Detaails-folder {
            display: flex;
            align-items: center;
            flex-direction: column;
        } */
    .Student-Active-Lesson-folder p {
        width: 58%;
        /* width: 170%; */
        /* justify-content: flex-start; */
        justify-content: center;
    }
    #StudentActiveLesson-section .Student-Active-Lesson-folder p{
        width: 40%;
    }
    /* chat box css */

    .right_chat_sec_right img,
    .right_chat_sec_left img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        right: 10px;
        position: absolute;
        top: -25px;
    }

    .right_chat_sec_new {
        padding: 20px;
    }

    .right_chat_sec_right {
        margin-bottom: 46px;
    }

    .right_chat_sec_left img {
        position: absolute;
        left: 10px;
        top: -29px;
    }

    .right_chat_sec_right span, 
    .right_chat_sec_left span {
        font-size: 11px;
        padding: 8px 15px;
        margin: 16px 0px 0px 0px;
    }
    .right_chat_sec_new_head {
        /* padding-bottom: 14px; */
        padding-bottom: 0px;
    }

    .right_chat_sec_left {
        flex-direction: column;
        gap: 5px;
    }

    .right_chat_sec_right {
        flex-direction: column-reverse;
        gap: 5px;
    }

    .right_chat_sec_left span {
        width: 200px;
    }

    .right_chat_sec_new_head img {
        width: 45px !important;
        height: 45px;
    }

    .view-lessons-wrapper {
        width: 100%;
    }

    .overview-next-or-preview-btn-folder,
    .overview-next-or-preview-btn-folder button {
        gap: 5px;
    }

    .overview-next-or-preview-btn-folder button,
    .overview-next-or-preview-btn-folder button p {
        font-size: 9px !important;
    }

    .StudentViewCourseDetails-lesson-des,
    .StudentViewCourseDetails-lesson-toggle-dropdown-section p {
        font-size: 12px;
    }

    .view-course-details-edit-and-share-folder {
        width: 100%;
    }

    .enroll_now_btn {
        font-size: 11px !important;
        width: 90px !important;
    }

    .enroll_now_btn {
        font-size: 10px !important;
        padding: 6px 0px !important;
        width: 100px !important;
    }

    .studentDashboar-profile-btn-folder button:disabled {
        font-size: 10px !important;
    }

    .studentDashboard-chatbot-container {
        right: 3%;
    }

    .studentDashboar-profile-btn-folder {
        display: flex;
        align-items: flex-start;
    }

    .StudentViewCourseDetails-lesson-folder {
        padding: 13px;
        margin-bottom: 10px;
    }

    .StudentViewCourseDetails-lesson-toggle-btn i {
        font-size: 10px;
    }

    .StudentViewCourseDetails-lesson-des {
        width: 155px;
        height: 40px;
        white-space: unset;
        text-overflow: unset;
    }

    /* .StudentViewCourseDetails-lesson-toggle-dropdown-section{
    align-items: flex-start;
} */
    .StudentViewCourseDetails-lesson-toggle-btn {
        display: flex;
        align-items: center;
        gap: 7px;
        width: 145px;
    }

    .right_chat_sec_new_body_sec {
        margin-top: 5px;
        padding-top: 28px;
    }

    .teacher_tooltip {
        position: absolute;
        top: -10px;
        left: 76px;
        background: #535353;
        border-radius: 8px;
        padding: 6px 8px;
        opacity: 0;
        transition: opacity 1s;
        display: flex;
    }

    .teacher_tooltip p {
        color: #FFFFFF;
        font-size: 8px;
    }

    .tooltip_btn {
        margin-top: 0px;
        font-size: 8px;
        width: 70px;
    }

    .teacher_tooltip img {
        position: absolute;
        bottom: -6px;
        width: 10px;
    }

    .StudentCourseCard-lessons-tab-container {
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .studentDashboar-profile-course-name {
        font-size: 12px;
    }

    .StudentViewCourseDetails-lesson-toggle-btn {
        width: 40%;
    }

    .StudentViewCourseDetails-lesson-des {
        width: 30%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        height: auto;
    }

    .StudentViewCourseDetails-lesson-inner-assignments-container {
        border: 1px solid #e7e7e7;
        padding: 15px;
        border-radius: 10px;
        margin: 10px 0px 0px 0px;
    }

    .StudentViewCourseDetails-lesson-inner-assignments-container h2 {
        font-size: 12px !important;
        padding-bottom: 13px;
    }

    .StudentViewCourseDetails-lesson-inner-assignments-folder p {
        font-size: 12px !important;
    }

    .Student-Active-main-wrapper {
        margin-bottom: 5px;
    }

    .Student-Active-Lesson-folder {
        padding: 15px 6px;
        gap: 13px;
    }

    .Student-Active-start-Lesson {
        width: 53%;
        padding: 6px 12px;
    }

    .settings_main_sec {
        padding: 0px;
    }

    .LessonViewProgress-header-section .view-course-details-container {
        display: inline-flex;
        gap: 4px;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }

    .view-Students-course-details-folder {
        width: 49%;
    }

    .LessonViewProgress-student-course-folder {
        padding: 3px 6px;
        width: 100%;
    }

    .view-Students-course-details-folder {
        width: 48.2%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    #Lesson_Container .Student-Active-Lesson-folder.active_assignment_folder .view-course-details-container .view-Students-course-details-folder {
        width: 100%;
    }
    #Lesson_Container .Student-Active-Lesson-folder.active_assignment_folder .view-course-details-container .view-Students-course-details-folder .Student-Active-Lesson-folder p {
        justify-content: center !important;
        padding: 4px 0px !important;
    }

    .view-Students-course-details-folder span {
        font-size: 10px !important;
    }

    .LessonViewProgress-header-section button {
        width: 100%;
        margin: 0 auto;
    }

    /* new css for table card */
    .list-of-students-wrappper {
        overflow-x: auto;
    }

    .list-of-students-gap {
        height: 16px;
        /* Adjust the gap height as needed */
    }

    .list-of-students-wrappper table {
        width: 100%;
        border-collapse: collapse;
    }

    .list-of-students-wrappper th,
    .list-of-students-wrappper td {
        padding: 12px;
        text-align: left;
    }

    .list-of-students-wrappper th {
        background-color: #f8f9fa;
        /* Light gray background for table headers */
    }

    .list-of-students-wrappper td {
        border-bottom: 1px solid #dee2e6;
        /* Border between rows */
    }

    .list-of-students-wrappper td:last-child {
        border-bottom: none;
        /* Remove bottom border for the last cell in each row */
    }

    .list-of-students-wrappper tbody td:before {
        content: attr(data-label) ": ";
        font-weight: bold;
    }

    /* Card styles */
    .list-of-students-wrappper tbody tr {
        display: block;
        margin-bottom: 20px;
        border: 1px solid #dee2e6;
        border-radius: 8px;
    }

    .list-of-students-wrappper tbody td {
        display: block;
    }

    .list-of-students-wrappper tbody td:first-child {
        border-bottom: 1px solid #dee2e6;
    }

    .list-of-students-wrappper tbody td:last-child {
        border-bottom: none;
        margin: 10px auto;
    }

    .list-of-students-wrappper tbody td:before {
        content: none;
        /* Hide label for mobile layout */
    }

    .list-of-students-wrappper tbody tr:hover {
        background-color: #f8f9fa;
        /* Highlight on hover */
    }

    .create-Assignment-container-popup-folder2 {
        display: flex;
        gap: 10px;
        margin: 13px 0px 0px 0px;
        flex-direction: column;
    }

    .Create-Course-inner-input label {
        font-size: 11px !important;
    }

    .create-Assignment-container-popup-folder1 {
        display: flex;
        width: 100%;
        gap: 7px;
        flex-direction: column;
    }

    .promote_part_sec textarea {
        font-size: 11px !important;
    }

    .create-Assignment-input-feild {
        width: 100%;
    }

    #AssingmentMCQs-back-btn h2 {
        width: 46px !important;
    }

    .StudentViewCourseDetails_view_Score {
        padding: 5px 8px;
        font-size: 8px;
        width: 74px;
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }
    .Student-Active-main-wrapper {
        margin: 0px;
        padding: 0px;
    }
    .StudentViewCourseDetails-lesson-inner-assignments-folder {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
    }
    .AssingmentMCQs-Congratulations-popup-container {
        max-width: 340px;
        padding: 25px;
    }
    .grade_icon {
        position: absolute;
        /* top: 60px; */
        top: 36px;
        left: 3.6%;
    }
    .universalsearch-image svg{
        width: 19px;
    }
    .msg_sec_new__left_single_inner img {
        width: 40px;
        height: 40px;
    }
    .msg_data_sec h6 {
        font-size: 12px;
    }
}

@media only screen and (min-width: 717px) and (max-width: 720px) {

    /* Your styles for medium-sized screens go here */
    .dashborad-Courses-folder {
        width: 100%;
    }
    .AssingmentMCQs-Congratulations-popup-container {
        max-width: 472px;
    }
    .StudentCourseCard-main-container {
        width: 48%;
    }
    .StudentCourseCard-main-container .studentDashboar-Suggested-Courses-folder {
        width: 100%;
        padding: 10px;
    }
    .tab-content-courses {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
    .studentDashboar-Suggested-Courses-folder {
        width: 48%;
        padding: 10px;
    }
}


/* Responsive Design for galaxy fold start */
@media (min-width: 280px) and (max-width: 320px) {

    .studentDashboar-wrapper h2,
    .editor-heading-h2,
    .view-course-details-right-section h2,
    .about_teach_sec_new_left h5,
    .editor-heading-h3,
    .StudentViewCourseDetails-lesson-inner-assignments-container h2,
    .editor-heading-h1,
    .view-course-details-share-folder h2,
    .studentDashboar-Suggested-Courses-short-by-filter-container p {
        font-size: 14px !important;
    }

    .studentDashboardSidePannel button,
    .subject_special_sec h5,
    .teacher_profile_body h6,
    .overview-lesson-read-left-des p,
    .msg_data_sec h6,
    .right_chat_sec_new_head h6,
    .view-course-details-share-folder p,
    .studentDashboardSidePannel button.active {
        font-size: 12px;
    }

    .tudentDashboar-profile-card-title,
    .view-course-Details-back-btn-folder h2,
    .studentDashboar-wrapper h2,
    .StudentViewCourseDetails-lesson-inner-assignments-container h2,
    .studentDashboar-profile-course-title {
        font-size: 12px !important;
    }

    .StudentViewCourseDetails-lesson-inner-assignments-container h2 {
        font-size: 11px !important;
    }

    .studentDashboar-search-filter input,
    .msg_data_sec p,
    .right_chat_sec_new_head p,
    .read-min-folder p,
    .StudentViewCourseDetails-lesson-toggle-dropdown-section p,
    .studentDashboar-profile-btn-folder button {
        font-size: 10px;
    }

    .editor-heading-h1 span,
    .editor-heading-h2 {
        font-weight: 600;
    }

    p.teacherviewcoursedetails-des,
    .about_teacher_cont p {
        font-size: 10px !important;
    }

    .studentDashboar-actives-course-count p,
    .studentDashboar-search-filter input,
    .StudentViewCourseDetails-lesson-toggle-btn h2,
    .studentDashboar-Suggested-Courses-short-by-filter-inner-folder span,
    .Student-Active-Lesson-title-folder h2,
    .editor-text-bold,
    .about_teach_sec_new_left h5,
    .view_course_sec,
    .AssingmentMCQs-mock-test-question-answer-container h2,
    .delete-course-form-btn-folder button {
        font-size: 10px;
    }

    .Student-Active-Lesson-title-folder h2,
    .Student-Active-Lesson-folder p,
    .copy-link-or-path-folder button {
        font-size: 9px !important;
    }

    .Student-Active-start-Lesson,
    .copy-link-or-path-folder input {
        font-size: 8px;
    }
    .copy-link-or-path-folder input {
        font-size: 11px ;
    }

    .view-lessons-btn-container button {
        padding: 2px 1px;
    }

    .studentDashboar-profile-image img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }

    .studentDashboar-actives-course-count p,
    .studentDashboar-teacher-inner-des {
        margin-top: 0px;
    }

    .msg_data_sec h6 {
        margin-bottom: 0px;
    }

    .studentDashboar-actives-folder {
        padding: 10px 11px !important;
    }

    .studentDashboar-actives-image img {
        width: 70px;
    }

    .serach-input-icon svg {
        width: 15px;
    }

    .studentDashboar-search-filter input {
        padding: 6px 30px;
    }

    .msg_left_search i {
        position: absolute;
        left: 12px;
        top: 8px;
        z-index: 11;
        color: #C5C5C5;
        font-size: 12px;
    }

    .msg_left_search input {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0.10000000149011612px #00000040;
        height: 26px;
        color: #B4B4B4;
        font-weight: 400;
        font-size: 10px;
        border: none;
        padding-left: 30px;
        border-radius: 50px;
    }

    .msg_sec_new__left_single_inner img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }

    .msg_left_search {
        position: relative;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .msg_sec_new__left_single {
        position: relative;
        padding: 8px 10px 0 10px;
    }

    .msg_sec_new__left_single_inner {
        padding-bottom: 8px;
    }

    .right_chat_sec_new,
    .StudentViewCourseDetails-lesson-inner-assignments-container {
        padding: 10px;
    }

    .right_chat_sec_new_head img {
        width: 40px !important;
        height: 40px;
    }

    .right_chat_sec_new_head {
        gap: 10px;
        /* padding-bottom: 10px; */
    }
    .right_chat_sec-profile i {
        padding-bottom: 10px;
    }

    .right_chat_sec_new_body_sec {
        position: relative;
        padding: 28px 0;
    }

    .right_chat_sec_right img,
    .right_chat_sec_left img {
        width: 32px;
        height: 32px;
    }

    .right_chat_sec_right span,
    .right_chat_sec_left span {
        /* font-size: 8px; */
        font-size: 10px;
    }

    .right_chat_sec_left span {
        width: 145px;
    }

    .right_chat_sec_left {
        margin-bottom: 30px;
    }

    .studentDashboar-teacher-popup-cross-btn i {
        width: 34px;
        height: 34px;
        top: -46px;
    }

    .fa-x:before {
        content: "\58";
        font-size: 12px;
    }

    .view-course-details-container {
        margin: 0px 0px 12px 0px;
        display: flex;
        flex-wrap: nowrap;
    }

    .fa-arrow-left:before {
        font-size: 13px;
    }

    .view-course-details-edit-and-share-folder {
        padding: 3px 5px;
        gap: 10px;
    }

    /* .studentDashboar-profile-btn-folder button {
        padding: 4px 0px;
        font-size: 11px !important;
    } */

    p.teacherviewcoursedetails-des {
        margin: 4px 0px 0px 0px !important;
    }

    .view-Students-course-details-folder {
        width: 46%;
    }

    .view-course-details-container {
        margin: 16px 0px 12px 0px;
        display: flex;
        flex-wrap: wrap;
    }

    .view-course-Details-back-btn-folder,
    .LessonViewProgress-back-btn-folder {
        margin: 0px 0px 10px 0px;
    }

    .teacherviewcoursedetails-inner-popup-folder {
        padding: 10px 10px 10px 10px;
        width: 260px;
    }

    .teacherviewcoursedetails-inner-popup-folder textarea {
        padding: 10px;
        font-size: 10px;
    }

    .view-course-details-right-section h2 {
        font-size: 12px !important;
        margin: 6px 0px;
    }

    .my-course-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 10px;
    }

    .my-course-close-btn .fa-x:before {
        font-size: 10px;
    }

    .StudentViewCourseDetails-lesson-inner-assignments-container,
    .StudentViewCourseDetails-lesson-wrapper {
        margin-top: 0px;
    }

    .StudentViewCourseDetails-lesson-des {
        width: 80px;
        font-size: 10px;
    }

    .StudentViewCourseDetails-lesson-toggle-dropdown-section p {
        gap: 3px;
    }

    .StudentViewCourseDetails-lesson-des {
        width: 30%;
        font-size: 9px;
        margin-bottom: 5px;
        height: auto;
    }

    .StudentViewCourseDetails-lesson-assignments-tab,
    .StudentViewCourseDetails-lesson-toggle-btn {
        width: 40%;
    }

    .StudentViewCourseDetails-lesson-toggle-btn {
        gap: 4px;
    }

    .StudentViewCourseDetails-lesson-folder {
        padding: 6px;
        margin-bottom: 10px;
    }

    .StudentViewCourseDetails-lesson-toggle-dropdown-section {
        align-items: flex-start;
        gap: 5px;
    }

    .StudentViewCourseDetails-lesson-toggle-btn i {
        font-size: 7px;
    }

    .StudentViewCourseDetails-lesson-toggle-btn h2 {
        font-size: 9px;
    }

    .StudentViewCourseDetails-lesson-toggle-btn {
        align-items: baseline;
        display: flex;
    }

    .StudentViewCourseDetails-lesson-toggle-btn span {
        display: flex;
    }

    .studentDashboar-teacher-popup-folder {
        gap: 10px !important;
        width: 92% !important;
    }
    .studentDashboar-teacher-des-view-profile-wrapper {
        display: flex;
        width: 168px !important;
        gap: 2px !important;
    }

    .StudentViewCourseDetails-lesson-wrapper h2 {
        margin: 0px;
        margin-bottom: 10px;
    }

    .studentDashboar-profile-btn-folder button:disabled {
        font-size: 10px !important;
    }

    button.enroll_now_btn {
        font-size: 9px !important;
        padding: 6px 0px;
    }

    .view-course-details-folder {
        gap: 8px;
    }

    .view-lessons-btn-container button {
        font-size: 10px;
    }

    .view-lessons-btn-container {
        margin-top: 0px;
        gap: 5px;
    }

    .Student-Active-Lesson-folder {
        gap: 8px;
    }

    .account_setting_tab_sec,
    .Student-Active-Lesson-folder {
        padding: 5px 10px;
    }

    .dashboard-popup-teacher-profile-details-image-plus-btn {
        position: absolute;
        bottom: 3px;
        right: 72px !important;
    }

    .Student-Active-start-Lesson {
        padding: 3px 12px;
        width: 30%;
    }

    .overview-lesson-read-right-inner-body {
        padding: 10px;
    }

    .Student-Active-Lesson-folder p {
        width: 54%;
        justify-content: flex-start;
    }

    .dashboard-header-search {
        width: 167px !important;
    }

    .Student-Active-start-Lesson {
        padding: 5px 8px;
        width: 60%;
    }

    .studentDashboar-teacher-image img {
        width: 40px;
        height: 26px;
        object-fit: cover;
        border-radius: 100%;
    }

    .studentDashboar-wrapper h2 {
        margin-bottom: 5px;
    }

    .studentDashboar-actives-course-count {
        padding: 6px 0px;
    }

    .studentDashboard-chatbot-container {
        right: 3%;
    }

    .studentDashboard-universal-chatbot-AI-des {
        width: 100%;
    }

    .about_left_pic img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100%;
    }

    .view-course-details-share-folder.aos-init.aos-animate {
        width: 90%;
    }

    .copy-link-or-path-folder button {
        padding: 0px 20px;
    }

    .view-course-social-links-image img {
        width: 33px;
    }

    .shocial-cross-btn {
        position: absolute;
        top: -39px;
        right: 2%;
        font-size: 10px;
        border: 1px solid;
        padding: 2px 6px;
        border-radius: 100%;
        color: black;
    }

    .subject_special_sec {
        padding-left: 0px;
    }

    .welcome-polly-popup-container.aos-init.aos-animate {
        width: 90%;
    }

    .welcome-polly-cross-btn {
        position: absolute;
        top: -60px;
        left: 110px;
    }
    .studentDashboar-wrapper h2{
        font-size: 11px !important;
    }
    .Overview-Lesson-date-container {
        display: flex;
        gap: 15px;
        margin-left: auto;
    }
    /* .Overview-Lesson-inner-header {
        display: flex;
        flex-direction: column;
        gap: 17px;
    } */
    .Overview-Lesson-inner-header {
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;
    }
    .student-Active-Lessons {
        font-size: 15px;
    }
    .AssingmentMCQs-Congratulations-popup-container {
        max-width: 259px;
        padding: 25px;
    }
    .your-score-title {
        font-size: 12px;
    }
    .your-score span {
        font-size: 13px;
    }
    .your-score-check {
        font-size: 15px;
    }
    .AssingmentMCQs-Congratulations-popup-container p {
        max-width: 400px;
        font-size: 11px;
    }
    .Your-feedback-appreciated-container button {
        font-size: 11px;
    }
    .Your-feedback-appreciated-inner-folder h2 {
        font-size: 12px;
    }
    .Your-feedback-appreciated-popup-section {
        width: 86% !important;
        padding: 0.5rem !important;
    }
    .studentDashboar-profile-btn-folder button {
        font-size: 10px !important;
    }
}

/* Responsive Design for galaxy fold end */