.book-demo-container {
    max-width: 89%;
    margin: 0px auto;
    margin: 48px auto 20px auto;
}
.book-demo-container h2{
    font-size: 24px;
    font-weight: 700;
    margin: 0px 0px 0px 0px;
}
.book-demo-container p{
    font-size: 14px;
    color: #636363;
    max-width: 500px;
    margin: 12px 0px 34px 0px;
}
.book-demo-form{
    width: 64%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}
.book-demo-inner-input {
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 13px;
}
.book-demo-inner-input {
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 13px;
    position: relative;
}
.book-demo-inner-input label{
    font-size: 14px;
    font-weight: 600;
    position: relative;
}
.book-demo-inner-input label::after{
    content: "*";
    /* position: absolute; */
    top: 4px;
    left: 78px;
    color: #FC2D3E;
    margin: 10p 0px 0px 10px;
    margin: 6px;
}
.request-demo-btn-folder span::after {
    content: "*";
    position: absolute;
    top: -5.2px;
    left: -17px;
    color: #FC2D3E;
    margin: 10p 0px 0px 10px;
    margin: 6px;
    font-size: 16px;
}
.book-demo-inner-input:nth-child(3) label::after, 
.book-demo-inner-input:nth-child(4) label::after{
    content: "";
}
.book-demo-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 5px !important;
    background-color: transparent !important;
}
.book-demo-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12.5px 14px !important;
    font-size: 13px !important;
}
.book-demo-inner-input span{
    font-size: 11px;
    color: #CACACA;
}
.What-brought-you-us-today-select h2{
    font-size: 15px;
    font-weight: 600;
}
.What-brought-you-us-today-select-input {
    display: flex;
    align-items: center;
    gap: 9px;
}
.What-brought-you-us-today-select-input p {
    margin: 0px;
    font-size: 13px;
    font-weight: 500;
}
.What-brought-you-us-today-select textarea {
    width: 64%;
    border: 1px solid #CDCDCD;
    resize: none;
    margin: 17px 0px 0px 0px;
    border-radius: 4px;
    font-size: 13px;
    padding: 14px;
}
.What-brought-you-us-today-select-folder {
    display: flex;
    flex-direction: column;
    gap: 11px;
}
.What-brought-you-us-today-select{
    margin: 20px 0px 30px 0px;
}
.What-brought-you-us-today-select h2{
    font-size: 15px;
    font-weight: 600;
}
.What-brought-you-us-today-select-folder {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin: 22px 0px 0px 0px;
}
.request-demo-btn-folder {
    display: flex;
    align-items: center;
    gap: 35px;
}
.request-demo-btn {
    display: flex;
    align-items: center;
}
.request-demo-btn-folder span{
    font-size: 12px;
    position: relative;
}
.request-demo-btn button {
    background-color: #2B81FF;
    color: white;
    padding: 11px 52px;
    font-size: 13px;
    border-radius: 40px;
    border: none;
    background-color: #2b81ff;
    color: white;
    border-radius: 40px;
}
.request-demo-arrow-icon {
    border: 1px solid;
    background-color: #2B81FF;
    border-radius: 100%;
    padding: 7px;
    margin-left: -46px;
    background: rgb(255 255 255 / 39%);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 41px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}
.request-demo-arrow-icon img{
    width: 31px;
}
.book-demo-inner-input .pac-target-input {
    padding: 12.6px 41px !important;
    font-size: 12px !important;
    background-color: transparent;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}
.book-demo-ProschoolLogo img {
    width: 152px;
}
.book-demo-ProschoolLogo {
    margin: 24px 0px;
}
.What-brought-you-us-today-select-input label{
    font-size: 14px;
    font-weight: 500;
}
.book-demo-inner-input #teacher-create-profile-input {
    padding: 12.6px 0px 12.6px 138px !important;
    font-size: 12px !important;
    background-color: transparent;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}
.book-demo-inner-input .teacher-create-popup-gender-input {
    position: absolute;
    top: 41.2px;
    left: 1%;
    font-size: 14px;
}
.book-demo-inner-input .teacher-create-popup-gender-input-folder .form-icon {
    position: absolute;
    top: 53px;
    left: 3.6%;
    transform: translateY(-50%);
    z-index: 2;
}
.book-demo-inner-input i{
    position: absolute;
    top: 53%;
    left: 4%;
    color: #CDCDCD;
}
.book-demo-section{
    background: url(../../assets/images/book-demo-background.png) 0 0;
    background-repeat: no-repeat;
}
.book-demon-request-received {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 505px;
    justify-content: center;
    padding: 11px 0px;
    border-radius: 62px;
    background-color: white;
    box-shadow: 0px 1.4px 10px #d5d5d5;
    margin: 0px 0px 30px 0px;
    position: fixed;
    top: 15%;
}
.book-demon-request-received-image img{
    width: 30px;
}
.book-demon-request-received p{
    color: black;
}
.select-phone-nymber {
    position: absolute;
    bottom: 15px;
    left: 42px;
    width: 22%;
}
.select-phone-nymber .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: 20px;
}
.select-phone-nymber .css-953pxc-MuiInputBase-root-MuiInput-root::before {
    border-bottom: none !important;
}


@media (min-width: 280px) and (max-width: 320px){
    .What-brought-you-us-today-select h2 {
        font-size: 13px !important;
    }
    .What-brought-you-us-today-select-folder {
        margin: 12px 0px 0px 0px !important;
    }
    .book-demo-container h2 {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .book-demo-form {
        width: 100%;
        gap: 14px;
    }
    .request-demo-btn button {
        padding: 11px 53px;
        font-size: 11px;
    }
    .book-demo-inner-input i {
        position: absolute;
        top: 51%;
        left: 4%;
    }
    .book-demo-inner-input {
        max-width: 48%;
        gap: 9px;
    }
    .What-brought-you-us-today-select h2{
        font-size: 14px;
    }
    .request-demo-btn-folder {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }
}

@media only screen and (max-width: 767px){
    .book-demo-container {
        max-width: 100%;
        margin: 33px auto 20px auto;
    }
    .book-demo-container h2 {
        font-size: 20px;
        margin: 0px 0px 0px 0px;
    }
    .book-demo-container p {
        font-size: 12px;
        max-width: 500px;
        margin: 12px 0px 20px 0px;
    }
    .book-demo-inner-input {
        width: 100%;
        gap: 9px;
    }
    .book-demo-inner-input label {
        font-size: 12px;
    }
    .book-demo-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input, 
    .book-demo-inner-input #teacher-create-profile-input , 
    .book-demo-inner-input .pac-target-input{
        padding: 12.5px 14px !important;
        font-size: 11px !important;
    }
    .book-demo-inner-input .teacher-create-popup-gender-input {
        position: absolute;
        top: 34.2px;
        left: 1%;
        font-size: 14px;
    }
    .book-demo-inner-input .teacher-create-popup-gender-input-folder .form-icon {
        position: absolute;
        top: 45px;
        left: 3.6%;
    }
    .book-demo-inner-input i {
        position: absolute;
        top: 59%;
        left: 4%;
    }
    .What-brought-you-us-today-select h2 {
        font-size: 13px;
        font-weight: 600;
    }
    .What-brought-you-us-today-select-folder {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin: 22px 0px 0px 0px;
    }
    .What-brought-you-us-today-select-input label {
        font-size: 11px;
    }
    .What-brought-you-us-today-select textarea {
        width: 100%;
        font-size: 12px;
    }
    .request-demo-btn button {
        padding: 11px 57px;
        font-size: 13px;
    }
    .request-demo-btn-folder {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
    .request-demo-btn-folder span {
        font-size: 11px;
    }
    .book-demo-form {
        width: 100%;
        gap: 14px;
    }
    .book-demo-inner-input #teacher-create-profile-input {
        padding: 12.6px 0px 12.6px 90px !important;
    }
    .book-demo-inner-input .pac-target-input {
        padding: 12.6px 41px !important;
    }
    .book-demo-container h2 {
        font-size: 18px;
    }
    .book-demo-section {
        background: transparent;
    }
    .book-demo-inner-input span {
        font-size: 10px;
    }
}