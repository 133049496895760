.course-inner-info-wrapper {
    display: flex;
    gap: 12px;
    margin: 7px 0px 0px 0px;
}
.course-inner-info {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}
/* .course-inner-info-image{
    width: 32px;
    height: 33px;
} */
.course-inner-info-image img{
    width: 32px;
    height: 33px;
}
.course-inner-info-title h2 {
    font-size: 13px;
    margin: 0px;
    font-weight: 300;
    color: #898989;
}
.Sales-Courses-toggle-btn {
    display: flex;
    align-items: center;
    gap: 11px;
}
.course-location {
    display: flex;
    gap: 10px;
    align-items: center;
}
.course-location span{
    font-size: 18px;
}
.course-location p{
    font-size: 13px;
    color: black;
}
.course-inner-title-wrapper{
    display: flex;
    align-items: center;
    gap: 11px;
}
.course-main-container {
    display: flex;
    align-items: center;
    gap: 13px;
    margin: 12px 0px 0px 0px;
    /* position: absolute;
    bottom: 14px; */
}
.course-inner-info-title p {
    margin: 0px;
    color: #0068FF;
}