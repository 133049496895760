.student-table-container {
    font-family: Arial, sans-serif;
    overflow-x: auto;
}
  
.student-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #898989;
}
.student-table th, 
.student-table td {
    text-align: center;
    padding: 8px;
    font-size: 14px;
}
.student-table td{
    border: 1px solid #898989;
}
.student-table th {
    background-color: #CFE5FB;
}
.table-Records-header-wrapper {
    display: flex;
    justify-content: space-between;
}
.table-records-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 28px;
}
.table-records-add-studsent, .table-records-Scan-studsent {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table-records-add-studsent span{
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0068FF;
    color: white;
}
.table-records-add-studsent p{
    font-size: 15px;
    color: #0068FF;
    font-weight: 600;
}
.table-records-Scan-studsent{
    border: 2px solid black;
    border-radius: 10px;
    padding: 8px 17px;
}
.scan-table-image img{
    width: 18px;
}
.table-records-Scan-studsent p{
    color: black;
    font-weight: 500;
}
.table-records-btn-wrapper button {
    border-radius: 10px;
    padding: 13px 32px;
    font-size: 13px;
    background-color: #0068FF;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
}
.table-Records-header-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}