.Add-school-inner-input, 
.Add-school-inner-des{
    width: 100% !important;
}
.Add-school-inner-input-feilds-folder .Add-school-inner-input{
    width: 49% !important;
}
.Add-school-inner-input-feilds-folder{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
}
.Add-school-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
}
.Add-school-wrapper .Create-Course-inner-input-container {
    gap: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 68%;
}
.Add-school-right-section{
    width: 35% !important;
}
.Add-school-right-section .uploded-image-section {
    width: auto !important;
}
.add-school-file-image {
    /* height: 424px; */
    height: 128px !important;
}
#add-school-image-container #uplode_image_btn {
    position: absolute;
    top: 65px;
}
#add-school-image-container #uplode_image_btn .course-main-image .course-image-close {
    position: absolute;
    top: 31%;
    right: -2%;
    z-index: 2;
}
.add-school-btn-folder{
    /* margin: 20px 0px 0px 0px; */
    margin: 0px 0px 0px 0px !important;
}
.create-your-Sub-domainproschool_ai_btn {
    position: absolute;
    top: 31px;
    right: 5px;
    font-size: 13px;
    background-color: white;
    border: navy;
    padding: 6px 20px;
    border-radius: 4px;
}
.add-school-cancle-btn{
    font-size: 13px !important;
    padding: 4px 43px !important;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid black !important;
}
.add-school-btn-folder .Create-Course-btn-wrapper button {
    width: 100%;
}
#uplode_image_btn .course-main-image {
    width: 100%;
    height: 425px;
}
#uplode_image_btn .course-main-image .course-uploded-image {
    width: 100%;
    height: 100%;
}
#uplode_image_btn {
    position: absolute;
    top: 168px;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}
#add-school-image-container {
    position: absolute;
    top: 17.1%;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.pac-target-input {
    width: 100%;
    /* background-color: #ECECEC; */
}
#uplode_image_btn .course-main-image .course-image-close {
    position: absolute;
    top: 1%;
    right: 2%;
}
.add-school-btn-folder .add-school-btn{
    font-size: 11px;
    padding: 8px 43px;
    height: 31px;
    border-radius: 6px;
}
.add-school-btn-folder .reuplode-image-folder {
    margin: 0px 0px 0px 0px;
}
.School-location{
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
    padding: 0px;
}
/* .School-location input{
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
    font-size: 13px;
    outline: none;
    padding: 11px 20px;
} */
.School-location input{
    border: 1px solid #bababa;
    background-color: #F5F5F5;
}
.Add-school-inner-input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
}
.add-school-icons {
    position: absolute;
    top: 33px;
    left: 2%;
}
.add-school-icons2 {
    position: absolute;
    top: 33px;
    left: 18px;
}
.add-school-icons3{
    position: absolute;
    top: 34px;
    left: 18px;
}
.add-school-input input{
    padding: 10px 50px !important;
}
.Add-school-inner-input-feilds-folder .Add-school-inner-input .School-location input, 
.Add-school-left-inner-input .Add-school-inner-input .admin-name input {
    padding: 11px 49px;
}
.Add-school-inner-des label span{
    font-size: 10px;
    color: #CACACA;
}
.add-school-des-wrapper .Add-school-inner-des {
    width: 100% !important;
}
.add-school-des-wrapper {
    display: flex;
    gap: 12px;
}
.add-school-des-wrapper .Add-school-inner-input .add-school-icons2 {
    position: absolute;
    top: 11%;
    left: 18px;
}
.Add-school-left-inner-input {
    display: flex;
    gap: 12px;
}
.Add-school-left-inner-input .Add-school-inner-input{
    width: 50% !important;
}

.Create-Courses-wrapper .add-school{
    margin: 0 0 8px;
    padding-bottom: 6px;
}
.admin-add-school-section .Add-school-wrapper .Create-Course-inner-input-container {
    display: flex;
    gap: 2px;
}
.admin-add-school-section .Add-school-inner-input-feilds-folder {
    display: flex;
    gap: 0px 12px;
}

@media only screen and (min-resolution: 125dpi) {
    .add-school-file-image {
        min-height: auto;
    }
    .create-your-Sub-domainproschool_ai_btn {
        position: absolute;
        top: 27px;
        right: 5px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 767px){
    .Add-school-left-inner-input {
        display: flex;
        flex-direction: column;
    }
    .add-school-des-wrapper .Add-school-inner-input .add-school-icons2 {
        position: absolute;
        top: 44%;
        left: 18px;
    }
    .add-school-btn-folder .add-school-btn {
        margin: 10px 0px 0px 0px;
    }
    .add-school-des-wrapper .Add-school-inner-des .add-school-btn-folder .add-school-cancle-btn {
        width: 100% !important;
    }
    .add-school-icons3 {
        position: absolute;
        top: 26px;
        left: 18px;
    }
    .add-school-icons2 {
        position: absolute;
        top: 24px;
        left: 18px;
    }
    .add-school-icons {
        position: absolute;
        top: 22px;
        left: 6%;
    }
}