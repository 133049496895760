.are-you-sure-delete-popup-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 114;
    background-color: rgba(0, 0, 0, 0.469);
}

.are-you-sure-delete-popup-section-container {
    max-width: 718px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 47px 36px;
    background-color: white;
    border-radius: 17px;
    position: fixed;
    top: 50%;
    left: 0%;
    right: 0%;
    transform: translateY(-50%);
}

.are-you-sure-delete-popup-section-folder h2 {
    font-size: 26px;
}

.delete-alert-popup {
    margin: 0px 0px 17px 0px;
}

#remove-popup-section .Course-close {
    background-color: #F0183D;
    color: white;
}