.StudentReview-wrapper {
    display: flex;
    width: 100%;
    gap: 31px;
}
.StudentReview-left-section {
    width: 25%;
}
.StudentReview-left-container {
    text-align: center;
    padding: 28px;
    border-radius: 11px;
    box-shadow: 0px 2px 11px #f2f2f2;
}
.StudentReview-card-folder span{
    font-size: 20px;
    font-weight: 600;
}
.Students-reviews-title{
    margin: 11px 0px;
}
.StudentReview-star-folder {
    display: flex;
    justify-content: center;
    gap: 11px;
    margin: 0px 0px 3px 0px;
}
.StudentReview-star-folder span{
    color: #FAAF19;
    font-size: 16px;
}
.Students-reviews-number-rating, 
.Students-reviews-number-rating span{
    font-size: 14px !important;
}
.StudentReview-progress-folder {
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.StudentReview-progress {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.StudentReview-progress span{
    font-size: 14px;
}
.StudentReview-btn {
    padding: 7px 0px;
    width: 100%;
    font-size: 13px;
    color: white;
    background-color: #0068FF;
    border: none;
    margin: 20px 0px 0px 0px;
    border-radius: 6px;
}
.StudentReview-card-folder h2{
    margin: 13px 0px;
    font-size: 27px;
}
.StudentReview-right-section {
    width: 75%;
    height: 100vh;
    overflow-y: scroll;
}
.StudentReview-right-section h2{
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}
.student-review-card-title {
    display: flex;
    align-items: center;
    gap: 13px;
}
.student-review-card-title-image{
    width: 48px;
    height: 48px;
}
.student-review-card-title-image img{
    width: 100%;
}
.student-review-card-title p{
    font-size: 13px;
    color: black;
    font-weight: 600;
}
.StudentReview-star-wrapper {
    display: flex;
    gap: 11px;
    margin: 10px 0px 10px 0px;
}
.StudentReview-star-wrapper span{
    color: #FAAF19;
}
.StudentReview-card-des-folder p{
    font-size: 13px;
    color: black;
}
.StudentReview-card-des-folder span a{
    font-size: 13px;
    text-decoration: underline;
}
.Student-Review-cad0-wrapper {
    display: flex;
    flex-direction: column;
    gap: 27px;
    padding-bottom: 20px;
}