.Overview-Lesson-downlode-btn, 
.Overview-Lesson-downlode-pdf-btn{
    cursor: pointer;
}
.Overview-Lesson-downlode-btn span, 
.Overview-Lesson-downlode-pdf-btn span {
    font-size: 14px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0068ff;
    color: white;
}

.pdf-file-container {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 1px 7px #e4e4e4b8;
}


@media only screen and (min-width: 653px) and (max-width: 720px){
    .overview-lesson-read-wrapper {
        display: flex;
        flex-direction: row;
    }
    .overview-lesson-read-left-section {
        width: 35%;
        height: 387px;
        overflow-y: scroll;
    }
    .overview-lesson-read-right-section {
        width: 80%;
    }
}

@media only screen and (max-width: 767px){
    .overview-lesson-read-left-container {
        height: 357px;
    }
    .Overview-Lesson-ai-voice-animation-folder .view-course-Details-back-btn-folder{
        margin: 0px;
    }
}

.overview-lesson-start-assignment-btn{
    background-color: #cccccc !important;
    color: #666666;
    cursor: not-allowed;
    font-size: 13px;
}

