.Add-school-inner-input,
.Add-school-inner-des {
    width: 100% !important;
}

.Add-school-inner-input-feilds-folder .Add-school-inner-input {
    width: 49% !important;
}

.Add-school-inner-input-feilds-folder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
}

.Add-school-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
}

.Add-school-wrapper .Create-Course-inner-input-container {
    gap: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 68%;
}

.Add-school-right-section {
    width: 35% !important;
}

.Add-school-right-section .uploded-image-section {
    width: auto !important;
}

.add-school-file-image {
    height: 424px;
}

.add-school-btn-folder {
    margin: 20px 0px 0px 0px;
}

.add-school-btn-folder .Create-Course-btn-wrapper button {
    width: 100%;
}

#uplode_image_btn .course-main-image {
    width: 100%;
    /* height: unset; */
    /* margin-top: 30px; */
}

#uplode_image_btn .course-main-image .course-uploded-image {
    width: 100%;
    /* height: 100%; */
    height: 131px;
    position: absolute;
    top: 50% !important;
    left: 0%;
    right: 0%;
    transform: translateY(-50%) !important;
}

/* #uplode_image_btn {
    position: absolute;
    top: 303px;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
} */

#add-school-image-container {
    position: absolute;
    top: 17.1%;
    left: 0%;
    right: 0%;
    margin: auto;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.pac-target-input {
    width: 100%;
    /* background-color: #ECECEC; */
}

#uplode_image_btn .course-main-image .course-image-close {
    position: absolute;
    top: 1%;
    right: 2%;
}
.School-location{
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
    padding: 0px;
}
.School-location input{
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
    font-size: 13px;
    outline: none;
    padding: 11px 20px;
}