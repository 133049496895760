.topratingreview-wrapper {
    display: flex;
    width: 100%;
    gap: 18px;
    margin: 50px 0px 20px 0px;
}
.topratingreview-left-section{
    width: 20%;
}
.topratingreview-left-container{
    text-align: center;
    padding: 13px;
    box-shadow: 0 1px 8px #d5d5d5bd;
    height: 326px;
    position: sticky;
    top: 99px;
    border-radius: 5px;
}
.topratingreview-title{
    font-size: 16px;
    color: black;
    font-weight: 600;
}
.topratingreview-left-section h2{
    font-size: 25px;
    font-weight: 600;
    margin: 13px 0px 10px 0px;
}
.topratingreview-stars {
    display: flex;
    justify-content: center;
    gap: 9px;
}
.topratingreview-stars span {
    color: #FAAF19;
    font-size: 14px;
}
.topratingreview-progress-bar-folder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
.topratingreview-progress-bar-folder p{
    color: black;
    font-size: 13px;
}
.rating-numbers p{
    color: black;
    margin: 5px 0px 15px 0px;
}
.topratingreview-right-section{
    width: 80%;
}
.topratingreview-right-section h2{
    font-size: 17px;
}
.send-message-icon {
    position: absolute;
    top: 11px;
    right: 13px;
    cursor: pointer;
}
.topratingreview-card-folder{
    padding: 20px;
    box-shadow: 0 1px 8px #d5d5d5bd;
    border-radius: 5px;
}
.topratingreview-card-folder .topratingreview-stars{
    justify-content: flex-start;
}
.topratingreview-card-inner-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0px 0px 10px 0px;
}
.topratingreview-card-inner-profile-folder {
    display: flex;
    align-items: center;
    gap: 13px;
}
.topratingreview-card-inner-profile {
    width: 45px;
    height: 45px;
}
.topratingreview-card-inner-profile img{
    width: 100%;
}
.topratingreview-card-profile-title {
    color: black;
    font-weight: 500;
    font-size: 13px;
    margin: 0px 0px 2px 0px;
}
.topratingreview-stars-number{
    color: black !important;
}
.topratingreview_btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 27px;
}
.topratingreview-reply-btn{
    font-size: 13px;
    border: none;
    padding: 6px 24px;
    background-color: transparent;
    box-shadow: 0px 0px 5px #c7c7c7ad;
    border-radius: 6px;
}
.topratingreview-reply-btn i{
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}
.topratingreview_btn-container span{
    color: #FF6363;
    cursor: pointer;
}
.topratingreview-card-des-folder {
    margin: 10px 0px 15px 0px;
}
.topratingreview-card-des-folder p{
    font-size: 12px;
    color: black;
    line-height: 22px;
}
.topratingreview-card-massege-wrapper {
    display: flex;
    align-items: center;
    gap: 19px;
    position: relative;
}
.topratingreview-card-inner-profile {
    width: 45px;
    height: 45px;
}
.topratingreview-card-inner-profile img{
    width: 100%;
    border-radius: 100%;
}
.topratingreview-card-massege-wrapper textarea {
    width: 100%;
    padding: 9px 50px 9px 9px;
    height: 37px;
    font-size: 13px;
    background-color: #FCF9F9;
    border: none;
    resize: none;
    color: black;
}
.topratingreview-card-massege-edit-folder, 
.topratingreview-card-date-folder {
    display: flex;
    justify-content: flex-end;
}
.topratingreview-card-massege-edit-folder button{
    border: none;
    background-color: transparent;
    font-size: 13px;
    color: #0068FF;
}
.topratingreview-card-massege-edit-folder button:nth-child(2){
    color: #FF6363;
}
.topratingreview-card-date-folder span{
    font-size: 11px;
    color: #7D7B7B;
}
.topratingreview-card-massege-edit-folder{
    margin: 10px 0px;
    gap: 12px;
}
.topratingreview-progress-bar-folder .css-8atqhb {
    width: 77%;
}
.topratingreview-progress-bar-wrapper {
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    gap: 11px;
}
.topratingreview-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.AddToCart_button{
    width: 100%;
    padding: 7px 0px;
    font-size: 13px;
    background-color: #0068FF;
    color: white;
    border: none;
    margin: 10px 0px 0px 0px;
}
.topratingreview-card-des-folder p span{
    color: #005BB0;
    text-decoration: underline;
    cursor: pointer;
}
.top-reviews-headre-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.topratingreview-filter {
    position: absolute;
    top: 45px;
    right: -20px;
    z-index: 2;
}
.topratingreview-sortby-filter{
    position: absolute;
    top: 45px;
    right: -20px;
    z-index: 2;
}
.topratingreview-filter-wrapper {
    width: 158px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #d7d7d7ad;
}
.topratingreview-filter-wrapper li{
    padding: 10px;
    border-bottom: 1px solid #F2F2F2;
}
.topratingreview-filter-wrapper li:hover{
    color: #0068FF;
    background-color: #6aa6ff3f;
}
.topratingreview-filter-wrapper li a{
    font-size: 14px;
    cursor: pointer;
}
.top-reviews-filter-dropdown, 
.top-reviews-Sort-dropdown{
    position: relative;
}
.top-rating-review-rating-popup-overlay {
    background: transparent !important;
}
.top-rating-review-rating-popup {
    position: absolute !important;
    top: 45% !important;
    right: 8% !important;
    padding: 0px !important;
    border-radius: 10px !important;
    background: transparent !important;
}
.topratingreview-reply-btn:focus{
    box-shadow: 0px 0px 5px #c7c7c7ad;
    border: none;
}
#ratings_progress_bar {
    appearance: none;
    -webkit-appearance: none;
    height: 9px;
}
  
  #ratings_progress_bar::-webkit-progress-bar {
    background-color: #F0F0F0;
    border-radius: 10px;
  }

  #ratings_progress_bar::-webkit-progress-value {
    background-color: #FAAF19;
    border-radius: 10px;
  }

  #ratings_progress_bar::-moz-progress-bar {
    background-color: #FAAF19;
    border-radius: 10px;
  }
.topratingreview_btn_replay_btn {
    display: none;
}
.topratingreview-card-message{
    font-size: 14px;
    color: black;
}
.topratingreview-card-edit-massege {
    font-size: 14px;
    color: black;
}

@media only screen and (max-width: 768px){
    .topratingreview-left-section {
        width: 50%;
    }
    .topratingreview-title {
        font-size: 15px;
    }
}

@media only screen and (max-width: 820px){
    .topratingreview-left-section {
        width: 50%;
    }
}

@media only screen and (min-width: 830px) and (max-width:  900px){
    .topratingreview-left-section {
        width: 30%;
    }
    .topratingreview-right-section {
        width: 68%;
    }
    .topratingreview-title {
        font-size: 13px;
    }
    .topratingreview-left-section h2 {
        font-size: 21px;
        margin: 6px 0px 5px 0px;
    }
    .topratingreview-stars span {
        font-size: 12px;
    }
    .topratingreview-right-section {
        width: 68%;
    }
}

@media only screen and (max-width: 767px){
    .ratingDetails-wrapper .course-inner-info-title h2{
        font-size: 11px !important;
    }
    .topratingreview-wrapper {
        margin: 20px 0px 20px 0px;
        display: flex;
        flex-direction: column;
    }
    .topratingreview-left-section {
        width: 100%;
        position: inherit;
    }
    .topratingreview-right-section {
        width: 100%;
    }
    .topratingreview_btn-container {
        gap: 10px;
    }
    .topratingreview-reply-btn {
        font-size: 10px ;
        padding: 6px 12px ;
    }
    .topratingreview-card-des-folder p {
        font-size: 12px;
    }
    .topratingreview-card-massege-wrapper {
        display: flex;
        align-items: baseline;
    }
    .topratingreview-card-massege-wrapper textarea {
        height: 32px;
        font-size: 10px;
        padding: 9px 30px 9px 9px;
    }
    .send-message-icon {
        position: absolute;
        top: 7px;
        right: 5px;
    }
    .topratingreview-card-inner-profile {
        width: 45px;
        height: 45px;
    }
    .topratingreview-card-edit-massege {
        font-size: 12px;
    }
    .topratingreview-card-edit-massege {
        font-size: 12px;
        width: 82%;
    }
    .send-message-icon img{
        width: 19px;
    }
    .topratingreview-card-massege-edit-folder button {
        font-size: 11px;
    }
    .topratingreview-card-date-folder span {
        font-size: 9px;
    }
    .topratingreview-title {
        font-size: 14px;
    }
    .topratingreview-left-section h2 {
        font-size: 23px;
    }
    .rating-numbers p {
        font-size: 10px;
    }
    .topratingreview-progress-bar-folder p, 
    .topratingreview-progress-bar-folder span {
        font-size: 12px;
    }
    .topratingreview-right-section h2 {
        font-size: 16px;
    }
    .topratingreview_btn-container span {
        font-size: 14px;
    }
    .topratingreview-stars span {
        font-size: 12px;
    }
    .topratingreview-card-des-folder p {
        font-size: 11px;
    }
    .topratingreview-card-massege-wrapper {
        gap: 7px;
    }
    .topratingreview-card-massege-edit-folder {
        gap: 0px;
    }
    .topratingreview-card-profile-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
    }
    .topratingreview-stars {
        gap: 5px;
    }
    .topratingreview_btn_replay_btn {
        display: none;
    }
}
@media only screen and (min-width: 653px) and (max-width: 720px){
    .topratingreview-wrapper {
        margin: 20px 0px 20px 0px;
        display: flex;
        flex-direction: row;
    }
    .topratingreview-left-section {
        width: 35%;
        position: inherit;
    }
    .topratingreview-right-section {
        width: 62%;
    }
    .topratingreview-title {
        font-size: 12px;
    }
    .topratingreview-left-section h2 {
        font-size: 20px;
    }
    .topratingreview-progress-bar-folder p {
        font-size: 11px;
    }
    .topratingreview-card-profile-title {
        font-size: 12px;
    }
    .topratingreview-left-container {
        height: 295px;
    }
    #ratings_progress_bar {
        width: 71%;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .topratingreview-title {
        font-size: 12px;
    }
    .topratingreview-left-section h2 {
        font-size: 21px;
    }
    .topratingreview-stars span {
        font-size: 11px;
    }
    .rating-numbers p {
        font-size: 10px;
    }
    .topratingreview-progress-bar-folder p {
        font-size: 11px;
    }
    .topratingreview-right-section h2 {
        font-size: 14px;
    }
    .topratingreview-card-folder {
        padding: 13px;
    }
    .topratingreview-card-massege-wrapper textarea {
        font-size: 9px;
    }
    .topratingreview-card-massege-edit-folder button {
        font-size: 10px;
    }
    .topratingreview_btn_replay_btn {
        justify-content: flex-start;
    }
    .topratingreview-card-profile-title {
        font-weight: 500;
        font-size: 12px;
        margin: 0px 0px 4px 0px;
    }
    .topratingreview_btn-container {
        display: none;
    }
    .topratingreview-card-profile-title {
        width: 150px;
    }
}