.countdown-timer {
    display: flex;
    justify-content: center;
}
.countdown-timer-folder {
    background: #dce8ff63;
    padding: 14px;
    margin: 0px 0px 20px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 105px;
}
.coming-soon-image{
    width: 95px;
}
.countdown-timer-folder p {
    font-size: 15px;
    font-weight: 700;
    font-family: cursive;
    /* background-color: #0068ff; */
    /* width: 328px; */
    color: #0068ff;
    padding: 15px 16px 0px 0px;
    border-radius: 7px;
    margin: 0px auto 0px auto;
    letter-spacing: 1px;
}
  
.time-section {
    background-color: white;
    color: red;
    /* border: 1px solid #666; */
    margin: 0 5px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 113px;
    /* box-shadow: 0px 2px 5px #dfdfdfba; */
    border-radius: 7px;
}
.time {
    font-size: 1.3em;
    font-weight: bold;
    color: #002469;
}
.label {
    font-size: 0.55em;
    color: #002469;
}

@media only screen and (max-width: 767px){
    .time-section {
        margin: 0 5px;
        padding: 6px;
        width: 56px;
    }
    .time {
        font-size: 1.1em;
    }
    .countdown-timer-folder p {
        font-size: 11px;
        padding: 9px 0px 0px 0px;
        margin: 0px auto 0px auto;
    }
    .coming-soon-image{
        display: none;
    }
}