.my-orders-table table{
    width: 100%;
}
.my-orders-table table thead{
    border-bottom: 1px solid #A1D3EF;
}
.my-orders-table table thead th{
    font-size: 14px;
    padding: 30px;
    font-weight: 600;
}
.my-orders-table table tbody tr {
    background-color: #F5FBFF;
    box-shadow: 0px 1px 9px #e6e6e6;
}
.my-orders-table table tbody tr td{
    padding: 30px;
    font-weight: 600;
}
.my-orders-table table tbody tr td:last-child{
    text-align: end;
}
.my-orders-table table tbody tr td p{
    font-size: 12px;
    font-weight: 500;
    color: black;
}
.my-orders-course ,
.my-orders-price{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.my-orders-course button{
    font-size: 12px;
    background-color: transparent;
    border: none;
    text-align: left;
    color: #0068FF;
}
.my-orders-price span {
    font-size: 14px;
    color: #018010;
    font-weight: 500;
}
.my-orders-Invoice-btn {
    padding: 5px 79px;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #0068FF;
    color: #0068FF;
    border-radius: 4px;
    cursor: pointer;
}
.my-orders-Invoice-btn:focus{
    border: 1px solid #0068FF;
}
.my-orders-line{
    margin: 0px 0px 20px 0px;
}
.my-orders-space{
    margin: 0px 0px 30px 0px;
}
.my-orders-serach input {
    width: 100%;
    padding: 12px 57px;
    font-size: 13px;
    border-radius: 40px;
    box-shadow: 0px 1px 8px #e7e7e7;
    border: none;
    position: relative;
}
.my-orders-serach {
    position: relative;
    margin: 30px 0px;
}
.my-orders-serach span{
    position: absolute;
    top: 10px;
    left: 25px;
    font-size: 17px;
    color: #999999;
}
.my-orders-table-card-wrapper{
    display: none;
}

@media only screen and (max-width: 768px){
    .my-orders-Invoice-btn {
        padding: 5px 35px;
    }
    .my-orders-table table tbody tr td {
        padding: 17px;
    }
}

@media (max-width: 767px) {
    .my-orders-table{
        /* display: none; */
        overflow-x: scroll;
    }
    .my-orders-table table {
        width: 607px;
    }
    .my-orders-table-card-folder {
        padding: 21px;
        box-shadow: 0px 1px 11px #dfdfdf;
        border-radius: 11px;
    }
    .my-orders-table-card-wrapper {
        display: flex;
        flex-direction: column;
        gap: 26px;
        margin-bottom: 20px;
    }
    .my-orders-table-card-date {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .my-orders-table-card-date span, 
    .my-orders-table-card-course span, 
    .my-orders-table-card-course-price span{
        font-size: 13px;
        font-weight: 600;
    }
    .my-orders-table-card-date p{
        font-size: 12px;
        color: black;
        font-weight: 500;
    }
    .my-orders-table-card-price {
        display: flex;
        width: 100%;
        margin: 10px 0px 30px 0px;
        justify-content: space-between;
    }   
    .my-orders-table-card-course {
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .my-orders-table-card-course-price {
        width: 17%;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .my-orders-Invoice-btn-folder{
        text-align: center;
    }
    .my-orders-table-card-course .my-orders-course button {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: -5px;
    }
    .my-orders-table table thead th {
        font-size: 13px;
        padding: 18px;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .my-orders-table-card-date span, .my-orders-table-card-course span, .my-orders-table-card-course-price span {
        font-size: 12px;
    }
    .my-orders-table-card-date p {
        font-size: 10px;
    }
    .my-orders-table-card-course .my-orders-course button {
        width: 144px;
        font-size: 11px;
    }
    .my-orders-Invoice-btn {
        font-size: 11px;
    }
    .my-orders-serach input {
        font-size: 11px;
    }
    .my-orders-serach span {
        font-size: 15px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px) {
    .my-orders-table-card-folder {
        padding: 21px;
        box-shadow: 0px 1px 11px #dfdfdf;
        border-radius: 11px;
        width: 48%;
    }
    .my-orders-table-card-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: inherit;
        gap: 21px;
        margin-bottom: 20px;
    }
}