.user-activity-log-section {
    margin: 30px 0px 30px 0px;
}
.user-activity-log-wrapper {
    padding: 23px;
    border-radius: 10px;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}
.user-activity-log-title h2{
    font-size: 17px;
}
.user-activity-log-card-folder {
    display: flex;
    gap: 15px;
}
.user-activity-log-card-image{
    width: 50px;
    height: 50px;
}
.user-activity-log-card-image img{
    width: 100%;
    border-radius: 100%;
}
.user-activity-log-card-des-folder {
    width: 100%;
}
.user-activity-log-card-des-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-activity-log-card-des-title {
    display: flex;
    align-items: baseline;
    gap: 10px;
}
.user-activity-log-title {
    color: black;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 9px;
    margin: 0px 0px 25px 0px;
}
.user-activity-log-title h2 {
    font-size: 17px;
    margin: 0px;
}
.user-activity-log-inner-title{
    color: black;
    font-weight: 400;
}
.user-activity-log-time {
    font-size: 11px;
    color: #AAAFB6;
}
.user-activity-log-card-des-title-wrapper span{
    font-size: 13px;
    color: #AAAAAA;
}
.user-activity-log-card-des {
    font-size: 14px;
    color: #252525;
    font-weight: 400;
    margin: 3px 0px 0px 0px;
}
.user-activity-log-card-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    overflow: hidden;
    height: 500px;
}
.user-activity-log-view-all-btn {
    display: flex;
    justify-content: flex-end;
    color: #0068ff;
    font-size: 14px;
    margin: 15px 15px 0px 0px;
    background-color: transparent;
    border: none;
    margin-left: auto;
}
.user-activity-log-card {
    background-color: #F5F5F5;
    padding: 19px;
    border-radius: 9px;
}
.user-activity-log-des{
    display: none;
}

@media only screen and (max-width: 767px){
    .user-activity-log-title h2 {
        font-size: 15px;
        margin: 0px;
    }
    .user-activity-log-title img{
        width: 20px;
    }
    .user-activity-log-card {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding: 15px;
    }
    .user-activity-log-inner-title{
        font-size: 12px;
    }
    .user-activity-log-time {
        font-size: 10px;
    }
    .user-activity-log-card-des-title-wrapper span {
        font-size: 11px;
        margin-left: auto;
    }
    .user-activity-log-card-des {
        font-size: 12px;
        margin: 3px 0px 0px 0px;
    }
    .user-activity-log-card-des{
        display: none;
    }
    .user-activity-log-des {
        display: block;
        margin-top: -5px;
        word-wrap: break-word;
    }
    .user-activity-log-card-des-title-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 9px;
        margin-bottom: 6px;
    }
    .user-activity-log-card-des-title {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .user-activity-log-title {
        margin: 0px 0px 15px 0px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .user-activity-log-card-des-title {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 10px;
    }
    .user-activity-log-card-des {
        display: block;
    }
    .user-activity-log-des {
        display: none;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .user-activity-log-des {
        display: block;
        margin-top: 0px;
    }
    .user-activity-log-card-des-title-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0px;
        margin-bottom: 6px;
    }
    .user-activity-log-card-des-title-wrapper span {
        margin-left: 0px;
    }
}