.Terms-and-Conditions-wrapper {
    margin: 92px 0px 0px 0px;
}
.Terms-and-Conditions-list, 
.Privacy_Policy_list {
    margin: 10px 0px;
    /* max-width: 1102px; */
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.Terms-and-Conditions-list li span, 
.Privacy_Policy_list span {
    font-weight: 600;
    font-size: 13px;
}
.Terms-and-Conditions-list li, 
.Privacy_Policy_list li p {
    list-style: lower-alpha;
    margin-left: 27px;
    font-size: 13px;
    color: black;
}
.childrens-online-privacy-protection-act{
    max-width: 100%;
}
.Privacy_Policy_list li p {
    margin-left: 8px;
}
.Privacy_policy-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}