.BulkUplode-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px auto;
    width: 100%;
    margin: 10px 0px 0px 0px;
    gap: 11px;
}
.BulkUplode-image img{
    width: 65%;
}
.BulkUplode-button-folder {
    border: 1px solid black;
    width: 284px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    padding: 8px 0px;
    gap: 10px;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
}
.dashboard-popup-teacher-profile-details-form span {
    font-size: 14px;
    color: #6e6e6e;
}
.BulkUplode-button-folder p {
    /* color: green; */
    font-size: 14px;
    color: black;
}

.BulkUplode-button-wrapper div {
    /* color: green;
    font-size: 14px; */
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.fileName p {
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BulkUplode-button-wrapper .File_Uploaded,
.BulkUplode-button-wrapper .fileName p {
    font-size: 13px !important;
    color: green;
}