
.live-activity-log-title h2{
    font-size: 17px;
}
.live-activity-log-card-folder {
    display: flex;
    gap: 15px;
    background-color: #F5F5F5;
    padding: 19px;
    border-radius: 9px;
    align-items: baseline;
}
.live-activity-log-card-image{
    width: 50px;
    height: 50px;
}
.live-activity-log-card-image img{
    width: 100%;
    border-radius: 100%;
}
.live-activity-log-card-des-folder {
    width: 100%;
}
.live-activity-log-card-des {
    font-size: 14px;
    color: #252525;
    font-weight: 500;
    margin: 3px 0px 0px 0px;
}
.live-activity-log-card-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.live-activity-log-view-all-btn {
    display: flex;
    justify-content: flex-end;
    color: #0068ff;
    font-size: 14px;
    margin: 15px 15px 0px 0px;
    background-color: transparent;
    border: none;
    margin-left: auto;
}
.live-activity-log-card-des-folder span {
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
    color: #000000;
    margin: 10px 0px 0px 0px;
}

@media only screen and (max-width: 767px){
    .live-activity-log-title img{
        width: 20px;
    }
    .live-activity-log-card-folder {
        display: flex;
        gap: 10px;
        padding: 15px;
        align-items: flex-start;
    }
    .live-activity-log-inner-title{
        font-size: 11px;
    }
    .live-activity-log-time {
        font-size: 10px;
    }
    .live-activity-log-card-des-title-wrapper span {
        font-size: 11px;
        margin-left: auto;
    }
    .live-activity-log-card-des {
        font-size: 12px;
        margin: 3px 0px 0px 0px;
        word-wrap: break-word;
    }
    .live-activity-log-card-des-title-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 9px;
        margin-bottom: 6px;
    }
    .live-activity-log-card-des-title {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .live-activity-log-card-des-folder span {
        font-size: 10px;
    }
    .live-activity-log-card-container {
        margin: 20px 0px 0px 0px;
    }
    .live-activity-log-card-des-folder {
        width: 80%;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .live-activity-log-card-folder {
        padding: 12px;
    }
    .live-activity-log-card-des {
        font-size: 11px;
    }
    .live-activity-log-card-des-folder span {
        font-size: 9px;
    }
    .live-activity-log-card-image {
        width: 45px;
        height: 45px;
    }
}