@media only screen and (min-width: 830px) and (max-width:  900px){
    .invited-student-list-table-wrapper .list-of-students-wrappper table tbody tr td {
        font-size: 12px;
        padding: 15px 0px;
    }
    .invited_bg {
        width: 110px;
        font-size: 11px;
    }
    .list-of-students-wrappper table thead tr th, 
    .LessonViewProgress-table-container table thead tr th {
        font-size: 13px;
    }
    .student-remove {
        font-size: 10px;
    }
    .list-of-students-wrappper table tbody tr td img {
        width: 50px;
        height: 50px;
    }
}