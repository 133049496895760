.lesson-lsit-section {
    margin: 0px 0px 20px 0px;
}

@media only screen and (max-width: 767px){
    .student-Active-Lessons {
        font-size: 16px;
    }
    .lesson-lsit-wrapper .Student-Active-Lesson-title-folder h2{
        font-size: 12px !important;
    }
}