.assignment-list-section {
    margin: 0px 0px 20px 0px;
}
#Lesson_Container .view-Students-course-details-folder {
    width: 137px;
}

#Lesson_Container .view-Students-course-details-folder {
    width: 137px;
}
@media only screen and (max-width: 767px){
    .assignment-list-wraper .Student-Active-Lesson-title-folder h2{
        font-size: 12px !important;
    }
}