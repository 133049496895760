.subscription-plan-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    background-color: #00000063;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscription-plan-container {
    /* border: 1px solid; */
    width: 70%;
    /* display: flex; */
    margin: 30px;
    background-color: white;
    padding: 34px;
    border-radius: 20px;
    height: 670px;
    position: relative;
}
.subscription-plan-container h2{
    font-size: 21px;
    text-align: center;
    margin: 0px 0px;
}
.subscription-plan-card-folder {
    width: 285px;
    padding: 36px 0px;
    border-radius: 20px;
    background-color: white;
    position: relative;
    z-index: 2;
    box-shadow: 0px 2px 13px #dddddd;
}
.subscription-plan-price-folder {
    /* border: 1px solid; */
    width: 157px;
    margin: 0px auto;
    padding: 7px;
    border-radius: 13px;
    box-shadow: 0px 2px 7px #c9c9c9;
    position: relative;
    z-index: 3;
    background-color: white;
}
.subscription-plan-price-folder span {
    font-size: 13px;
    font-weight: 600;
}
.subscription-plan-price-folder h2{
    text-align: center;
    margin: 0px 0px 3px 0px;
    font-size: 26px;
}
.subscription-plan-price-folder p{
    text-align: center;
    font-size: 11px;
}
.subscription-plan-list {
    /* width: 83%; */
    width: 89%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin: 40px auto 68px auto;
    height: 177px;
}
.subscription-plan-list li {
    position: relative;
    list-style: none;
    padding-left: 22px;
    font-size: 12px;
}
.subscription-plan-list li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background: url(../../assets/images/check-circle-outline.png);
    background-size: cover;
}
.subscription-plan-card-folder:nth-child(2) .subscription-plan-list li:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background: url(../../assets/images/check-circle-outline2.png);
    background-size: cover;
}
.subscription-plan-card-folder:nth-child(3) .subscription-plan-list li:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background: url(../../assets/images/check-circle-outline3.png);
    background-size: cover;
}
.Subscribe-btn {
    padding: 10px 26px;
    font-size: 12px;
    border-radius: 33px;
    color: white;
    background-color: #6970FC;
    border: none;
    margin: 0px auto -51px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}
.subscription-plan-background1 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.subscription-plan-background2 {
    position: absolute;
    top: 0px;
}
.subscription-price-icon {
    position: absolute;
    top: -13px;
    right: -11px;
}
.subscription-price-icon img {
    width: 41px;
}
.subscription-plan-card-container {
    display: flex;
    justify-content: space-between;
}
.subscription-plan-card-folder:nth-child(2) .Subscribe-btn{
    background-color: #D054CD;
}
.basic{
    color: #6E56FB;
}
.balanced{
    color: #D63AE1;
}
.best, 
.enterprise{
    color: #3AABCA;
}
.subscription-plan-card-folder:nth-child(3) .Subscribe-btn, 
.subscription-plan-enterprice-card-folder .Subscribe-btn{
    background-color: #3AABCA;
}
.subscription-plan-enterprice-card-folder {
    width: 452px;
    /* height: 100vh; */
    padding: 36px 0px;
    border-radius: 20px;
    background-color: white;
    position: relative;
    z-index: 2;
    box-shadow: 0px 2px 13px #dddddd;
}
.subscription-plan-enterprice-list-folder {
    display: flex;
    gap: 22px;
}
.subscription-plan-enterprice-list-folder .subscription-plan-list {
    width: auto;
}
.Subscription-Plan-enterprice-contact-form-folder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 28px;
}
.Subscription-Plan-enterprice-contact-form-container p{
    font-size: 13px;
    margin: 0px 0px 20px 0px;
    color: #717171;
}
.Subscription-Plan-enterprice-inner-input {
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 13px;
    position: relative;
}
.Subscription-Plan-enterprice-inner-input label, 
.Subscription-Plan-enterprice-inner-description-input label {
    font-size: 13px;
    font-weight: 400;
    position: relative;
}
.Subscription-Plan-enterprice-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12.5px 14px !important;
    font-size: 13px !important;
}
.Subscription-Plan-enterprice-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 5px !important;
    background-color: transparent !important;
}
.Subscription-Plan-enterprice-inner-input .pac-target-input {
    padding: 12.6px 41px !important;
    font-size: 12px !important;
    background-color: transparent;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}
.Subscription-Plan-enterprice-inner-input i {
    position: absolute;
    top: 53%;
    left: 4%;
    color: #CDCDCD;
}
.Subscription-Plan-enterprice-contact-form-container {
    width: 80%;
    margin: 0px auto;
}
.Subscription-Plan-enterprice-inner-description-input textarea{
    padding: 10px;
    font-size: 13px;
    width: 100%;
    height: 100px;
    border: 1px solid #CDCDCD;
}
.Subscription-Plan-enterprice-inner-description-input{
    display: flex;
    flex-direction: column;
    gap: 13px;
    resize: none;
}
.Subscription-plan-submit{
    padding: 10px 26px;
    font-size: 12px;
    border-radius: 33px;
    color: white;
    background-color: #6970FC;
    border: none;
    margin: 24px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}
.subscription-plan-enterprice-price-folder {
    /* border: 1px solid; */
    width: 157px;
    margin: 0px auto;
    padding: 7px;
    border-radius: 13px;
    box-shadow: 0px 2px 7px #c9c9c9;
    position: relative;
    z-index: 3;
    background-color: white;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscription-plan-container .Custom_Tabs_section {
    margin: 21px auto;
}
.subscription-plan-card-folder::after {
    content: "";
    position: absolute;
    top: 140px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 64px;
    height: 2px;
    background: linear-gradient(90deg, #7B1DF9 0%, #772FFA 7%, #714AFA 20%, #6D5FFB 35%, #696EFC 51%, #6876FC 70%, #6779FC 100%);
}
.subscription-plan-card-folder:nth-child(2):after {
    content: "";
    position: absolute;
    top: 140px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 64px;
    height: 2px;
    background: linear-gradient(90deg, #F91DD0 0%, #EB29D7 6%, #D03FE4 19%, #BB51EF 34%, #AC5DF6 50%, #A465FB 70%, #A167FC 100%);
}
.subscription-plan-card-folder:nth-child(3):after {
    content: "";
    position: absolute;
    top: 140px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 64px;
    height: 2px;
    background: linear-gradient(90deg, #69C8E1 0.59%, #0D90B4 100.1%);
}
.circle1 {
    position: absolute;
    top: 0px;
    left: -16px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 100%;
}
.circle2 {
    position: absolute;
    top: -17px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 100%;
}
.circle3 {
    position: absolute;
    top: -7px;
    right: 33px;
    margin: auto;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0px 1px 7px #b1b1b1;
}
.circle4 {
    position: absolute;
    bottom: -5px;
    right: 9px;
    margin: auto;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0px 1px 7px #b1b1b1;
}
.Subscription-Plan-enterprice-container {
    margin: 0px auto;
    display: flex;
    justify-content: center;
}
.subscription-plan-container-close {
    position: absolute;
    top: 17px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.Subscription-Plan-enterprice-inner-input {
    /* padding: 12.6px 0px 12.6px 138px !important; */
    font-size: 12px !important;
    /* background-color: transparent;
    border: 1px solid #CDCDCD;
    border-radius: 6px; */
}
#Subscription-Plan-enterprice-inner-input-contact{
    padding: 12.6px 0px 12.6px 135px;
    /* padding: 12.6px 41px !important; */
    font-size: 12px !important;
    background-color: transparent;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
}
.Subscription-Plan-enterprice-inner-input .select-phone-nymber {
    position: absolute;
    bottom: 26px;
    left: 42px;
    width: 22%;
}
.Subscription-Plan-enterprice-inner-input .form-icon {
    position: absolute;
    top: 52px;
    left: 3.6%;
}
.subscription-plan-card-container {
    display: flex;
    justify-content: center;
    gap: 58px;
}

@media only screen and (min-resolution: 125dpi){
    .subscription-plan-container {
        width: 80%;
        margin: 30px;
        padding: 23px;
        height: 542px;
        position: relative;
    }
    .subscription-plan-container h2 {
        font-size: 19px;
    }
    .subscription-plan-container .Custom_Tabs_section {
        margin: 13px auto;
        padding: 9px;
    }
    .subscription-plan-card-folder {
        width: 285px;
        padding: 27px 0px;
    }
    .subscription-plan-card-folder:nth-child(2):after, 
    .subscription-plan-card-folder:nth-child(1):after, 
    .subscription-plan-card-folder:nth-child(3):after {
        position: absolute;
        top: 118px;
        left: 0px;
    }
    .subscription-plan-list {
        display: flex;
        gap: 7px;
        margin: 22px auto 68px auto;
        height: 149px;
    }
    .subscription-plan-list li {
        font-size: 11px;
    }
    .Subscription-Plan-enterprice-contact-form-container p {
        font-size: 11px;
        margin: 0px 0px 15px 0px;
    }
    .Subscription-Plan-enterprice-inner-input , 
    .Subscription-Plan-enterprice-inner-description-input{
        display: flex;
        gap: 7px;
    }
    .Subscription-Plan-enterprice-inner-input label, 
    .Subscription-Plan-enterprice-inner-description-input label {
        font-size: 11px;
    }
    .Subscription-Plan-enterprice-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 11px !important;
    }
    .Subscription-Plan-enterprice-inner-description-input textarea {
        font-size: 11px;
    }
    .Subscription-Plan-enterprice-inner-input .form-icon {
        position: absolute;
        top: 43px;
        left: 3.6%;
    }
}

@media only screen and (max-width: 768px){
    .subscription-plan-container {
        width: 93%;
        padding: 26px;
        border-radius: 20px;
        height: 670px;
    }
    .subscription-plan-card-container {
        display: flex;
        gap: 15px;
    }
    .subscription-plan-list {
        margin: 36px auto 68px auto;
        height: 209px;
    }
    .Subscription-Plan-enterprice-contact-form-folder {
        display: flex;
        gap: 21px !important;
    }
    .subscription-plan-container-close {
        position: absolute;
        top: 193px !important;
        right: 45px !important;
    }
}

@media only screen and (max-width: 820px){
    .subscription-plan-card-container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
    .subscription-plan-card-folder {
        width: 285px;
    }
    .Subscription-Plan-enterprice-contact-form-folder {
        display: flex;
        gap: 21px !important;
    }
    .subscription-plan-container-close {
        position: absolute;
        top: 273px !important;
        right: 36px !important;
    }
    .subscription-plan-container {
        width: 94%;
        padding: 24px;
    }
    .subscription-plan-list {
        margin: 40px auto 99px auto;
        height: 177px;
    }
}

@media only screen and (max-width: 1024px){
    .subscription-plan-card-container {
        display: flex;
        gap: 20px;
    }
    .Subscription-Plan-enterprice-contact-form-folder {
        display: flex;
        gap: 25px;
    }
    .subscription-plan-container {
        width: 94%;
    }
    .subscription-plan-container-close {
        position: absolute;
        top: 369px;
        right: 48px;
    }
}

@media only screen and (max-width: 767px){
    .subscription-plan-container-close {
        position: absolute;
        top: 14px !important;
        right: 14px !important;
        border: 2px solid;
        width: 22px;
        height: 22px;
    }
    .subscription-plan-wrapper {
        position: fixed;
        top: 0%;
        left: 0%;
        right: 0%;
        bottom: 0%;
        width: 100%;
        height: 100%;
        margin: 0px auto;
        z-index: 113;
        overflow-y: scroll;
    }
    .subscription-plan-container {
        width: 92%;
        margin: 30px;
        padding: 22px;
        border-radius: 20px;
        height: auto;
        position: absolute;
        top: -2%;
    }
    .subscription-plan-card-container {
        display: flex;
        flex-direction: column;
        gap: 43px;
    }
    .subscription-plan-card-folder {
        width: 100%;
        padding: 36px 0px;
    }
    .circle1 {
        max-width: 10px;
        max-height: 10px;
    }
    .circle2 {
        max-width: 8px;
        max-height: 8px;
    }
    .subscription-plan-enterprice-list-folder {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .Subscription-Plan-enterprice-contact-form-container p {
        font-size: 12px;
    }
    .Subscription-Plan-enterprice-inner-input {
        width: 100%;
    }
    .Subscription-Plan-enterprice-contact-form-container {
        width: 100%;
    }
    .Subscription-Plan-enterprice-inner-description-input {
        margin: 18px 0px 0px 0px;
    }
    .subscription-plan-container h2 {
        font-size: 16px;
    }
    .subscription-plan-price-folder h2 {
        font-size: 26px !important;
    }
    .Subscription-Plan-enterprice-contact-form-folder {
        display: flex;
        gap: 15px;
    }
    .Subscription-Plan-enterprice-inner-input label, 
    .Subscription-Plan-enterprice-inner-description-input label{
        font-size: 12px;
    }
    .subscription-price-icon img {
        width: 34px;
    }
    .Subscription-Plan-enterprice-inner-description-input textarea {
        font-size: 12px;
    }
    .subscription-plan-list {
        height: auto;
    }
    .subscription-plan-enterprice-list-folder .subscription-plan-list:nth-child(1){
        margin: 26px auto 26px auto;
    }
    .subscription-plan-enterprice-list-folder .subscription-plan-list:nth-child(2){
        margin: 0px auto 38px auto;
    }
    .subscription-plan-tabs-folder .Custom_Tabs_section {
        width: 485px !important;
        flex-direction: row;
    }
    .subscription-plan-tabs-folder {
        overflow-x: scroll;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .subscription-plan-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 22px;
        justify-content: center;
    }
    .subscription-plan-card-folder {
        width: 48%;
        padding: 36px 0px;
        margin-bottom: 18px;
    }
    .subscription-plan-list {
        height: 177px;
        margin: 40px auto 68px auto !important;
    }
    .subscription-plan-enterprice-list-folder {
        display: flex;
        flex-direction: row;
        gap: 0px;
    }
    .subscription-plan-container {
        position: absolute;
        top: 0px;
    }
    .subscription-plan-enterprice-card-folder {
        margin-bottom: 18px;
    }
    .Subscription-Plan-enterprice-inner-input {
        width: 48%;
    }
    .subscription-plan-price-folder h2 {
        font-size: 24px !important;
    }
    .subscription-plan-list li {
        font-size: 11px;
    }
    .subscription-plan-container h2 {
        font-size: 15px;
    }
    .Subscription-Plan-enterprice-inner-input label, 
    .Subscription-Plan-enterprice-inner-description-input label {
        font-size: 11px;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .subscription-plan-price-folder, 
    .subscription-plan-enterprice-price-folder {
        width: 136px;
    }
    .subscription-plan-list {
        width: 89%;
    }
    .subscription-plan-list li {
        font-size: 11px;
    }
    .subscription-plan-price-folder span {
        font-size: 12px;
    }
    .Subscribe-btn {
        padding: 8px 26px;
        font-size: 11px;
    }
    .Subscription-Plan-enterprice-contact-form-container p {
        margin: 0px 0px 11px 0px;
    }
    .Subscription-Plan-enterprice-inner-input .css-2fv5wa-MuiInputBase-root-MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 12px !important;
    }
    .subscription-plan-price-folder h2 {
        font-size: 20px !important;
    }
}
