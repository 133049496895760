@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
/*font-family: 'Roboto', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap');

/*font-family: 'Roboto Flex', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;500;600;700;800;900&display=swap');
/*font-family: 'Barlow Semi Condensed', sans-serif;*/

/*font-family: 'Montserrat', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*html {scroll-behavior: smooth;}*/

/* font-family: 'Poppins', sans-serif; */



/*Rj Code*/

::selection {
  background: #0096C7;
  color: #FFF;
}

::-moz-selection {
  background: #0096C7;
  color: #FFF;
}

/*html {scroll-behavior: smooth;}*/
select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  -ms-transition: all .3s ease;
  transition: all .3s ease;
}

/* h1 {
  margin: 0px 0px 30px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h2 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h3 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 46px;
}

h4 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  line-height: 1.2;
  font-weight: 600;
}

h5 {
  margin: 0px 0px 15px 0px;
  line-height: 25px;
  font-weight: 600;
}

h6 {
  margin: 0px 0px 15px 0px;
  line-height: 1.1;
  font-weight: normal;
} */

h1 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h2 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h3 {
  margin: 25px 0px 7px 0px;
  padding: 0px;
  line-height: 30px;
  /* font-weight: 600; */
  font-size: 22px;
}

h4 {
  margin: 25px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2;
  /* font-weight: 600; */
}

h5 {
  margin: 25px 0px 5px 0px;
  line-height: 25px;
  /* font-weight: 600; */
}

h6 {
  margin: 15px 0px 5px 0px;
  line-height: 1.1;
  font-weight: normal;
}

.spacer1px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 1px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer5px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 5px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer15px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 15px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer10px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 10px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer20px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 20px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer30px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 30px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer50px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 50px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

.spacer80px {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  height: 50px;
  background: none;
  margin: 0px 0px 0px 0px;
  width: 100%;
  border: none;
}

body {
  overflow-x: hidden;
  color: #333333;
  font-size: 16px;
  line-height: 1.4;
  margin: 0px;
  padding: 0px;
  outline: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  /*height: 100vh;*/
  overflow: auto;
}

p {
  line-height: 1.4;
  margin: 0px 0px 20px 0px;
  font-family: 'Poppins', sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

-webkit-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: 'Poppins', sans-serif;
}

-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: 'Poppins', sans-serif;
}

-ms-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: 'Poppins', sans-serif;
}

-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
  font-family: 'Poppins', sans-serif;
}

.form-control::-webkit-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control::-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control:-ms-input-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

.form-control:-moz-placeholder {
  color: #a1a1a1 !important;
  opacity: 1 !important;
}

:focus,
button:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

*:focus {
  outline: none;
}

input:focus {
  outline: none;
}

.pagetitle {
  position: relative;
  text-align: left;
  padding: 0px 0px 70px 0px;
}

.pagetitle h2 {
  line-height: 1.1;
  font-size: 51px;
  color: #fff;
  padding: 0px;
  margin: 0px 0px 0px 0px;
}

img {
  max-width: 100%;
  height: auto;
  outline: none;
}

a {
  outline: none;
  border: none;
  text-decoration: none;
}

.fl_w {
  float: left;
}

.container-fluid {
  padding-left: 60px;
  padding-right: 60px;
}

.lesson_generator {
  position: relative;
  text-align: left;
}

.lesson_generator_inner {
  position: relative;
  margin-bottom: 30px;
}

.lesson_generator_inner h6 {
  position: relative;
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin-bottom: 25px;
}

.assign_main_box {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.assign_main_box_left {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.assign_main_box_left_single {
  position: relative;
}

.assign_main_box_left_single label {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}

.assign_main_box_left_single label::after{
  content: "*";
  color: red;
  margin-left:2px
}

/* .assign_main_box_left_single input {
  position: relative;
  width: 100%;
  height: 45px;
  box-shadow: 0px 4px 5px 0px #0000000D;
  border: 1px solid #ECECEC;
  padding-left: 15px;
  color: #979797;
  border-radius: 6px;
  font-size: 13px;
} */
.assign_main_box_left_single select {
  position: relative;
  width: 100%;
  height: 45px;
  box-shadow: 0px 4px 5px 0px #0000000D;
  border: 1px solid #ECECEC;
  padding-left: 15px;
  color: #979797;
  border-radius: 6px;
  font-size: 13px;
}

.assign_main_box_left_single input::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.assign_main_box_left_single input::-moz-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.assign_main_box_left_single input:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.assign_main_box_left_single input:-moz-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.assign_main_box_right {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.assign_main_box_right_single {
  position: relative;
}

.assign_main_box_right_single label {
  position: relative;
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}

.assign_main_box_right_single label::after{
  content: "*";
  color: red;
  margin-left:2px
}


.assign_main_box_right_single input {
  position: relative;
  width: 100%;
  height: 45px;
  box-shadow: 0px 4px 5px 0px #0000000D;
  border: 1px solid #ECECEC;
  padding-left: 40px;
  color: #979797;
  border-radius: 6px;
  font-size: 13px;
}
.assign_main_box_right_date label {
  position: relative;
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
}

.assign_main_box_right_date label::after{
  content: "*";
  color: red;
  margin-left:2px
}
.assign_main_box_right_date:nth-child(2) label:after{
  content: "";
}

.assign_main_box_right_date input {
  font-size: 13px;
}

.assing_cal_sec_new {
  position: relative;
}
.cal_img_sec_assignment{
  position: absolute;
  left: 8px;
  top: 5px;
  z-index: 11;
  display: flex;
  align-items: center;
  width: auto;
  bottom: 0;
}
.cal_img_sec {
  position: absolute;
  left: 8px;
  /* top: 10px; */
  top: 8px;
  z-index: 3;
  display: flex;
  align-items: center;
  width: auto;
  bottom: 0;
}

/* .lesson_generator_body_sec {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
} */
.lesson_generator_body_sec {
  position: relative;
  display: flex;
  width: 100%;
  gap: 25px;
}
.lesson_generator_body_sec_left {
    width: 50%;
}

/* .lesson_generator_body_sec_left {
  position: relative;
  width: 690px;
  width: 100%;
} */

.lesson_generator_body_sec_left h6,
.lesson_generator_body_sec_right h6 {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.create-lesson-Personalize-contaiber {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Preview-lesson-Content {
  display: flex;
  align-items: center;
  gap: 42px;
  margin-bottom: 10px;
}

.lesson_generator_body_sec_left_body {
  position: relative;
  padding: 20px 20px 10px 20px;
  background: #F2F2F2;
  border-radius: 15px;
}

.lesson_generator_body_sec_left_text_part {
  background: #FFFFFF;
  border-radius: 15px;
  position: relative;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  display: none;
}

.lesson_generator_body_sec_left_text_part h5 {
  position: relative;
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.promote_part_sec {
  position: relative;
}
/* .promote_part_sec::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 35px;
  width: 1px;
  height: 36px;
  background-color: #ADA5A5;
} */

.promote_part_sec textarea {
  position: relative;
  /* width: 90%; */
  width: 77%;
  background: #fff;
  border-radius: 6px;
  padding: 5px 12px 10px 12px;
  color: black;
  font-size: 14px;
  font-weight: 400;
  padding-left: 22px;
  border: none;
  /* height: 52px; */
  resize: none;
  /* margin-left: 64px; */
}
.speech-to-text {
  position: absolute;
  right: 9%;
  bottom: 13px;
  transform: translateY(-50%);
  cursor: pointer;
}

.promote_part_sec i {
  /* position: absolute;
  left: 20px;
  z-index: 11;
  top: 21px;
  font-size: 20px;
  border-right: 1px solid #ADA5A5;
  padding-right: 10px; */
  position: absolute;
  left: 8px !important;
  z-index: 11;
  top: 8px !important;
  font-size: 14px !important;
  border-right: 0px !important;
  padding-right: 0px !important;
  color: black;
}

.go_cls_btn {
  position: absolute;
  bottom: 15px;
  /* right: 20px; */
  right: 11px;
  display: flex;
  align-items: center;
  width: 28px;
  border: none;
  padding: 0px;
  background: none;
}

.lesson_generator_body_sec_right {
  position: relative;
  width: 50%;
}

.lesson_generator_body_sec_right_body {
  position: relative;
  padding: 13px 15px 15px 15px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #E0DDDD;
}

.lesson_generator_body_sec_right_body_inner {
  position: relative;
  padding: 15px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #E0DDDD;
  height: 550px;
  width: 100%;
  resize: none;
  font-size: 13px;
}

.proc_btn_new {
  position: relative;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 40px; */
  width: 100%;
  box-shadow: 0px 1px 12px 0px #0000002E;
  background: #0068FF;
  border-radius: 4px;
  color: #fff;
  border: none;
  font-size: 14px;
  padding: 10px 0px;
}

.proc_btn_new:disabled { background-color: grey; } 

.input--file {
  position: relative;
  color: #000;
}

.input--file input[type="file"] {
  position: absolute;
  top: 10px;
  left: 12px;
  opacity: 0;
  z-index: 111;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.pdf_box_sec {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 12px 0px #0000002E;
  padding: 9px 15px 0px 15px;
  /* overflow: hidden; */
  width: 93%;
}
.pdf_box_sec {
  position: absolute;
  bottom: 37px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.create-Assignment-container-popup{display: none;
  overflow: unset !important;}
/* .create-Assignment-container-popup.show {
  position: absolute;
  bottom: 75px;
  width: 100%;
  left: 0;
  z-index: 11;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 9px 0px #9898982e;
  transition: 0.5s ease-in-out;
  padding: 9px 15px 0px 15px;
  display: block;
  border: 9px solid white;
} */

.create-Assignment-container-popup.show {
  /* position: absolute; */
  /* bottom: 75px; */
  width: 100%;
  /* left: 0; */
  /* z-index: 11; */
  /* background-color: #fff; */
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 9px 0px #9898982e;
  transition: 0.5s ease-in-out;
  /* padding: 9px 15px 0px 15px; */
  display: block;
  /* margin: 10px; */
  border: 9px solid white;
  /* border-radius: 10px; */
  margin: 10px 0px 10px 0px;
}

.pdf_box_sec_inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.pdf_box_sec_inner_single {
  align-items: center;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  display: flex;
  gap: 7px;
  padding: 4px 9px;
  position: relative;
}
.pdf_box_sec_inner_single img {
  width: 20px;
}
.pdf_box_sec_inner_single .MuiCircularProgress-root{
  width: 20px !important;
  height: 20px !important;
  transform: rotate(-90deg);
  position: relative !important;
  left: -56px !important;
}

h5 {
  font-size: 13px;
  margin: 0;
  line-height: inherit;
}

p {
  position: relative;
  font-size: 12px;
  color: #909090;
  margin: 0px;
}

/* .chat-with-ai-lession-container {
  border-radius: 15px;
  position: relative;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
} */
.chat-with-ai-lession-container {
  border-radius: 15px;
  position: relative;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 79px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 0px;
}

.chat-with-ai-lession-user-folder {
  /* max-width: 439px; */
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 11px;
  margin-left: auto;
  margin-bottom: 23px;
  margin-right: 0px;
}

.loading-chat-answer {
  position: relative;
  margin: auto;
  /* white-space: pre-wrap; */
  /* Preserve white spaces and line breaks */
  /* overflow-x: hidden;   Hide the horizontal scrollbar */
  font-family: 'Poppins';
  /* Ensure monospace font for typewriter effect */
  text-align: left;
}

.waveform{
  height: 15px;
}


.chat-with-ai-lession-AI-message {
  margin-right: auto;
  margin-left: 0px;
  background-color: white;
  padding: 8px 12px;
  border-radius: 10px;
  align-items: flex-start;
}

.chat-with-ai-lession-user-sned-massege-container {
  /* width: 360px; */
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #CECECE;
}

.chat-with-ai-lession-user-sned-massege-container .pdf_box_sec_inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.text-massege-user {
  margin: 0px 0px 0px 0px;
  text-align: end;
  color: black;
  font-weight: 500;
}

.chat-with-ai-send-pdf-folder {
  background-color: white;
  border: none;
}

.send-user-name h2 {
  font-size: 16px;
  margin: 0px;
  width: 44px;
  height: 42px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0px 1px 9px #e4e4e463;
}

.chat-with-ai-lession-AI-message {
  max-width: 100%;
  justify-content: flex-start;
  position: relative;
}

.chat-with-ai-lession-AI-message .chat-with-ai-lession-user-sned-massege-container {
  padding: 0px;
}

.chat-with-ai-lession-AI-message .chat-with-ai-lession-user-sned-massege-container p {
  text-align: left;
  margin: 36px 0px 10px 0px;
}

.chat-with-ai-lession-AI-message .chat-with-ai-lession-user-sned-massege-container {
  background-color: white;
  width: 100%;
  overflow: auto;
}

.chat-with-ai-lession-AI-message .send-user-name h2,
.chat-with-ai-edit-user-copy-past-container .send-user-name h2 {
  background-color: #2396D8;
  color: white;
}

.chat-with-ai-edit-wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin-bottom: 20px;
}

.chat-with-ai-edit-user-text-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.chat-with-ai-edit-user-copy-past-container {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 18px;
}

.chat-with-ai-edit-des-folder {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: #DADADA;
  border-radius: 8px;
  padding: 10PX 10PX;
}

.chat-with-ai-edit-des-folder p {
  width: 95%;
  color: black;
  font-weight: 600;
}

.chat-with-ai-edit-des-folder a {
  width: 5%;
  display: flex;
  font-size: 18px;
  color: #767070;
}

.chat-with-ai-edit-user-copy-past-folder {
  width: 100%;
  height: 427px;
  background-color: white;
  border-radius: 8px;
  padding: 10px 10px;
}

.user-name-with-copy-past-wrapper,
.preview-lession_generator_body_inner_des_folder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

/* .copy-past-text-container {
  display: flex;
  gap: 9px;
  align-items: center;
} */
.copy-past-text-container {
  /* display: flex;
  gap: 9px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 15px; */
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  /* position: absolute; */
  bottom: 0px;
  top: 10px;
  right: 15px;
  margin: -11px 0px 10px 0px;
  position: relative;
  }
  
  .play-pause-btn-lesson-generator #loading-bar-spinner.spinner {
    position: absolute;
    right: 10% !important;
    left: auto !important;
}
.read-chat-container .play-pause-btn-lesson-generator audio{
  height: 38px;
}
.copy-past-text-container i{
  cursor: pointer;
}

.copy-past-text-container a {
  font-size: 17px;
  color: #9A9797;
}

.inner-chat-name li {
  font-size: 13px;
  list-style: auto;
  margin-left: 10px;
}

.genrate-des-AI-inner-container {
  width: 100%;
  height: 367px;
  overflow-y: scroll;
}

.chat-with-AI-image img {
  width: 47px;
}

.genrate-des-AI-inner-text li {
  list-style: upper-alpha;
  margin-left: 15px;
  font-size: 12px;
  color: #555555;
}

.list li {
  list-style: disc;
  margin-left: 22px;
  line-height: 20px;
  color: #555555;
}

.lesson_generator_body_sec_right_past_container {
  height: 514px;
}

.lesson_generator_body_sec_right_past_container .genrate-des-AI-inner-container {
  width: 100%;
  height: 472px;
  overflow-y: scroll;
}

.Preview-lesson-text-editor-features-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin: 0px 0px 20px 0px;
  position: relative;
}

.Preview-lesson-text-editor-features {
  display: flex;
  width: 540px;
  gap: 22px;
  padding: 13px 21px;
  background-color: #F2F7FF;
  border-radius: 8px;
}

.Preview-lesson-text-editor-features p {
  font-size: 15px;
  font-weight: 600;
}

.Preview-lesson-text-editor-features-personalize {
  display: flex;
  align-items: center;
  gap: 10px;
}

.personlized_popup {
  position: absolute;
  top: 77px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #535353;
  color: white;
  padding: 16px 0px;
  width: 517px;
  border-radius: 8px;
  z-index: 5;
  text-align: center;
}

.Preview-lesson-text-editor-features-personalize h2 {
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}

.preview-lession-dat-container {
  display: flex;
  gap: 20px;
}

.preview-lession_generator_body_inner {
  position: relative;
  padding: 15px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #E0DDDD;
  height: 550px;
}

.preview-lession_generator_body_inner_text_container {
  height: 514px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 10px 10px;
  text-align: left;
}

.preview-lession_generator_body_inner_des_folder_list {
  width: 100%;
  height: 474px;
  overflow-y: scroll;
}

.Preview-lesson-Content {
  display: flex;
  align-items: center;
  gap: 42px;
}

.preview-lession-btn-folder {
  margin: 30px 0px;
}

.preview-lession-date-folder span {
  font-size: 14px !important;
  color: #888888;
}

.preview-lession-date {
  width: 96px;
  text-align: center;
  margin: 4px auto;
  padding: 4px 0px;
  background-color: #D6FFD6;
  border-radius: 30px;
}

.preview-lession-date p {
  margin: 0px;
  font-size: 11px;
  color: #0FBA00;
}

.preview-lession-date-folder .preview-lession-date:nth-child(2) {
  background-color: #FFECD6;
}

.preview-lession-date:nth-child(2) p {
  color: #FF8A00;
}

.preview-lession_generator_body_inner_des_folder .inner-chat-name li {
  font-size: 14px;
  font-weight: 700;
}

.preview-lession_generator_body_inner_des_folder_list .genrate-des-AI-inner-text li {
  font-size: 13px;
  font-weight: 500;
}

.preview-lession-course-created-successfully-wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #00000061;
}







.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.studentDashboar-teacher-popup-section {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #00000033;
  color: white;
  z-index: 113;
}

.studentDashboar-teacher-popup-wrapper {
  position: fixed;
  top: 50%;
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%) !important;
  border: 1px solid;
  max-width: 802px;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 16px;
}

.studentDashboar-teacher-title {
  text-align: center;
  border-bottom: 1px solid #C9C4C4;
  padding: 28px 0px 20px 0px;
  font-size: 22px !important;
  color: black;
}

.studentDashboar-teacher-popup-folder {
  display: flex;
  width: 49%;
  gap: 19px;
  padding: 17px 0px 12px 14px;
  color: black;
  border-radius: 9px;
  background-color: #F8F8F8;
}

.studentDashboar-teacher-popup-folder:hover {
  background-color: #E1F4FF;
}

.studentDashboar-teacher-popup-container {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  padding: 0px 48px;
  /* overflow-y: scroll; */
  /* max-height: 461px; */
}
.studentDashboar-teacher-popup-main-container {
  height: 346px;
  overflow-y: scroll;
  position: relative;
}
.studentDashboar-teacher-popup-cross-btn i{
  position: absolute;
  /* z-index: 14; */
  color: black;
  top: -56px;
  left: 0%;
  right: 0%;
  margin: auto;
  display: flex;
  justify-content: center;
  /* border: 1px solid; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
}

.studentDashboar-teacher-image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.studentDashboar-teacher-des-inner-title-folder {
  display: flex;
  gap: 13px;
  align-items: center;
}

.studentDashboar-teacher-des-inner-name {
  font-size: 17px !important;
  margin: 0px 0px 0px 0px !important;
  font-weight: 400;
}

.studentDashboar-teacher-des-inner-title-rate-wrapper {
  display: flex;
  gap: 7px;
  align-items: baseline;
}

.studentDashboar-teacher-des-inner-title-rate-folder {
  display: flex;
  gap: 6px;
}

.studentDashboar-teacher-des-inner-title-rate-folder a,
.studentDashboar-teacher-des-inner-title-rate-folder p {
  font-size: 12px;
  color: #FFBB33;
}

.studentDashboar-teacher-des-inner-title-rate-wrapper span {
  font-size: 11px;
}

.studentDashboar-teacher-inner-des {
  font-size: 11px;
  margin: 6px 0px 3px 0px;
}

.studentDashboar-teacher-des-view-profile-wrapper {
  display: flex;
  width: 204px;
  gap: 12px;
}

.studentDashboar-teacher-des-view-profile-folder {
  width: 50%;
  display: flex;
  align-items: baseline;
  gap: 10px;
  cursor: pointer;
  position: relative;
}
.studentDashboar-teacher-des-view-profile-folder:nth-child(1):after {
  content: "";
  position: absolute;
  top: 62%;
  left: 93%;
  right: 0%;
  margin: auto;
  transform: translateY(-50%);
  width: 1px;
  height: 14px;
  background-color: #C2C2C2;
}

.studentDashboar-teacher-des-view-profile-folder span i,
.studentDashboar-teacher-des-view-profile-folder p {
  font-size: 10px;
  color: #0068FF;
}

.studentDashboar-teacher-popup-close-btn {
  position: absolute;
  top: -70px;
  left: 47%;
  /* right: 0%; */
  margin: auto;
  padding: 6px 14px;
  border-radius: 100%;
  text-decoration: none;
  background-color: white;
  font-size: 21px;
  border: none;
  outline: none;
}

.studentDashboar-teacher-popup-close-btn a {
  color: black;
}

.studentDashboar-Suggested-Courses-short-by-filter-serach-inner-inpuut {
  display: flex;
  align-items: center;
  gap: 91px;
}

.studentDashboar-search-filter {
  width: 600px;
  box-shadow: 0px 1px 9px #e7e7e7;
  border-radius: 36px;
}

.loading-chat-answer p {
  font-size: inherit !important;
  color: black !important;
}

.custom-datetime input {
  color: black;
  padding-left: 40px;
}
.my-custom-picker .rdtPicker {
  position: absolute !important;
  bottom: 100%;                  /* Positions the picker above the input field */
  left: 0 !important;            /* Aligns the picker to the left edge of the input field */
  z-index: 9999 !important;      /* Ensures the picker is above other content */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a shadow for better visibility */
}

.chat-with-ai-lession-container::-webkit-scrollbar {
  width: 10px;
}
.chat-with-ai-lession-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.chat-with-ai-lession-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}
.chat-with-ai-lession-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.editor-container::-webkit-scrollbar {
  width: 10px;
}
.editor-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.editor-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}
.editor-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* This ensures the stacking context is created for the date picker's parent */
/* .my-custom-picker {
  position: relative;
  z-index: 0;
} */



.custom-text-field input::placeholder {
  color: #979797 !important;
  font-size: 14px;
}

.studentDashboar-search-filter {
  width: 600px;
}
/* .textarea-container {
  position: relative; 
  height: 50px; 
  overflow: hidden;
}

#lesson_promote_textarea {
  position: absolute;
  bottom: 0; 
  width: 100%; 
  overflow-y: hidden; 
  resize: none; 
} */

#lesson_promote_textarea{
  overflow-y: hidden; 
  resize: none; 
}

.preview-student-wrapper{
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  margin:auto;
  padding: 30px 20px;
  width: 100%;
  height: 100%;
  z-index: 113;
  background-color: #00000061;
}
.close-modal-btn-student-view {
  position: absolute;
  top: -12px;
  right: -17px;
  background-color: white;
  font-size: 16px;
  border-radius: 25px;
  padding: 5px;
  width: 34px;
  box-shadow: 0px 0px 3px #a7a7a7;
}
.dismis-close{
  color: black;
}
.play-pause-btn-lesson-genrater .fa-circle-play, 
.play-pause-btn-lesson-genrater .fa-circle-pause{
  font-size: 17px;
}
.play-pause-btn-lesson-genrater .fa-circle-pause{
  display: none;
}
.genrater-close-btn {
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 93px;
}
.read-chat-container{
  width: 100%;
}
.genrater-close-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: white;
  color: #fff;
  font-size: 31px;
  border: none;
  border-radius: 50%;
  transition: background-color 0.25s;
  position: absolute;
  bottom: 16px;
  right: 90px;
  box-shadow: 0px 1px 5px #c2c2c2;
}
.genrater-close-btn i {
  position: absolute;
  left: 5.9px !important;
  z-index: 11;
  top: 6.9px !important;
  font-size: 17px !important;
  border-right: 0px !important;
  padding-right: 0px !important;
  color: black;
}
.lesson-genrater-downlode,
.lesson-genrater-toggle-folder {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
.lesson-generator-massege-folder {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.create-Assignment-input-feild .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px;
}



/* Style the dropdown button */
.tab-dropdown {
  border-right: 1px solid;
}
.dropbtn {
  color: black !important;
  border: none !important;
  background-color: transparent !important;
  display: flex !important;
  align-items: center !important;
  gap: 7px !important;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 156px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 15;
  border-radius: 10px;
  top: 47px;
}

/* Style the assignment items */
.assignment-item, #add-assignment {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}
div#add-assignment {
  color: #0068FF;
}

/* Change color of dropdown links on hover */
.assignment-item:hover, #add-assignment:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the button is clicked */
.dropdown-content {
  display: block;
}
.save-assignment-popup-wrapper{
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  margin: auto;
  background-color: #35353585;
  z-index: 113;
  width: 100%;
  height: 100%;
}
.save-assignment-popup-cotainer{
  max-width: 718px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 47px 36px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 0%;
  right: 0%;
  margin: auto;
  border-radius: 14px;
  z-index: 2;
}
.save-assignment-popup-btn button{
  border: 1px solid;
    padding: 8px 33px;
    font-size: 15px;
    border-radius: 8px;
    background-color: #0068ff;
    color: white;
}
.save-assignment-popup-btn button:nth-child(1){
  border: 1px solid black !important;
  color: black !important;
  background-color: transparent !important;
}
.save-assignment-popup-cotainer h2{
  font-size: 25px;
}
.save-assignment-popup-btn{
  display: flex;
  gap: 20px;
  align-items: center;
}
.rubric-popup-wrapper{
  z-index: 113;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 600px;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000036;
}
.rubric-popup-container {
  width: 543px;
  text-align: center;
  padding: 31px;
  background-color: white;
  border-radius: 11px;
}
.rubric-popup-container h2{
  font-size: 19px;
}
.rubric-popup-container button{
  background-color: #2E83FF;
  padding: 9px 20px;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 15px;
}

.lesson_generator_body_sec_left_text_part .chat-with-ai-lession-container .chat-with-ai-lession-user-sned-massege-container .text-massege-user {
  text-align: left;
}

@media only screen and (min-resolution: 125dpi){
  .lesson_generator_body_sec_left h6, .lesson_generator_body_sec_right h6 {
    font-size: 12px;
  }
  .lesson-genrater-downlode, 
  .lesson-genrater-toggle-folder {
    display: flex;
    align-items: baseline;
    gap: 14px;
  }
}

@media only screen and (min-width:1200px) and (max-width:1300px) {
  
  .container-fluid {
    padding-left: 45px;
    padding-right: 45px;
  }

}


@media only screen and (min-width:992px) and (max-width:1199px) {

  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }

}

@media only screen and (min-width:768px) and (max-width:991px) {
  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
}  
}

@media (min-width: 280px) and (max-width: 320px){
  .pdf_box_sec_inner_single span h5 {
    width: 55px !important;
  }
  .create-lesson-Personalize-contaiber {
    display: flex;
    flex-wrap: wrap;
  }
  .Preview-lesson-Content {
    margin-left: auto;
  }
  .button-section {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .promote_part_sec textarea {
    max-width: 63% !important;
  }
  .pdf_box_sec_inner_single .MuiCircularProgress-root {
    width: 18px !important;
    height: 18px !important;
    left: -32px !important;
  }
  .lesson_generator_editor_toos-folder .edit-active-lesson-btn-container button {
    padding: 2px 6px !important;
  }
  .assignment-item, #add-assignment {
    padding: 12px 4px;
  }
  .dropdown-content {
   width: 100px !important;
   position: absolute;
   min-width: 156px;
   top: 47px;
   right: 49px;
  }
  .save-assignment-popup-cotainer {
    width: 263px;
    padding: 20px 8px;
  }
}

@media only screen and (min-width: 830px) and (max-width:  900px){
  .promote_part_sec textarea {
    height: 25px !important;
  }
}

@media only screen and (min-width: 653px) and (max-width: 720px) {
  .pdf_box_sec_inner_single span h5 {
    min-width: 232px;
  }
  .lesson_generator_body_sec .view-lessons-btn-container button {
    font-size: 13px;
  }
  .preview-student-wrapper .Course-created-successfully-container {
    max-width: 500px;
  }
  .promote_part_sec textarea {
    min-width: 84% !important;
  } 
  .assign_main_box_right {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
  .lesson_generator_editor_toos-folder .edit-active-lesson-btn-container button {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 767px){
  .lesson_generator_body_sec_right h6, 
  .Preview-lesson-text-editor-features-personalize h2 {
    font-size: 12px;
  }
  .Preview-lesson-Content {
    margin-bottom: 16px;
  }
  .preview-student-wrapper .Course-created-successfully-container {
    height: 534px;
  }
  .close-modal-btn-student-view {
    position: absolute;
    top: -12px;
    right: -8px;
    box-shadow: 0px 0px 3px #a7a7a7;
  }
  .pdf_box_sec_inner_single span h5 {
    width: 92px;
  }
  .preview-student-wrapper .Course-created-successfully-folder {
    height: 474px !important;
  }
  .preview-student-wrapper .Course-created-successfully-folder h4{
    font-size: 20px;
  }
  .chat-with-ai-lession-container {
    padding: 0px 5px 0px 0px;
  }
  .pdf_box_sec_inner_single {
    gap: 6px !important;
    padding: 4px 7px;
    margin-top: 8px !important;
  }
  .pdf_box_sec_inner_single .MuiCircularProgress-root {
    width: 18px !important;
    height: 18px !important;
    left: -52px !important;
  }
  .promote_part_sec textarea {
    width: 70% !important;
    line-height: 16px;
  }
  .mic {
    position: absolute;
    bottom: 13px !important;
    right: 30px !important;  
  }
  .lesson-genrater-mic {
    /* display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: white;
    color: #fff;
    font-size: 31px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.25s; */
    position: absolute;
    bottom: 13px !important;
    right: 30px !important;
    /* box-shadow: 0px 1px 5px #c2c2c2; */
  }
  .genrater-close-btn {
    position: absolute;
    bottom: 13px !important;
    right: 64px !important;
  }
  .genrater-close-btn i {
    position: absolute;
    left: 5.5px !important;
    top: 6.9px !important;
  }
  .assignment-item, #add-assignment {
    font-size: 11px;
  }
  .dropdown-content {
    min-width: 133px;
  }
  .lesson_generator_body_sec_left h6, 
  .lesson_generator_body_sec_right h6 {
    font-size: 11px;
  }
  .lesson-generator-massege-folder {
    display: flex;
    flex-wrap: wrap;
  }
  .lesson_generator_editor_toos-folder .edit-active-lesson-btn-container {
    display: flex;
    gap: 1px;
  }
  .lesson_generator_editor_toos-folder .edit-active-lesson-btn-container button {
    padding: 2px 8px !important;
  }
  .save-assignment-popup-cotainer {
    max-width: 338px;
    padding: 27px 8px;
  }
  .save-assignment-popup-cotainer h2{
    font-size: 17px;
    margin: 0px 0px 15px 0px;
  }
  .save-assignment-popup-btn {
    display: flex;
    gap: 15px;
  }
  .save-assignment-popup-btn button{
    padding: 5px 30px !important;
    font-size: 12px !important;
  }
}