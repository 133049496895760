.StudentList-wrapper{
    margin: 0px 0px 20px 0px
}
.StudentList-wrapper .list-of-students-wrappper {
    padding: 15px;
    margin: 20px 0px 20px 0px;
}
.StudentList-wrapper .list-of-students-wrappper table tbody tr td:nth-child(1) {
    width: auto;
}

@media only screen and (max-width: 767px){
    .StudentList-wrapper .list-of-students-wrappper {
        margin: 0px;
    }
}
