/* .MarketPlace-Shopping-Cart-section h2 {
    font-size: 21px;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}

.marketplace-shopping-cart-wrapper {
    display: flex;
    width: 100%;
    gap: 36px;
    margin: 0px 0px 70px 0px;
}
.marketplace-shopping-cart-left-main-wrapper{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.marketplace-shopping-cart-left-section {
    display: flex;
    gap: 25px;
    padding: 19px;
    background-color: white;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}

.course-bg-image {
    width: 25%;
    height: 215px;
}

.marketplace-shopping-cart-des-wrapper {
    width: 75%;
}

.marketplace-shopping-cart-des-wrapper .delete {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    color: #FF6363;
    cursor: pointer;
}

.marketplace-shopping-cart-des-wrapper h2 {
    text-align: left;
    font-size: 25px;
    margin: 6px 0px 10px 0px;
    word-wrap: break-word;
    width: 740px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.marketplace-shopping-cart-des-wrapper .teacher-name {
    font-size: 16px;
    color: #0068FF;
}

.studentDashboar-Suggested-Courses-profile-rate {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 14px 0px 10px 0px;
}

.course-list-counts {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 0px 0px 11px 17px;
}

.course-list-counts li {
    font-size: 14px;
    list-style: disc;
    margin-right: 15px;
}

.marketplace-shopping-cart-price {
    font-size: 25px;
    text-align: end !important;
    width: 100% !important;
}

.marketplace-shopping-cart-right-section {
    width: 25%;
}

.marketplace-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding-bottom: 14px;
}

.marketplace-price p {
    font-size: 16px;
    color: black;
}

.marketplace-price h2 {
    margin: 0px;
    font-size: 21px;
}

.marketplace-inner-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.marketplace-inner-price span {
    font-size: 13px;
    color: #24C159;
    font-weight: 500;
}

.price-checkout-btn {
    width: 100%;
    font-size: 16px;
    padding: 10px 0px;
    background-color: #0068FF;
    color: white;
    border: none;
}

.marketplace-price-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 15px;
    margin-bottom: 19px;
    border-bottom: 1px solid;
}

.cupen-Applied-btn {
    border: 1px solid #D8D8D8;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 7px;
    margin: 10px 0px 16px 0px;
}

.cupen-Applied-btn span {
    font-size: 13px;
}

.cupen-Applied-btn button {
    font-size: 12px;
    background-color: #0068FF;
    border: none;
    border: 1px solid;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
}

.marketplace-shopping-cart-section .title {
    font-size: 24px;
    margin: 0px;
}

.marketplace-inner-price span i {
    color: black;
    cursor: pointer;
    margin-left: 6px;
}
.marketplace-course-price-scroller {
    display: flex;
    justify-content: space-between;
}
.marketplace-course-price-scroller p{
    color: #797979;
    font-size: 13px;
}
.marketplace_course{
    font-size: 20px;
}
.marketplace-course-price-scroller-folder {
    height: 98px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: scroll;
    padding: 0px 4px 0px 0px;
}

@media only screen and (max-width: 767px) {
    .marketplace-shopping-cart-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin: 16px 0px 0px 0px;
    }

    .marketplace-shopping-cart-right-section {
        width: 100%;
    }

    .marketplace-shopping-cart-left-section, 
    .marketplace-shopping-cart-left-main-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .marketplace-shopping-cart-left-section .course-bg-image {
        width: 100%;
    }

    .marketplace-price p {
        font-size: 13px;
    }

    .marketplace-price h2 {
        font-size: 17px;
    }

    .marketplace-price-header {
        gap: 10px;
        padding-bottom: 13px;
        margin-bottom: 13px;
    }

    .cupen-Applied-btn {
        padding: 10px 7px;
        margin: 10px 0px 10px 0px;
    }

    .cupen-Applied-btn span {
        font-size: 11px;
    }

    .cupen-Applied-btn button {
        font-size: 10px;
        padding: 5px 20px;
    }

    .price-checkout-btn {
        font-size: 12px;
        padding: 10px 0px;
    }

    .course-name {
        font-size: 13px;
    }

    .marketplace-shopping-cart-des-wrapper h2 {
        font-size: 14px;
        margin: 6px 0px 5px 0px;
        width: 100%;
    }

    .marketplace-shopping-cart-des-wrapper .teacher-name {
        font-size: 13px;
        color: #0068FF;
    }

    .studentDashboar-Suggested-Courses-profile-rate {
        gap: 6px;
        margin: 7px 0px 10px 0px;
    }
    .ratingCard-wrapper {
        margin: 17px 0px 0px 0px;
    }

    .studentDashboar-Suggested-Courses-profile-rate p {
        font-size: 11px;
    }

    .marketplace-shopping-cart-price {
        font-size: 18px !important;
    }
    .studentDashboar-Suggested-Courses-profile-rate p {
        font-size: 11px;
    }
    .course-list-counts {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 7px;
        margin: 0px 0px 11px 17px;
    }
    .marketplace-shopping-cart-price {
        font-size: 18px !important;
    }
    .marketplace-shopping-cart-des-wrapper {
        width: 100%;
    }
    .marketplace-shopping-cart-des-wrapper .delete {
        font-size: 14px;
    }
    .course-list-counts li {
        font-size: 13px;
        list-style: disc;
    }
    .marketplace-shopping-cart-des-wrapper h2 {
        margin: 6px 0px 10px 0px;
    }
    .marketplace-shopping-cart-section .title {
        font-size: 19px;
        margin: 26px 0px 0px 0px;
    }
    .marketplace_course {
        font-size: 17px;
        margin: 14px 0px 0px 0px;
    }
} */

.MarketPlace-Shopping-Cart-section h2 {
    font-size: 21px;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}

.marketplace-shopping-cart-wrapper {
    display: flex;
    width: 100%;
    gap: 16px;
}
.ratingCard-wrapper {
    margin: 8px 0px 30px 0px;
}
.marketplace-shopping-cart-left-main-wrapper {
    width: 73%;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.marketplace-shopping-cart-left-container {
    overflow-y: scroll;
    height: 100vh;
    padding: 9px 9px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.marketplace-shopping-cart-left-section {
    display: flex;
    gap: 18px;
    padding: 16px 19px;
    background-color: white;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}

.course-bg-image {
    width: 18%;
    height: 146px;
}
.course-name{
    font-size: 11px;
}

.marketplace-shopping-cart-des-wrapper {
    width: 80%;
}

.marketplace-shopping-cart-des-wrapper .delete {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    color: #FF6363;
    cursor: pointer;
}

.marketplace-shopping-cart-des-wrapper h2 {
    text-align: left;
    font-size: 19px;
    margin: 6px 0px 8px 0px;
    word-wrap: break-word;
    width: 740px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.marketplace-shopping-cart-des-wrapper .teacher-name {
    font-size: 12px;
    color: #0068FF;
}

.studentDashboar-Suggested-Courses-profile-rate {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 9px 0px 10px 0px;
    cursor: pointer;
}
.studentDashboar-Suggested-Courses-profile-rate  a{
    color: #ffbb33 !important;
}

.course-list-counts {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 0px 0px 0px 17px;
}
.marketplace-shopping-cart-price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.course-list-counts li {
    font-size: 14px;
    list-style: disc;
    margin-right: 15px;
    font-weight: 500;
}
.marketplace-shopping-cart-price-discount{
    color: #A19B99;
    font-size: 16px;
}

.marketplace-shopping-cart-price {
    font-size: 20px !important;
    text-align: end !important;
    width: auto !important;
    margin: 0px !important;
    color: #EE7139;
}
.marketplace-shopping-cart-right-section {
    width: 30%;
}
.marketplace-shopping-cart-price-discount {
    display: flex;
    align-items: center;
    gap: 16px;
}
.marketplace-shopping-cart-des-wrapper .studentDashboar-Suggested-Courses-profile-rate a, 
.marketplace-shopping-cart-des-wrapper .studentDashboar-Suggested-Courses-profile-rate p {
    font-size: 12px;
}
.marketplace-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    padding-bottom: 14px;
}
.course-list-counts li {
    font-size: 13px;
}

.marketplace-price p {
    font-size: 15px;
    color: black;
    font-weight: 600;
}

.marketplace-price h2 {
    margin: 0px;
    font-size: 17px;
}

.marketplace-inner-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.marketplace-inner-price span {
    font-size: 13px;
    color: #24C159;
    font-weight: 500;
}

.price-checkout-btn {
    width: 100%;
    font-size: 13px;
    padding: 10px 0px;
    background-color: #0068FF;
    color: white;
    border: none;
    border-radius: 5px;
}
.marketplace-price-container {
    padding: 16px;
    background-color: white;
    box-shadow: 0px 1px 11px #d5d5d57a;
    border-radius: 10px;
}

.marketplace-price-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 15px;
    margin-bottom: 13px;
    border-bottom: 1px solid;
}

.cupen-Applied-btn {
    position: relative;
}
.cupen-Applied-btn textarea{
    width: 100%;
    font-size: 12px;
    border: 1px solid #D8D8D8;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 12px 7px;
    margin: 10px 0px 16px 0px;
    resize: none;
}
.slick-slide {
    margin: 0px 0.4px;
}
.ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
    /* width: 325px; */
    width: 299px;
}
.ratingCard-wrapper .studentDashboar-Suggested-Courses-folder .studentDashboar-Suggested-Courses-profile-rate {
    margin: 0px;
}

.cupen-Applied-btn span {
    font-size: 13px;
}

.cupen-Applied-btn button {
    font-size: 12px;
    background-color: #0068FF;
    border: none;
    border: 1px solid;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    position: absolute;
    top: 7px;
    right: 7px;
}

.marketplace-shopping-cart-section .title {
    font-size: 20px;
    margin: 5px 0px 0px 0px;
}

.marketplace-inner-price span i {
    color: black;
    cursor: pointer;
    margin-left: 6px;
}
.marketplace-course-price-scroller {
    display: flex;
    justify-content: space-between;
}
.marketplace-course-price-scroller button{
    color: #0068FF;
    font-size: 13px;
    background-color: transparent;
    border: none;
}
.marketplace-course-price-scroller p{
    color: #797979;
    font-size: 13px;
}
.marketplace-course-price-scroller p span {
    cursor: pointer;
    font-size: 12px;
    position: relative;
    left: -5px;
}
.discount-price {
    font-size: 14px !important;
    color: #A6A6A6;
    text-decoration: line-through;
}
.discount-price-folder {
    display: flex;
    gap: 10px;
    align-items: center;
}
.discount-big-price{
    color: #EE7139;
}
.marketplace_course {
    font-size: 20px;
    margin: -11px 0px 3px 0px;
}
.marketplace-course-price-scroller-folder {
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: scroll;
    padding: 0px 4px 0px 0px;
}
.ratingCard-wrapper .studentDashboar-Suggested-Courses-image img {
    height: 137px;
}
.ratingCard-wrapper .studentDashboar-profile-course-title {
    min-height: auto !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}
.ratingCard-wrapper .slick-slider{
    width: 100%;
}
.slick-prev, .slick-next {
    width: 45px !important;
    height: 45px !important;
    background-color: #ffffff !important;
    transform: translate(0, -50%) !important;
    border-radius: 100% !important;
    box-shadow: 0px 2px 12px #e2e2e2 !important;
}
.slick-prev:before, 
.slick-next:before {
    font-size: 21px !important;
    line-height: 1 !important;
    opacity: .75 !important;
    color: #0068FF !important;
}
.slick-prev:before {
    content: '❮' !important; 
}

.slick-next:before {
    content: '❯' !important;
}
.slick-next {
    right: -15px !important;
}
.ratingCard-wrapper .studentDashboar-profile-btn-folder button{
    background-color: #0068ff !important;
    color: white !important;
}
.ratingCard-wrapper .studentDashboar-profile-btn-folder button:nth-child(1) {
    background-color: transparent !important;
    color: #0068ff !important;
}

@media only screen and (max-width: 768px){
    .marketplace-shopping-cart-left-main-wrapper {
        width: 56%;
    }
    .marketplace-shopping-cart-right-section {
        width: 43%;
    }
}

@media only screen and (max-width: 820px){
    .marketplace-shopping-cart-left-main-wrapper {
        width: 55%;
    }
    .marketplace-shopping-cart-right-section {
        width: 43%;
    }
}

@media only screen and (min-width: 912px) and (max-width: 1024px){
    .marketplace-shopping-cart-left-main-wrapper {
        width: 46%;
    }
    .marketplace-shopping-cart-right-section {
        width: 52%;
    }
}

@media only screen and (min-width: 830px) and (max-width:  900px){
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        max-width: 270px;
    }
    .marketplace-shopping-cart-left-main-wrapper {
        width: 50%;
    }
    .marketplace-shopping-cart-right-section {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .marketplace-shopping-cart-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin: 16px 0px 0px 0px;
        gap: 16px;
    }
    .marketplace-shopping-cart-left-container {
        overflow-y: auto;
        height: auto;
        gap: 0px;
    }

    .marketplace-shopping-cart-right-section {
        width: 100%;
    }

    .marketplace-shopping-cart-left-section, 
    .marketplace-shopping-cart-left-main-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .marketplace-shopping-cart-left-section .course-bg-image {
        width: 100%;
    }

    .marketplace-price p {
        font-size: 13px;
    }

    .marketplace-price h2 {
        font-size: 15px;
    }

    .marketplace-price-header {
        gap: 10px;
        padding-bottom: 13px;
        margin-bottom: 13px;
    }

    .cupen-Applied-btn {
        padding: 10px 7px;
        margin: 0px 0px 0px 0px;
    }

    .cupen-Applied-btn span {
        font-size: 11px;
    }

    .cupen-Applied-btn button {
        font-size: 8px;
        padding: 5px 17px;
        top: 20px;
        right: 18px;
    }
    .cupen-Applied-btn textarea {
        margin: 1px 0px 9px 0px;
        font-size: 11px;
    }

    .price-checkout-btn {
        font-size: 12px;
        padding: 10px 0px;
    }

    .course-name {
        font-size: 13px;
    }

    .marketplace-shopping-cart-des-wrapper h2 {
        font-size: 14px;
        margin: 6px 0px 5px 0px;
        width: 100%;
    }

    .marketplace-shopping-cart-des-wrapper .teacher-name {
        font-size: 13px;
        color: #0068FF;
    }

    .studentDashboar-Suggested-Courses-profile-rate {
        gap: 6px;
        margin: 7px 0px 10px 0px;
    }
    .ratingCard-wrapper {
        margin: 17px 0px 0px 0px;
    }

    .studentDashboar-Suggested-Courses-profile-rate p {
        font-size: 11px;
    }

    /* .course-list-counts {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0px 0px 11px 17px;
    } */

    .marketplace-shopping-cart-price {
        font-size: 18px !important;
    }
    /* .marketplace-shopping-cart-des-wrapper h2 {
        font-size: 14px;
        margin: 6px 0px 5px 0px;
        width: 100%;
    } */
      /* .marketplace-shopping-cart-des-wrapper .teacher-name {
        font-size: 12px;
        color: #0068FF;
      }
      .studentDashboar-Suggested-Courses-profile-rate {
        gap: 6px;
        margin: 7px 0px 10px 0px;
      } */
      .studentDashboar-Suggested-Courses-profile-rate p {
        font-size: 11px;
      }
      .course-list-counts {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 7px;
        margin: 0px 0px 11px 17px;
      }
      .marketplace-shopping-cart-price {
        font-size: 18px !important;
      }
      .marketplace-shopping-cart-des-wrapper {
        width: 100%;
    }
    .marketplace-shopping-cart-des-wrapper .delete {
        font-size: 14px;
    }
    .course-list-counts li {
        font-size: 13px;
        list-style: disc;
    }
    .marketplace-shopping-cart-des-wrapper h2 {
        margin: 6px 0px 10px 0px;
    }
    .marketplace-shopping-cart-section .title {
        font-size: 18px;
        margin: 0px 0px 0px 0px;
        /* margin: 26px 0px 0px 0px; */
    }
    .ratingCard-wrapper .studentDashboar-profile-course-title {
        font-size: 15px;
    }
    .ratingCard-wrapper .studentDashboar-profile-btn-folder button {
        font-size: 11px;
    }
    .marketplace_course {
        font-size: 17px;
        margin: 14px 0px 0px 0px;
    }
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 100% !important;
    }
    .marketplace-course-price-scroller button , 
    .marketplace-course-price-scroller p{
        font-size: 11px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px){
    .marketplace-shopping-cart-wrapper {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
    .marketplace-shopping-cart-left-main-wrapper {
        width: 56%;
    }
    .marketplace-shopping-cart-right-section {
        width: 50%;
    }
    .marketplace-shopping-cart-section .title {
        margin: 0px 0px 0px 0px;
    }
    .marketplace-course-price-scroller button, 
    .marketplace-course-price-scroller p  {
        font-size: 11px;
    }
    .marketplace-course-price-scroller-folder {
        height: 93px;
    }
    .marketplace-price h2 {
        font-size: 17px;
    }
}

@media (min-width: 1280px) and (max-width: 1299px){
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        width: 251px;
    }
    .studentDashboar-Suggested-Courses-container, .ratingCard-wrapper {
        display: flex;
        /* gap: 37px; */
        gap: 19px;
    }
}

@media (min-width: 1300px) and (max-width: 1399px){
    /* .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        width: 264px;
    } */
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 403px !important;
    }
}


@media (min-width: 1600px) and (max-width: 1699px){
    /* .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        width: 317px;
    } */
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 326px !important;
    }
    .ratingCard-wrapper {
        display: flex;
        gap: 16px;
    }
}

@media (min-width: 1800px) and (max-width: 1820px){
    .studentDashboar-Suggested-Courses-folder {
        /* width: 367px !important; */
        width: 323px !important;
    }
}

@media screen and (min-width: 1918px) and (max-width: 1921px) {
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 293px !important;
    }
    .ratingCard-wrapper {
        display: flex;
        gap: 14px;
    }
}

@media (min-width: 2700px) and (max-width: 2799px){
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 350px !important;
    }
    .ratingCard-wrapper {
        display: flex;
        gap: 15px;
    }
}

@media only screen and (min-resolution: 125dpi) {
    .ratingCard-wrapper .studentDashboar-Suggested-Courses-folder {
        min-width: 368px;
    }
}