.payment-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
}
.payment-left-section {
    width: 55%;
    background-color: #F4F4F4;
    padding: 30px 31px 50px 31px;
    border-radius: 20px;
}
.payment-left-des-container {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.payment-left-des-container p{
    font-size: 14px;
    color: black;
    width: 70%;
}
.payment-card-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 30%;
}
.payment-details-inner-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.payment-details-inner-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.payment-details-inner-input label{
    font-size: 15px;
    font-weight: 600;
}
.payment-details-inner-input input {
    padding: 10px 20px;
    border-radius: 43px;
    font-size: 13px;
    border: none;
    outline: none;
}
.payment-details-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
}
.payment-details-date-folder {
    display: flex;
    gap: 17px;
    width: 100%;
}
.payment-details-inner-date {
    width: 50%;
}
.payment-right-section{
    width: 45%;
}
.payment-right-section h2{
    font-size: 18px;
    margin: 0px 0px 18px 0px;
}
.payment-right-section p{
    font-size: 14px;
    font-weight: 500;
    color: black;
    border-bottom: 1px solid;
    padding-bottom: 17px;
    margin-bottom: 21px;
}
.payment-course-title span{
    color: #797979;
}
.payment-price-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.payment-price-folder{
    display: flex;
    width: 100%;
    border-bottom: 1px dashed black;
    padding-bottom: 17px;
    padding-bottom: 21px;
}
.payment-price-folder:nth-child(2){
    border-bottom: none;
}
.payment-price-folder span{
    width: 50%;
    font-size: 14px;
    color: #797979;
}
.Promo-code a{
    font-size: 13px;
}
.Proceed-to-Pay{
    width: 100%;
    padding: 9px 0px;
    font-size: 13px;
    background-color: #0068FF;
    color: white;
    border: none;
    outline: none;
    border-radius: 8px;
    margin: 30px 0px 20px 0px;
}
.payment-info-folder p{
    margin: 0px;
    padding: 0px;
    border-bottom: none;
}
.payment-info-folder p a{
    color: black;
}
.payment-info-folder{
    max-width: 400px;
}