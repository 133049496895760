.course-revenue-table table{
    width: 100%;
}
.course-revenue-table table thead{
    border-bottom: 1px solid #A1D3EF;
}
.course-revenue-table table thead th{
    font-size: 14px;
    padding: 30px;
    font-weight: 600;
}
.course-revenue-table table tbody tr {
    background-color: #F5FBFF;
    box-shadow: 0px 1px 9px #e6e6e6;
}
.course-revenue-table table tbody tr td{
    padding: 30px;
    font-weight: 600;
}
.course-revenue-table table tbody tr td:last-child{
    text-align: end;
}
.course-revenue-course-title, 
.course-revenue-students-enrolled{
    font-size: 12px;
    font-weight: 500;
    color: #0068FF;
    background-color: transparent;
    border: none;
}
.course-revenue-students-enrolled{
    font-size: 14px;
}
.course-revenue-date{
    font-size: 12px;
    font-weight: 500;
    color: black;
}
.course-revenue-view-course {
    font-size: 11px;
    background-color: transparent;
    border: none;
    text-align: left;
    color: #0068FF;
    border: 1px solid;
    padding: 5px 13px;
    border-radius: 6px;
    font-weight: 500;
}
.course-revenue-price{
    font-size: 14px;
    color: #018010;
    font-weight: 500;
}
.course-revenue-serach input {
    width: 100%;
    padding: 12px 57px;
    font-size: 13px;
    border-radius: 40px;
    box-shadow: 0px 1px 8px #e7e7e7;
    border: none;
    position: relative;
}
.course-revenue-serach {
    position: relative;
    margin: 30px 0px;
}
.course-revenue-serach span{
    position: absolute;
    top: 10px;
    left: 25px;
    font-size: 17px;
    color: #999999;
}
.course-revenue-header-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 100px;
}
.course-revenue-serach {
    position: relative;
    margin: 30px 0px;
    width: 50%;
}
.course-revenue-header-filter {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 18px;
    justify-content: flex-end;
}
.course-revenue-dropdown-menu {
    width: 19%;
}
.course-revenue-Sort {
    width: 167px;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    top: 42px;
    right: 0px;
    box-shadow: 0px 2px 5px #e7e7e7;
}
.course-revenue-Sort li {
    border-bottom: 1px solid #F2F2F2;
    padding: 11px 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.course-revenue-Sort li:last-child{
    border: none;
}
.course-revenue-Sort-dropdown {
    position: relative;
}
.course-revenue-downlode-folder {
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 7px #d5d5d5;
    cursor: pointer;
}
.course-revenue-downlode-folder i{
    font-size: 17px;
    border: 1px solid;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #0068FF;
    color: white;
}

@media only screen and (max-width: 768px){
    .course-revenue-header-filter {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 33px;
    }
    .course-revenue-dropdown-menu {
        width: 27%;
    }
}

@media only screen and (max-width: 820px){
    .course-revenue-dropdown-menu {
        width: 30%;
    }
    .course-revenue-table table tbody tr td:nth-child(1) {
        width: 25%;
    }
    .course-revenue-table table tbody tr td:nth-child(2) {
        width: 30%;
    }
    .course-revenue-table table tbody tr td:nth-child(3) {
        width: 5%;
    }
    .course-revenue-table table tbody tr td:nth-child(4) {
        width: 5%;
    }
    .course-revenue-table table tbody tr td:nth-child(5) {
        width: 15%;
    }
    .course-revenue-view-course {
        padding: 5px 8px;
    }
    .course-revenue-table table thead th {
        padding: 11px;
    }
}

@media only screen and (max-width: 1024px){
    .course-revenue-dropdown-menu {
        width: 31%;
    }
    .course-revenue-table table tbody tr td {
        /* padding: 12px; */
        padding: 7px;
    }
}

@media only screen and (max-width: 767px){
    .course-revenue-table table {
        min-width: 100%;
    }
    .course-revenue-table table tbody tr td {
        display: block;
    }
    .course-revenue-table table thead {
        display: none;
    }
    .course-revenue-table table tbody tr {
        margin-bottom: 10px;
    }
    .course-revenue-table table tbody tr td {
        position: relative;
        text-align: left;
        padding: 10px;
    }
    .course-revenue-table table tbody tr td:before {
        position: absolute;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-th) ": ";
        font-weight: bold;
        display: contents;
        font-size: 12px;
    }
    .course-revenue-table table tbody tr td:last-child {
        text-align: left;
    }
    /* .course-revenue-course-title {
        display: flex !important;
        align-items: center !important;
        gap: 6px !important;
    } */
    .course-revenue-header-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 18px;
        margin: 20px 0px 0px 0px;
    }
    .course-revenue-serach {
        margin: 0px 0px;
        width: 100%;
    }
    .course-revenue-header-filter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        justify-content: flex-end;
    }
    .course-revenue-dropdown-menu {
        width: 37%;
    }
    .course-revenue-date, 
    .course-revenue-price, 
    .course-revenue-view-course, 
    .course-revenue-course-title, 
    .course-revenue-students-enrolled {
        margin-left: 7px;
    }
    .course-revenue-students-enrolled {
        font-size: 13px;
    }

    .table td,
    .table th {
        /* Stack the table cells on top of each other in a block layout */
        display: block;
        width: 100%;
    }

  .table td::before,
  .table th::before {
    /* Add a pseudo-element with the data-th content */
    content: attr(data-th) ": ";
    font-weight: bold;
    /* Display as a block to put it above the cell content */
    display: block;
  }
  .table td,
  .table th {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .course-revenue-Sort {
    position: absolute;
    top: 42px;
    z-index: 2;
  }
  .course-revenue-table table tbody tr td:nth-child(1), 
  .course-revenue-table table tbody tr td:nth-child(2),
  .course-revenue-table table tbody tr td:nth-child(3),
  .course-revenue-table table tbody tr td:nth-child(4),
  .course-revenue-table table tbody tr td:nth-child(5) {
    width: 100%;
  }
  .course-revenue-course-title{
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    height: 18px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 122px;
    text-align: left;
  }
}

@media (min-width: 280px) and (max-width: 320px){
    .course-revenue-dropdown-menu {
        min-width: 50%;
    }
}