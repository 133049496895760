.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dashboardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 20px 47px 0px 47px;
    position: relative;
}
.dashboardHeaderText {
    color: black;
    font-size: 20px;
    font-weight: 500;
    word-wrap: break-word;
    margin: 0px;
}

.search-container {
    position: relative;
    width: 469px;
}

.search-input {
    width: 100%;
    padding: 10px;
    padding-left: 49px;
    font-size: 13px;
    background-color: rgba(242, 242, 242, 1);
    border: none;
    border-radius: 20px;
}

.search-icon {
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px solid;
    padding-right: 5px;
    color: #ABABAB;
}

.search-icon svg {
    width: 20px;
}

.dashboardSearch {
    display: flex;
    align-items: center;
    gap: 20px;
}

.dashboardHeaderProfile {
    display: flex;
    margin: 0px;
    align-items: center;
    /* gap: 20px; */
    gap: 36px;
    cursor: pointer;
}

.dashboardHeaderProfileAvatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
}

.dashboardHeaderProfileNameInitial {
    background-color: #EBEBEB;
    color: #000;
    font-size: 15px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.dashboardHeaderProfileText {
    color: black;
    font-size: 15px;
    font-weight: 400;
    word-wrap: break-word;
    margin: 0px;
}

.dashboardBody {
    display: flex;
    flex: 1;
    min-height: 100vh;
}


.dashboardSidePannel {
    width: 212px;
    /* margin-left: 40px; */
    background-color: #F5F9FF;
    color: white;
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 20px;
}

.dashboardSidePannel .dashboardlink.active {
    display: flex;
    gap: 10px;
    /* padding: 8px 20px; */
    padding: 8px 16px;
    border-radius: 36px;
    background-color: #CFE5FB;
    align-items: center;
    font-size: 15px;
    color: #0068FF !important;
}

.dashboardSidePannel .dashboardlink {
    display: flex;
    gap: 10px;
    /* padding: 10px 20px; */
    padding: 10px 16px;
    border-radius: 36px;
    background-color: #F5F9FF;
    border: none;
    align-items: center;
    font-size: 15px;
    color: grey;
}

.dashboardSidePanel ReactSVG .active {
    color: #1E86FF;
}

.dashboardSidePanelIcon ReactSVG {
    color: #9D9D9D;
}

.create-course {
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: 0px;
    background: #0068FF;
    border-radius: 4px;
    padding: 8px;
    gap: 10px;
}

.create-course-text {
    color: white;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    margin: 0px;
}

.dashboardMainBody {
    flex: 1;
    padding: 20px;
}

.form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
    z-index: 1000;
}

.dot-indicator {
    display: flex;
    justify-content: center;
    margin: 50px 0px 0px 0px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    margin: 0 5px;
    border-radius: 50%;
}

.dot.active {
    background-color: #0068FF;
}

.form-content {
    margin-bottom: 20px;
}

.svg-inactive {
    fill: grey !important;
    /* Override any existing fill */
}

.svg-active {
    fill: #0068FF !important;
    /* Override any existing fill */
}

.form-buttons {
    display: flex;
    justify-content: space-between;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.form-buttons {
    margin: 0px auto 25px auto;
    max-width: 300px;
}

.form-buttons button,
.dashboard-form-next-btn {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 13px;
}

.dashboard-form-next-btn {
    border: 1px solid;
    margin-left: auto;
    margin-right: 0px;
}

/* Define a loading spinner animation */
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Style the loading spinner */
.loading-spinner {
    border: 4px solid white;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: loading 1s linear infinite;
    /* Apply the animation */
    margin: 0 auto;
    /* Center the spinner */
    margin-top: 20px;
    /* Adjust as needed */
}

.success-message {
    color: green;
    font-weight: bold;
}

.error-message {
    color: red;
    font-weight: bold;
}

/* .textarea-default {
    border: 1px solid blue !important;
  } */

.textarea-error {
    border: 1px solid #d32f2f !important;
}

#Customize-your-AI-dropdown .Customize-your-AI-assistant-tone-dropdown-inner-folder .Customize-your-AI-assistant-tone-dropdown-select-option-container .Customize-your-AI-assistant-tone-dropdown-select-option {
    width: 89px;
    justify-content: flex-start;
}


/* Devesh css for chatbot */

.Studentchatboticon{
    position: fixed;
    bottom: 20px; 
    right: 20px;

}
.Studentchatboticon img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
}