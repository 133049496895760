button.react-responsive-modal-closeButton{
    border: 2px solid;
    border-radius: 15px;
}
.react-responsive-modal-closeButton svg{
    width: 18px;
    height: 18px;
}
.forget_modal_sec_new_body_sec{
    text-align: center;
}
.crop_modal_sec_new h6{
    font-weight: 500;
}
.crop_btn_sec{
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
}
.crop_btn_sec a{
    color: #000;
    font-size: 18px;
    /* margin-top: 13px; */
}
.crop_btn_sec a span{
    padding-left: 15px;
}
.crop_btn_sec a.save_btn svg{
    color: #0068ff;
}